import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { AIAnalystUsageButton } from 'assets';

export default makeStyles((theme: Theme) => ({
  mainComponentContent: {
    height: 'calc(100% - 70px)',
    padding: '0 31px 35px 30px',

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette.primary.light,
      width: '0.5em',
      height: '0.5em',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      // backgroundColor: theme.palette.primary.dark,
      backgroundColor: theme.shadows[18],
      minHeight: 24,
      // border: '1px solid #FF8010',
      // visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1120px)': {
      padding: '0 30px 30px',
    },
    '@media (max-width: 1024px)': {
      height: '100%',
      padding: '0px 5% 30px',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 50px)',
      padding: '0px 5% 20px',
    },
    '& .MuiTableContainer-root': {
      minHeight: 260,
    },
    '& .MuiTableCell-root': {
      padding: 8,
    },
    '& .MuiTableCell-head': {
      padding: '15px 8px',
    },
  },
  mainHeaderBox: {
    '@media (min-width: 1025px)': {
      position: 'relative',
    },
  },
  isDeactivatePlan: {
    '& .MuiDialogContent-root': {
      '@media (max-width: 525px)': {
        padding: 0,
      },
    },
  },
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (min-width: 1025px)': {
      position: 'absolute',
      top: -55,
    },
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 20,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 10,
    },
  },
  primaryHeaderText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 10,
  },
  headerText: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
  },
  viewTransaction: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 237,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 150,
      height: 40,
    },
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    // background: theme.palette.secondary.main,
    borderRadius: '6px',
    border: '1px solid' + theme.palette.primary.main,
    boxShadow: theme.shadows[4],
    padding: '16px 20px 16px 20px',
  },
  priceBox: {},
  subsTableContainer: {
    height: 'calc(100vh - 190px)',
    overflow: 'auto',
    background: theme.palette.primary.contrastText,
    borderRadius: 11,
    padding: '20px',
    position: 'relative',
    boxShadow: theme.shadows[7],
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 170px)',
    },
    '@media (max-height: 600px)': {
      height: 'calc(100vh - 160px)',
    },
  },
  actionButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  downloadIcon: {
    position: 'relative',
    top: 3,
    margin: '0 5px 0 5px',
    cursor: 'pointer',
  },
  deactivateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoIcon: {
    height: 70,
    width: 70,
  },
  rejectBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
  },
  deactivateBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 600,
    width: 160,
    height: 25,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  outlinedBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
    border: '1px solid ' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 143,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    marginLeft: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 123,
    },
  },
  deactivatePlanBtn: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 800,
    width: 150,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  deactivateText: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '30px',
    letterSpacing: 0.3,
    marginTop: 20,
  },
  deactivatedText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: 0.3,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    marginTop: 10,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
  },
  planName: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '25px',
    marginRight: 20,
  },
  billingDetailBox: {
    textAlign: 'right',
    // display: 'flex',
  },
  billingDetailBoxJoininPlan: {
    width: '34%',
  },
  planBillingCycleText: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '25px',
    marginRight: 5,
  },
  planAmt: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '25px',
    // marginRight: 20,
  },
  planAmtToPaid: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '25px',
    // marginRight: 20,
    // marginBottom: 5,
  },
  freeTrialText: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: '20px',
  },
  freeTrialPeriodText: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginTop: 15,
    lineHeight: '20px',
  },
  planCancelText: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: '20px',
  },
  planCancelStausText: {
    fontWeight: 700,
  },
  addCardDetailsBtn: {
    marginTop: 30,
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 193,
    height: 45,
    borderRadius: 5,
    margin: '10px 0px 10px 0px',
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  managePaymentBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 600,
    width: 120,
    height: 25,
    borderRadius: 6,
    margin: '10px 0px 10px 0px',
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  topPriceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  transactionHistoryText: {
    marginTop: 30,
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '25px',
  },
  cardNameInput: {
    padding: 10,
    paddingLeft: 0,
    border: 'none',
    marginBottom: 10,
    outline: 'none',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    color: '#32325d',
    fontSize: 17,
    '&::-webkit-input-placeholder': {
      color: '#aab7c4',
    },
  },
  cardInputWrapper: {
    padding: 30,
    height: 'calc(100% - 70px)',
    overflow: 'hidden auto',
  },
  cardContainer: {
    boxShadow: theme.shadows[8],
    border: '1px solid #979797',
    borderRadius: 5,
    cursor: 'pointer',
  },
  cardListBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gridGap: 23,
    marginBottom: 8,
  },
  cardNumberBox: {
    display: 'flex',
    padding: '0 10px 5px',
  },
  masterCardImg: {
    height: 18,
    marginRight: 10,
  },
  creditCardText: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
  },
  creditCardBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 10px 13px',
  },
  creditCardHolderNameText: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.text.primary,
  },
  validTillText: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '15px',
    color: theme.palette.grey[100],
    paddingRight: 5,
    display: 'flex',
    alignItems: 'center',
  },
  addCardBox: {
    border: '1px solid #FF8010',
    background: '#FFF8F3',
    cursor: 'pointer',
    height: 124,
    width: 170,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 30,
    color: 'grey',
    fontWeight: 200,
  },
  validDateText: {
    color: theme.palette.text.primary,
    marginLeft: 5,
    fontWeight: 400,
    fontSize: 14,
  },
  dialogText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      paddingTop: 10,
    },
  },
  reviewFundDialogText: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 767px)': {
      paddingTop: 10,
    },
  },
  backContinueBox: {
    paddingTop: 20,
    display: 'flex',
    paddingBottom: 15,
    '@media (max-width: 767px)': {
      paddingTop: 30,
    },
  },
  paymentText: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    letterSpacing: 0.3,
    marginTop: 20,
  },
  selectedCard: {
    border: `1px solid #FF8010`,
    background: '#FFF8F3',
    borderRadius: 4,
  },
  manageCardBox: {
    background: '#FFE5CF',
    // background: theme.palette.primary.dark,
    display: 'flex',
    padding: 20,
    // padding: '10px 20px',
    marginTop: 30,
  },
  manageCardDeactivateBox: {
    background: '#C5C5C5',
    display: 'flex',
    padding: 20,
    marginTop: 30,
  },
  cardInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  btnPrevious: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 160,
    height: 47,
    marginLeft: 5,
    borderRadius: 5,
    margin: '10px 0px 10px 0px',
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  cardBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  cardAction: {
    cursor: 'pointer',
    color: 'grey',
  },
  topContainerDeactivate: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    background: theme.palette.grey[50],
    borderRadius: '6px',
    padding: '16px 20px 22px 20px',
  },
  billingText: {
    fontWeight: 700,
  },
  unpaidMakePay: {
    textAlign: 'center',
    marginTop: 20,
  },
  cancelBtn: {},
  planPriceText: {
    width: '40%',
  },
  powerByStripeLogo: {
    width: 100,
    height: 'auto',
  },
  dialogActionClass: {
    justifyContent: 'center',
  },
  accordBox: {
    marginBottom: '20px !important',
    marginTop: 10,
    borderRadius: 6,
    padding: 2,
    border: '1px solid' + theme.palette.primary.main,
    filter: 'drop-shadow(0px 0px 14px rgba(255, 128, 16, 0.04))',
    '&::before': {
      display: 'none',
      position: 'static',
    },
    '& .MuiAccordionSummary-root': {
      padding: '10px 10px',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.primary.dark,
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      paddingLeft: 7,
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
  },
  subscriptionBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    margin: 10,
    padding: '15px 16px',
    width: 230,
    height: 45,
    borderRadius: 24,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 1024px)': {
      width: 300,
      height: 40,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  // TODO refactor css
  subscriptionComponentContainer: {
    height: '100vh',
    padding: '15px 30px',
    background: theme.palette.background.default,
    '@media (max-width: 1200px)': {
      padding: '5px 30px',
    },
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
    },
    '@media (max-width: 767px)': {
      minHeight: 600,
      padding: '0px',
    },
  },
  subscriptionComponentHeader: {
    display: 'flex',
    // marginBottom: 10,
    '@media (max-width: 767px)': {
      alignItems: 'center',
      background: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[6],
      padding: 15,
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
  },
  logoHeaderBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    paddingTop: 10,
    paddingRight: 15,
    '@media (max-width: 1024px)': {
      paddingTop: 0,
    },
  },
  logoImage: {
    width: 100,
    height: 35,
    '@media (max-width: 767px)': {
      height: 24,
      width: 73,
    },
  },
  logoUserWelcomeBox: {
    width: '60%',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  logoUserWelcomeText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '25px',
    letterSpacing: 0.3,
    padding: 5,
    '@media (max-width: 767px)': {
      fontSize: 20,
    },
    '@media (max-width: 525px)': {
      fontSize: 18,
    },
  },
  clipBox: {
    maxWidth: 350,
    margin: 'auto',
    backgroundColor: '#FFF1E4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    'clip-path': 'polygon(0 0, 100% 0, 98% 50%, 100% 100%, 0 100%, 2% 50%)',
    '@media (max-width: 767px)': {
      marginTop: 0,
    },
  },

  pageHeaderBox: {
    width: '30%',
    height: 65,
    '@media (max-width: 767px)': {
      width: 'calc(100% - 88px)',
    },
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 30,
    padding: '5px 0px 15px 0px',
    marginTop: 30,
    marginBottom: 10,
    minHeight: '88%',
    '@media (max-width: 1200px)': {
      minHeight: '85%',
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      gap: 10,
      alignItems: 'center',
      paddingTop: 15,
    },
  },
  contentContainerSource: {
    display: 'flex',
    justifyContent: 'center',
    gap: 30,
    padding: '5px 0px 15px 0px',
    minHeight: '90%',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      gap: 10,
      alignItems: 'center',
      paddingTop: 15,
    },
  },
  subscriptionVentureInsightTile: {
    position: 'relative',
    width: '460px',
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #DEDEDE',
    borderRadius: 15,
    padding: '20px 0px 20px 0',
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  subscriptionVentureInsightPlusTile: {
    position: 'relative',
    width: '500px',
    backgroundColor: '#E8F7FF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #DEDEDE',
    borderRadius: 15,
    padding: 10,
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  subscriptionPlanDetailBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '70%',
    justifyContent: 'space-around',
  },
  subscriptionPlanDetailSourceBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '60%',
    justifyContent: 'space-around',
  },
  subscriptionPlanNameText: {
    fontSize: 28,
    fontWeight: 900,
    lineHeight: 'normal',
    letterSpacing: '0.33px',
    marginBottom: 5,
    marginTop: 25,
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      fontSize: 20,
    },
  },
  subPlanNameText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'normal',
    color: theme.palette.text.secondary,
  },
  priceText: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.33px',
    margin: 5,
    textAlign: 'center',
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      fontSize: 32,
    },
  },
  billedTextBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
  },
  billedText: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    color: theme.palette.text.disabled,
    alignSelf: 'self-end',
    textDecoration: 'line-through',
  },
  billedMonthlyText: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    color: theme.palette.text.secondary,
    alignSelf: 'self-end',
    marginLeft: 3,
    marginBottom: 3,
  },
  demoDayBox: {
    position: 'relative',
    width: '400px',
    padding: 2,
    backgroundColor: '#DBFFF6',
    border: '3px solid' + theme.palette.primary.contrastText,
    boxShadow: theme.shadows[9],
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    '& .MuiButtonBase-root': {
      padding: '2px',
    },
    '& .MuiList-root': {
      paddingTop: '4px ',
      paddingBottom: '4px ',
      margin: '0px',
      pointerEvents: 'none',
    },
    '& .MuiListItemText-root': {
      marginTop: 2,
      marginBottom: 2,
    },
    '& .MuiListItemText-primary': {
      fontSize: '13.5px',
      fontWeight: 600,
      lineHeight: 'normal',
      color: theme.palette.text.secondary,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 25,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  demoDayPlusBox: {
    position: 'relative',
    width: '400px',
    padding: 2,
    backgroundColor: '#DBFFF6',
    border: '3px solid #95F4DC',
    boxShadow: theme.shadows[9],
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    '& .MuiButtonBase-root': {
      padding: '2px',
    },
    '& .MuiList-root': {
      paddingTop: '4px ',
      paddingBottom: '4px ',
      margin: '0px',
      pointerEvents: 'none',
    },
    '& .MuiListItemText-root': {
      marginTop: 2,
      marginBottom: 2,
    },
    '& .MuiListItemText-primary': {
      fontSize: '13.5px',
      fontWeight: 600,
      lineHeight: 'normal',
      color: theme.palette.text.secondary,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 25,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  demoDayText: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    color: theme.palette.text.secondary,
    margin: '5px 10px 0px',
  },
  ribbon: {
    position: 'absolute',
    right: -13,
    top: 0,
    width: 37,
    height: 14,
    fontSize: 9,
    textAlign: 'center',
    transform: 'rotate(42deg)',
    //'-webkit-transform': 'perspective(201px) rotateX(41deg)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  checkIcon: {
    fontSize: 18,
    fontWeight: 600,
    stroke: '#323232',
  },
  freeTrialPlusBtn: {
    backgroundColor: '#228FCA',
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    marginTop: 10,
    padding: '15px 16px',
    width: 350,
    height: 60,
    borderRadius: 30,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#228FCA',
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 1024px)': {
      width: 300,
      height: 40,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  freeTrialBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    marginTop: 10,
    padding: '15px 16px',
    width: 350,
    height: 60,
    borderRadius: 30,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 1024px)': {
      width: 300,
      height: 40,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  demoDayVentureBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    marginTop: 10,
    padding: '15px 16px',
    width: 350,
    height: 60,
    borderRadius: 30,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      border: '1px solid' + theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      width: 300,
      height: 40,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  demoDayVenturePlusBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: '#228FCA',
    border: '1px solid #228FCA',
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    marginTop: 10,
    padding: '15px 16px',
    width: 350,
    height: 60,
    borderRadius: 30,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: '#228FCA',
      border: '1px solid #228FCA',
    },
    '@media (max-width: 1024px)': {
      width: 300,
      height: 40,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  subscriptionPlanAIBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '30%',
  },
  aiPoweredText: {
    width: '80%',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 700,
    marginTop: 16,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    '@media (max-width: 1024px)': {
      fontSize: 16,
    },
  },
  listBox: {
    // width: '285px',
    padding: 2,
    margin: 5,
    marginBottom: 30,
    '& .MuiButtonBase-root': {
      padding: '2px',
    },
    '& .MuiList-root': {
      paddingTop: '0px ',
      margin: '0px',
      pointerEvents: 'none',
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiListItemText-primary': {
      fontSize: '13.5px',
      fontWeight: 600,
      lineHeight: 'normal',
      color: theme.palette.text.secondary,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 25,
    },
  },
  listBoxSource: {
    // width: '285px',
    padding: 2,
    margin: 5,
    marginBottom: 30,
    '& .MuiButtonBase-root': {
      // padding: '2px',
    },
    '& .MuiList-root': {
      paddingTop: '0px ',
      margin: '0px',
      pointerEvents: 'none',
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiListItemText-primary': {
      fontSize: '13.5px',
      fontWeight: 600,
      lineHeight: 'normal',
      color: theme.palette.text.secondary,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 25,
    },
  },
  noteText: {
    textAlign: 'center',
    fontSize: 9,
    fontWeight: 700,
    lineHeight: 'normal',
    color: theme.palette.text.secondary,
    padding: '10px 15px',
    position: 'absolute',
    bottom: 0,
    '@media (max-width: 767px)': {
      position: 'relative',
    },
  },
  everyText: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 'normal',
    marginBottom: 2,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  subscriptionNotFoundText: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    margin: 10,
    textAlign: 'center',
    height: 'calc(100% - 200px)',
  },
  nocredCard: {
    marginTop: 5,
    marginBottom: 5,
  },
  creditedText: {
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: '#E4E8FF',
    marginBottom: 5,
    padding: '3px 15px',
    borderRadius: 9,
    '@media (max-width: 1024px)': {
      padding: '3px 10px',
    },
    '@media (max-width: 768px)': {
      fontSize: 12,
      padding: '3px 10px',
    },
    '@media (max-width: 520px)': {
      fontSize: 10,
      padding: '3px 10px',
    },
  },
  creditBox: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      margin: '0px 20px 0px 20px',
    },
  },
  joiningPlanDesc: {
    display: 'flex',
    alignItems: 'center',

    borderRadius: '6px',
    border: '1px solid' + theme.palette.primary.main,
    boxShadow: theme.shadows[4],
    padding: '16px 20px 16px 20px',
  },
  joiningPlanDescTest: {
    width: '66%',
    fontSize: 20,
    fontWeight: 600,
  },
  dialogAttentionText: {
    fontSize: 20,
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: '30px',
    letterSpacing: 0.3,
    textAlign: 'center',
  },
  confirmProceedBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    marginTop: 30,
    padding: '15px 16px',
    width: 280,
    height: 60,
    borderRadius: 10,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 1024px)': {
      width: 300,
      height: 40,
      fontSize: 14,
    },
    '@media (max-width: 767px)': {
      width: '80%',
    },
  },
  newsText: {
    marginTop: 20,
    width: 600,
    fontSize: 16,
  },
  upgradeToBox: {
    backgroundColor: '#42a548',
    position: 'absolute',
    top: -1,
    left: -1,
    color: 'white',
    padding: 3,
  },
  upgradeToText: {
    fontSize: 14,
  },
  dialogBody: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 600,
  },
  investorProfileBackdrop: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(180deg, #FFF 0%, #FFF 0.01%, rgba(255, 255, 255, 0.00) 100%)`,
    filter: 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.18))',
    '&::before': {
      content:
        '"Build your firm profile and match with startups based on your interests"',
      position: 'absolute',
      top: 10,
      fontSize: 26,
      fontWeight: 700,
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      letterSpacing: '0.3px',
    },
  },
  // Ai Analyst
  aiAnalystBtn: {
    backgroundImage: `url(${AIAnalystUsageButton})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 191,
    height: 37,
    paddingBottom: 5,
    cursor: 'pointer',
  },
  accordAiAnalystBox: {
    marginBottom: '20px !important',
    marginTop: 10,
    borderRadius: 6,
    padding: 2,
    background:
      'linear-gradient(#fff, #fff) padding-box, linear-gradient(97deg, #D1CC53 0%, #7C24C0 49%, #20ABBE 100%) border-box',
    border: '1px solid transparent',
    '&::before': {
      display: 'none',
      position: 'static',
    },
    '& .MuiAccordionSummary-root': {
      padding: '10px 10px',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.primary.contrastText,
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      paddingLeft: 7,
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
  },
  cardNumber: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '20px',
  },
  totalOustandingAmtText: {
    background: '#FFE5EC',
    fontSize: 18,
    fontWeight: 700,
    padding: 10,
  },
}));
