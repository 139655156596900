import React from 'react';
import { get, orderBy, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Chip } from '@mui/material';

import {
  WrappedTypography,
  Box,
  CustomizedTable,
  WrappedSelect,
} from 'components';
import { formatAmount, getDealActionListV2 } from 'common/utils/helpers';
import { FN } from 'common/types';
import { RootState } from 'redux-modules/Store/RootState';
import { DEAL_STATUS_TYPE, ROLE } from 'common/utils/constants';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import { CreateProspectiveDeal, DeleteDeal } from '../DialogBox';

type props = {
  isLoading: boolean;
  errorMessage: string;
  allVcDeals: Array<Record<string, FN>>;
  getAllVcDeals: any;
  userRoles: Array<string>;
  vcFirm: any;
  permissionInfo: any;
  vcFirmCurrency: string;
  totalfilteredSearchDataCount?: number;
};

const RejectedDeals = ({
  isLoading,
  errorMessage,
  allVcDeals,
  getAllVcDeals,
  userRoles,
  vcFirm,
  permissionInfo,
  vcFirmCurrency,
  totalfilteredSearchDataCount = 0,
}: props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { fundList } = useSelector(({ Funds }: RootState) => Funds);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const disableAsPerPermission = permissionInfo.DEAL_PIPELINE === 'view';
  const { valid } = subscription;

  const [modal, setModal] = React.useState<boolean>(false);
  const [selectedDeal, setSelectedDeal] = React.useState<any>('');
  const [dealData, setDealData] = React.useState<Record<string, any>>({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] =
    React.useState<boolean>(false);
  const [page, setPage] = React.useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(5);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headerText = [
    { name: 'Sr. No', key: 'srNo', textAlign: 'center' },
    {
      name: 'Fund Name',
      key: 'fundTitle',
      renderCell: (rowData: any) => (
        <p
          style={
            !isEmpty(get(rowData, 'fundTitle'))
              ? {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  margin: 0,
                }
              : {}
          }
          onClick={() => {
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            if (!isEmpty(get(rowData, 'fundTitle'))) {
              setSelectedDeal(get(rowData, 'id'));
              setModal(true);
            }
          }}
        >
          {get(rowData, 'fundTitle', '-')}
        </p>
      ),
      sortable: true,
      sortDataIndex: 'fundTitle',
    },
    {
      name: 'Company Name',
      key: 'companyName',
      renderCell: (rowData: any) => (
        <p
          style={
            !isEmpty(get(rowData, 'companyName'))
              ? {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  margin: 0,
                }
              : {}
          }
          onClick={() => {
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            }
            setSelectedDeal(get(rowData, 'id'));
            setModal(true);
          }}
        >
          {isEmpty(get(rowData, 'companyName'))
            ? '-'
            : get(rowData, 'companyName')}
          {!isEmpty(get(rowData, 'ycBatch')) && (
            <Chip
              className={classes.ycBatchTagText}
              label={get(rowData, 'ycBatch')}
            />
          )}
        </p>
      ),
      sortable: true,
      sortDataIndex: 'companyName',
    },
    {
      name: 'Amount Proposed',
      key: 'proposedAmount',
      renderCell: (_: any, value: any) =>
        value
          ? formatAmount(value || 0, {
              currency: vcFirmCurrency,
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'proposedAmount',
      numericSort: true,
    },
    {
      name: 'Proposed Valuation',
      key: 'proposedValuation',
      renderCell: (_: any, value: any) =>
        value
          ? formatAmount(value || 0, {
              currency: vcFirmCurrency,
            })
          : '-',
      textAlign: 'right',
      sortable: true,
      sortDataIndex: 'proposedValuation',
      numericSort: true,
    },
    { name: 'Remark', key: 'rejectedReason' },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionSelect(rowData),
    },
  ];

  const summaryAmount = [
    { key: 'total', renderCell: () => 'Total', textAlign: 'left' },
    {
      key: 'fundTitle',
    },
    { key: 'companyName' },
    {
      key: 'proposedAmount',
      renderCell: () =>
        formatAmount(
          allVcDeals.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + parseInt(get(currentValue, 'proposedAmount') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    {
      key: 'proposedValuation',
      renderCell: () =>
        formatAmount(
          allVcDeals.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator +
              parseInt(get(currentValue, 'proposedValuation') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'right',
    },
    { key: 'rejectedReason' },
    { key: '#' },
  ];

  if (userRoles.includes(ROLE.ANGEL_INVESTOR)) {
    headerText.splice(1, 1);
    summaryAmount.splice(1, 1);
  }

  const actionSelect = (rowData: any) => {
    return (
      <Box>
        {get(vcFirm, 'activated') ? (
          <WrappedSelect
            options={getDealActionListV2(
              rowData,
              DEAL_STATUS_TYPE.REJECTED,
              userRoles,
            )}
            placeholder="Action"
            variant={'standard'}
            value={''}
            className={classes.statusSelectFeild}
            onChange={(event: any) => {
              handleActionChange(event, rowData);
            }}
            disabled={disableAsPerPermission}
          />
        ) : (
          ''
        )}
      </Box>
    );
  };

  const handleActionChange = (event: any, rowData: any) => {
    const value = get(event, 'target.value');
    if (value === 'updateDeal') {
      setSelectedDeal(get(rowData, 'id'));
      setModal(true);
    } else if (value === 'delete') {
      setDealData({ ...rowData });
      setIsOpenDeleteModal(true);
    }
  };
  React.useEffect(() => {
    getAllVcDeals(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  return (
    <>
      {permissionInfo.DEAL_PIPELINE.toLowerCase() !== 'none' && (
        <Box className={classes.mainComponentContent}>
          {errorMessage && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage}
            </WrappedTypography>
          )}
          <Box className={classes.dealsTableBox}>
            <CustomizedTable
              columns={headerText}
              rows={orderBy(
                allVcDeals,
                [(obj: any) => obj.statusUpdatedAt],
                ['desc'],
              )}
              isLoading={isLoading}
              footerRow={summaryAmount}
              showPagination={false}
              totalRows={totalfilteredSearchDataCount}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      )}
      {modal && (
        <CreateProspectiveDeal
          modal={modal}
          setModal={setModal}
          fundList={fundList}
          selectedDeal={selectedDeal}
          getAllVcDeals={getAllVcDeals}
          closeDialog={() => {
            setSelectedDeal('');
          }}
          userRoles={userRoles}
          title={`Update Rejected Deal`}
          disableAsPerPermission={disableAsPerPermission}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteDeal
          isOpenDeleteModal={isOpenDeleteModal}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          dealData={dealData}
          refreshDeals={getAllVcDeals}
          closeDialog={() => {
            setDealData({});
          }}
          title={'Delete Rejected Deal'}
        />
      )}
    </>
  );
};

export default RejectedDeals;
