import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import {
  ANGEL_INVESTOR_FUND,
  DEAL_STATUS_TYPE,
  PUSHER_EVENT_NAME,
  ROLE,
} from 'common/utils/constants';
import { RootState } from 'redux-modules/Store/RootState';
import { getAllVcDealsByVcFirmId } from 'services';
import { errorMessageHandler, getUserRole } from 'common/utils/helpers';
import { FN } from 'common/types';
import { AIPoweredIcon } from 'assets';
import { WrappedTypography, Box, CustomizedTooltip } from 'components';
import history from 'common/utils/history';

import {
  ProspectiveDeals,
  ActiveDeals,
  RejectedDeals,
  ExitedDeals,
} from './components';
import styles from './styles';

type DealContentProps = {
  isLoading: boolean;
  errorMessage: string;
  fundList: Array<Record<string, FN>>;
  allVcDeals: Array<Record<string, FN>>;
  getAllVcDeals: () => void;
  filteredSearchData: Array<Record<string, FN>>;
  totalfilteredSearchDataCount: number;
  setFilteredSearchData: React.Dispatch<React.SetStateAction<FN>>;
  userRoles: Array<string>;
  vcFirm: any;
  permissionInfo: any;
  vcFirmCurrency: string;
  vcFirmCurrencySymbol: any;
  investorId: string;
  isLoadingFundList?: boolean;
  vcFirmUsers: FN;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  handleDivClick: any;
};

const getDealContent = (dealType: string | null, props: DealContentProps) => {
  switch (dealType) {
    case 'active':
      return <ActiveDeals {...props} />;
    case 'prospective':
      return <ProspectiveDeals {...props} />;
    case 'exited':
      return <ExitedDeals {...props} />;
    case 'rejected':
      return <RejectedDeals {...props} />;
  }
};

// TODO: Refactor code for common component
const AllDeals = ({ handleDivClick }: any) => {
  const classes = styles();
  const [searchParams] = useSearchParams();
  const dealType = searchParams.get('type');

  const { fundList, isLoading: isLoadingFundList } = useSelector(
    ({ Funds }: RootState) => Funds,
  );
  const {
    vcFirm,
    vcFirmUsers,
    vcFirmCurrency,
    vcFirmCurrencySymbol,
    workspace,
  } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const { channel, pusherInitialized } = useSelector(
    ({ Global }: RootState) => Global,
  );
  const {
    user: { role, investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const userRoles = getUserRole(role);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [allVcDeals, setAllVcDeals] = React.useState<any>([]);
  const [totalfilteredSearchDataCount, setTotalfilteredSearchDataCount] =
    React.useState<number>(0);
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);

  const DealsTabMenu = [
    {
      name: 'Prospective Deals',
      path: 'prospective',
      key: ['prospective'],
      description: '',
      renderPageHeader: () => (
        <WrappedTypography className={classes.mainHeaderText}>
          <img
            src={AIPoweredIcon}
            alt={AIPoweredIcon}
            className={classes.aiPoweredIcon}
          />
          Deal Pipeline Management
        </WrappedTypography>
      ),
    },
    {
      name: 'Portfolio',
      path: 'active',
      key: ['active'],
      description: '',
      renderPageHeader: () => (
        <WrappedTypography className={classes.mainHeaderText}>
          <img
            src={AIPoweredIcon}
            alt={AIPoweredIcon}
            className={classes.aiPoweredIcon}
          />
          Portfolio Companies
        </WrappedTypography>
      ),
    },
    {
      name: 'Realised Deals',
      path: 'exited',
      key: ['exited'],
      description:
        'Please add the deal first in Portfolio and then move it to Realised deals.',
      renderPageHeader: () => (
        <WrappedTypography className={classes.mainHeaderText}>
          Realised Deals
          <span className={classes.dealsHeadNote}>
            Please add the deal first in Portfolio and then move it to Realised
            deals.
          </span>
        </WrappedTypography>
      ),
    },
    {
      name: 'Rejected Deals',
      path: 'rejected',
      key: ['rejected'],
      description:
        'Please add the deal first in prospective deal and then move it to Rejected deals.',
      renderPageHeader: () => (
        <WrappedTypography className={classes.mainHeaderText}>
          Rejected Deals
          <span className={classes.dealsHeadNote}>
            Please add the deal first in prospective deal and then move it to
            Rejected deals.
          </span>
        </WrappedTypography>
      ),
    },
  ];

  const dealStatus = (): string => {
    return dealType === 'active'
      ? DEAL_STATUS_TYPE.ACTIVE
      : dealType === 'prospective'
      ? DEAL_STATUS_TYPE.PROSPECTIVE
      : dealType === 'exited'
      ? DEAL_STATUS_TYPE.EXITED
      : DEAL_STATUS_TYPE.REJECTED;
  };

  const getAllVcDeals = (
    page = 1,
    take = Number.MAX_SAFE_INTEGER,
    loading = true,
    queryString = '',
    filterByStage?: string,
    filterByAssignedUser?: string[],
  ) => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      loading && setIsLoading(true);
      getAllVcDealsByVcFirmId(
        get(vcFirm, 'id'),
        dealStatus(),
        page,
        take,
        `${queryString}`,
      )
        .then((response: any) => {
          setTotalfilteredSearchDataCount(response.totalCount);
          let res = response.deals;
          res = res.reduce((acc: any, cur: any) => {
            cur.assignedToUser = !isEmpty(cur.assignedTo)
              ? get(
                  get(vcFirmUsers, 'userList').find(
                    (Item: any) => Item.id === cur.assignedTo,
                  ),
                  'name',
                )
              : null;
            acc.push(cur);
            return acc;
          }, []);
          // const invitedDeal = res.filter(
          //   (item: any) => !isEmpty(item.inviteId) && isEmpty(item.s3Key),
          // );
          // const manuallyCreatedDeal = res.filter(
          //   (item: any) => isEmpty(item.inviteId) && isEmpty(item.s3Key),
          // );
          // const dealCreatedFromEmailReader = res.filter(
          //   (item: any) => !isEmpty(item.s3Key),
          // );
          // if (!isEmpty(dealCreatedFromEmailReader)) {
          //   dealCreatedFromEmailReader.map((item: any) => {
          //     if (
          //       !isEmpty(item.sector) ||
          //       !isEmpty(item.proposedAmount) ||
          //       !isEmpty(item.lastFundingRound) ||
          //       !isEmpty(item.geography)
          //     ) {
          //       manuallyCreatedDeal.push(item);
          //     } else {
          //       invitedDeal.push(item);
          //     }
          //   });
          // }
          // const newArray = [
          //   ...orderBy(manuallyCreatedDeal, ['createdAt'], ['desc']),
          //   ...orderBy(invitedDeal, ['createdAt'], ['desc']),
          // ];
          // const newArray = loading
          //   ? orderBy(res, [(obj: any) => obj.createdAt], ['desc'])
          //   : orderBy(
          //       [...allVcDeals, ...res],
          //       [(obj: any) => obj.createdAt],
          //       ['desc'],
          //     );
          const newArray = loading ? res : [...allVcDeals, ...res];
          setAllVcDeals(newArray);
          if (
            (!isEmpty(filterByStage) && filterByStage !== 'allDeal') ||
            !isEmpty(filterByAssignedUser)
          ) {
            const filteredData = newArray.filter(
              (item: any) =>
                (!isEmpty(filterByStage)
                  ? item.dealStage === filterByStage
                  : 1 == 1) &&
                (!isEmpty(filterByAssignedUser)
                  ? (filterByAssignedUser || []).includes(item.assignedTo)
                  : 1 == 1),
            );
            setFilteredSearchData(filteredData);
          } else setFilteredSearchData(newArray);

          setIsLoading(false);
        })
        .catch((err) => {
          const message = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  };

  // useEffect(() => {
  //   if (
  //     !isEmpty(dealType) &&
  //     !isEmpty(get(vcFirm, 'id')) &&
  //     isEmpty(allVcDeals)
  //   )
  //     getAllVcDeals();
  // }, [dealType, vcFirm]);

  useEffect(() => {
    if (pusherInitialized && channel) {
      channel?.bind(PUSHER_EVENT_NAME.DEAL_CREATED_UPDATED, () => {
        getAllVcDeals();
      });
      channel?.bind(PUSHER_EVENT_NAME.DEAL_STATUS_UPDATED, () => {
        getAllVcDeals(1, Number.MAX_SAFE_INTEGER, false);
      });
    }
  }, [pusherInitialized, channel]);

  return (
    <Box className={classes.mainComponentContainer}>
      <Box className={classes.mainComponentHeader}>
        <Box className={classes.mainHeaderBox}>
          {/* {DealsTabMenu.find(
            (item: any) => item.path === dealType,
          )?.renderPageHeader()} */}

          <Box style={{ display: 'flex', gap: 20 }}>
            {DealsTabMenu.map((item: any) => {
              if (
                (permissionInfo.DEAL_PIPELINE.toLowerCase() === 'none' &&
                  (item.path === 'prospective' || item.path === 'rejected')) ||
                (permissionInfo.PORTFOLIO.toLowerCase() == 'none' &&
                  (item.path === 'active' || item.path === 'exited'))
              ) {
                return <></>;
              }
              return (
                <CustomizedTooltip
                  key={item.key}
                  title={item.description || ''}
                  placement="top"
                  className={classes.customTagTooltip}
                  arrow={true}
                >
                  <span>
                    <Box
                      className={
                        item.path === dealType
                          ? `${classes.dealTypeTab} ${classes.dealTypeTabActive}`
                          : classes.dealTypeTab
                      }
                      onClick={() =>
                        history.push({
                          pathname: '/deals',
                          search: `?type=${item.path}`,
                        })
                      }
                    >
                      <WrappedTypography className={classes.dealText}>
                        {item.name}
                      </WrappedTypography>
                    </Box>
                  </span>
                </CustomizedTooltip>
              );
            })}
          </Box>
        </Box>
      </Box>
      {getDealContent(dealType, {
        isLoading,
        errorMessage,
        fundList: userRoles.includes(ROLE.ANGEL_INVESTOR)
          ? fundList.filter((fund: any) => fund.text === ANGEL_INVESTOR_FUND)
          : fundList,
        allVcDeals,
        getAllVcDeals,
        totalfilteredSearchDataCount,
        filteredSearchData,
        setFilteredSearchData,
        userRoles,
        vcFirm,
        permissionInfo,
        vcFirmCurrency,
        vcFirmCurrencySymbol,
        investorId,
        isLoadingFundList,
        vcFirmUsers,
        setErrorMessage,
        handleDivClick,
      })}
    </Box>
  );
};

export default AllDeals;
