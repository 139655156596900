import React from 'react';
import {
  Box,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, get } from 'lodash';

import { getVcDeal } from 'services';
import {
  Button,
  CustomizedTooltip,
  Loader,
  WrappedTypography,
} from 'components';
import { AIGradient, DragIndicatorGreyIcon, ProspectiveOnboard } from 'assets';
import { RootState } from 'redux-modules/Store/RootState';
import history from 'common/utils/history';
import { getVcFirmDashboardTileSummary } from 'redux-modules/Dashboard/Actions';
import { INV_NOTE_INSUFFICIENT_DATA_ERROR } from 'common/utils/constants';

import AiAnalystContent from '../../../Deals/ProspectiveDeals/components/AiAnalystContent';
import ProspectiveDealCharts from './ProspectiveDealCharts';
import styles from './styles';

const ProspectiveDealSummary = ({ vcFirm, investorId, provided }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    prospectiveDealTile: {
      isLoading,
      errorMessage,
      data: prospectiveDealsSummary,
    },
  } = useSelector(({ Dashboard }: RootState) => Dashboard);

  const [openInvestmentNote, setOpenInvestmentNote] =
    React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [investmentNoteDeal, setInvestmentNoteDeal] = React.useState<any>({});

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openInvestmentNoteDialog = (deal: any) => {
    getVcDeal(get(deal, 'id')).then((res: any) => {
      setInvestmentNoteDeal(res);
      setOpenInvestmentNote(true);
      // getDealJob(get(deal, 'id')).then((res: any) => {
      //   const documentsArray = get(res, 'deal.aiAnalyst.DOCUMENTS', []);
      //   if (documentsArray?.length > 0) {
      //     setGenerateNoteModal(true);
      //   }
      // });
    });
  };

  const getAiStatus = (deal: any) => {
    return (
      <>
        <Box className={classes.dealStatusBox}>
          {get(deal, `viewOutputs`) && (
            <Box
              className={classes.investNoteBtnContainer}
              onClick={() => openInvestmentNoteDialog(deal)}
            >
              <Button
                className={classes.investNoteBtn}
                name="Investment Note"
              />
            </Box>
          )}
          {!get(deal, `dataNeeded`) && !get(deal, `viewOutputs`) && (
            <CustomizedTooltip
              title={
                get(deal, `errMessage`)
                  ? INV_NOTE_INSUFFICIENT_DATA_ERROR[
                      get(deal, `errMessage`, '')
                    ]
                  : ''
              }
              arrow={true}
              placement="top"
              className={classes.customTagTooltip}
            >
              <span>
                <Button
                  type="button"
                  className={classes.investNoteBtn}
                  name="Investment Note"
                  disabled={!get(deal, 'aiAnalyst.INVESTMENT_NOTE')}
                  onClick={() =>
                    history.push(
                      `/deals?type=prospective&id=${get(deal, 'id')}`,
                    )
                  }
                />
              </span>
            </CustomizedTooltip>
          )}
          {get(deal, `dataNeeded`) && !get(deal, `viewOutputs`) && (
            <Box
              className={classes.dataNeeded}
              onClick={() =>
                history.push(
                  `/deals?type=prospective&id=${get(
                    deal,
                    'id',
                  )}&expand=Documents`,
                )
              }
            >
              Upload Pitch Deck
            </Box>
          )}
        </Box>
      </>
    );
  };

  React.useEffect(() => {
    if (!isEmpty(investorId)) {
      dispatch(
        getVcFirmDashboardTileSummary({
          investorId: investorId,
          tileName: 'prospectiveDeal',
        }),
      );
    }
  }, [investorId]);

  return (
    <>
      {(!isLoading && isEmpty(prospectiveDealsSummary)) ||
      get(prospectiveDealsSummary, 'totalCount') <= 0 ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 20,
            display: 'inline-flex',
            marginBottom: 42,
          }}
        >
          <div
            style={{
              alignSelf: 'stretch',
              height: 308,
              border: '1px rgba(166, 145, 113, 0.20) solid',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              display: 'flex',
            }}
          >
            <div
              style={{
                alignSelf: 'stretch',
                height: 60,
                padding: 15,
                background: '#F7F5F2',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 15,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  flex: '1 1 0',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 3,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    alignSelf: 'stretch',
                    color: '#1E1919',
                    fontSize: 20,
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  Prospective deals
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: 'stretch',
                paddingLeft: 35,
                paddingRight: 35,
                paddingTop: 32,
                paddingBottom: 32,
                justifyContent: 'center',
                alignItems: 'center',
                gap: 72,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  flex: '1 1 0',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 24,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    alignSelf: 'stretch',
                    color: '#1A1918',
                    fontSize: 22,
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  Pipeline Overview
                </div>
                <div
                  style={{
                    alignSelf: 'stretch',
                    color: '#323232',
                    fontSize: 14,
                    fontFamily: 'Mulish',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                  }}
                >
                  Manage your entire deal funnel, including inbounds from
                  founders.{' '}
                </div>
                <div
                  style={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 14,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      width: 322,
                      background: '#FDF2EC',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      display: 'inline-flex',
                    }}
                  >
                    <div
                      style={{
                        width: 322,
                        height: 62,
                        background: '#FDF2EC',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          alignSelf: 'stretch',
                          flex: '1 1 0',
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingLeft: 16,
                          paddingRight: 24,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 16,
                          display: 'inline-flex',
                        }}
                      >
                        <div
                          style={{
                            flex: '1 1 0',
                            alignSelf: 'stretch',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            display: 'inline-flex',
                          }}
                        >
                          <div
                            style={{
                              alignSelf: 'stretch',
                              color: '#49454F',
                              fontSize: 12,
                              fontWeight: '500',
                              letterSpacing: 0.5,
                              wordWrap: 'break-word',
                            }}
                          >
                            Forward prospective deals to:
                          </div>
                          <div
                            style={{
                              alignSelf: 'stretch',
                              color: '#1D1B20',
                              fontSize: 14,
                              fontFamily: 'Mulish',
                              fontWeight: '700',
                              letterSpacing: 0.5,
                              wordWrap: 'break-word',
                            }}
                          >
                            {get(vcFirm, 'emailReader')}{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    style={{
                      height: 40,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 8,
                      paddingBottom: 8,
                      borderRadius: 4,
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: 4,
                      display: 'flex',
                      color: '#FF8010',
                      fontSize: 16,
                      fontWeight: '600',
                      wordWrap: 'break-word',
                      textTransform: 'capitalize',
                    }}
                    name="Add manually"
                    onClick={(e) => handleMenuClick(e)}
                  >
                    Add manually
                  </Button>
                </div>
              </div>
              <div
                style={{
                  flex: '1 1 0',
                  paddingTop: 10,
                  paddingBottom: 10,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                  display: 'inline-flex',
                  boxShadow: 'rgba(30, 25, 25, 0.5) 0px 1px 40px',
                }}
              >
                <img
                  style={{
                    width: 536,
                    height: 164,
                  }}
                  src={ProspectiveOnboard}
                  alt="https://via.placeholder.com/536x164"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Box className={classes.mainContainer}>
            <Box className={classes.headerBox}>
              <WrappedTypography
                className={classes.prospectiveDealText}
                onClick={() => history.push('/deals?type=prospective')}
              >
                <img
                  src={DragIndicatorGreyIcon}
                  {...provided.dragHandleProps}
                />
                Prospective Deals
                <img src={AIGradient} />
              </WrappedTypography>
              <WrappedTypography className={classes.totalProspectiveDealText}>
                Total Prospective Deals :{' '}
                <span
                  style={
                    isEmpty(prospectiveDealsSummary)
                      ? { filter: 'blur(3px)' }
                      : {}
                  }
                >
                  {get(prospectiveDealsSummary, 'totalCount')}
                </span>
              </WrappedTypography>
            </Box>
            {errorMessage && (
              <WrappedTypography className={classes.errorMessage}>
                {errorMessage}
              </WrappedTypography>
            )}
            {isLoading ? (
              <Box className={classes.loader}>
                <Loader />
              </Box>
            ) : (
              <Box
                style={
                  isEmpty(prospectiveDealsSummary) ||
                  get(prospectiveDealsSummary, 'totalCount') <= 0
                    ? { position: 'relative' }
                    : {}
                }
              >
                <Box className={classes.tableAndChartContainer}>
                  <Box className={classes.leftTableContainer}>
                    <Box className={classes.tableHeaderBox}>
                      <WrappedTypography className={classes.recentlyAddedText}>
                        Recently Added Companies (
                        {get(
                          prospectiveDealsSummary,
                          'latestProspectiveDeals.length',
                          0,
                        )}
                        )
                      </WrappedTypography>
                      <WrappedTypography
                        className={classes.seeMoreText}
                        onClick={() => history.push('/deals?type=prospective')}
                      >
                        See more
                        <ChevronRightIcon />
                      </WrappedTypography>
                    </Box>
                    <TableContainer className={classes.tableContainer}>
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className={classes.invstTh}
                              component="th"
                            >
                              Company
                            </TableCell>
                            <TableCell
                              style={{ textAlign: 'end', paddingRight: 20 }}
                            >
                              AI Analyst
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody
                          style={
                            isEmpty(prospectiveDealsSummary) ||
                            get(prospectiveDealsSummary, 'totalCount') <= 0
                              ? { filter: 'blur(3px)' }
                              : {}
                          }
                        >
                          {!isEmpty(
                            get(
                              prospectiveDealsSummary,
                              'latestProspectiveDeals',
                            ),
                          ) ? (
                            get(
                              prospectiveDealsSummary,
                              'latestProspectiveDeals',
                            ).map((item: any) => (
                              <TableRow
                                key={get(item, 'id')}
                                className={classes.aiAnalystTableRow}
                              >
                                <TableCell className={classes.invstTh}>
                                  {get(item, 'companyName')}
                                  {!isEmpty(get(item, 'sector')) && (
                                    <Chip
                                      className={classes.tagText}
                                      label={get(item, 'sector')}
                                    />
                                  )}
                                  {!isEmpty(get(item, 'lastFundingRound')) && (
                                    <Chip
                                      className={classes.tagText}
                                      label={get(item, 'lastFundingRound')}
                                    />
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{ textAlign: 'end', paddingRight: 20 }}
                                >
                                  {getAiStatus(item)}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell>
                                <WrappedTypography
                                  className={classes.noRecordText}
                                >
                                  No Records Found
                                </WrappedTypography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box className={classes.rightCircleContainer}>
                    <ProspectiveDealCharts
                      prospectiveDealsSummary={prospectiveDealsSummary}
                    />
                  </Box>
                </Box>
                {isEmpty(prospectiveDealsSummary) ||
                  (get(prospectiveDealsSummary, 'totalCount') <= 0 && (
                    <Box className={classes.noDataContainer}>
                      <WrappedTypography className={classes.noDataText}>
                        Add deals to your Pipeline
                      </WrappedTypography>
                      <WrappedTypography
                        onClick={() => history.push('/deals?type=prospective')}
                        className={classes.noDataButton}
                      >
                        Add Deals
                      </WrappedTypography>
                    </Box>
                  ))}
              </Box>
            )}
          </Box>
          {openInvestmentNote && (
            <AiAnalystContent
              open={openInvestmentNote}
              setOpen={setOpenInvestmentNote}
              investmentNote={
                get(investmentNoteDeal, 'aiAnalyst.INVESTMENT_NOTE')
                  ? get(investmentNoteDeal, 'aiAnalyst.INVESTMENT_NOTE')
                  : get(investmentNoteDeal, 'aiAnalyst.investmentNoteAdmin')
              }
              dealId={get(investmentNoteDeal, 'id')}
              vcFirm={vcFirm}
              deal={investmentNoteDeal}
              vcAdminDeal={
                get(investmentNoteDeal, 'aiAnalyst.INVESTMENT_NOTE')
                  ? false
                  : get(investmentNoteDeal, 'ycDeal')
              }
            />
          )}
        </>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.avatarMenu}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            history.push('/deals?type=prospective&open=importCSV');
            handleMenuClose();
          }}
        >
          Import Excel
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            history.push('/deals?type=prospective&open=request');
            handleMenuClose();
          }}
        >
          Request Founder Input
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push('/deals?type=prospective&open=manually');
            handleMenuClose();
          }}
          className={classes.menuItem}
        >
          Add Manually
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProspectiveDealSummary;
