import React from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import InvestmentUpdateAccordion from 'views/shared/InvestmentUpdates/components/InvestmentUpdateAccordion';
import CompanySnapShotDialog from 'views/shared/InvestmentUpdates/components/CompanySnapShotDialog';
import history from 'common/utils/history';
// import { MONTH_SHORT_LABELS } from 'common/utils/constants';
import { updateVCFirm } from 'redux-modules/VCFirm/Actions';
import { RootState } from 'redux-modules/Store/RootState';
import { editVcDealInvestmentUpdateName } from 'services';
import DeleteInvestmentUpdateDialog from 'views/shared/InvestmentUpdates/components/DeleteInvestmentUpdateDialog';
import UploadInvestmentUpdateReport from 'views/shared/InvestmentUpdates/components/UploadInvestmentUpdateReport';
import ChargeDialog from 'views/shared/InvestmentUpdates/components/ChargeDialog';
import { INV_UPDATE_TYPE, QUARTER_LABELS } from 'common/utils/constants';

import styles from './styles';

const AIMonitorTab = ({
  selectedDealInvestmentUpdateData,
  selectedDealJobs,
  selectedDealData,
  getAllVcDealInvestmentUpdate,
  disableAsPerPermission,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { control, watch, getValues, setValue } = useForm();

  const [company, setCompany] = React.useState<any>({});
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [isEditMode, setIsEditMode] = React.useState(new Array(1).fill(false));
  const [isEditLoading, setIsEditLoading] = React.useState(
    new Array(1).fill(false),
  );
  const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
  const [modal, setModal] = React.useState<boolean>(false);
  const [deleteUpdate, setDeleteUpdate] = React.useState<any>('');
  const [isCompanyDelete, setIsCompanyDelete] = React.useState(false);
  const [isJobDelete, setIsJobDelete] = React.useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false);
  const [modalCompany, setModalCompany] = React.useState<any>();
  const [snapShotIndex, setSnapShotIndex] = React.useState<any>(0);
  const [snapShotIndexForCharge, setSnapShotIndexForCharge] =
    React.useState<any>(0);
  const [openUploadDialog, setOpenUploadDialog] =
    React.useState<boolean>(false);
  const [investmentUpdateId, setInvestmentUpdateId] = React.useState('');
  const [chargeDialog, setChargeDialog] = React.useState(false);
  const [processingStatusChange, setProcessingStatusChange] =
    React.useState<any>({});
  const [isCompanySnapshotClicked, setIsCompanySnapshotClicked] =
    React.useState(false);

  const handleNameEdit = (index: any, company: any, step?: any) => {
    const newIsEditMode = { ...isEditMode };
    newIsEditMode[get(company, 'id')][index] =
      !newIsEditMode[get(company, 'id')][index];
    setIsEditMode(newIsEditMode);
    if (step) setValue(`${step.id}#date`, step.receivedDate);
  };

  const handleAddToActive = (id: any, source = false) => {
    if (source) {
      history.push(`deals?type=active&inv-update-id=${id}&source=${source}`);
    } else {
      history.push(`deals?type=active&inv-update-id=${id}`);
    }
  };

  const handleChange = (panel: string) => () => {
    if (expanded.includes(panel)) {
      const newArray = expanded.filter((i) => i !== panel);
      setExpanded(newArray);
    } else {
      setExpanded([...expanded, panel]);
    }
  };

  const handleEditSave = (
    index: any,
    id: any,
    investorId: any,
    company: any,
    view: any = false,
  ) => {
    const newIsLoading = [...isEditLoading];
    newIsLoading[index] = true;
    setIsEditLoading(newIsLoading);

    const date = getValues(`${id}#date`);
    let reqObj: any = {};
    if (get(company, 'investmentUpdateType') === INV_UPDATE_TYPE.QUARTERLY) {
      reqObj = {
        id: id,
        investorId: investorId,
        quarter: get(date, 'quarter', 'Q1'),
        receivedMonth: QUARTER_LABELS.indexOf(get(date, 'quarter', 'Q1')) + 1,
        receivedYear: get(date, 'receivedYear', '2023'),
      };
    } else {
      const todayNumberDay = moment().date();
      const year = Number(moment(date).format('YYYY'));
      const month = Number(moment(date).format('MM'));
      reqObj = {
        id: id,
        investorId: investorId,
        receivedDate: `${year}-${month}-${todayNumberDay}`,
        receivedMonth: month,
        receivedYear: year,
      };
    }
    if (view) {
      reqObj = {
        id: id,
        investorId: investorId,
        lastViewedAt: new Date(),
      };
    }
    editVcDealInvestmentUpdateName(reqObj)
      .then((res: any) => {
        onUpdateInvestmentUpdate(id, get(company, 'id'), {
          receivedDate: get(res, 'receivedDate'),
          receivedMonth: get(res, 'receivedMonth'),
          receivedYear: get(res, 'receivedYear'),
          lastViewedAt: get(res, 'lastViewedAt'),
          quarter: get(res, 'quarter'),
          newReports: get(res, 'newReports'),
        });
        if (!view) {
          handleNameEdit(index, company);
        }
      })
      .finally(() => {
        const newIsLoading = [...isEditLoading];
        newIsLoading[index] = false;
        setIsEditLoading(newIsLoading);
      });
  };

  const handleInvestmentUpdateChargeSeen = (investorId: string) => {
    const investmentUpdateNudgeSeen = get(
      vcFirm,
      'dashboardSettings.investmentUpdateNudgeSeen',
      {},
    );
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        investmentUpdateNudgeSeen: {
          ...investmentUpdateNudgeSeen,
          charges: {
            ...get(
              vcFirm,
              'dashboardSettings.investmentUpdateNudgeSeen.charges',
              {},
            ),
            [investorId]: true,
          },
        },
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
  };

  const onUpdateInvestmentUpdate = (
    ipId: string,
    companyId: string,
    data: any,
  ) => {
    const investmentUpdateIndex = (
      get(company, 'investmentUpdates') || []
    ).findIndex((investmentUpdate: any) => investmentUpdate.id === ipId);
    if (investmentUpdateIndex !== -1) {
      const investmentUpdate = company.investmentUpdates[investmentUpdateIndex];
      const updatedinvestmentUpdate = { ...investmentUpdate, ...data };
      company.investmentUpdates[investmentUpdateIndex] =
        updatedinvestmentUpdate;
      company.investmentUpdates.sort((a: any, b: any) => {
        const investmentUpdateType = get(company, 'investmentUpdateType', null);
        const quarterA = get(a, 'quarter', null);
        const quarterB = get(b, 'quarter', null);
        const receivedYearA = get(a, 'receivedYear', null);
        const receivedYearB = get(b, 'receivedYear', null);
        const receivedMonthA = get(a, 'receivedMonth', null);
        const receivedMonthB = get(b, 'receivedMonth', null);

        if (
          investmentUpdateType === INV_UPDATE_TYPE.QUARTERLY &&
          quarterA &&
          quarterB
        ) {
          const quarterIndexA = QUARTER_LABELS.indexOf(quarterA);
          const quarterIndexB = QUARTER_LABELS.indexOf(quarterB);
          const dateA: any = new Date(receivedYearA, quarterIndexA * 3);
          const dateB: any = new Date(receivedYearB, quarterIndexB * 3);
          return dateB - dateA;
        } else {
          const dateA: any = new Date(receivedYearA, receivedMonthA);
          const dateB: any = new Date(receivedYearB, receivedMonthB);
          return dateB - dateA;
        }
      });
      company.newReports = get(data, 'newReports', get(company, 'newReports'));
      setCompany(company);
    }
  };

  const setCompanyEditModeList = (res: any[]) => {
    const editCompany: any = [];
    res.forEach((company: any) => {
      editCompany[get(company, 'id')] = new Array(
        (get(company, 'investmentUpdates') || []).length,
      ).fill(false);
    });
    setIsEditMode(editCompany);
  };

  React.useEffect(() => {
    const data = {
      ...selectedDealData,
      jobs: selectedDealJobs,
      investmentUpdates: selectedDealInvestmentUpdateData,
    };
    setCompany(data);
    setCompanyEditModeList([data]);
  }, [selectedDealData, selectedDealJobs, selectedDealInvestmentUpdateData]);

  return (
    <>
      <InvestmentUpdateAccordion
        key={1}
        company={company}
        hardReset={false}
        processingStatusChange={processingStatusChange}
        setProcessingStatusChange={setProcessingStatusChange}
        setIsCompanySnapshotClicked={setIsCompanySnapshotClicked}
        setInvestmentUpdateId={setInvestmentUpdateId}
        setChargeDialog={setChargeDialog}
        setSnapShotIndexForCharge={setSnapShotIndexForCharge}
        setOpenUploadDialog={setOpenUploadDialog}
        handleAddToActive={handleAddToActive}
        setModalCompany={setModalCompany}
        setModal={setModal}
        processingStatus={selectedDealJobs.some(
          (job: any) => get(job, 'status') === 'IN_PROGRESS',
        )}
        hasPendingUpdateRef={get(company, 'hasPendingUpdateRef')}
        subheading={'Updates Feed'}
        expanded={true}
        handleChange={(e: string) => handleChange(e)}
        newReports={get(company, 'newReports', null) || null}
        index={get(company, 'companyName')}
        className={
          get(company, 'hasPendingUpdateRef', false)
            ? classes.accordABox
            : classes.accordAiBox
        }
        setIsOpenDeleteModal={setIsOpenDeleteModal}
        setDeleteUpdate={setDeleteUpdate}
        setIsCompanyDelete={setIsCompanyDelete}
        setIsJobDelete={setIsJobDelete}
        jobs={(selectedDealInvestmentUpdateData || []).filter(
          (obj: any) => obj.status === 'IN_PROGRESS',
        )}
        watch={watch}
        control={control}
        setValue={setValue}
        setSnapShotIndex={setSnapShotIndex}
        setIsEditMode={setIsEditMode}
        handleNameEdit={handleNameEdit}
        isEditMode={isEditMode}
        isEditLoading={isEditLoading}
        setIsEditLoading={setIsEditLoading}
        handleEditSave={handleEditSave}
        onUpdateInvestmentUpdate={onUpdateInvestmentUpdate}
        steps={company.investmentUpdates}
        step={get(company, 'investmentUpdates[0]')}
        aiMonitoring={true}
        getAllVcDealInvestmentUpdate={getAllVcDealInvestmentUpdate}
      ></InvestmentUpdateAccordion>

      {openUploadDialog && (
        <UploadInvestmentUpdateReport
          isOpen={openUploadDialog}
          setIsOpen={setOpenUploadDialog}
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
          company={modalCompany}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteInvestmentUpdateDialog
          isOpenDeleteModal={isOpenDeleteModal}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          isCompanyDelete={isCompanyDelete}
          setIsCompanyDelete={setIsCompanyDelete}
          isJobDelete={isJobDelete}
          dealData={deleteUpdate}
          refreshDeals={() => getAllVcDealInvestmentUpdate()}
          closeDialog={() => {
            setDeleteUpdate('');
            setIsCompanyDelete(false);
            setIsJobDelete(false);
          }}
          title={
            isCompanyDelete
              ? 'Delete Investment Deal'
              : 'Delete Investment Update'
          }
        />
      )}
      {modal && (
        <CompanySnapShotDialog
          modal={modal}
          setModal={setModal}
          getAllVcDealInvestmentUpdate={getAllVcDealInvestmentUpdate}
          setInvestmentUpdateId={setInvestmentUpdateId}
          setChargeDialog={setChargeDialog}
          setSnapShotIndexForCharge={setSnapShotIndexForCharge}
          modalCompany={modalCompany}
          setModalCompany={setModalCompany}
          companyList={[company]}
          snapShotIndex={snapShotIndex}
          setSnapShotIndex={setSnapShotIndex}
          handleEditSave={handleEditSave}
          handleAddToActive={handleAddToActive}
          onUpdateInvestmentUpdate={onUpdateInvestmentUpdate}
          disableEditable={disableAsPerPermission}
        />
      )}
      {chargeDialog && (
        <ChargeDialog
          modal={modal}
          isCompanySnapshotClicked={isCompanySnapshotClicked}
          setIsCompanySnapshotClicked={setIsCompanySnapshotClicked}
          chargeDialog={chargeDialog}
          investmentUpdateId={investmentUpdateId}
          setInvestmentUpdateId={setInvestmentUpdateId}
          setChargeDialog={setChargeDialog}
          setSnapShotIndex={setSnapShotIndex}
          snapShotIndexForCharge={snapShotIndexForCharge}
          onChargeInvestmentUpdate={onUpdateInvestmentUpdate}
          setSnapShotIndexForCharge={setSnapShotIndexForCharge}
          handleInvestmentUpdateChargeSeen={handleInvestmentUpdateChargeSeen}
          setModal={setModal}
          vcFirm={vcFirm}
          dialogSeen={
            !get(
              vcFirm,
              `dashboardSettings.investmentUpdateNudgeSeen.charges[${get(
                vcFirm,
                'vcFirmUser.id',
              )}]`,
              false,
            )
          }
        />
      )}
    </>
  );
};

export default AIMonitorTab;
