/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect } from 'react';
import { isEmpty, get, orderBy, size } from 'lodash';
import { Box, IconButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import {
  WrappedTypography,
  InputTextField,
  AutoCompleteField,
  InputPriceField,
  // Button,
  CheckBoxField,
} from 'components';
import {
  TYPE_OF_INSTRUMENT,
  TERMS_OF_SAFE_MAPPER,
} from 'common/utils/constants';
import {
  formatAmount,
  getPricingLatestRoundObject,
  getSafeTermFieldsCalculation,
  sortSafeFields,
} from 'common/utils/helpers';
import { fundingRoundStageList } from 'common/utils/option-list';
import { FN } from 'common/types';

import styles from './styles';

type props = {
  type?: any;
  vcFirmCurrency: any;
  vcFirmCurrencySymbol: any;
  dealPartiallyExited: boolean;
  selectedDealData: any;
  control: any;
  setValue: FN;
  getValues: FN;
  watch: FN;
  resetField: any;
  getCalculatedCurrentValuation: FN;
  getCalculatedCurrentValue: FN;
  getCalculatedPercentageOwned: FN;
  getCalculatedMoic: FN;
  getCalculatedRealisedMoic: FN;
  safeFields: FN;
  setSafeFields: FN;
  valuationField: any;
  valuationPrepend: any;
  valuationRemove: any;
  onSubmit: FN;
  handleSubmit: FN;
  setIsMFNFreezeChecked: FN;
  isMFNFreezeChecked: boolean;
};

const LatestValuationAccord = ({
  vcFirmCurrencySymbol,
  selectedDealData,
  control,
  setValue,
  getValues,
  watch,
  resetField,
  getCalculatedCurrentValuation,
  getCalculatedCurrentValue,
  getCalculatedPercentageOwned,
  getCalculatedMoic,
  safeFields,
  setSafeFields,
  valuationField,
  valuationPrepend,
  valuationRemove,
  onSubmit,
  handleSubmit,
  isMFNFreezeChecked,
}: props) => {
  const classes = styles();

  const [showPercentageOwnedTooltip, setShowPercentageOwnedTooltip] =
    React.useState<string>('');
  const [roundStageList, setRoundStageList] = React.useState<any>(
    fundingRoundStageList,
  );
  const [confirmationDialog, setOpenConfirmationDialog] =
    React.useState<boolean>(false);

  const setAllToFalse = () => {
    watch('valuationRoundDetail').forEach((item: any, index: any) => {
      setValue(`valuationRoundDetail[${index}].isPriceRound`, false);
    });
  };

  const getCalculatedEntryValuation = () => {
    const payload = {
      priceRoundValuation: watch('priceRoundValuation'),
      discountMfn: watch('discountMfn'),
      mfnValuation: watch('mfnValuation'),
      valuationCap: watch('valuationCap'),
      freezeMfnValuation: isMFNFreezeChecked,
    };

    if (watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE) {
      const termsOfSafe = getValues('termsOfSafe');
      if (
        termsOfSafe &&
        termsOfSafe.length > 0 &&
        TERMS_OF_SAFE_MAPPER.UNCAPPED === termsOfSafe[0]
      ) {
        setValue('lastRoundValuation', safeFields?.entryValuation(payload));
        setValue(
          'safeToEquityValuation',
          safeFields?.safeToEquityValuation(payload),
        );
      }
    }

    getCalculatedCurrentValuation();
    getCalculatedCurrentValue();
    getCalculatedPercentageOwned();
    getCalculatedMoic();
  };

  const getRoundList = () => {
    const selectedRoundList: any[] = [];
    watch('valuationRoundDetail').map(
      (i: any) => !isEmpty(i.roundName) && selectedRoundList.push(i.roundName),
    );
    const newArr = fundingRoundStageList.map((object: any) => {
      if (selectedRoundList.includes(object.value)) {
        return { ...object, disableOption: true };
      }
      return object;
    });
    setRoundStageList(newArr);
  };

  const getLastRoundDate = () => {
    const dates: string[] = [];
    (watch('valuationRoundDetail') || []).map(
      (i: any) => !isEmpty(i.date) && dates.push(i.date),
    );
    if (!isEmpty(dates)) {
      const arrDates = dates.map((str) => new Date(str));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const max = new Date(Math.max(...arrDates));
      return moment(max).format('YYYY-MM-DD');
    } else return '';
  };

  const handleClearInvestmentDetail = (
    array: string[] | ConcatArray<string>,
  ) => {
    let invField = [
      'investmentDate',
      'priceRoundValuation',
      'valuationCap',
      'discountMfn',
      'mfnValuation',
      'investedAmount',
      'exitValuation',
      'percentageOwned',
      'moic',
      'currentValue',
      'lastRoundValuation',
    ];
    if (!isEmpty(array)) {
      invField = invField.concat(array);
    }
    invField.map((i: string) => {
      resetField(i);
    });
    getCurrentValuationText();
    confirmationDialog && setOpenConfirmationDialog(false);
  };

  const getLatestRound = () => {
    let valuationRoundDetail = watch('valuationRoundDetail');
    valuationRoundDetail = orderBy(
      valuationRoundDetail,
      ['createdAt'],
      ['desc'],
    );
    return !isEmpty(valuationRoundDetail) ? valuationRoundDetail[0] : [];
  };

  const getCurrentValuationText = () => {
    if (!isEmpty(watch('valuationRoundDetail'))) {
      if (!isEmpty(getLatestRound())) {
        return `${
          !isEmpty(get(getLatestRound(), 'roundName'))
            ? get(getLatestRound(), 'roundName.value')
            : ''
        } - ${
          !isEmpty(get(getLatestRound(), 'valuation'))
            ? formatAmount(get(getLatestRound(), 'valuation'))
            : ''
        }`;
      } else if (
        isEmpty(getLatestRound()) &&
        watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
        !isEmpty(watch('priceRoundValuation'))
      ) {
        return `1st Priced Round - ${formatAmount(
          watch('priceRoundValuation'),
        )}`;
      } else if (
        isEmpty(getLatestRound()) &&
        watch('investedInstrumentType') !== TYPE_OF_INSTRUMENT.SAFE &&
        !isEmpty(watch('lastRoundValuation'))
      ) {
        return `1st Priced Round -  ${formatAmount(
          watch('lastRoundValuation'),
        )}`;
      } else {
        return `Update latest valuation after each funding round`;
      }
    } else {
      return `Update latest valuation after each funding round`;
    }
  };

  const isDefaultRoundPresent = (index?: number) => {
    const defaultRoundIndex = (watch('valuationRoundDetail') || []).findIndex(
      (round: any) => round.defaultRound,
    );
    if (
      index !== undefined &&
      index >= 0 &&
      watch('valuationRoundDetail').length > 0
    ) {
      if (defaultRoundIndex > -1 && index === defaultRoundIndex) {
        return true;
      } else if (defaultRoundIndex <= -1) return true;
      else return false;
    } else {
      if (defaultRoundIndex > -1) {
        return true;
      } else return false;
    }
  };

  const isPriceRoundCheckboxChecked = (index: any) => {
    const defaultRoundIndex = (watch('valuationRoundDetail') || []).findIndex(
      (round: any) => round.isPriceRound,
    );
    if (defaultRoundIndex === -1) {
      return true;
    } else if (defaultRoundIndex === index) {
      return true;
    } else return false;
  };

  const isPriceRouncdCheckboxDisabled = () => {
    const defaultRoundIndex = (watch('valuationRoundDetail') || []).findIndex(
      (round: any) => round.defaultRound,
    );

    if (
      defaultRoundIndex > -1 &&
      !isEmpty(get(selectedDealData, 'valuationRoundDetail'))
    ) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (!isEmpty(watch('termsOfSafe'))) {
      const sortedData = sortSafeFields(watch('termsOfSafe'));
      // alert(JSON.stringify(getSafeTermFieldsCalculation(sortedData)));
      if (!get(getSafeTermFieldsCalculation(sortedData), 'error')) {
        setSafeFields(getSafeTermFieldsCalculation(sortedData));
      }
    }
  }, [watch('termsOfSafe')]);

  useEffect(() => {
    if (!isEmpty(watch('valuationRoundDetail'))) {
      getRoundList();
      getLastRoundDate();
    }
    getCurrentValuationText();
  }, [watch('valuationRoundDetail')]);

  useEffect(() => {
    if (!isEmpty(watch('valuationRoundDetail'))) {
      getCurrentValuationText();
    }
  }, [
    watch(`valuationRoundDetail[0].valuation`),
    watch(`valuationRoundDetail[0].roundName`),
    watch(`valuationRoundDetail[0].date`),
  ]);

  // useEffect(() => {
  //   if (
  //     isEmpty(get(selectedDealData, 'valuationRoundDetail')) &&
  //     ['', null, undefined].includes(watch('priceRoundValuation')) &&
  //     size(watch('valuationRoundDetail')) >= 1
  //   ) {
  //     setValue('valuationRoundDetail', []);
  //     setValue('valuationCap', null);
  //     setValue('discountMfn', null);
  //     setValue('mfnValuation', null);
  //   }
  //   getCurrentValuationText();
  // }, [watch('priceRoundValuation')]);

  useEffect(() => {
    if (
      ['', null, undefined].includes(watch('lastRoundValuation')) &&
      [TYPE_OF_INSTRUMENT.EQUITY].includes(watch('investedInstrumentType'))
    ) {
      setValue('valuationRoundDetail', []);
      setValue('percentageOwned', '');
      setValue('moic', '');
      setValue('currentValue', '');
    } else if (
      ['', null, undefined].includes(watch('investedAmount')) &&
      [TYPE_OF_INSTRUMENT.EQUITY].includes(watch('investedInstrumentType'))
    ) {
      setValue('percentageOwned', '');
      setValue('moic', '');
      setValue('currentValue', '');
    } else if (
      ['', null, undefined].includes(watch('investedAmount')) &&
      [TYPE_OF_INSTRUMENT.SAFE].includes(watch('investedInstrumentType'))
    ) {
      setValue('percentageOwned', '');
      setValue('moic', '');
      setValue('currentValue', '');
    }
    getCurrentValuationText();
  }, [watch('lastRoundValuation'), watch('investedAmount')]);

  const isdisableAddLatestRoundBtn = () => {
    let latestround;
    if (!isEmpty(watch('valuationRoundDetail'))) {
      latestround = watch('valuationRoundDetail')[0];
    }

    if (
      !isEmpty(watch('valuationRoundDetail')) &&
      isEmpty(get(latestround, 'date')) &&
      isEmpty(get(latestround, 'valuation')) &&
      isEmpty(get(latestround, 'roundName.value'))
    ) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (!isEmpty(safeFields)) {
      getCalculatedEntryValuation();
    }
  }, [isMFNFreezeChecked]);

  useEffect(() => {
    // window.alert(watch('investmentDate'));

    // window.alert(watch('lastRoundValuation'));
    // Initiate with one default valuation round item if the list is empty
    if (valuationField.length === 0) {
      valuationPrepend({
        roundName: !isEmpty(watch('lastFundingRound'))
          ? getPricingLatestRoundObject(watch('lastFundingRound'))
          : null,
        date: !isEmpty(watch('investmentDate'))
          ? watch('investmentDate')
          : null,
        valuation: watch('lastRoundValuation'),
        defaultRound: true,
        isNew: true,
        isPriceRound: false,
      });
      getCalculatedEntryValuation();
    }
  }, [valuationField]);

  return (
    <>
      <Box className={classes.parentContainer}>
        <Box className={classes.childMenuContainer}>
          <Box sx={{ width: 'inherit' }}>
            {valuationField.map((item: any, index: any) => (
              <Fragment key={`${item.id}`}>
                <Box className={classes.valuationMenuBox}>
                  <Box className={classes.checkboxFieldBox}>
                    <WrappedTypography type={'body2'}>
                      {index === 0 ? 'Latest Round' : 'Round Name'}
                    </WrappedTypography>
                    <AutoCompleteField
                      id="round"
                      control={control}
                      name={`valuationRoundDetail[${index}].roundName` as const}
                      placeholder="Select Round"
                      options={roundStageList}
                      fieldValue={getPricingLatestRoundObject(
                        get(
                          selectedDealData,
                          `valuationRoundDetail[${index}].roundName`,
                        ),
                      )}
                      getOptionLabel={(option: any) => option.value || ''}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.value === value.value ||
                        value === undefined ||
                        value === ''
                      }
                      disableClearable
                      // getOptionDisabled={(option: any) =>
                      //   getAllSelectedRoundName().includes(option.value)
                      // }
                      onChange={(_: any, data: any) => {
                        const defaultRoundIndex = valuationField.findIndex(
                          (round: any) => round.defaultRound,
                        );
                        if (
                          defaultRoundIndex > -1 &&
                          index === defaultRoundIndex &&
                          watch('investedInstrumentType') !==
                            TYPE_OF_INSTRUMENT.SAFE
                        ) {
                          setValue('lastFundingRound', data.value);
                        }
                      }}
                    />
                    {!isEmpty(get(selectedDealData, 'valuationRoundDetail')) &&
                      isPriceRoundCheckboxChecked(index) &&
                      watch('investedInstrumentType') !==
                        TYPE_OF_INSTRUMENT.EQUITY && (
                        <Box className={classes.checkBoxContainer}>
                          <CheckBoxField
                            control={control}
                            name={
                              `valuationRoundDetail[${index}].isPriceRound` as const
                            }
                            disabled={index !== 0}
                            onInputChange={(e: any) => {
                              const defaultRoundIndex = (
                                watch('valuationRoundDetail') || []
                              ).findIndex((round: any) => round.isPriceRound);
                              if (defaultRoundIndex === -1) {
                                resetField('NoOfSharesAlloted');
                                resetField('totalNoOfShares');
                                resetField('pricePerShare');
                              }
                              // watch('valuationRoundDetail').map(
                              //   (i: any, pos: number) => {
                              //     if (pos !== index) {
                              //       setValue(
                              //         `valuationRoundDetail[${pos}].defaultRound`,
                              //         false,
                              //       );
                              //     }
                              //   },
                              // );
                              // if (
                              //   watch(
                              //     `valuationRoundDetail[${index}].defaultRound`,
                              //   ) &&
                              //   ![null, undefined, ''].includes(
                              //     watch(
                              //       `valuationRoundDetail[${index}].valuation`,
                              //     ),
                              //   )
                              // ) {
                              //   setValue(
                              //     'priceRoundValuation',
                              //     watch(
                              //       `valuationRoundDetail[${index}].valuation`,
                              //     ),
                              //   );
                              // }
                              setValue(
                                'priceRoundValuation',
                                watch(
                                  `valuationRoundDetail[${index}].valuation`,
                                ),
                              );
                              setAllToFalse();
                              setValue(
                                `valuationRoundDetail[${index}].isPriceRound`,
                                e.target.checked,
                              );
                              getCalculatedEntryValuation();
                              // const defaultRoundIndex = (
                              //   watch('valuationRoundDetail') || []
                              // ).findIndex((round: any) => round.defaultRound);
                              // if (defaultRoundIndex === -1) {
                              //   handleClearInvestmentDetail([
                              //     'termsOfSafe',
                              //     'lastFundingRound',
                              //   ]);
                              //   setValue('valuationRoundDetail', []);
                              //   resetField('lastRoundValuation');
                              // }
                            }}
                            label="Priced Round"
                            className={classes.sponsorCheckbox}
                          />
                        </Box>
                      )}
                  </Box>
                  <Box style={{ width: '100%' }}>
                    <WrappedTypography type={'body2'}>Date</WrappedTypography>
                    <InputTextField
                      placeholder="Enter Date"
                      control={control}
                      name={`valuationRoundDetail[${index}].date` as const}
                      type={'date'}
                      InputProps={{
                        inputProps: {
                          min:
                            !isEmpty(getLastRoundDate()) &&
                            size(watch('valuationRoundDetail')) <= 1
                              ? undefined
                              : getLastRoundDate(),

                          max: isEmpty(getLastRoundDate())
                            ? moment().format('YYYY-MM-DD')
                            : moment().format('YYYY-MM-DD'),
                        },
                      }}
                      onInputChange={(data: any) => {
                        const defaultRoundIndex = valuationField.findIndex(
                          (round: any) => round.defaultRound,
                        );
                        if (
                          defaultRoundIndex > -1 &&
                          index === defaultRoundIndex
                        ) {
                          setValue('investmentDate', data.target.value);
                        }
                      }}
                    />
                  </Box>
                  <Box style={{ width: '100%' }}>
                    <WrappedTypography type={'body2'}>
                      Valuation
                    </WrappedTypography>
                    <InputPriceField
                      placeholder="Enter Valuation"
                      control={control}
                      name={`valuationRoundDetail[${index}].valuation` as const}
                      startAdornment={vcFirmCurrencySymbol}
                      priceInputProps={{
                        thousandSeparator: true,
                        decimalScale: 0,
                      }}
                      onInputChange={() => {
                        const defaultRoundIndex = watch(
                          'valuationRoundDetail',
                        ).findIndex((round: any) => round.defaultRound);
                        if (
                          defaultRoundIndex > -1 &&
                          watch('investedInstrumentType') !==
                            TYPE_OF_INSTRUMENT.SAFE
                        ) {
                          setValue(
                            'lastRoundValuation',
                            watch(
                              `valuationRoundDetail[${defaultRoundIndex}].valuation`,
                            ),
                          );
                        } else if (
                          defaultRoundIndex > -1 &&
                          watch('investedInstrumentType') ===
                            TYPE_OF_INSTRUMENT.SAFE
                        ) {
                          setValue(
                            'priceRoundValuation',
                            watch(
                              `valuationRoundDetail[${defaultRoundIndex}].valuation`,
                            ),
                          );
                          setValue(
                            'lastRoundValuation',
                            watch(
                              `valuationRoundDetail[${defaultRoundIndex}].valuation`,
                            ),
                          );
                          getCalculatedEntryValuation();
                        }
                        if (index === 0) {
                          setValue(
                            'currentValuation',
                            watch(`valuationRoundDetail[${index}].valuation`),
                          );
                          getCalculatedCurrentValue();
                          getCalculatedMoic();
                        }
                      }}
                    />
                  </Box>
                  <Box className={classes.btnContainer}>
                    {index === 0 && (
                      <Box
                        onClick={
                          [TYPE_OF_INSTRUMENT.SAFE].includes(
                            watch('investedInstrumentType'),
                          ) &&
                          !isEmpty(watch('termsOfSafe')) &&
                          !isEmpty(get(safeFields, 'error'))
                            ? () => null
                            : !isEmpty(getLatestRound()) &&
                              showPercentageOwnedTooltip === '' &&
                              ![null, undefined, ''].includes(
                                watch('percentageOwned'),
                              )
                            ? () => setShowPercentageOwnedTooltip('show')
                            : handleSubmit(() => {
                                setShowPercentageOwnedTooltip('');
                                onSubmit(false);
                              })
                        }
                      >
                        <SaveOutlinedIcon sx={{ cursor: 'pointer' }} />
                      </Box>
                    )}
                    {index === 0 && (
                      <Box
                        onClick={() => {
                          if (!isdisableAddLatestRoundBtn()) {
                            const defaultRoundIndex = valuationField.findIndex(
                              (round: any) => round.defaultRound,
                            );
                            if (
                              [TYPE_OF_INSTRUMENT.SAFE].includes(
                                watch('investedInstrumentType'),
                              )
                            ) {
                              valuationPrepend({
                                roundName: { text: '', value: '' },
                                date: null,
                                valuation: '',
                                isNew: true,
                                defaultRound: false,
                              });
                            } else {
                              valuationPrepend({
                                roundName: { text: '', value: '' },
                                date: null,
                                valuation: '',
                                defaultRound:
                                  defaultRoundIndex === -1 ? true : false,
                                isNew: true,
                              });
                            }
                          }
                        }}
                        sx={{
                          cursor: isdisableAddLatestRoundBtn()
                            ? 'not-allowed'
                            : 'pointer',
                          color: isdisableAddLatestRoundBtn()
                            ? 'grey'
                            : '#FF8010',
                          opacity: isdisableAddLatestRoundBtn() ? 0.5 : 1,
                        }}
                      >
                        <AddIcon />
                      </Box>
                    )}

                    {valuationField.length > 1 && (
                      <IconButton
                        sx={{
                          paddingLeft: '0px !important',
                        }}
                        onClick={() => {
                          const defaultRoundIndex = watch(
                            'valuationRoundDetail',
                          ).findIndex((round: any) => round.defaultRound);
                          if (defaultRoundIndex === index) {
                            handleClearInvestmentDetail([
                              'valuationRoundDetail',
                            ]);
                            setValue('termsOfSafe', []);
                          } else {
                            valuationRemove(index);
                            if (index === 0) {
                              setValue(
                                'currentValuation',
                                watch(
                                  `valuationRoundDetail[${index}].valuation`,
                                ),
                              );
                              getCalculatedCurrentValue();
                              getCalculatedMoic();
                              if (
                                size(watch('valuationRoundDetail')) === 1 &&
                                [
                                  TYPE_OF_INSTRUMENT.EQUITY,
                                  TYPE_OF_INSTRUMENT.SAFE,
                                ].includes(watch('investedInstrumentType'))
                              ) {
                                getCalculatedPercentageOwned();
                              }
                            }
                            if (
                              size(watch('valuationRoundDetail')) >= 2 &&
                              !isEmpty(watch('percentageOwned')) &&
                              [
                                TYPE_OF_INSTRUMENT.EQUITY,
                                TYPE_OF_INSTRUMENT.SAFE,
                              ].includes(watch('investedInstrumentType'))
                            ) {
                              setShowPercentageOwnedTooltip('show');
                            }
                          }
                        }}
                        // disabled={
                        //   [TYPE_OF_INSTRUMENT.SAFE].includes(
                        //     watch('investedInstrumentType'),
                        //   ) && index !== 0
                        // }
                      >
                        <DeleteOutlinedIcon
                          sx={{
                            cursor: 'pointer',
                            color: '#FF8010',
                          }}
                          className={
                            index !== 0 &&
                            [TYPE_OF_INSTRUMENT.SAFE].includes(
                              watch('investedInstrumentType'),
                            )
                              ? classes.deleteBtn
                              : classes.deleteBtn
                          }
                        />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Fragment>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LatestValuationAccord;
