import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { isEmpty, get, isArray, differenceBy, orderBy, size } from 'lodash';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import {
  SidePane,
  Box,
  WrappedTypography,
  InputTextField,
  Accordion,
  Button,
  CustomizedTooltip,
} from 'components';
import {
  createOrUpdateVcDeal,
  getVcDeal,
  getSignedUrlForVCFirmDocs,
  addOrUpdateVcDealsMultipleDocuments,
  getVcFirmDocuments,
  convertVcDeal,
  getVcDealInvestmentUpdateById,
  getDealJob,
} from 'services';
import {
  DEAL_STATUS_TYPE,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  VC_FIRM_DOCUMENT_NAME,
  ROLE,
  TYPE_OF_INSTRUMENT,
  PUSHER_EVENT_NAME,
} from 'common/utils/constants';
import {
  errorMessageHandler,
  getBusinessOpsCountryObject,
  getRemainingInvestedAmount,
  getFilteredDocuments,
  getCountryOfOperationsObject,
  getPricingLatestRoundObject,
} from 'common/utils/helpers';
import { AIArt, LockIcon, AIGradient } from 'assets';
import { FN } from 'common/types';
import { RootState } from 'redux-modules/Store/RootState';
import history from 'common/utils/history';

import styles from './styles';
import MoveDealToRealised from '../DialogBox/MoveDealToRealised';
//import AIMonitorTab from './components/AiMonitorTab';
import { AddActiveDealSchema } from './validation';
import WrittenOffDeal from '../DialogBox/WrittenOffDeal';
import {
  DeleteDealDocument,
  AddOtherDocument,
  SaveChangesAlert,
} from '../DialogBox';
import OverviewTab from './components/OverviewTab';
import InvestmentTab from './components/InvestmentTab';
import DocumentsTab from './components/DocumentsTab';
import AIMonitorTab from './components/AiMonitorTab';

type props = {
  open: boolean;
  fundList: Array<Record<string, FN>>;
  selectedDeal: string;
  getAllVcDeals: () => void;
  closeDialog: FN;
  fromYardstick?: boolean;
  yardstickDealData?: Record<string, any> | null;
  defaultExpanded?: string;
  title?: string;
  userRoles?: Array<string>;
  type?: any;
  dealId: string;
  searchParams: any;
  permissionInfo: any;
};

// TODO: FUTURE IMPL Instead ycDeal: true Check dealSource: 8VdX_ADMIN_DEAL
const ActiveSidePane = ({
  open,
  fundList,
  selectedDeal,
  getAllVcDeals,
  closeDialog,
  defaultExpanded,
  userRoles,
  type,
  dealId,
  searchParams,
  permissionInfo,
}: props) => {
  const classes = styles();

  const {
    user: { investorId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm, vcFirmCurrency, vcFirmCurrencySymbol } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const vcFirmId = get(vcFirm, 'id');
  const { channel, pusherInitialized } = useSelector(
    ({ Global }: RootState) => Global,
  );

  const [expanded, setExpanded] = React.useState<string[]>(
    defaultExpanded ? [defaultExpanded] : ['Overview'],
  );
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [isEditModeURL, setIsEditModeURL] = React.useState<Record<string, any>>(
    {
      companyUrl: true,
      linkedinUrl: { 0: true, 1: true, 2: true, 3: true, 4: true },
    },
  );
  const [isDealLoading, setIsDealLoading] = React.useState<boolean>(false);
  const [isDocLoading, setIsDocLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedDealData, setSelectedDealData] = React.useState<any>({});
  const [isAddDocument, setIsAddDocument] = React.useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
  const [isDeleteDocument, setIsDeleteDocument] =
    React.useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = React.useState<any>({});
  const [emails, setEmails] = React.useState<any>({});
  const [selectedEmail, setSelectedEmail] = React.useState<any>();
  const [isConvertDeal, setIsConvertDeal] = React.useState<boolean>(false);
  const [isRealisedDeal, setIsRealisedDeal] = React.useState<boolean>(false);
  const [isWrittenOffDeal, setIsWrittenOffDeal] =
    React.useState<boolean>(false);
  const [dealPartiallyExited, setDealPartiallyExited] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<any>({
    active: false,
    reject: false,
    upload: false,
  });
  const [dealMapping, setDealMapping] = React.useState<any>({});
  const [safeFields, setSafeFields] = React.useState<any>({});
  console.log(safeFields, errorMessage); // eslint-disable-line
  const [saveChangesModal, setSaveChangesModal] =
    React.useState<boolean>(false);
  const [isMFNFreezeChecked, setIsMFNFreezeChecked] = React.useState(false);
  const [
    selectedDealInvestmentUpdateData,
    setSelectedDealInvestmentUpdateData,
  ] = React.useState<any>([]);

  const [selectedDealJobs, setSelectedDealJobs] = React.useState<any>([]);

  const disableAsPerPermission = permissionInfo.PORTFOLIO === 'view';
  const handleEditModeChange = (args: any) => {
    if (disableAsPerPermission) {
      return;
    }
    setIsEditMode(args);
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
    resetField,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AddActiveDealSchema),
  });

  const {
    fields: founderField,
    append: founderAppend,
    prepend: founderPrepend,
    remove: founderRemove,
  } = useFieldArray({
    control,
    name: 'vcDealFounders',
  });
  if (!founderField.length) {
    founderPrepend({ name: '', linkedinUrl: '' });
  }

  const {
    fields: valuationField,
    prepend: valuationPrepend,
    remove: valuationRemove,
  } = useFieldArray({
    control,
    name: 'valuationRoundDetail',
  });

  const handleChange = (panel: string) => () => {
    if (expanded.includes(panel)) {
      const newArray = expanded.filter((i) => i !== panel);
      setExpanded(newArray);
    } else {
      setExpanded([...expanded, panel]);
    }
  };

  const handleClose = (updated = false) => {
    closeDialog();
    setSelectedFiles([]);
    setSelectedDealData({});
    resetFieldValues();
    setExpanded([]);
    if (searchParams.has('inv-update-id') && !updated) {
      if (searchParams.has('source')) {
        history.replace(
          `deals?type=active&tab=INVESTMENT_UPDATE&inv-source=${searchParams.get(
            'inv-update-id',
          )}`,
        );
      } else {
        history.replace(`deals?type=active&tab=INVESTMENT_UPDATE`);
      }
    } else if (searchParams.has('prospectiveId')) {
      searchParams.delete('prospectiveId');
      history.replace({
        search: searchParams.toString(),
      });
    }
  };

  const resetFieldValues = () => {
    reset({
      vcFundId: null,
      companyName: '',
      email: null,
      companyUrl: '',
      sector: '',
      geography: '',
      lastFundingRound: '',
      investedInstrumentType: '',
      investmentDate: null,
      investedAmount: null,
      currentValue: null,
      lastRoundValuation: null,
      currentValuation: null,
      percentageOwned: null,
      moic: null,
      // irr: null,
      investmentMemoLink: '',
      companyPitchdeckLink: '',
      exitValuation: null,
      otherEmails: null,
      remark: null,
      tags: null,
    });
    setErrorMessage('');
    setEmails({});
  };

  const isFieldDataChanged = (): boolean => {
    if (isEmpty(selectedDeal)) {
      const data = { ...getValues() };
      const isEmptyField = Object.keys(data).every((x: any) =>
        x === 'vcDealFounders'
          ? get(data, 'vcDealFounders').every(
              (i: any) => !i.linkedinUrl && !i.name,
            )
          : isEmpty(get(data, x)),
      );
      return isEmptyField ? false : true;
    } else if (
      isDirty ||
      selectedFiles.some((item: any) => isEmpty(get(item, 'documentStatus')))
    )
      return true;
    else return false;
  };

  const isLoadingSidePaneData = (): boolean => {
    return (
      isDealLoading ||
      isDocLoading ||
      get(isLoading, 'active') ||
      get(isLoading, 'upload')
    );
  };

  const uploadMultipleDocumentFile = async (
    id: string,
    isCloseSidepane: boolean,
  ) => {
    setErrorMessage('');
    if (!isEmpty(selectedFiles)) {
      setIsLoading((prevState: any) => ({
        ...prevState,
        upload: true,
      }));
      const objArray: any = [];
      try {
        await Promise.all(
          getFilteredDocuments(selectedFiles).map(async (item: any) => {
            let url;
            if (get(item, 'documentUrlType') !== 'LINK') {
              url = await getSignedUrlForVCFirmDocs({
                investorId: investorId,
                filename: item.filename,
                vcFirmId: get(vcFirm, 'id'),
                vcDealId: id,
                documentName: item.documentName,
                type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
              });
              // eslint-disable-next-line no-undef
              await fetch(get(url, 'uploadUrl'), {
                method: 'PUT',
                body: item.file,
              });
            }
            objArray.push({
              vcFirmId: get(vcFirm, 'id'),
              investorId: investorId,
              documentName: item.documentName,
              vcDealId: id,
              filename: !isEmpty(get(item, 'filename'))
                ? item.filename
                : undefined,
              type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
              documentUrl: !isEmpty(get(item, 'documentUrlType'))
                ? get(item, 'link')
                : get(url, 'accessUrl'),
              id: get(item, 'id', null),
              documentUrlType: !isEmpty(get(item, 'documentUrlType'))
                ? get(item, 'documentUrlType')
                : undefined,
            });
          }),
        );
        if (!isEmpty(objArray)) {
          const res = await addOrUpdateVcDealsMultipleDocuments(objArray);
          !isCloseSidepane && setSelectedFiles(res);
        }
        setIsLoading((prevState: any) => ({
          ...prevState,
          upload: false,
        }));
        getAllVcDeals();
        isCloseSidepane && handleClose();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading((prevState: any) => ({
          ...prevState,
          upload: false,
        }));
      }
    } else {
      setErrorMessage('Please upload file');
    }
  };

  const createActiveDealObj = (data: any) => {
    const foundersDetails: any[] = [];
    get(data, 'vcDealFounders').map((item: any) => {
      if (!isEmpty(get(item, 'name'))) {
        return foundersDetails.push({
          id: get(item, 'id') ? get(item, 'id') : undefined,
          name: get(item, 'name'),
          linkedinUrl: get(item, 'linkedinUrl'),
        });
      }
    });
    const deletedFounder = differenceBy(
      get(selectedDealData, 'vcDealFounders'),
      foundersDetails,
      'id',
    );
    if (!isEmpty(deletedFounder)) {
      deletedFounder.forEach((item: any) => {
        return foundersDetails.push({
          id: get(item, 'id') ? get(item, 'id') : undefined,
          name: get(item, 'name'),
          linkedinUrl: get(item, 'linkedinUrl'),
          isDeleted: true,
        });
      });
    }
    const roundDetail: any[] = [];
    if (!isEmpty(get(data, 'valuationRoundDetail'))) {
      get(data, 'valuationRoundDetail').map((i: any) => {
        return roundDetail.push({
          ...i,
          roundName: get(i, 'roundName.value'),
        });
      });
    }
    const obj = {
      ...selectedDealData,
      ...data,
      investorId,
      vcFirmId: get(vcFirm, 'id'),
      id: !isEmpty(get(selectedDealData, 'id'))
        ? get(selectedDealData, 'id')
        : !isEmpty(selectedDealData)
        ? selectedDeal
        : undefined,
      vcFundId: isEmpty(get(data, 'vcFundId')) ? null : get(data, 'vcFundId'),
      dealStatus:
        selectedDealData.dealStatus === DEAL_STATUS_TYPE.EXITED
          ? DEAL_STATUS_TYPE.EXITED
          : DEAL_STATUS_TYPE.ACTIVE,
      geography: !isEmpty(get(data, 'geography'))
        ? get(data, 'geography.countryCode')
        : null,
      countryOfOperation: !isEmpty(get(data, 'countryOfOperation'))
        ? get(data, 'countryOfOperation')
            .map((i: any) => i.countryCode)
            .toString()
        : null,
      email: !isEmpty(get(data, 'email'))
        ? get(data, 'email', []).find((item: any) => emails[item])
        : '',
      otherEmails: !isEmpty(get(data, 'email'))
        ? get(data, 'email', [])
            .filter((item: any) => !emails[item] || item === undefined)
            .toString()
        : null,
      tags: !isEmpty(get(data, 'tags')) ? get(data, 'tags').toString() : null,
      termsOfSafe: !isEmpty(get(data, 'termsOfSafe'))
        ? get(data, 'termsOfSafe').toString()
        : null,
      vcDealFounders: !isEmpty(foundersDetails) ? foundersDetails : undefined,
      priceRoundValuation: ![null, undefined, ''].includes(
        get(data, 'priceRoundValuation'),
      )
        ? get(data, 'priceRoundValuation')
        : null,
      valuationCap: ![null, undefined, ''].includes(get(data, 'valuationCap'))
        ? get(data, 'valuationCap')
        : null,
      discountMfn: ![null, undefined, ''].includes(get(data, 'discountMfn'))
        ? get(data, 'discountMfn')
        : null,
      mfnValuation: ![null, undefined, ''].includes(get(data, 'mfnValuation'))
        ? get(data, 'mfnValuation')
        : null,
      lastRoundValuation: ![null, undefined, ''].includes(
        get(data, 'lastRoundValuation'),
      )
        ? get(data, 'lastRoundValuation')
        : null,
      currentValue: ![null, undefined, ''].includes(get(data, 'currentValue'))
        ? get(data, 'currentValue')
        : null,
      investedAmount: ![null, undefined, ''].includes(
        get(data, 'investedAmount'),
      )
        ? get(data, 'investedAmount')
        : null,
      valuationRoundDetail: !isEmpty(roundDetail) ? roundDetail : null,
      moic: ![null, undefined, ''].includes(get(data, 'moic'))
        ? get(data, 'moic')
        : null,
      percentageOwned: ![null, undefined, ''].includes(
        get(data, 'percentageOwned'),
      )
        ? get(data, 'percentageOwned')
        : null,
      investmentDate: !isEmpty(get(data, 'investmentDate'))
        ? get(data, 'investmentDate')
        : null,
      remark: !isEmpty(get(data, 'remark')) ? get(data, 'remark') : null,
      other: !isEmpty(get(data, 'other')) ? get(data, 'other') : null,
      companyUrl: !isEmpty(get(data, 'companyUrl'))
        ? get(data, 'companyUrl')
        : null,
      lastFundingRound: !isEmpty(get(data, 'lastFundingRound'))
        ? get(data, 'lastFundingRound')
        : null,
      sector: !isEmpty(get(data, 'sector')) ? get(data, 'sector') : null,
      companyBlurp: !isEmpty(get(data, 'companyBlurp'))
        ? get(data, 'companyBlurp')
        : null,

      safeToEquityValuation: ![null, undefined, ''].includes(
        get(data, 'safeToEquityValuation'),
      )
        ? get(data, 'safeToEquityValuation')
        : null,

      freezeMfnValuation: isMFNFreezeChecked,
    };
    return obj;
  };

  const onSubmit = (isCloseSidepane = true) => {
    if (disableAsPerPermission) {
      return;
    }
    const invUpdateId: any = searchParams.get('inv-update-id');
    setErrorMessage('');
    const data: any = { ...getValues() };
    if (!isEmpty(get(data, 'email', [])) && isEmpty(emails)) {
      setErrorMessage('Please Add one Primary Email');
      return;
    }
    if (type === 'REALIZED_DEAL' || dealPartiallyExited) {
      delete data.investedAmount; // This is deleted from obj because user can not update this amount in this options
      delete data.moic; // This is deleted from obj because user can not update this amount in this options
    }
    const obj = createActiveDealObj(data);
    obj.dealStatus =
      selectedDealData.dealStatus === DEAL_STATUS_TYPE.EXITED
        ? DEAL_STATUS_TYPE.EXITED
        : DEAL_STATUS_TYPE.ACTIVE;
    delete obj.investmentNoteLink;
    setIsLoading((prevState: any) => ({
      ...prevState,
      active: true,
    }));
    if (invUpdateId) {
      obj.hasPendingUpdateRef = false;
    }
    createOrUpdateVcDeal(obj)
      .then((res: any) => {
        if (!isEmpty(selectedFiles)) {
          if (isEmpty(selectedDealData) && !isCloseSidepane) {
            setSelectedDealData(res);
          }
          uploadMultipleDocumentFile(res.id, isCloseSidepane);
        } else {
          getAllVcDeals();
          if (isEmpty(selectedDealData) && !isCloseSidepane) {
            setSelectedDealData(res);
          }
          isCloseSidepane && handleClose(true);
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        if (message.includes(`Please select different email`)) {
          setError(`email`, {
            type: 'custom',
            message: 'Please add different email.',
          });
        }
      })
      .finally(() =>
        setIsLoading((prevState: any) => ({
          ...prevState,
          active: false,
        })),
      );
  };

  const getUploadedDocuments = async () => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setIsDocLoading(true);
      setErrorMessage('');
      const obj = {
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
        vcFirmId: get(vcFirm, 'id'),
        vcDealId: selectedDeal,
      };
      await getVcFirmDocuments(obj, get(vcFirm, 'id'))
        .then((res: any) => {
          if (!isEmpty(res) && isArray(res)) {
            const data: any = [];
            res.map((item: any) => {
              if (
                item.documentName ===
                  VC_FIRM_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC ||
                VC_FIRM_DOCUMENT_NAME.OTHER_DOC ||
                VC_FIRM_DOCUMENT_NAME.INVESTMENT_MEMO_DOC ||
                VC_FIRM_DOCUMENT_NAME.DEAL_TRANSACTION_DOC
              ) {
                data.push(item);
              }
            });
            setSelectedFiles(data);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => {
          setIsDocLoading(false);
        });
    }
  };

  const getPrimaryEmail = () => {
    const dataEmails: any = {};
    const allEmails = [
      get(selectedDealData, 'email'),
      get(selectedDealData, 'otherEmails'),
    ].filter((x) => x);
    Object.keys(allEmails).forEach((key) => {
      dataEmails[key] = false;
    });
    dataEmails[get(selectedDealData, 'email')] = true;
    setEmails(dataEmails);
  };

  const isLockedYcDealFields = (fieldName?: string) => {
    if (fieldName) {
      if (
        !isEmpty(dealMapping) &&
        !isEmpty(watch(fieldName)) &&
        get(dealMapping, 'unlocked') === false
      )
        return true;
    } else if (!isEmpty(dealMapping) && get(dealMapping, 'unlocked') === false)
      return true;
    return false;
  };

  const getCalculatedCurrentValuation = () => {
    // if (
    //   isEmpty(selectedDealData) &&
    //   parseFloat(watch('lastRoundValuation')) >= 0
    // ) {
    //   if (!isEmpty(get(errors, 'currentValuation')))
    //     clearErrors('currentValuation');
    //   setValue('currentValuation', watch('lastRoundValuation'));
    // }
    if (size(watch('valuationRoundDetail')) > 0) {
      if (!isEmpty(get(errors, 'currentValuation')))
        clearErrors('currentValuation');
      setValue('currentValuation', watch(`valuationRoundDetail[0].valuation`));
    }
  };

  const getCalculatedCurrentValue = () => {
    // IF SAFE && only one default price round currentValue = investedAmount + (((priceRoundValuation - lastRoundValuation) / lastRoundValuation) * investedAmount)
    // ELSE currentValue = investedAmount * (currentValuation / entryValuation)
    // currentValuation = Latest Valuation of Price Round
    if (
      !isEmpty(watch('NoOfSharesAlloted')) &&
      !isEmpty(watch('totalNoOfShares')) &&
      !isEmpty(watch('pricePerShare'))
    ) {
      return;
    }
    if (
      [null, undefined, ''].includes(watch('lastRoundValuation')) &&
      [TYPE_OF_INSTRUMENT.SAFE].includes(watch('investedInstrumentType'))
    ) {
      setValue('currentValue', '');
    } else if (parseFloat(watch('lastRoundValuation')) === 0) {
      if (!isEmpty(get(errors, 'currentValue'))) clearErrors('currentValue');
      setValue('currentValue', 0);
      return;
    } else {
      // if (
      //   [TYPE_OF_INSTRUMENT.SAFE].includes(watch('investedInstrumentType')) &&
      //   size(watch('valuationRoundDetail')) <= 1
      // ) {
      //   if (
      //     parseFloat(watch('investedAmount')) >= 0 &&
      //     parseFloat(watch('lastRoundValuation')) > 0 &&
      //     parseFloat(watch('priceRoundValuation')) >= 0
      //   ) {
      //     if (!isEmpty(get(errors, 'currentValue')))
      //       clearErrors('currentValue');
      //     setValue(
      //       'currentValue',
      //       parseFloat(
      //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //         //@ts-ignore
      //         parseFloat(watch('investedAmount')) +
      //           ((parseFloat(watch('priceRoundValuation')) -
      //             parseFloat(watch('lastRoundValuation'))) /
      //             parseFloat(watch('lastRoundValuation'))) *
      //             parseFloat(watch('investedAmount')),
      //       ).toFixed(2),
      //     );
      //   }
      // }

      if (
        parseFloat(watch('investedAmount')) >= 0 &&
        parseFloat(watch('lastRoundValuation')) > 0 &&
        parseFloat(watch('currentValuation')) >= 0
      ) {
        if (!isEmpty(get(errors, 'currentValue'))) clearErrors('currentValue');
        setValue(
          'currentValue',
          (
            parseFloat(watch('investedAmount')) *
            (parseFloat(watch('currentValuation')) /
              parseFloat(watch('lastRoundValuation')))
          ).toFixed(2),
        );
      }
    }
  };

  const getCalculatedPercentageOwned = () => {
    if (
      !isEmpty(watch('NoOfSharesAlloted')) &&
      !isEmpty(watch('totalNoOfShares')) &&
      !isEmpty(watch('pricePerShare'))
    ) {
      return;
    }
    if (
      [null, undefined, ''].includes(watch('lastRoundValuation')) &&
      [TYPE_OF_INSTRUMENT.SAFE].includes(watch('investedInstrumentType'))
    ) {
      setValue('percentageOwned', '');
    } else if (parseFloat(watch('lastRoundValuation')) === 0) {
      setValue('percentageOwned', 0);
      return;
    } else {
      if (
        parseFloat(watch('investedAmount')) >= 0 &&
        parseFloat(watch('lastRoundValuation')) > 0 &&
        [
          TYPE_OF_INSTRUMENT.SAFE,
          TYPE_OF_INSTRUMENT.EQUITY,
          TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
        ].includes(watch('investedInstrumentType'))
      ) {
        setValue(
          'percentageOwned',
          (
            (parseFloat(watch('investedAmount')) /
              parseFloat(watch('lastRoundValuation'))) *
            100
          ).toFixed(2),
        );
      }
    }
  };

  const getCalculatedRealisedMoic = () => {
    if (parseFloat(watch('investedAmount')) === 0) {
      setValue('moic', 0);
      return;
    }
    if (
      parseFloat(watch('investedAmount')) > 0 &&
      parseFloat(watch('exitValuation')) >= 0 &&
      [
        TYPE_OF_INSTRUMENT.SAFE,
        TYPE_OF_INSTRUMENT.EQUITY,
        TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
      ].includes(watch('investedInstrumentType'))
    ) {
      setValue(
        'moic',
        (
          parseFloat(watch('exitValuation')) /
          parseFloat(watch('investedAmount'))
        ).toFixed(1),
      );
    }
  };

  const getCalculatedMoic = () => {
    if (
      [null, undefined, ''].includes(watch('lastRoundValuation')) &&
      [TYPE_OF_INSTRUMENT.SAFE].includes(watch('investedInstrumentType'))
    ) {
      setValue('moic', '');
    } else if (parseFloat(watch('investedAmount')) === 0) {
      setValue('moic', 0);
      return;
    } else {
      if (
        parseFloat(watch('investedAmount')) > 0 &&
        parseFloat(watch('currentValue')) >= 0 &&
        [
          TYPE_OF_INSTRUMENT.SAFE,
          TYPE_OF_INSTRUMENT.EQUITY,
          TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
        ].includes(watch('investedInstrumentType'))
      ) {
        setValue(
          'moic',
          (
            parseFloat(watch('currentValue')) /
            parseFloat(watch('investedAmount'))
          ).toFixed(1),
        );
      }
    }
  };

  const onConvertDealToActive = (isCloseSidepane = true) => {
    if (disableAsPerPermission) {
      return;
    }
    setErrorMessage('');
    const data: any = { ...getValues() };
    setErrorMessage('');
    const obj = createActiveDealObj(data);
    obj.dealStatus = DEAL_STATUS_TYPE.ACTIVE;
    if (obj.userRoles) {
      delete obj.userRoles;
    }
    obj.createdAt = new Date();
    setIsLoading((prevState: any) => ({
      ...prevState,
      active: true,
    }));
    convertVcDeal(obj)
      .then((res: any) => {
        if (!isEmpty(selectedFiles)) {
          uploadMultipleDocumentFile(res.id, isCloseSidepane);
        } else {
          getAllVcDeals();
          handleClose();
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        if (message.includes(`Please select different email`)) {
          setError(`email`, {
            type: 'custom',
            message: 'Please add different email.',
          });
        }
      })
      .finally(() =>
        setIsLoading((prevState: any) => ({
          ...prevState,
          active: false,
        })),
      );
  };

  const isAIMonotoringAccordionLocked = () => {
    const data: any = {
      expanded: expanded.includes('Monitoring'),
      tooltip: '',
    };

    if (isEmpty(selectedDealData)) {
      data.tooltip = `Please make sure to add and save the company name ${
        (userRoles || []).includes(ROLE.ANGEL_INVESTOR) ? '' : 'and fund'
      }  to be able to use the AI Monitoring.`;
      data.expanded = false;
    }

    return data;
  };

  React.useEffect(() => {
    if (selectedDeal) {
      setIsDealLoading(true);
      getVcDeal(selectedDeal)
        .then((res: any) => {
          setIsEditModeURL({
            ...isEditModeURL,
            companyUrl: res.companyUrl ? false : true,
          });
          setSelectedDealData(res);
          setIsDealLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsDealLoading(false);
        });
      getUploadedDocuments();
    }
  }, [selectedDeal]);

  React.useEffect(() => {
    if (!isEmpty(selectedDealData)) {
      setValue('dealStatus', get(selectedDealData, 'dealStatus'));
      if (
        !isEmpty(get(selectedDealData, 'vcFundId')) &&
        (userRoles || []).includes(ROLE.ANGEL_INVESTOR)
      ) {
        setValue('vcFundId', get(selectedDealData, 'vcFundId'));
      } else if ((userRoles || []).includes(ROLE.VC_ADMIN)) {
        setValue(
          'vcFundId',
          get(selectedDealData, 'vcFundId') &&
            isEmpty(
              fundList.find(
                (fund: any) => fund.value === get(selectedDealData, 'vcFundId'),
              ),
            )
            ? null
            : get(selectedDealData, 'vcFundId'),
        );
      }
      setValue('companyName', get(selectedDealData, 'companyName'));
      if (!isEmpty(get(selectedDealData, 'otherEmails'))) {
        setValue(
          'email',
          [
            get(selectedDealData, 'email'),
            ...get(selectedDealData, 'otherEmails').split(','),
          ].filter((x) => x),
        );
      } else if (!isEmpty(get(selectedDealData, 'email'))) {
        setValue('email', [get(selectedDealData, 'email')]);
      }
      setValue('companyUrl', get(selectedDealData, 'companyUrl'));
      setIsMFNFreezeChecked(get(selectedDealData, 'freezeMfnValuation'));
      setValue('sector', get(selectedDealData, 'sector'));
      setValue(
        'geography',
        getBusinessOpsCountryObject(get(selectedDealData, 'geography')),
      );
      setValue(
        'countryOfOperation',
        get(selectedDealData, 'countryOfOperation')
          ? get(selectedDealData, 'countryOfOperation')
              ?.split(',')
              .map((i: any) => getCountryOfOperationsObject(i))
          : [],
      );
      setValue(
        'tags',
        get(selectedDealData, 'tags')
          ? get(selectedDealData, 'tags').split(',')
          : [],
      );
      setValue('other', get(selectedDealData, 'other'));
      setValue('lastFundingRound', get(selectedDealData, 'lastFundingRound'));
      setValue(
        'investedInstrumentType',
        get(selectedDealData, 'investedInstrumentType'),
      );
      setValue(
        'termsOfSafe',
        get(selectedDealData, 'termsOfSafe')
          ? get(selectedDealData, 'termsOfSafe').split(',')
          : [],
      );
      if (get(selectedDealData, 'priceRoundValuation')) {
        setValue(
          'priceRoundValuation',
          get(selectedDealData, 'priceRoundValuation'),
        );
      }
      if (get(selectedDealData, 'valuationCap')) {
        setValue('valuationCap', get(selectedDealData, 'valuationCap'));
      }
      if (get(selectedDealData, 'discountMfn')) {
        setValue('discountMfn', get(selectedDealData, 'discountMfn'));
      }
      if (get(selectedDealData, 'mfnValuation')) {
        setValue('mfnValuation', get(selectedDealData, 'mfnValuation'));
      }
      if (get(selectedDealData, 'safeToEquityValuation')) {
        setValue(
          'safeToEquityValuation',
          get(selectedDealData, 'safeToEquityValuation'),
        );
      }
      setValue(
        'investmentDate',
        !isEmpty(get(selectedDealData, 'investmentDate'))
          ? moment(get(selectedDealData, 'investmentDate')).format('YYYY-MM-DD')
          : get(selectedDealData, 'investmentDate'),
      );
      setValue('investedAmount', get(selectedDealData, 'investedAmount'));
      setValue(
        'lastRoundValuation',
        get(selectedDealData, 'lastRoundValuation'),
      );
      setValue('currentValuation', get(selectedDealData, 'currentValuation'));
      setValue('currentValue', get(selectedDealData, 'currentValue'));
      setValue('percentageOwned', get(selectedDealData, 'percentageOwned'));
      setValue('moic', get(selectedDealData, 'moic'));
      // setValue('irr', get(selectedDealData, 'irr'));
      setValue('remark', get(selectedDealData, 'remark'));
      setValue('companyBlurp', get(selectedDealData, 'companyBlurp'));
      setValue(
        'investmentMemoLink',
        get(selectedDealData, 'investmentMemoLink'),
      );
      setValue(
        'companyPitchdeckLink',
        get(selectedDealData, 'companyPitchdeckLink'),
      );
      setValue('exitValuation', get(selectedDealData, 'exitValuation'));
      handleEditModeChange(get(selectedDealData, 'companyName') ? true : false);
      setIsEditModeURL({
        ...isEditModeURL,
        companyUrl: get(selectedDealData, 'companyUrl') ? false : true,
      });
      getPrimaryEmail();
    }
    if (searchParams.has('prospectiveId')) {
      setIsConvertDeal(true);
      setValue(
        'lastRoundValuation',
        get(selectedDealData, 'proposedValuation'),
      );
      setValue('currentValuation', get(selectedDealData, 'proposedValuation'));
      setValue(
        'investmentDate',
        !isEmpty(get(selectedDealData, 'estimatedCloseDate'))
          ? moment(get(selectedDealData, 'estimatedCloseDate')).format(
              'YYYY-MM-DD',
            )
          : get(selectedDealData, 'estimatedCloseDate'),
      );
      setValue('investedAmount', get(selectedDealData, 'proposedAmount'));
    }
  }, [selectedDealData]);

  const vcDealFoundersDetails = get(selectedDealData, 'vcDealFounders');
  React.useEffect(() => {
    if (vcDealFoundersDetails && vcDealFoundersDetails.length) {
      let founderUrlEmptyCheck = { ...isEditModeURL };
      setValue('vcDealFounders', vcDealFoundersDetails);
      vcDealFoundersDetails.map((item: any, index: any) => {
        setValue(`vcDealFounders[${index}].id`, get(item, 'id'));
        setValue(`vcDealFounders[${index}].name`, get(item, 'name'));
        setValue(
          `vcDealFounders[${index}].linkedinUrl`,
          get(item, 'linkedinUrl'),
        );
        founderUrlEmptyCheck = {
          ...founderUrlEmptyCheck,
          linkedinUrl: {
            ...founderUrlEmptyCheck.linkedinUrl,
            [index]: get(item, 'linkedinUrl') ? false : true,
          },
        };
      });
      setIsEditModeURL({ ...founderUrlEmptyCheck });
    }
  }, [vcDealFoundersDetails]);

  let valuationRoundDetail = get(selectedDealData, 'valuationRoundDetail');
  React.useEffect(() => {
    if (valuationRoundDetail && valuationRoundDetail.length) {
      valuationRoundDetail = orderBy(
        valuationRoundDetail,
        ['createdAt'],
        ['desc'],
      );
      setValue('valuationRoundDetail', valuationRoundDetail);
      valuationRoundDetail.map((item: any, index: any) => {
        setValue(`valuationRoundDetail[${index}].id`, get(item, 'id'));
        setValue(
          `valuationRoundDetail[${index}].roundName`,
          getPricingLatestRoundObject(
            get(selectedDealData, `valuationRoundDetail[${index}].roundName`),
          ),
        );
        setValue(`valuationRoundDetail[${index}].date`, get(item, 'date'));
        setValue(
          `valuationRoundDetail[${index}].valuation`,
          get(item, 'valuation'),
        );
      });
    }
  }, [valuationRoundDetail]);

  React.useEffect(() => {
    if (!isEmpty(selectedDealData) && dealPartiallyExited) {
      if (type === 'REALIZED_DEAL') {
        const investedAmount =
          parseFloat(get(selectedDealData, 'investedAmount')) *
          (parseInt(get(selectedDealData, 'exitPercentage') || 0) / 100);
        setValue('investedAmount', investedAmount);
        setValue(
          'moic',
          (get(selectedDealData, 'exitValuation') / investedAmount).toFixed(1),
        );
      } else {
        const investedAmount = getRemainingInvestedAmount(
          get(selectedDealData, 'exitPercentage') || 0,
          get(selectedDealData, 'investedAmount'),
        );
        setValue('investedAmount', investedAmount);
      }
    }
  }, [selectedDealData, dealPartiallyExited]);

  React.useEffect(() => {
    if (
      !isEmpty(selectedDealData) &&
      get(selectedDealData, 'exitPercentage') &&
      parseInt(get(selectedDealData, 'exitPercentage')) > 1 &&
      parseInt(get(selectedDealData, 'exitPercentage')) < 100
    ) {
      setDealPartiallyExited(true);
    } else {
      setDealPartiallyExited(false);
    }
  }, [selectedDealData]);

  React.useEffect(() => {
    if (!isEmpty(fundList) && (userRoles || []).includes(ROLE.ANGEL_INVESTOR)) {
      setValue('vcFundId', get(fundList[0], 'value'));
    }
    setDealMapping({});
  }, [fundList]);

  React.useEffect(() => {
    if (!isEmpty(errors)) {
      setExpanded(['Overview', 'Investment Details', 'Documents', 'Notes']);
    }
  }, [errors]);

  const getAllVcDealInvestmentUpdate = () => {
    if (selectedDeal) {
      Promise.all([
        getVcDealInvestmentUpdateById(selectedDeal),
        getDealJob(selectedDeal),
      ])
        .then(([invUpdate, dealJob]: any) => {
          setSelectedDealInvestmentUpdateData(
            get(invUpdate, 'investmentUpdates'),
          );
          // setSelectedDealInvestmentUpdateData([invUpdate]);
          setSelectedDealJobs(dealJob?.jobs);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  React.useEffect(() => {
    getAllVcDealInvestmentUpdate();
  }, [selectedDeal]);

  React.useEffect(() => {
    if (pusherInitialized && channel) {
      channel?.bind(PUSHER_EVENT_NAME.INVESTMENT_UPDATE_JOB_CREATED, () => {
        getAllVcDealInvestmentUpdate();
      });
    }
  }, [pusherInitialized, channel]);

  return (
    <>
      <SidePane
        anchor={'right'}
        open={open}
        showDrawerHeader={false}
        onSidePaneClose={() => {
          if (isFieldDataChanged()) {
            setSaveChangesModal(true);
          } else handleClose();
        }}
        isLoading={isLoadingSidePaneData()}
      >
        <>
          <Box className={classes.sidePaneHeaderContainer}>
            {searchParams.has('prospectiveId') && isConvertDeal && (
              <Box className={classes.preloadedPipelineTextBox}>
                <WrappedTypography className={classes.preloadedPipelineText}>
                  <CloseIcon
                    className={classes.closeIconImg}
                    onClick={() => {
                      if (disableAsPerPermission) {
                        return;
                      }
                      setIsConvertDeal(false);
                    }}
                  />
                  {`The company ${watch(
                    'companyName',
                  )} has been added to your Portfolio. Please provide the investment details for this deal.
`}
                </WrappedTypography>
              </Box>
            )}
            {errorMessage && (
              <WrappedTypography className={classes.errorMessageSidePen}>
                {errorMessage}
              </WrappedTypography>
            )}
            <Box className={classes.sidePaneHeader}>
              <Box className={classes.rightHeadSubContainer}>
                <Box className={classes.companyNameEditBox}>
                  {!isEditMode && (
                    <>
                      <WrappedTypography className={classes.companyNameText}>
                        {watch('companyName') || 'Company Name'}
                        <span className={classes.errorText}>*</span>
                      </WrappedTypography>
                      {!disableAsPerPermission && (
                        <EditOutlinedIcon
                          className={classes.editIcon}
                          onClick={() => {
                            handleEditModeChange(isEditMode ? false : true);
                          }}
                        />
                      )}
                    </>
                  )}
                </Box>
                <Box
                  className={
                    isEditMode
                      ? classes.companyNameEditModeBox
                      : classes.companyNameEditBox
                  }
                >
                  <InputTextField
                    placeholder={'Enter Company Name'}
                    control={control}
                    className="company_name"
                    name={'companyName'}
                    InputProps={
                      !isEditMode
                        ? {
                            style: {
                              visibility: 'hidden',
                              height: 0,
                            },
                          }
                        : {}
                    }
                    onBlur={() => {
                      handleEditModeChange(isEditMode ? false : true);
                    }}
                    disabled={disableAsPerPermission}
                  />
                  {isEditMode && !disableAsPerPermission && (
                    <EditOutlinedIcon
                      className={classes.editIcon}
                      onClick={() => {
                        handleEditModeChange(isEditMode ? false : true);
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box className={classes.leftHeadSubContainer}>
                {!disableAsPerPermission && (
                  <Box
                    className={classes.saveBox}
                    onClick={
                      (watch('investedInstrumentType') ===
                        TYPE_OF_INSTRUMENT.SAFE &&
                        !isEmpty(watch('termsOfSafe')) &&
                        !isEmpty(get(safeFields, 'error'))) ||
                      get(isLoading, 'active')
                        ? () => null
                        : !isEmpty(dealId)
                        ? handleSubmit(() => onConvertDealToActive())
                        : handleSubmit(() => onSubmit(true))
                    }
                  >
                    <SaveOutlinedIcon className={classes.saveIcon} />
                  </Box>
                )}
                <CloseIcon
                  onClick={() => {
                    handleClose();
                  }}
                  className={classes.closeIcon}
                />
              </Box>
            </Box>
            {!isLoadingSidePaneData() &&
              !isEmpty(get(selectedDealData, 'aiAnalyst.ONE_LINER')) && (
                <Box className={classes.aiContainer}>
                  <Box className={classes.oneLiner}>
                    {get(selectedDealData, 'aiAnalyst.ONE_LINER')}
                  </Box>
                </Box>
              )}
          </Box>
          <Box className={classes.sidePaneContainer}>
            <form
              onSubmit={handleSubmit(() => onSubmit(true))}
              data-testid="ActiveDealForm"
              noValidate
            >
              <Box className={classes.accordMainBox}>
                <Accordion
                  subheading="Overview"
                  expanded={expanded.includes('Overview')}
                  handleChange={(e: string) => handleChange(e)}
                  index="Overview"
                  className={classes.accordBox}
                  backgroundStyle={{
                    borderRadius: `8px`,
                  }}
                >
                  <OverviewTab
                    userRoles={userRoles}
                    fundList={fundList}
                    selectedDealData={selectedDealData}
                    control={control}
                    errors={errors}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    fields={founderField}
                    append={founderAppend}
                    remove={founderRemove}
                    isLockedYcDealFields={isLockedYcDealFields}
                    isEditModeURL={isEditModeURL}
                    setIsEditModeURL={setIsEditModeURL}
                    emails={emails}
                    setEmails={setEmails}
                    selectedEmail={selectedEmail}
                    setSelectedEmail={setSelectedEmail}
                    disableAsPerPermission={disableAsPerPermission}
                  />
                </Accordion>
                <Accordion
                  subheading={
                    <span className={classes.aiMonitoringText}>
                      Monitoring
                      <img src={AIGradient} alt={AIGradient} height={25} />
                    </span>
                  }
                  expanded={
                    isLockedYcDealFields()
                      ? false
                      : isAIMonotoringAccordionLocked().expanded
                  }
                  handleChange={(e: string) => handleChange(e)}
                  index="Monitoring"
                  className={classes.accordBox}
                  backgroundStyle={{
                    backgroundImage: `url(${AIArt})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '8px 8px 0px 0px',
                  }}
                  tooltip={isAIMonotoringAccordionLocked().tooltip}
                >
                  <AIMonitorTab
                    selectedDealInvestmentUpdateData={
                      selectedDealInvestmentUpdateData
                    }
                    selectedDealJobs={selectedDealJobs}
                    selectedDealData={selectedDealData}
                    setSelectedDealInvestmentUpdateData={
                      setSelectedDealInvestmentUpdateData
                    }
                    getAllVcDealInvestmentUpdate={getAllVcDealInvestmentUpdate}
                    disableAsPerPermission={disableAsPerPermission}
                  />
                </Accordion>
                <Accordion
                  subheading="Investment Details"
                  expanded={expanded.includes('Investment Details')}
                  handleChange={(e: string) => handleChange(e)}
                  index="Investment Details"
                  className={classes.accordBox}
                  backgroundStyle={{
                    borderRadius: `8px`,
                  }}
                >
                  <InvestmentTab
                    type={type}
                    investorId={investorId}
                    vcFirmId={vcFirmId}
                    vcFirmCurrency={vcFirmCurrency}
                    vcFirmCurrencySymbol={vcFirmCurrencySymbol}
                    dealPartiallyExited={dealPartiallyExited}
                    selectedDealData={selectedDealData}
                    selectedDeal={selectedDeal}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    watch={watch}
                    resetField={resetField}
                    getCalculatedCurrentValuation={
                      getCalculatedCurrentValuation
                    }
                    getCalculatedCurrentValue={getCalculatedCurrentValue}
                    getCalculatedPercentageOwned={getCalculatedPercentageOwned}
                    getCalculatedMoic={getCalculatedMoic}
                    getCalculatedRealisedMoic={getCalculatedRealisedMoic}
                    safeFields={safeFields}
                    setSafeFields={setSafeFields}
                    valuationField={valuationField}
                    valuationPrepend={valuationPrepend}
                    valuationRemove={valuationRemove}
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    setIsMFNFreezeChecked={setIsMFNFreezeChecked}
                    isMFNFreezeChecked={isMFNFreezeChecked}
                    disableAsPerPermission={disableAsPerPermission}
                  />
                </Accordion>
                <Accordion
                  subheading="Documents"
                  expanded={
                    isLockedYcDealFields()
                      ? false
                      : expanded.includes('Documents')
                  }
                  buttons={
                    isLockedYcDealFields() ? (
                      <CustomizedTooltip
                        title="You have to unlock the deal to acess documents."
                        placement="left"
                      >
                        <img src={LockIcon} alt={LockIcon} height={18} />
                      </CustomizedTooltip>
                    ) : (
                      ''
                    )
                  }
                  handleChange={(e: string) => handleChange(e)}
                  index="Documents"
                  className={classes.accordBox}
                  backgroundStyle={{
                    borderRadius: `8px`,
                  }}
                >
                  <DocumentsTab
                    selectedDealData={selectedDealData}
                    expanded={expanded}
                    control={control}
                    watch={watch}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    setSelectedDocument={setSelectedDocument}
                    setIsDeleteDocument={setIsDeleteDocument}
                    setIsAddDocument={setIsAddDocument}
                    handleChange={handleChange}
                    disableAsPerPermission={disableAsPerPermission}
                  />
                </Accordion>
                <Accordion
                  subheading="Notes"
                  expanded={expanded.includes('Notes')}
                  handleChange={(e: string) => handleChange(e)}
                  index="Notes"
                  className={classes.accordBox}
                  backgroundStyle={{
                    borderRadius: `8px`,
                  }}
                >
                  <Grid container spacing={1} className={classes.textfieldBox}>
                    <Grid item xs={12}>
                      <WrappedTypography type={'caption'}>
                        Notes
                      </WrappedTypography>
                      <InputTextField
                        placeholder="Write here..."
                        control={control}
                        name="remark"
                        multiline={true}
                        variant={'outlined'}
                        rows={2}
                        disabled={disableAsPerPermission}
                      />
                    </Grid>
                  </Grid>
                </Accordion>
              </Box>
            </form>
          </Box>
          <Box className={classes.backProspectiveContinueBox}>
            {selectedDeal ? (
              <>
                {!isEmpty(dealId) ? (
                  <Button
                    type="button"
                    variant="standard"
                    name={`Save`}
                    isLoading={
                      get(isLoading, 'active') || get(isLoading, 'upload')
                    }
                    disabled={
                      get(isLoading, 'active') ||
                      get(isLoading, 'upload') ||
                      disableAsPerPermission
                    }
                    onClick={handleSubmit(() => onConvertDealToActive())}
                  />
                ) : (
                  <Button
                    type="button"
                    variant="standard"
                    name={`Update`}
                    isLoading={
                      get(isLoading, 'active') || get(isLoading, 'upload')
                    }
                    disabled={
                      get(isLoading, 'active') ||
                      get(isLoading, 'upload') ||
                      disableAsPerPermission
                    }
                    onClick={() => {
                      setSafeFields({});
                      onSubmit();
                    }}
                  />
                )}
                {selectedDealData.dealStatus !== DEAL_STATUS_TYPE.EXITED &&
                type !== 'REALIZED_DEAL' ? (
                  <>
                    <Button
                      sx={{ marginLeft: 5 }}
                      name="Move to Realised Deals"
                      className={classes.movedToRealisedDealBtn}
                      disabled={
                        get(isLoading, 'active') ||
                        get(selectedDealData, 'lastRoundValuation') === null ||
                        get(selectedDealData, 'investedAmount') === null ||
                        get(selectedDealData, 'investmentDate') === null ||
                        disableAsPerPermission
                      }
                      onClick={() => setIsRealisedDeal(true)}
                    />
                    <Button
                      sx={{ marginLeft: 5 }}
                      name="Written off"
                      className={classes.writtenOffDealBtn}
                      disabled={
                        get(isLoading, 'active') ||
                        get(selectedDealData, 'lastRoundValuation') === null ||
                        get(selectedDealData, 'investedAmount') === null ||
                        get(selectedDealData, 'investmentDate') === null ||
                        disableAsPerPermission
                      }
                      onClick={() => setIsWrittenOffDeal(true)}
                    />
                  </>
                ) : (
                  <Button
                    sx={{ marginLeft: 5 }}
                    variant={'outlined'}
                    name="Cancel"
                    disabled={get(isLoading, 'active')}
                    onClick={() => handleClose()}
                  />
                )}
              </>
            ) : (
              <>
                <Button
                  type="button"
                  variant="standard"
                  name={`Save`}
                  isLoading={
                    get(isLoading, 'active') || get(isLoading, 'upload')
                  }
                  disabled={
                    get(isLoading, 'active') ||
                    get(isLoading, 'upload') ||
                    disableAsPerPermission
                  }
                  onClick={handleSubmit(() => onSubmit())}
                />
                <Button
                  sx={{ marginLeft: 5 }}
                  variant={'outlined'}
                  name="Cancel"
                  disabled={get(isLoading, 'active')}
                  onClick={() => handleClose()}
                />
              </>
            )}
          </Box>
        </>
      </SidePane>
      {isRealisedDeal && (
        <MoveDealToRealised
          isRealisedDeal={isRealisedDeal}
          setIsRealisedDeal={setIsRealisedDeal}
          investorId={investorId}
          getAllVcDeals={getAllVcDeals}
          handleClose={handleClose}
          selectedDealData={selectedDealData}
          selectedDeal={selectedDeal}
          vcFirm={vcFirm}
          vcFirmCurrency={vcFirmCurrency}
          vcFirmCurrencySymbol={vcFirmCurrencySymbol}
        />
      )}
      {isWrittenOffDeal && (
        <WrittenOffDeal
          setIsWrittenOffDeal={setIsWrittenOffDeal}
          isWrittenOffDeal={isWrittenOffDeal}
          getAllVcDeals={getAllVcDeals}
          handleClose={handleClose}
          selectedDealData={selectedDealData}
          selectedDeal={selectedDeal}
          vcFirm={vcFirm}
          investorId={investorId}
        />
      )}
      {isDeleteDocument && (
        <DeleteDealDocument
          isDeleteDocument={isDeleteDocument}
          setIsDeleteDocument={setIsDeleteDocument}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
          investorId={investorId}
          selectedDeal={selectedDeal}
          documents={selectedFiles}
          setDocuments={setSelectedFiles}
          type={'ACTIVE'}
        />
      )}
      {isAddDocument && (
        <AddOtherDocument
          isOpen={isAddDocument}
          setIsOpen={setIsAddDocument}
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
        />
      )}
      {saveChangesModal && (
        <SaveChangesAlert
          saveChangesModal={saveChangesModal}
          setSaveChangesModal={setSaveChangesModal}
          onClickIgnore={handleClose}
          onClickSave={handleSubmit(() => onSubmit())}
        />
      )}
    </>
  );
};

export default ActiveSidePane;
