/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import {
  IconButton,
  InputBase,
  MenuItem,
  Menu,
  Chip,
  // Button as MuiButton,
  CircularProgress,
  Skeleton,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogContent,
  InputAdornment,
  // Slide,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { get, isEmpty, filter, findIndex, size, orderBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarIcon from '@mui/icons-material/Star';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Switch, { SwitchProps } from '@mui/material/Switch';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Auth } from 'aws-amplify';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';

import {
  CopyWhiteIcon,
  DownloadExcel,
  EditIcon,
  FilterIcon,
  // AddUserIcon,
  ProgressBackground,
  ProspectiveDemo,
  RefreshOrangeIcon,
  SaveIcon,
} from 'assets';
import {
  WrappedTypography,
  Box,
  Button,
  CustomizedTable,
  WrappedSelect,
  ConfirmationDialog,
  SnackBar,
  CustomizedTooltip,
  WrappedAutocomplete,
  Loader,
  InputTextField,
  InteractionObserver,
} from 'components';
import {
  errorMessageHandler,
  getDealActionListV2,
  getBusinessOpsCountryObject,
  checkSubstringExists,
  formatAmount,
  userHasCreditBalance,
  exhaustCreditLimit,
  isUserOnJoiningPlan,
  downloadLink,
  // checkVcInsightsReportUsageLimit,
} from 'common/utils/helpers';
import { FN } from 'common/types';
import history from 'common/utils/history';
import {
  switchToStartupYardstick,
  addVcDealToStarredDeal,
  updateVcDealInfo,
  getAllYcPreloadedVcAdminDeals,
  getVcAdminDealMappingsByInvestorId,
  unlockVcAdminPreloadedDeal,
  getVcDeal,
  createDealJob,
  getVcFirmDocuments,
  getDealJob,
  downloadExcelByVcFirmId,
  investInAdminFundProcess,
  updateDashboardSettings,
  updateInvestUpdateOrDealEmailReader,
} from 'services';
import {
  ROLE,
  DEAL_STATUS_TYPE,
  CURRENCY_MODAL_TYPE,
  PROSPECTIVE_DEAL_SAMPLE_URL,
  ANGEL_PROSPECTIVE_DEAL_SAMPLE_URL,
  VC_DEAL_SOURCE,
  VC_DEAL_STAGE,
  VC_DEAL_STAGE_MAPPER,
  ERROR_MESSAGES,
  FREE_PLATFORM_DEAL,
  PUSHER_EVENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  VC_INVESTOR_WEB_URL,
  // ONBOARD_TYPE,
  emailRegex,
} from 'common/utils/constants';
import { RootState } from 'redux-modules/Store/RootState';
import {
  prospectiveDealColumnMapping,
  vcDealStageList,
  sectorList,
  vcDealsTagsList,
  countryOfOperationList,
} from 'common/utils/option-list';
import { postLoginLogAmpEvent } from 'config/amplitude';
import {
  fetchOutstandingBalance,
  planExpiredDialog,
} from 'redux-modules/Global/Actions';
import {
  getVCFirmSubscription,
  submitVCProfileDetails,
  updateVCFirm,
} from 'redux-modules/VCFirm/Actions';
import { getDealAIStatus } from 'redux-modules/Deals/Actions';

import CsvUploadDeal from '../components/CsvUploadDeal';
import styles from './styles';
import {
  InviteToYardstick,
  AddDealsToCoInvest,
  DeleteDeal,
  UpdateCurrency,
  SendNotification,
  ConvertDealToActive,
  RejectDeal,
  FundDetail,
  StartSubscription,
} from '../DialogBox';
import UnlockDealDialog from './components/UnlockDealDialog';
import ProspectiveDealSidePane from './ProspectiveDealSidePane';
import YCDeals from './components/YCDeals';
import UnlockLatestYcDealDialog from './components/UnlockLatestYcDealDialog';
import AiAnalystContent from './components/AiAnalystContent';
import GenerateNoteDialog from './components/GenerateNoteDialog';
import AiAnalystTableCell from './components/AiAnalystTableCell';
import ChargeDialog from './components/ChargeDialog';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CryptoJS = require('crypto-js');

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props}></Slide>;
// });

export const YCDealSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: '6px 8px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.primary.main
            : theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const TYPE = {
  USER: 'USER',
  STATUS: 'STATUS',
};

type props = {
  isLoading: boolean;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  fundList: Array<Record<string, FN>>;
  allVcDeals: Array<Record<string, FN>>;
  getAllVcDeals: any;
  totalfilteredSearchDataCount?: number;
  filteredSearchData: Array<Record<string, FN>>;
  setFilteredSearchData: React.Dispatch<React.SetStateAction<FN>>;
  userRoles: Array<string>;
  vcFirm?: any;
  permissionInfo: any;
  vcFirmCurrency: string;
  vcFirmCurrencySymbol: any;
  vcFirmUsers: FN;
  handleDivClick: any;
};

type currencyModalProps = {
  isOpen: boolean;
  type: string;
};

const UNLOCK_DIALOG_KEY = 'dontShowUnlockDealDialog';
const UNLOCK_VC_DIALOG_KEY = 'dontShowVCUnlockDealDialog';

const ProspectiveDeals = ({
  isLoading,
  errorMessage,
  setErrorMessage,
  fundList,
  allVcDeals,
  getAllVcDeals,
  totalfilteredSearchDataCount = 0,
  filteredSearchData,
  setFilteredSearchData,
  userRoles,
  // vcFirm,
  permissionInfo,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
  vcFirmUsers,
  handleDivClick,
}: props) => {
  const {
    control,
    watch,
    setValue,
    setError: setEmailReaderError,
    clearErrors,
    formState: { errors: emailReaderErrors },
  } = useForm();

  const classes = styles();
  const dispatch = useDispatch();
  const env: string = process.env.REACT_APP_ENV || 'dev';

  const {
    user: { investorId, email },
    user,
  } = useSelector(({ Auth }: RootState) => Auth);
  const { ycBatch } = useSelector(({ Global }: RootState) => Global);
  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);
  const { dealAiStatus, loadingDealAiStatus } = useSelector(
    ({ Deals }: RootState) => Deals,
  );

  const [searchParams] = useSearchParams();
  const dealStage: any = searchParams.get('stage');
  const dealId: any = searchParams.get('id');
  const ycFilter = searchParams.get('filter') === 'YC';
  const ycbatchname = searchParams.get('ycbatchname') || '';
  const batch = ycBatch?.allYCBatchList?.some(
    (item: any) => item?.ycBatchName === decodeURIComponent(ycbatchname),
  )
    ? decodeURIComponent(ycbatchname)
    : get(ycBatch, 'currentYCBatch', '');
  const expandSectionSidePane: any = searchParams.get('expand');
  const menuOpen: any = searchParams.get('open');

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const disableAsPerPermission = permissionInfo.DEAL_PIPELINE === 'view';
  const {
    paymentCards = [],
    dealPipeline,
    channel,
    pusherInitialized,
    balance = 0,
    aiAnalaystUsage,
  } = useSelector(({ Global }: RootState) => Global);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);

  const { valid, joiningPlan } = subscription;

  const [selectedStageFilter, setSelectedStageFilter] =
    React.useState<any>('allDeal');
  const [searchStringText, setSearchStringText] = React.useState<string>('');
  const [updateCurrencyModal, setUpdateCurrencyModal] =
    React.useState<currencyModalProps>({
      isOpen: false,
      type: '',
    });
  const [usersList, setUsersList] = React.useState<any[]>([]);
  const [modal, setModal] = React.useState<boolean>(
    menuOpen === 'manually' || false,
  );
  const [isOpenDeleteModal, setIsOpenDeleteModal] =
    React.useState<boolean>(false);
  const [selectedDeal, setSelectedDeal] = React.useState<any>('');
  const [openInviteToYardStick, setOpenInviteToYardStick] =
    React.useState<boolean>(false);
  const [openAddToCoInvest, setOpenAddToCoInvest] =
    React.useState<boolean>(false);
  const [dealData, setDealData] = React.useState<any>({});
  const [confirmationDialog, setOpenConfirmationDialog] =
    React.useState<boolean>(false);
  const [swithchingYardstick, setSwitchingYardstick] =
    React.useState<boolean>(false);
  const [invitationSent, setInvitationSent] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [anchorElAssignUser, setAnchorElAssignUser] = React.useState<any>(null);
  const [anchorElChangeStatus, setAnchorElChangeStatus] =
    React.useState<any>(null);
  const [anchorElAssignUserFilter, setAnchorElAssignUserFilter] =
    React.useState<any>(null);
  const [isSendNotification, setIsSendNotification] = React.useState<boolean>(
    menuOpen === 'request' || false,
  );
  const [emailReaderEditMode, setEmailReaderEditMode] =
    React.useState<boolean>(false);
  const [showNotification, setShowNotification] =
    React.useState<boolean>(false);
  const [isRejectDeal, setIsRejectDeal] = React.useState<boolean>(false);
  const [isConvertDeal, setIsConvertDeal] = React.useState<boolean>(false);
  const [convertDealHighlight, setConvertDealHighlight] =
    React.useState<boolean>(false);
  const [openCsvUpload, setOpenCsvUpload] = React.useState<boolean>(
    menuOpen === 'importCSV' || false,
  );
  const [isFundDetail, setIsFundDetail] = React.useState<boolean>(false);
  const [isStarredLoading, setIsStarredLoading] = React.useState<string>('');
  const [selectedStarred, setSelectedStarred] = React.useState<boolean>(false);
  const [selectedUserFilter, setSelectedUserFilter] = React.useState<string[]>(
    [],
  );
  const [isDealInfoLoading, setIsDealInfoLoading] = React.useState<any>({
    USER: '',
    STATUS: '',
  });
  const [deafultSectionToExpand, setDeafultSectionToExpand] =
    React.useState<string>('');
  const [investNoteOpen, setInvestNoteOpen] = React.useState<boolean>(false);
  const [isStartSubscription, setIsStartSubscription] =
    React.useState<boolean>(false);
  const [unlockDealOpen, setUnlockDealOpen] = React.useState<boolean>(false);
  const [unlockLatestYcDealOpen, setUnlockLatestYcDealOpen] =
    React.useState<boolean>(false);
  const [generateNoteModal, setGenerateNoteModal] =
    React.useState<boolean>(false);
  const [unlockDeal, setUnlockDeal] = React.useState<Record<string, any>>({});
  const [investmentNoteDeal, setInvestmentNoteDeal] = React.useState<any>({});
  const [unlockingDeal, setUnlockingDeal] = React.useState<
    Record<string, boolean>
  >({});
  const [chargeDialog, setChargeDialog] = React.useState<boolean>(false);

  // YCDeals
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [showYcDealUnlockBanner, setShowYcDealUnlockBanner] =
    React.useState<boolean>(false);
  const [fetchingYcDeals, setFetchingYcDeals] = React.useState<boolean>(false);
  const [ycDeals, setYcDeals] = React.useState<Array<Record<string, any>>>([]);
  const [fetchingYcDealsMapping, setFetchingYcDealsMapping] =
    React.useState<boolean>(false);
  const [ycDealsMappings, setYcDealsMappings] = React.useState<
    Array<Record<string, any>>
  >([]);
  const [filteredSearchYCDealsData, setFilteredSearchYCDealsData] =
    React.useState<any>([]);
  const [openNotification, setOpenNotification] =
    React.useState<boolean>(false);
  const [notificationError, setNotificationError] = React.useState<string>('');
  const [selectedSector, setSelectedSector] = React.useState<any>([]);
  const [openInvestmentNote, setOpenInvestmentNote] =
    React.useState<boolean>(false);
  const [deal, setDeal] = React.useState<any>({});
  const [selectedRegion, setSelectedRegion] = React.useState<any>([]);
  const [selectedTag, setSelectedTag] = React.useState<any>([]);
  const ycDealsRef = React.useRef(null);
  const [anchorElChangeYCBatch, setAnchorElChangeYCBatch] =
    React.useState<any>(null);
  const [interestedInYCStartup, setInterestedInYCStartup] = React.useState<any>(
    get(vcFirm, 'interestedInYCStartup'),
  );
  const [selectedYcBatch, setSelectedYcBatch] =
    React.useState<string>(ycbatchname);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [isLoadingInvest, setIsLoadingInvest] = React.useState<boolean>(false);
  const [errorMessageInvest, setErrorMessageInvest] =
    React.useState<string>('');
  const [openIntro, setOpenIntro] = React.useState(true);
  const [emailReaderUpdateLoading, setEmailReaderUpdateLoading] =
    React.useState<boolean>(false);
  const [copyText, setCopyText] = React.useState<string>('Copy Email');
  const emailReaderText = watch('emailReaderText');
  const emailReader: any = get(vcFirm, 'emailReader');
  const emailReaderDomain = emailReader?.split('@')[1];
  const [page, setPage] = React.useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(5);
  const [sortModel, setSortModel] = React.useState<any>({
    field: '',
    sort: false,
  });

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnboardTourClose = () => {
    const obj = {
      investorId,
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        dealsOnboardingTourCompleted: [
          ...get(vcFirm, 'dealsOnboardingTourCompleted', []),
          email,
        ],
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        dealsOnboardingTourCompleted: [
          ...get(vcFirm, 'dealsOnboardingTourCompleted', []),
          email,
        ],
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
    updateDashboardSettings(obj) // eslint-disable-next-line no-console
      .then(() => {
        handleDivClick();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
      });
  };

  const fetchScrollData = (
    page: any,
    rowsPerPage: any,
    loading: any,
    sortModel: any = { field: '', sort: '' },
  ) => {
    getAllVcDeals(
      page + 1,
      rowsPerPage,
      loading,
      `&sort=${sortModel.field}&order=${(
        sortModel?.sort || ''
      )?.toUpperCase()}`,
    );
  };

  const fetchSortedData = (
    page: any,
    rowsPerPage: any,
    loading: any,
    sortModel: any = { field: '', sort: '' },
  ) => {
    handleChangePage('', 0);
    getAllVcDeals(
      page + 1,
      rowsPerPage,
      loading,
      `&sort=${sortModel.field}&order=${(sortModel?.sort || '').toUpperCase()}`,
    );
  };

  const validateEmailReader = () => {
    const newEmailReader = `${emailReaderText?.trim() || ''}@${
      emailReaderDomain || ''
    }`;
    clearErrors('emailReaderText');
    if (!emailRegex.test(newEmailReader)) {
      setEmailReaderError(`emailReaderText`, {
        type: 'custom',
        message: 'Please enter valid Email ID',
      });
      return true;
    }
  };

  const handleUpdateInvestmentUpdateReader = () => {
    if (validateEmailReader()) return;
    const newEmailReader = `${emailReaderText?.trim() || ''}@${
      emailReaderDomain || ''
    }`;
    if (!isEmpty(newEmailReader) && newEmailReader !== emailReader) {
      setEmailReaderUpdateLoading(true);
      updateInvestUpdateOrDealEmailReader({
        investorId: investorId,
        emailReader: newEmailReader,
      })
        .then((res: any) => {
          dispatch(
            updateVCFirm({
              emailReader: res?.emailReader,
            }),
          );
          setEmailReaderUpdateLoading(false);
          setEmailReaderEditMode(false);
        })
        .catch((err: any) => {
          setEmailReaderUpdateLoading(false);
          const message: string = errorMessageHandler(err);
          setEmailReaderError(`emailReaderText`, {
            type: 'custom',
            message: message,
          });
          return;
        });
    } else if (newEmailReader === emailReader) {
      setEmailReaderEditMode((prevState: any) => !prevState);
    }
  };

  const handleIntroClose = () => {
    setOpenIntro(false);
    const driverObj = driver({
      popoverClass: 'driverjs-theme',
      showButtons: ['next'],
      nextBtnText: 'Next',
      doneBtnText: 'Got it',
      showProgress: true,
      allowClose: false,
      disableActiveInteraction: true,
      // smoothScroll: true,
      allowKeyboardControl: false,
      onPopoverRender: (popover) => {
        const customSVG = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'svg',
        );
        customSVG.setAttribute('width', '24');
        customSVG.setAttribute('height', '24');
        customSVG.setAttribute('viewBox', '0 0 24 24');
        customSVG.setAttribute('fill', 'none');
        customSVG.style.cursor = 'pointer';
        // Create the first path element
        const path1 = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path',
        );
        path1.setAttribute('d', 'M18 6L6 18');
        path1.setAttribute('stroke', 'white');
        path1.setAttribute('stroke-width', '2');
        path1.setAttribute('stroke-linecap', 'round');
        path1.setAttribute('stroke-linejoin', 'round');

        // Create the second path element
        const path2 = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path',
        );
        path2.setAttribute('d', 'M6 6L18 18');
        path2.setAttribute('stroke', 'white');
        path2.setAttribute('stroke-width', '2');
        path2.setAttribute('stroke-linecap', 'round');
        path2.setAttribute('stroke-linejoin', 'round');

        // Append the path elements to the SVG
        customSVG.appendChild(path1);
        customSVG.appendChild(path2);

        // Append your custom SVG to the title element
        popover.title.appendChild(customSVG);
        customSVG.addEventListener('click', () => {
          handleOnboardTourClose();
          driverObj.destroy();
        });
      },
      steps: [
        {
          element: '#element-introduction',
          popover: {
            title:
              filteredSearchData.length === 1 &&
              get(filteredSearchData[0], 'additionalFields[0].sample', false)
                ? 'Quick intro to deal pipeline'
                : '🎉 Congrats on forwarding your first deal',
            description:
              filteredSearchData.length === 1 &&
              get(filteredSearchData[0], 'additionalFields[0].sample', false)
                ? `Welcome to a smarter way to evaluate deals! Explore a sample deal: See how we neatly compile key information from emails into an actionable profile.`
                : `Great start! We've transformed the email you forwarded into an organised profile, capturing essential startup details for easy reference.`,
            side: 'bottom',
            align: 'start',
            onNextClick: () => {
              setDeafultSectionToExpand('AiAnalyst');
              setDeal(filteredSearchData[0]);
              setSelectedDeal(get(filteredSearchData[0], 'id'));
              setModal(true);
              if (!modal) {
                setTimeout(() => {
                  driverObj.drive(1);
                }, 2000);
              }
            },
          },
        },
        {
          element: '#ai-element-introduction',
          popover: {
            title: 'AI generated investment note',
            description: `Here's the power at your fingertips: an AI-crafted Investment Note that condenses startup info into an insightful report, ready when you are.`,
            side: 'top',
            align: 'start',
            onNextClick: () => {
              setInvestNoteOpen(true);
              setTimeout(() => {
                driverObj.drive(2);
              }, 0);
            },
          },
        },
        {
          element: '#update-title',
          popover: {
            side: 'right',
            align: 'start',
            title: 'AI driven insights',
            description: `Take a peek at your tailored Investment Note. Two pages of concentrated insights give you the full picture of a startup's potential, without the clutter.`,
            onNextClick: () => {
              handleOnboardTourClose();
              driverObj.destroy();
            },
          },
        },
      ],
    });
    driverObj.drive();
  };

  const headerText = [
    // {
    //   name: 'Fund Name',
    //   key: 'fundTitle',
    //   renderCell: (rowData: any) => (
    //     <p
    //       style={
    //         !isEmpty(get(rowData, 'fundTitle'))
    //           ? {
    //               cursor: 'pointer',
    //               textDecoration: 'underline',
    //               margin: 0,
    //             }
    //           : {
    //               margin: 0,
    //             }
    //       }
    //       onClick={() => {
    //         if (!isEmpty(get(rowData, 'fundTitle'))) {
    //           setDealData({ ...rowData });
    //           setIsFundDetail(true);
    //         }
    //       }}
    //     >
    //       {get(rowData, 'fundTitle', '-')}
    //     </p>
    //   ),
    //   minWidth: 170,
    //   sortable: true,
    //   sortDataIndex: 'fundTitle',
    // },
    {
      name: 'Company Name',
      key: 'companyName',
      renderCell: (rowData: any) => (
        <div id="element-introduction">
          <Box className={classes.companyStarredBox}>
            {isStarredLoading === get(rowData, 'id') ? (
              <CircularProgress
                color="inherit"
                size={12}
                sx={{ marginRight: 2 }}
              />
            ) : !get(rowData, 'starred') ? (
              <StarBorderOutlinedIcon
                className={classes.starredFilterIcon}
                onClick={() => {
                  if (!valid) {
                    dispatch(planExpiredDialog(true));
                  } else {
                    addDealToStarredDeal(rowData);
                  }
                }}
              />
            ) : (
              <StarIcon
                className={classes.starredActiveFilterIcon}
                onClick={() => {
                  if (!valid) {
                    dispatch(planExpiredDialog(true));
                  } else {
                    addDealToStarredDeal(rowData);
                  }
                }}
              />
            )}
            <p
              style={
                !isEmpty(get(rowData, 'companyName'))
                  ? {
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      margin: 0,
                    }
                  : {
                      margin: 0,
                      cursor: 'pointer',
                    }
              }
              onClick={() => {
                if (!valid) {
                  dispatch(planExpiredDialog(true));
                } else {
                  setDeal(rowData);
                  setSelectedDeal(get(rowData, 'id'));
                  setModal(true);
                }
              }}
            >
              {isEmpty(get(rowData, 'companyName'))
                ? '-'
                : get(rowData, 'companyName')}
              {!isEmpty(get(rowData, 'ycBatch')) && (
                <Chip
                  className={
                    get(rowData, 'ycBatch') === get(ycBatch, 'currentYCBatch')
                      ? classes.ycBatchLatestTagText
                      : classes.ycBatchTagText
                  }
                  label={get(rowData, 'ycBatch')}
                />
              )}
            </p>
          </Box>
        </div>
      ),
      minWidth: 190,
      sortable: true,
      sortDataIndex: 'companyName',
      headerFilter: () =>
        selectedStarred ? (
          <StarIcon
            className={classes.starredActiveFilterIcon}
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
              } else {
                handleStarredFilterChange(!selectedStarred);
              }
            }}
          />
        ) : (
          <StarBorderOutlinedIcon
            className={classes.starredFilterHeaderIcon}
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
              } else {
                handleStarredFilterChange(!selectedStarred);
              }
            }}
          />
        ),
    },
    {
      name: 'Sector',
      key: 'sector',
      renderCell: (rowData: any) => (
        <>
          <p
            style={{
              margin: 0,
            }}
          >
            {get(rowData, 'sector') ? get(rowData, 'sector') : '-'}
          </p>
          {!isEmpty(get(rowData, 'tags')) &&
            get(rowData, 'tags')
              .split(',')
              .map((item: any, index: number) => (
                <Chip
                  key={`tag_${get(rowData, 'id')}_${index}`}
                  className={classes.tagText}
                  label={item}
                />
              ))}
        </>
      ),
      minWidth: 170,
      sortable: true,
      sortDataIndex: 'sector',
    },
    {
      name: 'Owner',
      key: 'assignedToUser',
      renderCell: (rowData: any) => (
        <Box
          className={classes.assignedUserDropdownBox}
          onClick={(event: any) => {
            if (disableAsPerPermission) {
              return;
            }
            if (!valid) {
              dispatch(planExpiredDialog(true));
            } else {
              handleMenuAssignUserClick(event);
              setSelectedDeal(rowData);
            }
          }}
        >
          <WrappedTypography className={classes.assignedUserDropdownText}>
            {get(rowData, 'assignedTo')
              ? get(
                  get(vcFirmUsers, 'userList').find(
                    (item: any) => item.id === get(rowData, 'assignedTo'),
                  ),
                  'name',
                )
              : get(vcFirm, 'name')}
          </WrappedTypography>
          {get(isDealInfoLoading, TYPE.USER) === get(rowData, 'id') ? (
            <CircularProgress color="primary" size={12} />
          ) : anchorElAssignUser ? (
            <KeyboardArrowUpIcon className={classes.assignedUserDropdownIcon} />
          ) : (
            <KeyboardArrowDownIcon
              className={classes.assignedUserDropdownIcon}
            />
          )}
        </Box>
        // <p
        //   style={{
        //     margin: 0,
        //     display: 'flex',
        //   }}
        // >
        //   {get(isDealInfoLoading, TYPE.USER) === get(rowData, 'id') ? (
        //     <CircularProgress
        //       color="inherit"
        //       size={12}
        //       sx={{ marginRight: 2 }}
        //     />
        //   ) : (
        //     <img
        //       src={AddUserIcon}
        //       alt={AddUserIcon}
        //       className={classes.addUserIcon}
        //       onClick={(event) => {
        //         handleMenuAssignUserClick(event);
        //         setSelectedDeal(rowData);
        //       }}
        //     />
        //   )}
        // </p>
      ),
      minWidth: 170,
      sortable: false,
      // sortDataIndex: 'assignedToUser',
      headerFilter: () =>
        isEmpty(selectedUserFilter) ? (
          <img
            src={FilterIcon}
            alt={FilterIcon}
            className={classes.assignedUserFilterIcon}
            onClick={(event) => handleMenuAssignUserFilterClick(event)}
          />
        ) : (
          <FilterAltIcon
            className={classes.assignedUserFilterIcon}
            onClick={(event) => handleMenuAssignUserFilterClick(event)}
          />
        ),
      columnLableClickable: (event: any) =>
        handleMenuAssignUserFilterClick(event),
    },
    {
      name: 'AI Analyst',
      key: 'aiAnalyst',
      renderCell: (deal: any) => (
        <AiAnalystTableCell
          deal={deal}
          setDeal={setDeal}
          setChargeDialog={setChargeDialog}
          openinvestmentNoteDialog={openinvestmentNoteDialog}
          calculateProgressBar={calculateProgressBar}
          loadingDealAiStatus={loadingDealAiStatus}
          dealAiStatus={dealAiStatus}
          ycDealsMappings={ycDealsMappings}
          unlockingDeal={unlockingDeal}
          onClickDealUnlock={onClickDealUnlock}
          onClickAiStatusColumn={onClickAiStatusColumn}
          retryInvestmentNote={retryInvestmentNote}
          getDealJob={getDealJob}
          getVcDeal={getVcDeal}
          setInvestmentNoteDeal={setInvestmentNoteDeal}
          setOpenInvestmentNote={setOpenInvestmentNote}
          setGenerateNoteModal={setGenerateNoteModal}
          ProgressBackground={ProgressBackground}
          RefreshOrangeIcon={RefreshOrangeIcon}
          valid={valid}
          joiningPlan={joiningPlan}
        />
      ),
      minWidth: 170,
    },
    {
      name: 'Amount Proposed',
      key: 'proposedAmount',
      renderCell: (_: any, value: any) =>
        value && value != 0
          ? formatAmount(value, {
              currency: vcFirmCurrency,
              notation: 'compact',
            })
          : '-',
      textAlign: 'left',
      minWidth: 150,
      sortable: true,
      sortDataIndex: 'proposedAmount',
      numericSort: true,
    },
    // {
    //   name: `Emails`,
    //   key: 'email',
    //   renderCell: (rowData: any) => (
    //     <>
    //       <p
    //         style={{
    //           margin: 0,
    //         }}
    //       >
    //         {get(rowData, 'email') ? (
    //           <>
    //             {get(rowData, 'email')}
    //             <Chip className={classes.tagText} label={'Primary'} />
    //           </>
    //         ) : (
    //           '-'
    //         )}
    //       </p>
    //       {!isEmpty(get(rowData, 'otherEmails')) && (
    //         <>
    //           <Box className={classes.otherEmailBox}>
    //             <CustomizedTooltip
    //               className={classes.editToolTip}
    //               title={
    //                 <React.Fragment>
    //                   {get(rowData, 'otherEmails')
    //                     .split(',')
    //                     .map((item: any) => (
    //                       <WrappedTypography
    //                         key={item}
    //                         className={classes.tooltipText}
    //                       >
    //                         {item}
    //                       </WrappedTypography>
    //                     ))}
    //                 </React.Fragment>
    //               }
    //               placement="bottom-end"
    //             >
    //               <MuiButton className={classes.otherEmailBtn}>
    //                 Other Emails
    //               </MuiButton>
    //             </CustomizedTooltip>
    //           </Box>
    //         </>
    //       )}
    //     </>
    //   ),
    //   minWidth: 170,
    //   sortable: true,
    //   sortDataIndex: 'email',
    // },
    {
      name: 'Proposed Valuation',
      key: 'proposedValuation',
      renderCell: (_: any, value: any) =>
        value
          ? formatAmount(value, {
              currency: vcFirmCurrency,
              notation: 'compact',
            })
          : '-',
      textAlign: 'left',
      minWidth: 150,
      sortable: true,
      sortDataIndex: 'proposedValuation',
      numericSort: true,
    },
    {
      name: 'Status',
      key: 'status',
      renderCell: (rowData: any) => (
        <Box
          className={classes.assignedUserDropdownBox}
          onClick={(event: any) => {
            if (disableAsPerPermission) {
              return;
            }
            if (!valid) {
              dispatch(planExpiredDialog(true));
            } else {
              handleMenuChangeStatusClick(event);
              setSelectedDeal(rowData);
            }
          }}
        >
          <WrappedTypography className={classes.assignedUserDropdownText}>
            {getDealStatus(rowData)}
          </WrappedTypography>
          {get(isDealInfoLoading, TYPE.STATUS) === get(rowData, 'id') ? (
            <CircularProgress color="primary" size={12} />
          ) : anchorElChangeStatus ? (
            <KeyboardArrowUpIcon className={classes.assignedUserDropdownIcon} />
          ) : (
            <KeyboardArrowDownIcon
              className={classes.assignedUserDropdownIcon}
            />
          )}
        </Box>
        // <p
        //   style={{
        //     margin: 0,
        //     display: 'flex',
        //     // justifyContent: 'space-between',
        //   }}
        // >
        //   {get(isDealInfoLoading, TYPE.STATUS) === get(rowData, 'id') ? (
        //     <CircularProgress
        //       color="inherit"
        //       size={12}
        //       sx={{ marginRight: 2 }}
        //     />
        //   ) : (
        //     <ArrowForwardIcon
        //       className={classes.changeStatusIcon}
        //       onClick={(event) => {
        //         handleMenuChangeStatusClick(event);
        //         setSelectedDeal(rowData);
        //       }}
        //     />
        //   )}
        //   {getDealStatus(rowData)}
        // </p>
      ),
      minWidth: 170,
    },
    {
      name: 'Action',
      key: '#',
      renderCell: (rowData: any) => actionSelect(rowData),
      minWidth: 170,
    },
  ];

  const summaryAmount = [
    {
      key: 'total',
      renderCell: () => 'Total',
      textAlign: 'left',
      minWidth: 170,
    },
    // {
    //   key: 'fundTitle',
    //   minWidth: 170,
    // },
    // {
    //   key: 'companyName',
    //   minWidth: 190,
    // },
    { key: 'sector', minWidth: 170 },
    {
      key: 'assignedToUser',
      minWidth: 170,
    },
    {
      key: 'aiAnalyst',
      minWidth: 170,
    },
    {
      key: 'proposedAmount',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + parseInt(get(currentValue, 'proposedAmount') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'left',
      minWidth: 150,
    },
    // { key: 'email', minWidth: 170 },
    {
      key: 'proposedValuation',
      renderCell: () =>
        formatAmount(
          filteredSearchData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator +
              parseInt(get(currentValue, 'proposedValuation') || 0),
            0,
          ),
          {
            currency: vcFirmCurrency,
          },
        ),
      textAlign: 'left',
      minWidth: 150,
    },
    {
      key: 'status',
      minWidth: 170,
    },
    {
      key: '#',
      minWidth: 170,
    },
  ];

  if (userRoles.includes(ROLE.ANGEL_INVESTOR)) {
    // Remove Fund Name column
    //  headerText.splice(findIndex(headerText, { key: 'fundTitle' }), 1);
    //  summaryAmount.splice(findIndex(summaryAmount, { key: 'fundTitle' }), 1);

    // Remove Assigned User column
    headerText.splice(findIndex(headerText, { key: 'assignedToUser' }), 1);
    summaryAmount.splice(
      findIndex(summaryAmount, { key: 'assignedToUser' }),
      1,
    );
  }

  const handleSwitchToStartupYardstick = () => {
    setSwitchingYardstick(true);
    switchToStartupYardstick({
      dealId: get(dealData, 'id'),
      inviteId: get(dealData, 'yardstickInviteId'),
    })
      .then(() => {
        getAllVcDeals();
        setOpenConfirmationDialog(false);
        setDealData({});
      })
      .finally(() => setSwitchingYardstick(false));
  };

  const onClickAiStatusColumn = (rowData: any) => {
    if (rowData.ycDeal) {
      setDeafultSectionToExpand('AiAnalyst');
    } else if (get(dealAiStatus, `${rowData.id}.dataNeeded`)) {
      setDeafultSectionToExpand('Documents');
    } else if (
      get(dealAiStatus, `${rowData.id}.viewOutputs`) ||
      get(dealAiStatus, `${rowData.id}.generateOutput`)
    ) {
      setDeafultSectionToExpand('AiAnalyst');
    }
    setSelectedDeal(get(rowData, 'id'));
    setModal(true);
  };

  const onClickDealUnlock = (
    deal: any,
    adminDealId: string,
    from: string,
    type?: string,
    companyName?: string,
  ) => {
    const vcDealId = get(deal, 'id');
    // if (
    //   isEmpty(paymentCards) &&
    //   !get(
    //     userHasCreditBalance(aiAnalaystUsage, subscription, balance),
    //     'hasBalance',
    //   )
    // ) {
    //   if (type === 'YC') {
    //     history.push(
    //       `/subscription-v2?vcDeal=${vcDealId}&adminDeal=${adminDealId}&source=${from}&type=${type}`,
    //     );
    //   } else {
    //     history.push(
    //       `/subscription-v2?vcDeal=${vcDealId}&source=${from}&type=${type}`,
    //     );
    //   }
    // } else {
    //   handleUnlockDealDialog({
    //     id: type === 'YC' ? adminDealId : vcDealId,
    //     type: type || 'YC',
    //     companyName,
    //   });
    // }
    handleUnlockDealDialog({
      id: type === 'YC' ? adminDealId : vcDealId,
      deal: deal,
      type: type || 'YC',
      companyName,
    });
  };

  const retryInvestmentNote = (deal: any) => {
    const obj = {
      type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
      vcFirmId: get(vcFirm, 'id'),
      vcDealId: get(deal, 'id'),
    };
    const documentsConstant = [
      'DEAL_PITCH_DECK_DOC',
      'DEAL_MEETING_NOTES',
      'DEAL_EMAIL_CONVERSATION',
    ];
    const documentNamesArr: any = [];
    getVcFirmDocuments(obj, get(vcFirm, 'id')).then((res: any) => {
      res?.map((ele: any) => {
        if (documentsConstant.includes(ele?.documentName)) {
          documentNamesArr.push(ele?.documentName);
        }
      });
      createDealJob({
        name: 'INVESTMENT_NOTE',
        dealId: get(deal, 'id'),
        documentsRequiredForInvestmentNote: [...documentNamesArr],
      }).catch((err) => {
        const message: string = errorMessageHandler(err);
        setNotificationError(ERROR_MESSAGES[message] || message);
        setOpenNotification(true);
      });
    });
  };

  const calculateProgressBar = (invNoteJob: any) => {
    const updatedAt = get(invNoteJob, 'updatedAt');
    const secondsDiff = moment().diff(moment(updatedAt), 'seconds');
    const value: any = (secondsDiff / 450) * 100;
    const progressBarValue: any = parseInt(value) > 100 ? 100 : parseInt(value);
    return progressBarValue > 99 ? 99 : progressBarValue;
  };

  const getDealStatus = (rowData: any) => {
    if (
      get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
      get(rowData, 'dealSource') === VC_DEAL_SOURCE.EMAIL_FORWARD
    ) {
      return `${VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]} - Email`;
    } else if (
      get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
      get(rowData, 'dealSource') === VC_DEAL_SOURCE.IMPORT
    ) {
      return `${VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]} - Excel`;
    } else if (
      get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
      get(rowData, 'dealSource') === VC_DEAL_SOURCE.MANUAL
    ) {
      return `${
        VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]
      } - Manually created`;
    } else if (
      get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
      get(rowData, 'dealSource') === VC_DEAL_SOURCE.ADMIN_8VDX_DEAL
    ) {
      return `${VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]} - 8vdX Deal`;
    } else if (
      get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
      get(rowData, 'dealSource') === VC_DEAL_SOURCE.REQUEST_FOUNDER &&
      get(rowData, 'dealProfileInviteStatus') ===
        VC_DEAL_STAGE.PROFILE_REQUEST_SENT
    ) {
      return `${
        VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]
      } - Profile Request Sent`;
    } else if (
      get(rowData, 'dealStage') === VC_DEAL_STAGE.LEAD &&
      get(rowData, 'dealProfileInviteStatus') ===
        VC_DEAL_STAGE.PROFILE_RECEIVED &&
      get(rowData, 'dealSource') === VC_DEAL_SOURCE.REQUEST_FOUNDER
    ) {
      return `${
        VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]
      } - Profile Received`;
    } else return `${VC_DEAL_STAGE_MAPPER[get(rowData, 'dealStage')]}`;
  };

  const actionSelect = (rowData: any) => {
    return (
      <Box>
        {get(vcFirm, 'activated') ? (
          <WrappedSelect
            options={getDealActionListV2(
              rowData,
              DEAL_STATUS_TYPE.PROSPECTIVE,
              userRoles,
            )}
            placeholder="Action"
            variant={'standard'}
            onClickChildren={(value: string) => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
              } else {
                handleActionChange(
                  {
                    target: {
                      value,
                    },
                  },
                  rowData,
                );
              }
            }}
            value={''}
            className={classes.statusSelectFeild}
            onChange={(event: any) => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
              } else {
                handleActionChange(event, rowData);
              }
            }}
            disabled={disableAsPerPermission}
          />
        ) : (
          ''
        )}
      </Box>
    );
  };

  const handleActionChange = (event: any, rowData: any) => {
    const value = get(event, 'target.value');
    if (value === 'updateDeal') {
      setSelectedDeal(get(rowData, 'id'));
      setModal(true);
    } else if (value === 'addToCoInvest') {
      setDealData({ ...rowData });
      setOpenAddToCoInvest(true); // change
    } else if (value === 'delete') {
      setDealData({ ...rowData });
      setIsOpenDeleteModal(true);
    } else if (value === 'inviteToYardstick') {
      setDealData({ ...rowData });
      setOpenInviteToYardStick(true);
    } else if (value === 'yardstickProfile') {
      if (get(rowData, 'yardstickInvite.startupId')) {
        history.push(`/yardstick/${get(rowData, 'yardstickInvite.startupId')}`);
      }
    } else if (value === 'addYardstickData') {
      history.push(`/yardstick/${get(rowData, 'startupId')}`);
    } else if (value === 'switch') {
      setDealData({ ...rowData });
      setOpenConfirmationDialog(true);
    } else if (value === 'inviteToYardstickResend') {
      setDealData({ ...rowData });
      setOpenInviteToYardStick(true);
      setInvitationSent(true);
    } else if (value === 'resendNotification') {
      setDealData({ ...rowData });
      setIsSendNotification(true);
    } else if (value === 'convertToActive') {
      if (
        get(rowData, 'vcFundId') === null ||
        isEmpty(get(rowData, 'companyName'))
      ) {
        setSelectedDeal(get(rowData, 'id'));
        setConvertDealHighlight(true);
        setModal(true);
      } else {
        setDealData({ ...rowData });
        history.push(`/deals?type=active&prospectiveId=${get(rowData, 'id')}`);
        //setIsConvertDeal(true);
      }
    } else if (value === 'rejectDeal') {
      setDealData({ ...rowData });
      setIsRejectDeal(true);
    }
  };

  const onSearchChange = (searchString: string) => {
    setSelectedStarred(false);
    setSelectedUserFilter([]);
    const substrings: string[] = searchString
      .split(' ')
      .map((item) => item?.toString().toLowerCase());

    let filteredData = filter(
      selectedStageFilter !== 'YC' ? allVcDeals : ycDeals,
      (column: any) => {
        if (selectedStageFilter !== 'YC') {
          if (
            searchString === '' ||
            checkSubstringExists(substrings, column.sector) ||
            checkSubstringExists(substrings, column.companyName) ||
            checkSubstringExists(substrings, column.lastFundingRound) ||
            checkSubstringExists(
              substrings,
              get(getBusinessOpsCountryObject(column.geography), 'country'),
            )
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (
            searchString === '' ||
            checkSubstringExists(substrings, column.sector) ||
            checkSubstringExists(substrings, column.companyName) ||
            checkSubstringExists(
              substrings,
              get(getBusinessOpsCountryObject(column.geography), 'country'),
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
      },
    );
    if (selectedStageFilter !== 'allDeal' && selectedStageFilter !== 'YC') {
      filteredData = filteredData.filter(
        (item: any) => item.dealStage === selectedStageFilter,
      );
    } else if (selectedStageFilter === 'YC') {
      setFilteredSearchYCDealsData(sortFilteredData(filteredData));
    }
    if (selectedStageFilter !== 'allDeal') {
      setFilteredSearchData(filteredData);
    }
  };

  const handleStarredFilterChange = (starred: boolean) => {
    setSearchStringText('');
    setSelectedUserFilter([]);
    setSelectedStarred(starred);
    selectStarredStageFilteredDeals(selectedStageFilter, starred, []);
  };

  const handleStageFilterChange = (value: string, fromYcDealTab = false) => {
    setSearchStringText('');
    setSelectedStarred(false);
    setSelectedUserFilter([]);
    setSelectedStageFilter(value);
    selectStarredStageFilteredDeals(value, false, []);
    !fromYcDealTab && setFilteredSearchYCDealsData(sortFilteredData(ycDeals));
  };

  const handleAssignedUserFilterChange = (selectedUser: string) => {
    setSearchStringText('');
    setSelectedStarred(false);
    let selectedUserList = selectedUserFilter;
    if (selectedUser === 'all') {
      selectedUserList = [];
    } else if (selectedUserFilter.includes(selectedUser)) {
      // Remove selected User if clicked again
      selectedUserList = selectedUserFilter.filter(
        (userId: any) => userId !== selectedUser,
      );
    } else {
      // If not included then add
      selectedUserList = [...selectedUserFilter, selectedUser];
    }
    setSelectedUserFilter(selectedUserList);
    selectStarredStageFilteredDeals(
      selectedStageFilter,
      false,
      selectedUserList,
    );
  };

  const selectStarredStageFilteredDeals = (
    selectedStage: string,
    starred: boolean,
    selectedUserList: string[],
  ) => {
    setSearchStringText('');
    if (selectedStage !== 'YC') {
      if (selectedStage !== 'allDeal' && starred) {
        const filteredData = allVcDeals.filter(
          (item: any) =>
            item.dealStage === selectedStage &&
            item.starred &&
            (!isEmpty(selectedUserList)
              ? selectedUserList.includes(item.assignedTo)
              : 1 == 1),
        );
        setFilteredSearchData(filteredData);
      } else if (
        selectedStage !== 'allDeal' &&
        selectedStage !== 'YC' &&
        !starred
      ) {
        const filteredData = allVcDeals.filter(
          (item: any) =>
            item.dealStage === selectedStage &&
            (!isEmpty(selectedUserList)
              ? selectedUserList.includes(item.assignedTo)
              : 1 == 1),
        );
        setFilteredSearchData(filteredData);
      } else if (starred) {
        const filteredData = allVcDeals.filter(
          (item: any) =>
            item.starred &&
            (!isEmpty(selectedUserList)
              ? selectedUserList.includes(item.assignedTo)
              : 1 == 1),
        );
        setFilteredSearchData(filteredData);
      } else if (!isEmpty(selectedUserList)) {
        const filteredData = allVcDeals.filter((item: any) =>
          selectedUserList.includes(item.assignedTo),
        );
        setFilteredSearchData(filteredData);
      } else {
        setFilteredSearchData(allVcDeals);
      }
    }
  };

  const getAllVcDealAndResetFilter = (loading = true, dealStage?: string) => {
    if (!isEmpty(dealStage) && selectedStageFilter !== dealStage) {
      setSelectedStageFilter(dealStage);
      getAllVcDeals(
        page + 1,
        rowsPerPage,
        loading,
        '',
        dealStage,
        selectedUserFilter,
      );
    } else if (
      selectedStageFilter !== 'allDeal' &&
      selectedStageFilter !== 'YC'
    ) {
      getAllVcDeals(
        page,
        rowsPerPage,
        loading,
        '',
        selectedStageFilter,
        selectedUserFilter,
      );
    } else if (!isEmpty(selectedUserFilter)) {
      getAllVcDeals(
        page,
        rowsPerPage,
        loading,
        '',
        selectedStageFilter,
        selectedUserFilter,
      );
    } else {
      getAllVcDeals(page + 1, rowsPerPage, loading, '', selectedStageFilter);
    }
    setSelectedStarred(false);
    setSearchStringText('');
  };

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuAssignUserClick = (event: any) => {
    setAnchorElAssignUser(event.currentTarget);
  };

  const handleMenuAssignUserClose = () => {
    setAnchorElAssignUser(null);
    setSelectedDeal('');
  };

  const handleMenuChangeStatusClick = (event: any) => {
    setAnchorElChangeStatus(event.currentTarget);
  };

  const handleMenuChangeStatusClose = () => {
    setAnchorElChangeStatus(null);
    setSelectedDeal('');
  };

  const handleMenuAssignUserFilterClick = (event: any) => {
    setAnchorElAssignUserFilter(event.currentTarget);
  };

  const handleMenuAssignUserFilterClose = () => {
    setAnchorElAssignUserFilter(null);
  };

  const handleMenuChangeYCBatchClose = () => {
    setAnchorElChangeYCBatch(null);
  };

  const handleMenuChangeYCBatchClick = (event: any) => {
    setAnchorElChangeYCBatch(event.currentTarget);
  };

  const handleYCDealChange = (event: any) => {
    setInterestedInYCStartup(event.target.checked);
    if (!event.target.checked) {
      handleStageFilterChange('allDeal');
    } else {
      setSelectedStageFilter('YC');
      selectStarredStageFilteredDeals('YC', false, []);
      getYCDeals(selectedYcBatch);
    }
    const obj: any = {
      ...vcFirm,
      interestedInYCStartup: event.target.checked,
      updateInfo: true,
    };
    dispatch(submitVCProfileDetails(obj));
  };

  const getImportDealColumnMapping = () => {
    if (userRoles.includes(ROLE.ANGEL_INVESTOR)) {
      delete prospectiveDealColumnMapping[1];
      return prospectiveDealColumnMapping;
    } else return prospectiveDealColumnMapping;
  };

  const copyUrlToClipboard = (str: any) => {
    setCopyText('Copied');
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(str).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('ERROR WHILE COPY URL TO CLIPBOARD:', error);
    });
    setTimeout(() => {
      setCopyText('Copy Email');
    }, 1000);
  };

  const addDealToStarredDeal = (rowData: any) => {
    setIsStarredLoading(get(rowData, 'id'));
    const obj = {
      id: get(rowData, 'id'),
      vcFirmId: get(rowData, 'vcFirmId'),
      investorId: get(user, 'investorId'),
      starred: !get(rowData, 'starred'),
    };
    addVcDealToStarredDeal(obj)
      .then((res: any) => {
        // getAllVcDeals();
        const updatedData = filteredSearchData.reduce((acc, deal) => {
          if (deal.id === get(rowData, 'id')) {
            acc.push({ ...deal, starred: get(res, 'starred') });
          } else {
            acc.push(deal);
          }
          return acc;
        }, []);
        setFilteredSearchData(updatedData);
        setIsStarredLoading('');
        allVcDeals = allVcDeals.map((deal: any) => {
          if (deal.id === get(rowData, 'id'))
            deal.starred = get(res, 'starred');
          return deal;
        });
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsStarredLoading('');
      });
  };

  const handleDealInfoChange = (value: string, type: string) => {
    const currSelectedStage = selectedStageFilter;
    setIsDealInfoLoading((prevState: any) => ({
      ...prevState,
      [type]: get(selectedDeal, 'id'),
    }));
    const obj = {
      id: get(selectedDeal, 'id'),
      vcFirmId: get(selectedDeal, 'vcFirmId'),
      investorId: get(user, 'investorId'),
      dealStage: type === TYPE.STATUS ? value : undefined,
      assignedTo: type === TYPE.USER ? value : undefined,
      type,
    };
    updateVcDealInfo(obj)
      .then((res: any) => {
        const updatedData = filteredSearchData.reduce((acc, deal) => {
          if (deal.id === get(selectedDeal, 'id')) {
            if (type === TYPE.STATUS) {
              acc.push({ ...deal, dealStage: get(res, 'dealStage') });
            } else {
              acc.push({
                ...deal,
                assignedTo: get(res, 'assignedTo'),
                assignedToUser: !isEmpty(get(res, 'assignedTo'))
                  ? get(
                      get(vcFirmUsers, 'userList').find(
                        (Item: any) => Item.id === get(res, 'assignedTo'),
                      ),
                      'name',
                    )
                  : null,
              });
            }
          } else {
            acc.push(deal);
          }
          return acc;
        }, []);
        setFilteredSearchData(updatedData);
        setIsDealInfoLoading((prevState: any) => ({
          ...prevState,
          [type]: '',
        }));
        allVcDeals = allVcDeals.map((deal: any) => {
          if (deal.id === get(selectedDeal, 'id')) {
            if (type === TYPE.STATUS) {
              deal.dealStage = get(res, 'dealStage');
            } else {
              deal.assignedTo = get(res, 'assignedTo');
              deal.assignedToUser = !isEmpty(get(res, 'assignedTo'))
                ? get(
                    get(vcFirmUsers, 'userList').find(
                      (Item: any) => Item.id === get(res, 'assignedTo'),
                    ),
                    'name',
                  )
                : null;
            }
          }
          return deal;
        });
        updateYcDealStage(res);
        if (type === TYPE.USER)
          selectStarredStageFilteredDeals(
            selectedStageFilter,
            false,
            selectedUserFilter,
          );
        if (type === TYPE.STATUS)
          handleStageFilterChange(currSelectedStage, true);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsDealInfoLoading((prevState: any) => ({
          ...prevState,
          [type]: '',
        }));
      });
  };

  const updateYcDealStage = (responseData: any) => {
    if (!isEmpty(get(responseData, 'adminDealRefId'))) {
      const filteredIndex = filteredSearchYCDealsData.findIndex(
        (item: any) =>
          item.vcAdminDealId === get(responseData, 'adminDealRefId'),
      );
      if (filteredIndex !== -1) {
        filteredSearchYCDealsData[filteredIndex].dealStage = get(
          responseData,
          'dealStage',
        );
        setFilteredSearchYCDealsData(
          sortFilteredData([...filteredSearchYCDealsData]),
        );
      }
      const index = ycDeals.findIndex(
        (item: any) =>
          item.vcAdminDealId === get(responseData, 'adminDealRefId'),
      );
      if (index !== -1) {
        ycDeals[index].dealStage = get(responseData, 'dealStage');
        setYcDeals([...ycDeals]);
      }
    }
  };

  const getYCDeals = (batch?: string) => {
    setFetchingYcDeals(true);
    getAllYcPreloadedVcAdminDeals(
      `ycDeal=true&ycBatch=${batch ? batch : selectedYcBatch}`,
    )
      .then((res: any) => {
        setYcDeals(orderBy(res, [(obj: any) => obj.createdAt], ['desc']));
        setFilteredSearchYCDealsData(
          orderBy(res, [(obj: any) => obj.createdAt], ['desc']),
        );
      })
      .finally(() => setFetchingYcDeals(false));
    // dispatch(fetchCreditBalance());
    // dispatch(fetchCurrentMonthAiUsage());
  };

  const getYcDealsMappings = (loader = true) => {
    setFetchingYcDealsMapping(loader);
    getVcAdminDealMappingsByInvestorId()
      .then((res: any) => {
        setYcDealsMappings([...res]);
      })
      .finally(() => setFetchingYcDealsMapping(false));
  };

  const filterDeals = () => {
    return ycDeals.filter((deal: any) => {
      const hasMatchingSector = isEmpty(selectedSector)
        ? true
        : selectedSector
            .map((i: any) => i.value)
            .some((i: any) => deal?.sector?.includes(i));
      const hasMatchingCountry = isEmpty(selectedRegion)
        ? true
        : selectedRegion
            .map((i: any) => i.countryCode)
            .some((i: any) => deal?.countryOfOperation?.includes(i));
      const hasMatchingTags = isEmpty(selectedTag)
        ? true
        : selectedTag.some((i: any) => deal?.tags?.includes(i));

      return hasMatchingTags && hasMatchingCountry && hasMatchingSector;
    });
  };

  const getPlanAddOnPrice = (subscriptionPlan: any) => {
    if (!isEmpty(subscription)) {
      const prices = get(subscriptionPlan, 'prices') || [];
      return get(
        prices.find((price: any) => price.addon),
        'amount',
      );
    }
  };

  const sortFilteredData = (data: any) => {
    return (data || [])
      .sort((a: any, b: any) => b.isFreeDeal - a.isFreeDeal)
      .sort((a: any, b: any) => b?.createdAt - a?.createdAt);
  };

  const onChangingYcDealStage = (
    id: string,
    dealStage: string,
    redirectToStage = true,
  ) => {
    const index = ycDeals.findIndex((item: any) => item.vcAdminDealId === id);
    if (index !== -1) {
      ycDeals[index].dealStage = dealStage;
      setYcDeals([...ycDeals]);
    }
    const filteredIndex = filteredSearchYCDealsData.findIndex(
      (item: any) => item.vcAdminDealId === id,
    );
    if (filteredIndex !== -1) {
      filteredSearchYCDealsData[filteredIndex].dealStage = dealStage;
      setFilteredSearchYCDealsData(
        sortFilteredData([...filteredSearchYCDealsData]),
      );
    }
    // Refresh the prospective deal and mapping after adding the deals
    getAllVcDeals(page + 1, rowsPerPage, true, '', dealStage);
    redirectToStage && handleStageFilterChange(dealStage, true);
    if (!isEmpty(searchStringText))
      setFilteredSearchYCDealsData(sortFilteredData(ycDeals));
  };

  const onAddingDealToPipeline = (
    id: string,
    vcDealId: string,
    dealStage: string,
    redirectToStage = true,
  ) => {
    dispatch(getDealAIStatus());
    const index = ycDeals.findIndex((item: any) => item.vcAdminDealId === id);
    if (index !== -1) {
      ycDeals[index].dealStage = dealStage;
      ycDeals[index].id = vcDealId;
      ycDeals[index].dealStatus = DEAL_STATUS_TYPE.PROSPECTIVE;
      setYcDeals([...ycDeals]);
    }
    const filteredIndex = filteredSearchYCDealsData.findIndex(
      (item: any) => item.vcAdminDealId === id,
    );
    if (filteredIndex !== -1) {
      filteredSearchYCDealsData[filteredIndex].dealStage = dealStage;
      filteredSearchYCDealsData[filteredIndex].id = vcDealId;
      filteredSearchYCDealsData[filteredIndex].dealStatus =
        DEAL_STATUS_TYPE.PROSPECTIVE;
      setFilteredSearchYCDealsData(
        sortFilteredData([...filteredSearchYCDealsData]),
      );
    }
    // Refresh the prospective deal and mapping after adding the deals
    getYcDealsMappings();
    getAllVcDeals(page + 1, rowsPerPage, true, '', dealStage);
    redirectToStage && handleStageFilterChange(dealStage, true);
    if (!isEmpty(searchStringText))
      setFilteredSearchYCDealsData(sortFilteredData(ycDeals));
  };

  const onContinueLatestYcDealUnlockDialog = () => {
    setChargeDialog(true);
  };

  const onContinueLatestYcDealUnlock = () => {
    // const currentBatch = get(ycBatch, 'currentYCBatch');
    // localStorage.setItem(`${currentBatch}unlockedLatestDealDialog`, 'yes');
    // localStorage.setItem(`dontShowVCUnlockDealDialog`, 'no');

    // const handleLatestYcConitue = false;
    // handleUnlockDealDialog(unlockDeal, null, handleLatestYcConitue);
    // setUnlockLatestYcDealOpen(false);
    onContinueUnlockDeal(
      {
        notToShowCheckbox: true,
        dealId: get(unlockDeal, 'id'),
        type: get(unlockDeal, 'type'),
        companyName: get(unlockDeal, 'companyName'),
      },
      () => {
        setChargeDialog(false);
      },
    );
  };

  const handleUnlockDealDialog = (
    deal: any,
    cb?: any,
    handleLatestYcConitue = true,
  ) => {
    setUnlockDeal(deal);
    return onContinueUnlockDeal(
      {
        deal: deal,
        dealId: deal.id,
        type: deal.type,
        companyName: get(deal, 'companyName'),
      },
      cb,
    );
  };

  const openinvestmentNoteDialog = (deal: any) => {
    setDeal(deal);
    getVcDeal(get(deal, 'id')).then((res: any) => {
      setInvestmentNoteDeal(res);
      setOpenInvestmentNote(true);
      getDealJob(get(deal, 'id')).then((res: any) => {
        const documentsArray = get(res, 'deal.aiAnalyst.DOCUMENTS', []);
        if (documentsArray?.length > 0) {
          setGenerateNoteModal(true);
        }
      });
    });
  };

  // const onContinueUnlockDeal = (
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   { notToShowCheckbox, dealId, type, companyName }: any,
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   cb: any,
  // ) => {
  //   // eslint-disable-next-line no-console
  //   console.log(dealId, type, companyName);
  //   const unlockId = dealId || get(unlockDeal, 'id');
  //   setUnlockingDeal({
  //     ...{
  //       [unlockId]: true,
  //     },
  //   });
  //   postLoginLogAmpEvent(
  //     type === 'YC' ? 'Unlock-YC-Deal' : 'Unlock-Manual-Deal',
  //     userInfo,
  //     { companyName },
  //   );
  //   getYcDealsMappings(false);
  //   setChargeDialog(false);
  //   if (!modal) {
  //     openinvestmentNoteDialog(deal);
  //   }
  //   const tempDeal = selectedDeal;
  //   setSelectedDeal('');
  //   setUnlockDeal({});
  //   setTimeout(() => setSelectedDeal(tempDeal), 100);

  //   if (type === 'YC' && selectedStageFilter === 'YC') {
  //     if (ycDealsRef && ycDealsRef.current) {
  //       const activeIndex = (filteredSearchYCDealsData || []).findIndex(
  //         (item: any) => item.vcAdminDealId === dealId,
  //       );
  //       if (activeIndex !== -1) {
  //         const left = filteredSearchYCDealsData[activeIndex - 1];
  //         const right = filteredSearchYCDealsData[activeIndex + 1];
  //         if (!isEmpty(left)) left.index = activeIndex - 1;
  //         if (!isEmpty(right)) right.index = activeIndex + 1;

  //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //         // @ts-ignore
  //         ycDealsRef.current(
  //           { vcAdminDealId: dealId, companyName },
  //           { left, right },
  //         );
  //       }
  //     }
  //   }
  //   setUnlockingDeal({});
  // };

  // Below function is for charging the user
  const onContinueUnlockDeal = async (
    { notToShowCheckbox, dealId, type, companyName, deal }: any,
    cb: any,
  ) => {
    const unlockId = dealId || get(unlockDeal, 'id');
    // Check Condition for free limit exceeds or not
    // const freeLimitAvailable = await checkVcInsightsReportUsageLimit(
    //   get(subscription, 'subscriptionPlan'),
    //   aiAnalaystUsage,
    //   subscription,
    // );
    dispatch(getVCFirmSubscription());
    // if (!freeLimitAvailable) {
    //   return;
    // }
    setUnlockingDeal({
      ...{
        [unlockId]: true,
      },
    });
    postLoginLogAmpEvent(
      type === 'YC' ? 'Unlock-YC-Deal' : 'Unlock-Manual-Deal',
      userInfo,
      { companyName },
    );
    (type === 'YC'
      ? unlockVcAdminPreloadedDeal({ adminDealId: unlockId, unlocked: true })
      : updateVcDealInfo({
          unlockedAiAnalyst: true,
          id: unlockId,
          investorId,
          vcFirmId: get(vcFirm, 'id'),
          type: 'UNLOCKED_AI_ANALYST',
        })
    )
      .then((res) => {
        // dispatch(fetchOutstandingBalance());
        if (cb) cb(res);
        getYcDealsMappings(false);
        setChargeDialog(false);
        if (!modal && selectedStageFilter !== 'YC') {
          openinvestmentNoteDialog(deal);
        }
        const tempDeal = selectedDeal;
        if (notToShowCheckbox) {
          // localStorage.setItem(
          //   type === 'YC' ? UNLOCK_DIALOG_KEY : UNLOCK_VC_DIALOG_KEY,
          //   'yes',
          // );
        }
        setSelectedDeal('');
        setUnlockDeal({});
        // setTimeout(() => setSelectedDeal(tempDeal), 100);
        // dispatch(fetchCreditBalance());
        // dispatch(fetchCurrentMonthAiUsage());

        if (type === 'YC' && selectedStageFilter === 'YC') {
          setTimeout(() => setSelectedDeal(tempDeal), 100);
          if (ycDealsRef && ycDealsRef.current) {
            const activeIndex = (filteredSearchYCDealsData || []).findIndex(
              (item: any) => item.vcAdminDealId === dealId,
            );
            if (activeIndex !== -1) {
              const left = filteredSearchYCDealsData[activeIndex - 1];
              const right = filteredSearchYCDealsData[activeIndex + 1];
              if (!isEmpty(left)) left.index = activeIndex - 1;
              if (!isEmpty(right)) right.index = activeIndex + 1;

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ycDealsRef.current(
                { vcAdminDealId: dealId, companyName },
                { left, right },
              );
            }
          }
        }
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setNotificationError(ERROR_MESSAGES[message] || message);
        setOpenNotification(true);
      })
      .finally(() => setUnlockingDeal({}));
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handleYcDealBannerClick = () => {
    // localStorage.setItem(`${investorId}-ycDealUnlockText`, 'false');
    setShowYcDealUnlockBanner(false);
  };

  const getDealCount = (dealStage: string) => {
    if (dealStage !== 'allDeal' && dealStage !== 'YC') {
      const filteredData = allVcDeals.filter(
        (item: any) => item.dealStage === dealStage,
      );
      return size(filteredData);
    } else if (dealStage === 'YC') {
      return size(ycDeals);
    } else return size(allVcDeals);
  };

  const removeItemFromList = (id: string) => {
    const filteredIndex = filteredSearchData.findIndex(
      (item: any) => item.id === id,
    );
    if (filteredIndex !== -1) {
      const newData = filteredSearchData;
      newData.splice(filteredIndex, 1);
      setFilteredSearchData([...newData]);
    }
    const index = allVcDeals.findIndex((item: any) => item.id === id);
    if (index !== -1) {
      const newData = allVcDeals;
      newData.splice(index, 1);
      allVcDeals = [...newData];
    }
  };

  const onSuccessYCRejectDeal = (vcDealId: string) => {
    const index = ycDeals.findIndex((item: any) => item.id === vcDealId);
    if (index !== -1) {
      ycDeals[index].dealStatus = DEAL_STATUS_TYPE.REJECTED;
      setYcDeals([...ycDeals]);
    }
    const filteredIndex = filteredSearchYCDealsData.findIndex(
      (item: any) => item.id === vcDealId,
    );
    if (filteredIndex !== -1) {
      filteredSearchYCDealsData[filteredIndex].dealStatus =
        DEAL_STATUS_TYPE.REJECTED;
      setFilteredSearchYCDealsData(
        sortFilteredData([...filteredSearchYCDealsData]),
      );
    }
  };

  const handleLPPortalRedirection = async (redirectUrl: string) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      // const currentSession = await Auth.currentSession();
      const refreshToken = (await Auth.currentSession()).getRefreshToken();
      await cognitoUser.refreshSession(
        refreshToken,
        (err: any, session: any) => {
          // eslint-disable-next-line no-console
          console.log(err, session);
          if (err) {
            // eslint-disable-next-line no-console
            console.error(
              'LP_PORTAL_REDIRECTION_ERROR_WHILE_REFRESH_TOKEN',
              err,
            );
          } else {
            const authentication = {
              idToken: session.getIdToken().getJwtToken(),
              accessToken: session.getAccessToken().getJwtToken(),
              refreshToken: session.getRefreshToken().getToken(),
            };
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const ciphertext = CryptoJS.AES.encrypt(
              JSON.stringify(authentication),
              secretKey,
            ).toString();
            if (!isEmpty(redirectUrl)) {
              window.open(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                `${VC_INVESTOR_WEB_URL[env]}investor/redirect-auth?authentication=${ciphertext}&redirecturl=${redirectUrl}`,
                '_blank',
              );
            } else {
              window.open(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                `${VC_INVESTOR_WEB_URL[env]}investor/redirect-auth?authentication=${ciphertext}`,
                '_blank',
              );
            }
          }
        },
      );
    } catch (err) {
      const message: string = errorMessageHandler(err);
      // eslint-disable-next-line no-console
      console.error(
        'LP_PORTAL_REDIRECTION_ERROR_WHILE_REFRESH_TOKEN',
        err,
        message,
      );
    }
  };

  const handleSortChange = (sortModelData: any) => {
    if (sortModelData.sort) {
      setPage(0);
    }
    setSortModel(sortModelData);
  };
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handleInvestInAdminFund = () => {
    setErrorMessageInvest('');
    setIsLoadingInvest(true);
    investInAdminFundProcess()
      .then(async (res: any) => {
        handleLPPortalRedirection(`/investor-funds/${get(res, 'vcFundId')}`);
        setIsLoadingInvest(false);
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-unused-vars
        const message: string = errorMessageHandler(err);
        setErrorMessageInvest(message);
        setIsLoadingInvest(false);
      });
  };

  const { targetRef: infinityScrollRef, isIntersecting } = InteractionObserver({
    threshold: 1.0,
  });

  useEffect(() => {
    if (isIntersecting) {
      setPage((prevPage: any) => prevPage + 1);
    }
  }, [isIntersecting]);

  React.useEffect(() => {
    getYcDealsMappings();
  }, []);

  React.useEffect(() => {
    if (
      !fetchingYcDeals &&
      !fetchingYcDealsMapping &&
      localStorage.getItem(`${investorId}-ycDealUnlockText`) !== 'false' &&
      ycDealsMappings.filter((item: any) => item.unlocked).length < 7
    ) {
      setShowYcDealUnlockBanner(true);
    } else {
      setShowYcDealUnlockBanner(false);
    }
  }, [ycDealsMappings, fetchingYcDeals]);

  useEffect(() => {
    if (ycFilter && !isEmpty(selectedYcBatch)) {
      setSelectedStageFilter('YC');
      selectStarredStageFilteredDeals('YC', false, []);
      getYCDeals(selectedYcBatch);
    }
  }, [ycFilter, selectedYcBatch]);

  React.useEffect(() => {
    if (!isEmpty(dealStage)) {
      handleStageFilterChange(dealStage || '');
    }
    if (!isEmpty(dealId)) {
      setSelectedDeal(dealId);
      setModal(true);
      if (!isEmpty(expandSectionSidePane))
        setDeafultSectionToExpand(expandSectionSidePane);
    }
  }, [dealId, dealStage]);

  React.useEffect(() => {
    if (!isEmpty(get(vcFirmUsers, 'userList')) && isEmpty(usersList)) {
      const user = get(vcFirmUsers, 'userList')
        .filter((item: any) => item.emailVerified)
        .map((item: any) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      setUsersList(user);
    }
  }, [vcFirmUsers]);

  useEffect(() => {
    setFilteredSearchYCDealsData(sortFilteredData(filterDeals()));
  }, [selectedSector, selectedRegion, selectedTag]);

  useEffect(() => {
    if (pusherInitialized && channel) {
      channel?.bind(PUSHER_EVENT_NAME.DEAL_CREATED_BY_MAIL, () => {
        getAllVcDeals();
        dispatch(getDealAIStatus());
      });
      channel?.bind(PUSHER_EVENT_NAME.DEAL_STATUS_UPDATED, () => {
        getYCDeals();
      });
      channel?.bind(PUSHER_EVENT_NAME.DEAL_INFO_UPDATED, (res: any) => {
        const data = get(res, 'data');
        if (
          data &&
          ['USER', 'STATUS'].includes(get(data, 'type')) &&
          get(data, 'email') !== email
        ) {
          getAllVcDealAndResetFilter(false);
        } else if (!['USER', 'STATUS'].includes(get(data, 'type'))) {
          getYCDeals();
          dispatch(getDealAIStatus());
        }
      });
    }
  }, [pusherInitialized, channel]);

  useEffect(() => {
    setInterestedInYCStartup(get(vcFirm, 'interestedInYCStartup'));
  }, [vcFirm]);

  useEffect(() => {
    if (
      !isEmpty(get(ycBatch, 'currentYCBatch')) &&
      batch === get(ycBatch, 'currentYCBatch')
    ) {
      setSelectedYcBatch(get(ycBatch, 'currentYCBatch'));
    }
  }, [ycBatch]);

  React.useEffect(() => {
    getAllVcDeals(
      page + 1,
      rowsPerPage,
      !isIntersecting,
      `&sort=${sortModel.field}&order=${(
        sortModel?.sort || ''
      )?.toUpperCase()}&search=${searchStringText}`,
    );
  }, [page, rowsPerPage, searchStringText, sortModel]);

  return (
    <>
      {permissionInfo.DEAL_PIPELINE.toLowerCase() !== 'none' && (
        <Box className={classes.mainComponentContent}>
          {(errorMessage || errorMessageInvest) && (
            <WrappedTypography className={classes.errorMessage}>
              {errorMessage || errorMessageInvest}
            </WrappedTypography>
          )}
          <Box className={classes.pageProspectiveHeaderBox}>
            {/* {showYcDealUnlockBanner && (
            <Box className={classes.preloadedPipelineTextBox}>
              <WrappedTypography className={classes.preloadedPipelineText}>
                <CloseIcon
                  className={classes.closeIconImg}
                  onClick={() => handleYcDealBannerClick()}
                />
                Unlock any five Investment Notes for Free! Further reports
                available at{' '}
                {formatAmount(
                  getPlanAddOnPrice(get(subscription, 'subscriptionPlan')) || 0,
                )}{' '}
                per company.
              </WrappedTypography>
            </Box>
          )} */}
            <Box className={classes.ycDealSwitchContainer}>
              {/* YC Deal Switch */}
              <Box
                className={
                  interestedInYCStartup
                    ? classes.ycDealSwitch
                    : classes.disableYcDealSwitch
                }
              >
                <FormControlLabel
                  control={
                    <YCDealSwitch
                      sx={{ m: 1 }}
                      defaultChecked
                      checked={interestedInYCStartup}
                      onChange={handleYCDealChange}
                    />
                  }
                  label="YC Deals"
                />
              </Box>
              {/* INvest in 8vdx FUND */}
              {/* <Button
              className={classes.investInSpvBtn}
              name={'Invest in YC W24 SPV'}
              onClick={() => handleInvestInAdminFund()}
              disabled={isLoadingInvest}
              // isLoading={isLoadingInvest}
            /> */}
              {/* YC Deal Filter For YC Deals */}
              {selectedStageFilter === 'YC' && (
                <Box className={classes.filterBox}>
                  <WrappedTypography className={classes.filterByText}>
                    Filter By
                  </WrappedTypography>
                  <Box className={classes.filterContainer}>
                    <WrappedAutocomplete
                      multiple
                      options={sectorList}
                      placeholder={'Industry'}
                      variant={'outlined'}
                      getOptionLabel={(option: any) => option.text}
                      value={selectedSector}
                      onChange={(event: any, newValue: any | null) => {
                        setSelectedSector(newValue);
                      }}
                      renderTags={(value: any[], getTagProps: any) => {
                        if (value.length === 1) {
                          return value.map((option: any, index: number) => (
                            <Chip
                              key={`name_${index}`}
                              size="small"
                              style={{ width: 80 }}
                              label={option.text}
                              deleteIcon={<CloseIcon />}
                              {...getTagProps({ index })}
                            />
                          ));
                        } else
                          return (
                            <Chip
                              size="small"
                              deleteIcon={<CloseIcon />}
                              style={{ width: 80 }}
                              onDelete={() => setSelectedSector([])}
                              label={` + ${value.length} `}
                            />
                          );
                      }}
                    />
                    <WrappedAutocomplete
                      multiple
                      placeholder={'Regions'}
                      variant={'outlined'}
                      options={countryOfOperationList}
                      getOptionLabel={(option: any) => option.country}
                      value={selectedRegion}
                      onChange={(event: any, newValue: any | null) => {
                        setSelectedRegion(newValue);
                      }}
                      renderTags={(value: any[], getTagProps: any) => {
                        if (value.length === 1) {
                          return value.map((option: any, index: number) => (
                            <Chip
                              key={`name_${index}`}
                              size="small"
                              style={{ width: 80 }}
                              label={option.country}
                              deleteIcon={<CloseIcon />}
                              {...getTagProps({ index })}
                            />
                          ));
                        } else
                          return (
                            <Chip
                              size="small"
                              deleteIcon={<CloseIcon />}
                              style={{ width: 80 }}
                              onDelete={() => setSelectedRegion([])}
                              label={` + ${value.length} `}
                            />
                          );
                      }}
                    />
                    <WrappedAutocomplete
                      multiple
                      placeholder={'Tags'}
                      variant={'outlined'}
                      options={vcDealsTagsList}
                      getOptionLabel={(option: any) => option}
                      value={selectedTag}
                      onChange={(event: any, newValue: any | null) => {
                        setSelectedTag(newValue);
                      }}
                      renderTags={(value: any[], getTagProps: any) => {
                        if (value.length === 1) {
                          return value.map((option: any, index: number) => (
                            <Chip
                              key={`name_${index}`}
                              size="small"
                              label={option}
                              style={{ width: 80 }}
                              deleteIcon={<CloseIcon />}
                              {...getTagProps({ index })}
                            />
                          ));
                        } else
                          return (
                            <Chip
                              size="small"
                              deleteIcon={<CloseIcon />}
                              style={{ width: 80 }}
                              onDelete={() => setSelectedTag([])}
                              label={` + ${value.length} `}
                            />
                          );
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            {/* Search Box & Add Button */}
            <Box className={classes.searchBoxDrop}>
              <Box
                className={
                  selectedStageFilter === 'YC'
                    ? classes.searchYcBox
                    : classes.searchBox
                }
              >
                <InputBase
                  value={searchStringText}
                  className={classes.inputSear}
                  placeholder={
                    isEmpty(filteredSearchData)
                      ? 'No Data Available'
                      : 'Search by company name, sector, stage or country or any combination of these'
                  }
                  inputProps={{ 'aria-label': 'search' }}
                  endAdornment={
                    !searchStringText.length ? (
                      <IconButton
                        className={classes.searchIcon}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        className={classes.clearIcon}
                        aria-label="clear"
                        onClick={() => {
                          handleChangePage('', 0);
                          setSearchStringText('');
                          // onSearchChange('');
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )
                  }
                  onChange={(e: any) => {
                    handleChangePage('', 0);
                    const searchString = e.target.value;
                    setSearchStringText(searchString);
                    // onSearchChange(searchString);
                  }}
                />
              </Box>
              <Box>
                <Button
                  type="submit"
                  className={classes.prospectiveDealsBtn}
                  name="Deal"
                  startIcon={<AddIcon />}
                  onClick={(event) => {
                    // if (
                    //   ((get(dealPipeline, 'status') === 'START_SUBSCRIPTION' ||
                    //     get(dealPipeline, 'status') === FREE_PLATFORM_DEAL) &&
                    //     isUserOnJoiningPlan(subscription)) ||
                    //   get(dealPipeline, 'status') === 'SUBSCRIPTION_CANCELLED'
                    // ) {
                    //   setIsStartSubscription(true);
                    //   return;
                    if (!valid) {
                      dispatch(planExpiredDialog(true));
                      return;
                    } else {
                      handleMenuClick(event);
                    }
                  }}
                  disabled={!get(vcFirm, 'activated') || disableAsPerPermission}
                />
                <CustomizedTooltip
                  title={'Export to Excel'}
                  placement="top"
                  className={classes.customTagTooltip}
                  arrow={true}
                >
                  <IconButton
                    className={classes.downloadExcelIcon}
                    aria-label="search"
                    onClick={() => {
                      downloadExcelByVcFirmId(
                        get(vcFirm, 'id'),
                        DEAL_STATUS_TYPE.PROSPECTIVE,
                      ).then((res: any) => {
                        downloadLink(res.url);
                      });
                    }}
                  >
                    <img src={DownloadExcel} alt={'add tag'} />
                  </IconButton>
                </CustomizedTooltip>
              </Box>
            </Box>
          </Box>
          {/* Second Row YC Button, ALL Deals and Stage Filter */}
          <Box className={classes.filtersContainer}>
            {interestedInYCStartup ? (
              <Box
                className={
                  size(filteredSearchYCDealsData) >= 100
                    ? classes.ycExtendedBtn
                    : classes.ycBtn
                }
              >
                <Box
                  className={classes.leftYCBox}
                  onClick={() => {
                    setSelectedYcBatch(selectedYcBatch);
                    handleStageFilterChange('YC');
                    getYCDeals(selectedYcBatch);
                    postLoginLogAmpEvent(
                      `${selectedYcBatch}-YCDeal-Click`,
                      userInfo,
                    );
                  }}
                >
                  {isLoading || fetchingYcDeals ? (
                    <Loader size={15} className={classes.loader} />
                  ) : (
                    `${selectedYcBatch}${
                      selectedStageFilter === 'YC' && !fetchingYcDeals
                        ? ` (${size(filteredSearchYCDealsData)})`
                        : ''
                    }`
                  )}
                </Box>
                <Box className={classes.rightYCBox}>
                  <ArrowDropDownIcon
                    onClick={(event) => {
                      handleMenuChangeYCBatchClick(event);
                      postLoginLogAmpEvent(
                        `${selectedYcBatch}-YCDeal-Click`,
                        userInfo,
                      );
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <Box></Box>
            )}
            <Button
              className={
                selectedStageFilter === 'allDeal'
                  ? classes.activeAllDealBtn
                  : classes.outlinedBtnDeal
              }
              name={`All Deals(${getDealCount('allDeal')})`}
              disabled={isLoading}
              onClick={() => {
                handleStageFilterChange('allDeal');
              }}
            />
            {vcDealStageList.map((item: any, index: number) => (
              <Box
                key={index}
                className={classes.filtersBox}
                onClick={() => {
                  handleStageFilterChange(item.value);
                }}
              >
                <Box
                  className={
                    item.value === selectedStageFilter
                      ? classes.selectedFilter
                      : classes.filtersInnerBox
                  }
                >
                  <WrappedTypography className={classes.filtersText}>
                    {item.text}
                    {`(${getDealCount(item.value)})`}
                  </WrappedTypography>
                </Box>
              </Box>
            ))}
          </Box>
          {selectedStageFilter === 'YC' ? (
            <>
              {fetchingYcDeals || get(ycBatch, 'isloading') ? (
                [1, 2, 3, 4, 5, 6, 7].map((item) => (
                  <Box className={classes.loaderBox} key={item}>
                    <Skeleton variant="text" height={30} />
                    <Skeleton variant="rectangular" height={50} />
                  </Box>
                ))
              ) : (
                <YCDeals
                  ycDealsRef={ycDealsRef}
                  vcFirm={vcFirm}
                  ycDeals={ycDeals}
                  filteredSearchYCDealsData={filteredSearchYCDealsData}
                  setFilteredSearchYCDealsData={setFilteredSearchYCDealsData}
                  ycDealsMappings={ycDealsMappings}
                  fetchingYcDealsMapping={fetchingYcDealsMapping}
                  onAddingDealToPipeline={onAddingDealToPipeline}
                  handleUnlockDealDialog={handleUnlockDealDialog}
                  unlockingDeal={unlockingDeal}
                  onChangingYcDealStage={onChangingYcDealStage}
                  setIsStartSubscription={setIsStartSubscription}
                  dealPipeline={dealPipeline}
                  valid={valid}
                  joiningPlan={joiningPlan}
                  permissionInfo={permissionInfo}
                />
              )}
            </>
          ) : (
            <Box className={classes.dealsProspectiveTableBox}>
              <CustomizedTable
                columns={headerText}
                rows={[...filteredSearchData]}
                isLoading={isLoading}
                footerRow={summaryAmount}
                stickyAction={true}
                showPagination={false}
                totalRows={
                  selectedStageFilter === 'allDeal'
                    ? totalfilteredSearchDataCount
                    : filteredSearchData.length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                infinityScrollRef={infinityScrollRef}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleSortChange={handleSortChange}
              />
            </Box>
          )}
          <Box className={classes.noteContainer}>
            <Box
              className={classes.forwardNoteBox}
              // style={{ width: 25, height: 22 }}
            >
              <span
                className={classes.forwardNoteText}
                style={{ display: 'flex' }}
              >
                Note : Forward your startup inbound emails to{' '}
                <Box
                  className={classes.emailInfoBox}
                  style={{
                    background: 'none',
                    width: emailReaderEditMode ? 376 : 'unset',
                    height: 21,
                  }}
                >
                  {emailReaderEditMode ? (
                    <Box className={classes.emailReaderEditBox}>
                      <Box className={classes.emailReaderEditTextBox}>
                        <InputTextField
                          control={control}
                          name="emailReaderText"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  fontSize: 14,
                                  fontWeight: 600,
                                  lineHeight: 20,
                                  color: 'black',
                                  marginBottom: 5,
                                }}
                              >
                                @{emailReader.split('@')[1]}
                              </InputAdornment>
                            ),
                          }}
                          defaultValue={
                            emailReaderText || emailReader.split('@')[0]
                          }
                          onBlur={validateEmailReader}
                          onInputChange={(e: any) => {
                            setValue(
                              'emailReaderText',
                              e.target.value.toString().slice(0, 20),
                            );
                          }}
                          className={
                            get(
                              emailReaderErrors['emailReaderText'],
                              'message',
                            ) ===
                            'This Email already taken for investment update. Please choose different.'
                              ? classes.emailReaderTextValInput
                              : classes.emailReaderTextInputBox
                          }
                        />
                      </Box>
                    </Box>
                  ) : (
                    <WrappedTypography className={classes.emailReaderText}>
                      {emailReader}
                    </WrappedTypography>
                  )}
                  {!emailReaderEditMode ? (
                    <CustomizedTooltip
                      title="You can personalize this email ID for easier recall"
                      placement="top"
                      className={classes.emailReaderTooltip}
                      arrow={true}
                    >
                      <img
                        src={EditIcon}
                        alt={EditIcon}
                        className={classes.emailReaderRightIcon}
                        onClick={() => {
                          setEmailReaderEditMode(true);
                          setValue(
                            'emailReaderText',
                            emailReader.split('@')[0],
                          );
                        }}
                      ></img>
                    </CustomizedTooltip>
                  ) : (
                    <>
                      {emailReaderUpdateLoading ? (
                        <Loader size={22} />
                      ) : (
                        <img
                          src={SaveIcon}
                          alt={SaveIcon}
                          className={classes.emailReaderRightIcon}
                          onClick={() => {
                            if (disableAsPerPermission) {
                              return;
                            }
                            handleUpdateInvestmentUpdateReader();
                          }}
                        />
                      )}
                    </>
                  )}
                  <CustomizedTooltip
                    title={copyText}
                    placement="top"
                    className={classes.emailReaderTooltip}
                    arrow={true}
                  >
                    <img
                      src={CopyWhiteIcon}
                      alt={CopyWhiteIcon}
                      onClick={() => copyUrlToClipboard(emailReader)}
                      className={classes.emailReaderRightIcon}
                    />
                  </CustomizedTooltip>
                </Box>
                , to centralise and track your prospective deals
              </span>
            </Box>
          </Box>
        </Box>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.avatarMenu}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            if (isEmpty(vcFirmCurrency) && !userRoles.includes(ROLE.VC_ADMIN)) {
              setUpdateCurrencyModal({
                isOpen: true,
                type: CURRENCY_MODAL_TYPE.IMPORT_DEAL,
              });
            } else if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            } else {
              setOpenCsvUpload(true);
            }
            handleMenuClose();
          }}
        >
          Import Excel
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            if (isEmpty(vcFirmCurrency) && !userRoles.includes(ROLE.VC_ADMIN)) {
              setUpdateCurrencyModal({
                isOpen: true,
                type: CURRENCY_MODAL_TYPE.SEND_NOTIFICATION,
              });
            } else {
              setIsSendNotification(true);
            }
            handleMenuClose();
          }}
        >
          Request Founder Input
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeal({});
            if (isEmpty(vcFirmCurrency) && !userRoles.includes(ROLE.VC_ADMIN)) {
              setUpdateCurrencyModal({
                isOpen: true,
                type: CURRENCY_MODAL_TYPE.FILL_DATA,
              });
            } else {
              setModal(true);
            }
            handleMenuClose();
          }}
          className={classes.menuItem}
        >
          Add Manually
        </MenuItem>
      </Menu>
      <Menu
        id="simple-menu-assign-user"
        anchorEl={anchorElAssignUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElAssignUser)}
        onClose={handleMenuAssignUserClose}
        className={classes.addUserMenu}
      >
        <MenuItem disabled sx={{ opacity: 1 }}>
          Assigned To
        </MenuItem>
        {[{ text: get(vcFirm, 'name'), value: null }, ...usersList].map(
          (item, index) => (
            <MenuItem
              key={`${item.value}${index}`}
              onClick={() => {
                handleMenuAssignUserClose();
                handleDealInfoChange(item.value, TYPE.USER);
              }}
            >
              {item.text}
            </MenuItem>
          ),
        )}
      </Menu>
      <Menu
        id="simple-menu-stage-change"
        anchorEl={anchorElChangeStatus}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElChangeStatus)}
        onClose={handleMenuChangeStatusClose}
        className={classes.dealStageDropdownMenu}
      >
        <MenuItem disabled sx={{ opacity: 1 }}>
          Move To
        </MenuItem>
        {vcDealStageList.map((item, index) => (
          <MenuItem
            key={`${item.value}${index}`}
            onClick={() => {
              handleMenuChangeStatusClose();
              handleDealInfoChange(item.value, TYPE.STATUS);
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="simple-menu-yc-deal-change"
        anchorEl={anchorElChangeYCBatch}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        PopoverClasses={{
          root: classes.popoverMenu,
        }}
        open={Boolean(anchorElChangeYCBatch)}
        onClose={handleMenuChangeYCBatchClose}
        className={classes.ycBatchMenu}
      >
        {get(ycBatch, 'allYCBatchList').map((item: any, index) => (
          <MenuItem
            key={`${item.value}${index}`}
            onClick={() => {
              setSelectedYcBatch(item.ycBatchName);
              handleStageFilterChange('YC');
              handleMenuChangeYCBatchClose();
              getYCDeals(item.ycBatchName);
            }}
            selected={item.ycBatchName === selectedYcBatch}
          >
            {item.ycBatchName}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="simple-menu-user-filter"
        anchorEl={anchorElAssignUserFilter}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElAssignUserFilter)}
        onClose={handleMenuAssignUserFilterClose}
        className={classes.filterUserMenu}
      >
        <MenuItem
          style={{ height: 55, backgroundColor: 'transparent' }}
        ></MenuItem>
        {[
          { text: 'All', value: 'all' },
          { text: get(vcFirm, 'name'), value: null },
          ...usersList,
        ].map((item, index) => (
          <MenuItem
            key={`${item.value}_${index}`}
            onClick={() => {
              handleMenuAssignUserFilterClose();
              handleAssignedUserFilterChange(item.value);
            }}
          >
            <Checkbox checked={selectedUserFilter.indexOf(item.value) > -1} />
            {item.text}
          </MenuItem>
        ))}
      </Menu>
      {updateCurrencyModal && (
        <UpdateCurrency
          isOpen={updateCurrencyModal}
          setIsOpen={setUpdateCurrencyModal}
          vcFirm={vcFirm}
          vcFirmCurrency={vcFirmCurrency}
          openAddDealDialog={() => setModal(true)}
          openSendNotificationDialog={() => setIsSendNotification(true)}
          setOpenCsvUpload={() => {
            if (!valid) {
              dispatch(planExpiredDialog(true));
              return;
            } else setOpenCsvUpload(true);
          }}
        />
      )}
      {openInviteToYardStick && (
        <InviteToYardstick
          type={'VC_FIRM'}
          isOpen={openInviteToYardStick}
          setIsOpen={setOpenInviteToYardStick}
          dealData={dealData}
          refreshDeals={getAllVcDealAndResetFilter}
          setShowNotification={setShowNotification}
          fund={(fundList || []).find(
            (item) => item.value === get(dealData, 'vcFundId'),
          )}
          invitationSent={invitationSent}
          closeDialog={() => {
            setDealData({});
            setInvitationSent(false);
          }}
        />
      )}
      {openAddToCoInvest && (
        <AddDealsToCoInvest
          isOpen={openAddToCoInvest}
          setIsOpen={setOpenAddToCoInvest}
          dealData={dealData}
          refreshDeals={getAllVcDealAndResetFilter}
          closeDialog={() => {
            setDealData({});
          }}
        />
      )}
      {confirmationDialog && (
        <ConfirmationDialog
          open={confirmationDialog}
          handleClose={() => {
            setOpenConfirmationDialog(false);
            setDealData({});
          }}
          title="Swith to startup yardstick data"
          content="Are you sure you want to switch to Startup yardstick data?"
          onOk={() => handleSwitchToStartupYardstick()}
          okButton="Switch"
          loading={swithchingYardstick}
          canceButton="Cancel"
        />
      )}
      {isOpenDeleteModal && (
        <DeleteDeal
          isOpenDeleteModal={isOpenDeleteModal}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          dealData={dealData}
          refreshDeals={getAllVcDealAndResetFilter}
          closeDialog={() => {
            setDealData({});
          }}
          title={'Delete Prospective Deal'}
        />
      )}
      {isSendNotification && (
        <SendNotification
          isSendNotification={isSendNotification}
          vcFirmCurrency={vcFirmCurrency}
          vcFirmCurrencySymbol={vcFirmCurrencySymbol}
          fundList={fundList}
          type={DEAL_STATUS_TYPE.PROSPECTIVE}
          dealData={dealData}
          refreshDeals={getAllVcDealAndResetFilter}
          closeDialog={() => {
            setDealData({});
            setIsSendNotification(false);
          }}
          userRoles={userRoles}
        />
      )}
      {showNotification && (
        <SnackBar
          type="success"
          open={true}
          // autoHideDuration={5000}
          handleClose={() => setShowNotification(false)}
          message={`Request has been ${
            invitationSent ? 'resent' : 'sent'
          } successfully`}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        />
      )}
      {isConvertDeal && (
        <ConvertDealToActive
          investorId={investorId}
          vcFirm={vcFirm}
          vcFirmCurrency={vcFirmCurrency}
          fundList={fundList}
          vcFirmCurrencySymbol={vcFirmCurrencySymbol}
          isConvertDeal={isConvertDeal}
          setIsConvertDeal={setIsConvertDeal}
          selectedDealData={dealData}
          getAllVcDeals={() => {
            getAllVcDealAndResetFilter();
            getYCDeals();
          }}
          handleClose={() => {
            setDealData({});
            setIsConvertDeal(false);
          }}
          userRoles={userRoles}
        />
      )}
      {isRejectDeal && (
        <RejectDeal
          isRejectDeal={isRejectDeal}
          setIsRejectDeal={setIsRejectDeal}
          vcFirm={vcFirm}
          selectedDealData={dealData}
          selectedDeal={get(dealData, 'id')}
          investorId={investorId}
          getAllVcDeals={() => {
            removeItemFromList(get(dealData, 'id'));
            onSuccessYCRejectDeal(get(dealData, 'id'));
          }}
          handleClose={() => {
            setDealData({});
            setIsRejectDeal(false);
          }}
        />
      )}
      {openCsvUpload && (
        <CsvUploadDeal
          openCsvUpload={openCsvUpload}
          setOpenCsvUpload={setOpenCsvUpload}
          dealStatus={DEAL_STATUS_TYPE.PROSPECTIVE}
          columnMapping={getImportDealColumnMapping()}
          sampleFile={
            userRoles.includes(ROLE.ANGEL_INVESTOR)
              ? ANGEL_PROSPECTIVE_DEAL_SAMPLE_URL
              : PROSPECTIVE_DEAL_SAMPLE_URL
          }
          fundList={fundList}
          investorId={investorId}
          vcFirm={vcFirm}
          userRoles={userRoles}
          refreshDeals={getAllVcDealAndResetFilter}
        />
      )}
      {isFundDetail && (
        <FundDetail
          isFundDetail={isFundDetail}
          closeDialog={() => {
            setDealData({});
            setIsFundDetail(false);
          }}
          fundId={get(dealData, 'vcFundId')}
          investorId={investorId}
          vcFirm={vcFirm}
          fundList={fundList}
        />
      )}
      {modal && (
        <ProspectiveDealSidePane
          deal={deal}
          investNoteOpen={investNoteOpen}
          retryInvestmentNote={retryInvestmentNote}
          calculateProgressBar={calculateProgressBar}
          open={modal}
          investorId={investorId}
          vcFirmCurrency={vcFirmCurrency}
          vcFirmCurrencySymbol={vcFirmCurrencySymbol}
          vcFirm={vcFirm}
          disableAsPerPermission={disableAsPerPermission}
          vcFirmUsers={vcFirmUsers}
          fundList={fundList}
          selectedDeal={selectedDeal}
          ycDealsMappings={ycDealsMappings}
          onClickDealUnlock={onClickDealUnlock}
          getAllVcDeals={(dealStage: string, dealToAppend: any) => {
            if (dealToAppend?.id) {
              const id = get(dealToAppend, 'id');
              const filteredIndex = filteredSearchData.findIndex(
                (item: any) => item.id === id,
              );
              if (filteredIndex !== -1) {
                const newData = filteredSearchData;
                newData[filteredIndex] = dealToAppend;
                setFilteredSearchData([...newData]);
              } else {
                getAllVcDealAndResetFilter(true, dealStage);
              }
              const index = allVcDeals.findIndex((item: any) => item.id === id);
              if (index !== -1) {
                const newData = allVcDeals;
                newData[index] = dealToAppend;
                allVcDeals = [...newData];
              }
            } else getAllVcDealAndResetFilter(true, dealStage);
          }}
          defaultExpanded={deafultSectionToExpand}
          convertDealHighlight={convertDealHighlight}
          setConvertDealHighlight={setConvertDealHighlight}
          closeDialog={() => {
            setSelectedDeal('');
            setDeafultSectionToExpand('');
            convertDealHighlight && setConvertDealHighlight(false);
            setModal(false);
          }}
          userRoles={userRoles}
          unlockingDeal={unlockingDeal}
          setNotificationError={setNotificationError}
          setOpenNotification={setOpenNotification}
          onSuccessRejectDeal={(id: string) => {
            removeItemFromList(id);
            onSuccessYCRejectDeal(id);
          }}
        />
      )}
      {openNotification && (
        <SnackBar
          type={notificationError ? 'error' : 'success'}
          open={openNotification}
          handleClose={() => {
            setOpenNotification(false);
          }}
          message={notificationError}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        />
      )}
      {false && (
        <UnlockDealDialog
          unlockDeal={unlockDeal}
          isOpen={unlockDealOpen}
          onClose={() => {
            setUnlockDealOpen(false);
            setUnlockDeal({});
          }}
          loading={unlockingDeal}
          onContinue={onContinueUnlockDeal}
        />
      )}
      {false && (
        <UnlockLatestYcDealDialog
          isOpen={unlockLatestYcDealOpen}
          loading={unlockingDeal}
          subscription={subscription}
          onClose={() => {
            setUnlockLatestYcDealOpen(false);
            setUnlockDeal({});
          }}
          onContinue={onContinueLatestYcDealUnlock}
          getPlanAddOnPrice={getPlanAddOnPrice}
        />
      )}
      {isStartSubscription && (
        <StartSubscription
          open={isStartSubscription}
          setOpen={setIsStartSubscription}
        />
      )}
      {chargeDialog && (
        <ChargeDialog
          unlockDeal={unlockDeal}
          chargeDialog={chargeDialog}
          vcFirm={vcFirm}
          onContinue={onContinueUnlockDeal}
          onYCDealContinue={onContinueLatestYcDealUnlock}
          onClose={() => {
            setChargeDialog(false);
            setUnlockDeal({});
          }}
        />
      )}
      {openInvestmentNote && (
        <AiAnalystContent
          open={openInvestmentNote}
          setOpen={setOpenInvestmentNote}
          investmentNote={
            get(investmentNoteDeal, 'aiAnalyst.INVESTMENT_NOTE')
              ? get(investmentNoteDeal, 'aiAnalyst.INVESTMENT_NOTE')
              : get(investmentNoteDeal, 'aiAnalyst.investmentNoteAdmin')
          }
          dealId={get(investmentNoteDeal, 'id')}
          vcFirm={vcFirm}
          deal={investmentNoteDeal}
          vcAdminDeal={
            get(investmentNoteDeal, 'aiAnalyst.INVESTMENT_NOTE')
              ? false
              : get(investmentNoteDeal, 'ycDeal')
          }
        />
      )}
      {generateNoteModal && (
        <GenerateNoteDialog
          saveChangesModal={generateNoteModal}
          setSaveChangesModal={setGenerateNoteModal}
          dealId={get(deal, 'id')}
          onClickIgnore={() => null}
          onClickSave={(checked: any) => {
            const obj = {
              type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
              vcFirmId: get(vcFirm, 'id'),
              vcDealId: get(deal, 'id'),
            };
            const documentsConstant = [
              'DEAL_PITCH_DECK_DOC',
              'DEAL_MEETING_NOTES',
              'DEAL_EMAIL_CONVERSATION',
            ];
            const documentNamesArr: any = [];
            getVcFirmDocuments(obj, get(vcFirm, 'id')).then((res: any) => {
              res?.map((ele: any) => {
                if (documentsConstant.includes(ele?.documentName)) {
                  documentNamesArr.push(ele?.documentName);
                }
                if (ele?.documentName === 'OTHER_DOC') {
                  documentNamesArr.push(ele?.filename);
                }
              });
              createDealJob({
                name: 'INVESTMENT_NOTE',
                dealId: get(deal, 'id'),
                documentsRequiredForInvestmentNote: [...checked],
              }).catch((err) => {
                const message: string = errorMessageHandler(err);
                setNotificationError(ERROR_MESSAGES[message] || message);
                setOpenNotification(true);
              });
            });
          }}
        />
      )}
      {!isLoading &&
        Array.from(searchParams.keys()).length === 1 &&
        !get(
          vcFirm,
          `dashboardSettings.dealsOnboardingTourCompleted`,
          [],
        ).includes(email) &&
        allVcDeals.length === 1 &&
        get(filteredSearchData, '[0].aiAnalyst.INVESTMENT_NOTE') && (
          <Dialog
            open={openIntro}
            // TransitionComponent={Transition}
            keepMounted
            maxWidth={'md'}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <div
                style={{
                  width: 806,
                  height: 416,
                  background: 'white',
                  boxShadow: '0px 1px 4px rgba(30, 25, 25, 0.03)',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    width: '50%',
                    display: 'inline-grid',
                    alignContent: 'space-evenly',
                    justifyContent: 'left',
                  }}
                >
                  <div style={{ width: 344, height: 73 }}>
                    <span
                      style={{
                        color: 'black',
                        fontSize: 20,
                        fontFamily: 'Mulish',
                        fontWeight: '700',
                        wordWrap: 'break-word',
                      }}
                    >
                      Great! Let us show you how you can effectively manage your
                      entire deal funnel
                    </span>
                  </div>
                  <div
                    style={{
                      width: 344,
                      color: '#323232',
                      fontSize: 14,
                      fontFamily: 'Mulish',
                      fontWeight: '400',
                      wordWrap: 'break-word',
                    }}
                  >
                    Forward startup inbound emails to your personalised 8vdx
                    email. AI-powered deal pipeline can revolutionise your
                    investment process, turning emails into actionable insights.
                  </div>
                  <div
                    style={{
                      width: 344,
                      background: '#FDF2EC',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      display: 'inline-flex',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 62,
                        background: '#FDF2EC',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          alignSelf: 'stretch',
                          flex: '1 1 0',
                          paddingTop: 4,
                          paddingBottom: 4,
                          // paddingLeft: 16,
                          paddingRight: 0,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 16,
                          display: 'inline-flex',
                        }}
                      >
                        <div
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            alignItems: 'flex-start',
                            display: 'inline-flex',
                            alignSelf: 'center',
                            width: '100%',
                          }}
                        >
                          <Box
                            className={classes.emailInfoMainBox}
                            style={{
                              top: 0,
                              left: 0,
                              // width: 344,
                              height: 62,
                              background: 'rgb(253, 242, 236)',
                            }}
                          >
                            <Box
                              className={classes.emailInfoBox}
                              style={{
                                background: 'none',
                                width: emailReaderEditMode ? 376 : 'unset',
                                height: 21,
                              }}
                            >
                              {emailReaderEditMode ? (
                                <Box className={classes.emailReaderEditBox}>
                                  <Box
                                    className={classes.emailReaderEditTextBox}
                                  >
                                    <InputTextField
                                      control={control}
                                      name="emailReaderText"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 600,
                                              lineHeight: 20,
                                              color: 'black',
                                              marginBottom: 5,
                                            }}
                                          >
                                            @{emailReader.split('@')[1]}
                                          </InputAdornment>
                                        ),
                                      }}
                                      defaultValue={
                                        emailReaderText ||
                                        emailReader.split('@')[0]
                                      }
                                      onBlur={validateEmailReader}
                                      onInputChange={(e: any) => {
                                        setValue(
                                          'emailReaderText',
                                          e.target.value
                                            .toString()
                                            .slice(0, 15),
                                        );
                                      }}
                                      className={
                                        get(
                                          emailReaderErrors['emailReaderText'],
                                          'message',
                                        ) ===
                                        'This Email already taken for investment update. Please choose different.'
                                          ? classes.emailReaderTextValInput
                                          : classes.emailReaderTextInputBox
                                      }
                                    />
                                  </Box>
                                </Box>
                              ) : (
                                <WrappedTypography
                                  className={classes.emailReaderText}
                                >
                                  {emailReader}
                                </WrappedTypography>
                              )}
                              {!emailReaderEditMode ? (
                                <CustomizedTooltip
                                  title="You can personalize this email ID for easier recall"
                                  placement="top"
                                  className={classes.emailReaderTooltip}
                                  arrow={true}
                                >
                                  <img
                                    src={EditIcon}
                                    alt={EditIcon}
                                    className={classes.emailReaderRightIcon}
                                    onClick={() => {
                                      setEmailReaderEditMode(true);
                                      setValue(
                                        'emailReaderText',
                                        emailReader.split('@')[0],
                                      );
                                    }}
                                  ></img>
                                </CustomizedTooltip>
                              ) : (
                                <>
                                  {emailReaderUpdateLoading ? (
                                    <Loader size={22} />
                                  ) : (
                                    <img
                                      src={SaveIcon}
                                      alt={SaveIcon}
                                      className={classes.emailReaderRightIcon}
                                      onClick={() => {
                                        handleUpdateInvestmentUpdateReader();
                                      }}
                                    />
                                  )}
                                </>
                              )}
                              <CustomizedTooltip
                                title={copyText}
                                placement="top"
                                className={classes.emailReaderTooltip}
                                arrow={true}
                              >
                                <img
                                  src={CopyWhiteIcon}
                                  alt={CopyWhiteIcon}
                                  onClick={() =>
                                    copyUrlToClipboard(emailReader)
                                  }
                                  className={classes.emailReaderRightIcon}
                                />
                              </CustomizedTooltip>
                            </Box>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: 344,
                      alignItems: 'center',
                      justifyContent: 'right',
                    }}
                  >
                    <Button
                      style={{
                        background: '#1A1918',
                        borderRadius: 4,
                        width: 71,
                        height: 32,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        cursor: 'pointer',
                        textTransform: 'capitalize',
                      }}
                      onClick={handleIntroClose}
                      name="Got it"
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: '50%',
                    background: '#F7F5F2',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <img
                        style={{ width: 400 }}
                        src={ProspectiveDemo}
                        alt={ProspectiveDemo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
    </>
  );
};

export default ProspectiveDeals;
