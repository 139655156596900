import { VcDealsModal } from 'common/types/common';
import { vcFirmUrlConstants } from 'common/utils/url-constants';

import { secureApi } from '../api/Axios';

export const createOrUpdateVcDeal = (payload: VcDealsModal) =>
  secureApi.post(vcFirmUrlConstants.createOrUpdateVcDeal, payload);

export const createSampleDeal = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.createSampleDeal, payload);

export const createSampleDealForInvitedUser = (payload: any) =>
  secureApi.post(vcFirmUrlConstants.createSampleDealForInvitedUser, payload);

export const getAllVcDealsByVcFirmId = (
  vcfirmId: string,
  type: string,
  page = 1,
  rowsPerPage: number = Number.MAX_SAFE_INTEGER,
  queryString = '',
) =>
  secureApi.get(
    `${vcFirmUrlConstants.getAllVcDealsByVcFirmId}/${vcfirmId}?status=${type}&page=${page}&take=${rowsPerPage}${queryString}`,
  );

export const downloadExcelByVcFirmId = (vcfirmId: string, type: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.downloadExcelByVcFirmId}/${vcfirmId}?dealStatus=${type}`,
  );

export const addOrUpdateVcDealsDocuments = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.addOrUpdateVcDealsDocuments}`, payload);

export const getVcFirmDocuments = (
  payload: any,
  investorId: string | undefined,
) =>
  secureApi.post(
    `${vcFirmUrlConstants.getVcFirmDocuments}/${investorId}`,
    payload,
  );

export const getVcDeal = (payload: string | undefined) =>
  secureApi.get(`${vcFirmUrlConstants.getVcDeal}/${payload}`);

export const convertVcDeal = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.convertVcDeal}`, payload);

export const yardstickInvite = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.yardstickInvite}`, payload);

export const yardstickInviteV2 = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.yardstickInvitev2}`, payload);

export const getAllVcCoInvestDealsByInvestorId = (payload: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.getAllVcCoInvestDealsByInvestorId}/${payload}`,
  );

export const convertVcDealToCoinvestDeal = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.convertVcDealToCoinvestDeal}`, payload);

export const inviteVcDealInvestorByVcFirm = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.inviteVcDealInvestorByVcFirm}`, payload);

export const getAllCoInvestDealInvestorsByDealId = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.getAllCoInvestDealInvestorsByDealId}`,
    payload,
  );

export const getAllCoInvestDealInvestorsByVcFirmId = (vcFirmId: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.getAllCoInvestDealInvestorsByVcFirmId}/${vcFirmId}`,
  );

export const approveVcDealInvestorInvestmentByVcFirm = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.approveVcDealInvestorInvestmentByVcFirm}`,
    payload,
  );

export const deleteVcDeal = (dealId: any) =>
  secureApi.delete(`${vcFirmUrlConstants.deleteVcDeal}/${dealId}`);

export const switchToStartupYardstick = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.switchToStartupYardstick}`, payload);

export const confirmInviteStarup = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.confirmInviteStarup}`, payload);

export const restoreRemovedCoinvestDeal = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.restoreRemovedCoinvestDeal}`, payload);

export const validateAndMarkVcCoinvestRemoved = (dealId: any) =>
  secureApi.delete(
    `${vcFirmUrlConstants.validateAndMarkVcCoinvestRemoved}/${dealId}`,
  );

export const inviteDealStartup = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.inviteDealStartup}`, payload);

export const addOrUpdateVcDealsMultipleDocuments = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.addOrUpdateVcDealsMultipleDocuments}`,
    payload,
  );

export const deleteVcFirmDocuments = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.deleteVcFirmDocuments}`, payload);

export const getEmailPreviewInviteDealStartup = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.getEmailPreviewInviteDealStartup}`,
    payload,
  );
export const getSignedUrlForVcDealsImport = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.getSignedUrlForVcDealsImport}`, payload);

export const processVcDealsCSVImportFromS3 = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.processVcDealsCSVImportFromS3}`,
    payload,
  );

export const addVcDealToStarredDeal = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.addVcDealToStarredDeal}`, payload);

export const updateVcDealInfo = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.updateVcDealInfo}`, payload);

export const updateVcDealSafeDetails = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.updateVcDealSafeDetails}`, payload);

export const getDealJob = (payload: any) =>
  secureApi.get(`${vcFirmUrlConstants.getDealJob}/${payload}`);

export const createDealJob = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.createDealJob}`, payload);

export const createUpdateSAFEDetail = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.createUpdateSAFEDetail}`, payload);

export const shareDealDoc = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.shareDealDoc}`, payload);

export const updateJobEmailSend = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.updateJobEmailSend}`, payload);

export const fetchDealAiStatus = (payload: any) =>
  secureApi.get(
    payload
      ? `${vcFirmUrlConstants.getDealAiStatus}?id=${payload}`
      : `${vcFirmUrlConstants.getDealAiStatus}`,
  );

export const getAllYcPreloadedVcAdminDeals = (payload?: any) =>
  secureApi.get(
    payload
      ? `${vcFirmUrlConstants.getAllYcPreloadedVcAdminDeals}?${payload}`
      : `${vcFirmUrlConstants.getAllYcPreloadedVcAdminDeals}`,
  );

export const getVcAdminDealMappingsByInvestorId = () =>
  secureApi.get(`${vcFirmUrlConstants.getVcAdminDealMappingsByInvestorId}`);

export const getYcPreloadedVcAdminDealInvestmentReport = (payload: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.getYcPreloadedVcAdminDealInvestmentReport}/${payload}`,
  );

export const unlockVcAdminPreloadedDeal = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.unlockVcAdminPreloadedDeal}`, payload);

export const addVcAdminDealsToPipeline = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.addVcAdminDealsToPipeline}`, payload);

export const incrementVcAdminDealView = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.incrementVcAdminDealView}`, payload);

export const checkDealPipelineToStartSubscription = () =>
  secureApi.get(`${vcFirmUrlConstants.checkDealPipelineToStartSubscription}`);

export const getVcDealInvestmentUpdate = (
  page = 1,
  take = Number.MAX_SAFE_INTEGER,
  queryString = '',
) =>
  secureApi.get(
    `${vcFirmUrlConstants.getVcDealInvestmentUpdate}?page=${page}&take=${take}${queryString}`,
  );

export const deleteVcDealInvestmentUpdate = (payload: string) =>
  secureApi.post(
    `${vcFirmUrlConstants.deleteVcDealInvestmentUpdate}/${payload}`,
  );

export const deleteVcDealInvestmentUpdateJob = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.deleteVcDealInvestmentUpdateJob}`,
    payload,
  );

export const editVcDealInvestmentUpdateName = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.editVcDealInvestmentUpdateName}`,
    payload,
  );

export const downloadInvestmentUpdate = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.downloadInvestmentUpdate}`, payload);

export const getInvestmentUpdatesSignedUrl = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.getInvestmentUpdatesSignedUrl}`,
    payload,
  );

export const createUploadedInvestmentUpdate = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.createUploadedInvestmentUpdate}`,
    payload,
  );

export const refreshInvestmentUpdateJob = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.refreshInvestmentUpdateJob}`, payload);

export const refreshInvestmentUpdate = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.refreshInvestmentUpdate}`, payload);

export const hardResetInvestmentUpdates = () =>
  secureApi.post(`${vcFirmUrlConstants.hardResetInvestmentUpdates}`);

export const getHardResetInvestmentUpdatesStatus = () =>
  secureApi.get(`${vcFirmUrlConstants.getHardResetInvestmentUpdatesStatus}`);

export const getVcDealInvestmentUpdateAttachments = (id: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.getVcDealInvestmentUpdateAttachments}/${id}`,
  );

export const deleteInvestmentUpdatesByDeal = (dealId: string) =>
  secureApi.delete(
    `${vcFirmUrlConstants.deleteInvestmentUpdatesByDeal}/${dealId}`,
  );

export const getVcDealInvestmentUpdateById = (payload: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.getVcDealInvestmentUpdateById}/${payload}`,
  );

export const remindInvestmentUpdateStartup = (id: string, payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.remindInvestmentUpdateStartup}/${id}`,
    payload,
  );

export const mergeInvestmentUpdateStartup = (payload: any) =>
  secureApi.post(`${vcFirmUrlConstants.mergeInvestmentUpdateStartup}`, payload);

export const recordInvestmentUpdateServiceUsage = (payload: any) =>
  secureApi.post(
    `${vcFirmUrlConstants.recordInvestmentUpdateServiceUsage}`,
    payload,
  );

export const unsubscribeNotifyUserEmailList = (email: string) =>
  secureApi.get(
    `${vcFirmUrlConstants.unsubscribeNotifyUserEmailList}/${email}`,
  );
