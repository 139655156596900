import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Grid, FormControlLabel, Checkbox, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { get } from 'lodash';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import {
  loginUser,
  emptyState,
  loginVerifyCode,
  emptyUserLogin,
} from 'redux-modules/Auth/Actions';
import { RootState } from 'redux-modules/Store/RootState';
import { Box, Button, InputTextField, WrappedTypography } from 'components';
import { SignUpImage, Logo, GoogleIcon, LinkedinIcon } from 'assets';
import history from 'common/utils/history';
import { errorMessageHandler, getPortalName } from 'common/utils/helpers';
import { loginPreCheck, checkUserForVCInvestorRole } from 'InvestorServices';

import {
  SignInEmailSchema,
  SignInSchema,
  SignInVerificationSchema,
} from '../validations';
import styles from './styles';

const SignIn = () => {
  const dispatch = useDispatch();
  const classes = styles();

  const { isLoading, errorMessage, verificationCodeEnable, tempCognitoUser } =
    useSelector(({ Auth }: RootState) => Auth);

  const [isCheckUserRoleLoading, setIsCheckUserRoleLoading] =
    React.useState<boolean>(false);
  const [checkUserRoleErrorMessage, setCheckUserRoleErrorMessage] =
    React.useState<string>('');
  // const [isUserConfigured, setIsUserConfigured] =
  //   React.useState<boolean>(false);
  const [isUserRoleCheck, setIsUserRoleCheck] = React.useState<boolean>(false);

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      !verificationCodeEnable
        ? !isUserRoleCheck
          ? SignInEmailSchema
          : SignInSchema
        : SignInVerificationSchema,
    ),
  });

  const loginPreCheckSubmit = async (data: any) => {
    setCheckUserRoleErrorMessage('');
    setIsCheckUserRoleLoading(true);
    try {
      const preCheckRes = await loginPreCheck({
        email: get(data, 'username'),
        portal: getPortalName(),
      });
      if (get(preCheckRes, 'MSG_CODE') === 'ROLE_NOT_EXIST') {
        const res: any = await checkUserForVCInvestorRole(
          get(data, 'username'),
        );
        if (res.action === 'INVESTOR_CONFIGURED') {
          // setIsUserConfigured(true);
          // eslint-disable-next-line no-console
          console.log('INVESTOR_CONFIGURED For User.');
        }
      }
      setIsUserRoleCheck(true);
      setIsCheckUserRoleLoading(false);
    } catch (err: any) {
      const message: string = errorMessageHandler(err);
      setCheckUserRoleErrorMessage(message);
      setIsCheckUserRoleLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    dispatch(loginUser(data));
  };

  const onSubmitCode = async (data: any) => {
    const { code } = data;
    dispatch(loginVerifyCode({ user: tempCognitoUser, code }));
  };

  React.useEffect(() => {
    if (errorMessage) {
      dispatch(emptyState());
    }
  }, []);

  return (
    <Box className={classes.signInContainer}>
      <Box className={classes.logoHeader}>
        <img src={Logo} alt={Logo} className={classes.logoImage} />
      </Box>
      <Box className={classes.signInBox}>
        {!verificationCodeEnable && (
          <Box className={classes.signInLeft}>
            <WrappedTypography type={'h3'} className={classes.welcome8vdx}>
              Hello, Investor
            </WrappedTypography>
            <WrappedTypography type={'h6'}>
              Please login to your account.
            </WrappedTypography>
            <Box className={classes.socialLoginWrapper}>
              <img
                src={GoogleIcon}
                alt={GoogleIcon}
                onClick={async () => {
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  });
                }}
              />
              <Box className={classes.linkedinLoginSvgWrapper}>
                <img
                  src={LinkedinIcon}
                  alt={LinkedinIcon}
                  onClick={() =>
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    Auth.federatedSignIn({
                      provider: 'Auth0-LinkedIn',
                    })
                  }
                />
              </Box>
            </Box>
            <form
              onSubmit={handleSubmit(
                isUserRoleCheck ? onSubmit : loginPreCheckSubmit,
              )}
              data-testid="signInForm"
              className={classes.validateForm}
            >
              {!isUserRoleCheck && (
                <Box className={classes.emailInputBox}>
                  <WrappedTypography type={'body2'}>
                    Email Address<span className={classes.errorText}>*</span>
                  </WrappedTypography>
                  <InputTextField
                    id={'username'}
                    type={'email'}
                    placeholder="Enter Email Address"
                    control={control}
                    name="username"
                  />
                </Box>
              )}
              {isUserRoleCheck && (
                <Box className={classes.passInputBox}>
                  <WrappedTypography type={'body2'}>
                    Password<p className={classes.errorRequiredText}>*</p>
                  </WrappedTypography>
                  <InputTextField
                    id={'password'}
                    type={'password'}
                    placeholder="Enter Password"
                    control={control}
                    name="password"
                  />
                </Box>
              )}
              <Grid container>
                <Grid item xs={7}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="checkedI"
                        color="primary"
                      />
                    }
                    label="Remember Me"
                  />
                </Grid>
                <Grid item xs={5} className={classes.forgotText}>
                  <p
                    className={classes.forgotPassword}
                    onClick={() => history.push('/investor/forgot-password')}
                  >
                    Forgot Password?
                  </p>
                </Grid>
              </Grid>
              {errorMessage && (
                <Typography variant="body1" className={classes.errorMessage}>
                  {errorMessage}
                </Typography>
              )}
              {checkUserRoleErrorMessage && (
                <Typography variant="body1" className={classes.errorMessage}>
                  {checkUserRoleErrorMessage}
                </Typography>
              )}
              <Box className={classes.logInBtnBox}>
                <Button
                  name={isUserRoleCheck ? 'Login' : 'Proceed'}
                  variant={'standard'}
                  type="submit"
                  isLoading={isLoading || isCheckUserRoleLoading}
                  disabled={isLoading || isCheckUserRoleLoading}
                />
              </Box>
              <Box className={classes.createAccountBox}>
                <WrappedTypography>
                  Not registered yet?
                  <span
                    className={classes.createAccountText}
                    onClick={() => history.push('/investor/sign-up')}
                  >
                    &nbsp;Create an Account
                  </span>
                </WrappedTypography>
              </Box>
            </form>
          </Box>
        )}
        {verificationCodeEnable && (
          <Box className={classes.signInLeft}>
            <WrappedTypography type={'h2'} className={classes.welcome8vdxMfa}>
              Two-factor Authentication
            </WrappedTypography>
            <WrappedTypography
              type={'h6'}
              className={classes.welcomeSub8vdxMfa}
            >
              Please enter verification code. The verification code is sent to
              your registered email id.
            </WrappedTypography>
            <form
              onSubmit={handleSubmit(onSubmitCode)}
              data-testid="signInFormCode"
              className={classes.validateForm}
            >
              <InputTextField
                placeholder="Verification Code"
                control={control}
                name="code"
              />
              <Box>
                {errorMessage && (
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.errorMessage}
                  >
                    {errorMessage}
                  </Typography>
                )}
                <Button
                  type="submit"
                  className={classes.verifyBtn}
                  name="Verify"
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </Box>
            </form>
            <Box>
              <Typography className={classes.alreadyAcText}>
                Already have an account ?
                <a
                  onClick={() => {
                    dispatch(emptyUserLogin());
                    setValue('code', null);
                    setValue('password', null);
                    setIsUserRoleCheck(false);
                  }}
                  rel="noreferrer"
                  className={classes.signInLink}
                >
                  {' '}
                  Back to Login
                </a>
              </Typography>
              <Typography className={classes.copyText}>
                © 2022 VentureInsights All rights reserved .
              </Typography>
            </Box>
          </Box>
        )}
        <Box className={classes.signInRight}>
          <img src={SignUpImage} className={classes.signupImage} alt="signUp" />
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
