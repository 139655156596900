import React, { useEffect } from 'react';
import { Menu, MenuItem, Chip, Skeleton, Divider } from '@mui/material';
import { isEmpty, get, uniqBy, find } from 'lodash';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

import {
  Box,
  WrappedTypography,
  Button,
  SnackBar,
  Loader,
  CustomizedTooltip,
} from 'components';
import { RootState } from 'redux-modules/Store/RootState';
import {
  addVcAdminDealsToPipeline,
  getYcPreloadedVcAdminDealInvestmentReport,
  incrementVcAdminDealView,
  updateVcDealInfo,
} from 'services';
import history from 'common/utils/history';
import {
  errorMessageHandler,
  getCountryOfOperationsObject,
  userHasCreditBalance,
} from 'common/utils/helpers';
import {
  VC_DEAL_STAGE_MAPPER,
  VC_YC_DEAL_STATUS_MAPPER,
  VC_ADMIN_DEAL_VIEW_TYPE,
  DEAL_STATUS_TYPE,
  VC_DEAL_STAGE_COLOR_MAPPER,
} from 'common/utils/constants';
import { postLoginLogAmpEvent } from 'config/amplitude';
import { vcDealStageList } from 'common/utils/option-list';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from '../styles';
import AiAnalystContent from './AiAnalystContent';
import YCDealSidePane from './YCDealSidePane';

type YcDealsProps = {
  ycDealsRef: any;
  vcFirm: any;
  ycDeals: Array<any>;
  filteredSearchYCDealsData: any;
  setFilteredSearchYCDealsData?: any;
  ycDealsMappings: any;
  fetchingYcDealsMapping: boolean;
  onAddingDealToPipeline: any;
  handleUnlockDealDialog: any;
  unlockingDeal: Record<string, boolean>;
  onChangingYcDealStage: any;
  setIsStartSubscription: any;
  dealPipeline: any;
  valid: boolean;
  joiningPlan: boolean;
  permissionInfo: any;
};

const YCDeals = ({
  ycDealsRef,
  vcFirm,
  ycDeals,
  filteredSearchYCDealsData,
  setFilteredSearchYCDealsData,
  ycDealsMappings,
  fetchingYcDealsMapping,
  onAddingDealToPipeline,
  handleUnlockDealDialog,
  unlockingDeal,
  onChangingYcDealStage,
  // setIsStartSubscription,
  // dealPipeline,
  valid,
  permissionInfo,
}: YcDealsProps) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const adminDealId = searchParams.get('adminDeal') || '';

  const {
    balance = 0,
    aiAnalaystUsage,
    aiAnalaystUsageLoading,
  } = useSelector(({ Global }: RootState) => Global);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { user: userInfo, user } = useSelector(({ Auth }: RootState) => Auth);

  const disableAsPerPermission = permissionInfo.DEAL_PIPELINE === 'view';

  const [open, setOpen] = React.useState<boolean>(false);
  const [ycDealSidepaneOpen, setYcDealSidepaneOpen] =
    React.useState<boolean>(false);
  const [sidePaneDeals, setSidePaneDeals] = React.useState<Record<string, any>>(
    { active: {}, left: {}, right: {} },
  );
  const [openNotification, setOpenNotification] =
    React.useState<boolean>(false);
  const [selectedDeal, setSelectedDeal] = React.useState<Record<string, any>>(
    {},
  );
  const [showCompanyBlurp, setShowCompanyBlurp] = React.useState<
    Record<string, boolean>
  >({});
  const [loadingInvestmentNote, setLoadingInvestmentNote] = React.useState<
    Record<string, boolean>
  >({});
  const [noteError, setNoteError] = React.useState<string>('false');
  const [addingToDealPipeline, setAddingToDealPipeline] = React.useState<
    Record<string, boolean>
  >({});
  const [anchorElChangeStatus, setAnchorElChangeStatus] =
    React.useState<any>(null);
  const [loadingDealStageChange, setLoadingDealStageChange] = React.useState<
    Record<string, boolean>
  >({});

  const handleMenuChangeStatusClick = (event: any) => {
    setAnchorElChangeStatus(event.currentTarget);
  };

  const handleMenuChangeStatusClose = () => {
    setAnchorElChangeStatus(null);
    setSelectedDeal({});
  };

  const addToPipeline = (adminDealId: string, dealStage: string) => {
    addingToDealPipeline[adminDealId] = true;
    setAddingToDealPipeline({ ...addingToDealPipeline });
    addVcAdminDealsToPipeline({
      vcAdminDealId: adminDealId,
      dealStage: dealStage,
      vcFirmId: get(vcFirm, 'id'),
      investorId: get(user, 'investorId'),
      addingToDealPipeline: true,
    })
      .then((res: any) => {
        postLoginLogAmpEvent('Deal-Added-To-Pipeline-Click', userInfo, {
          vcDealId: adminDealId,
          companyName: (ycDeals || []).find(
            (deal) => deal.vcAdminDealId === adminDealId,
          )?.companyName,
        });
        onAddingDealToPipeline(adminDealId, get(res, 'id'), dealStage, false);
        setAddingToDealPipeline({});
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR WHILE ADDING DEAL TO PIPELINE: ', message);
        setAddingToDealPipeline({});
      });
  };

  const addToPipelineFromSidepane = (
    adminDealId: string,
    dealStage: string,
  ) => {
    addingToDealPipeline[adminDealId] = true;
    setAddingToDealPipeline({ ...addingToDealPipeline });
    addVcAdminDealsToPipeline({
      vcAdminDealId: adminDealId,
      dealStage: dealStage,
      vcFirmId: get(vcFirm, 'id'),
      investorId: get(user, 'investorId'),
      addingToDealPipeline: true,
    })
      .then((res: any) => {
        postLoginLogAmpEvent('Deal-Added-To-Pipeline-Click', userInfo, {
          vcDealId: adminDealId,
          companyName: (ycDeals || []).find(
            (deal) => deal.vcAdminDealId === adminDealId,
          )?.companyName,
        });
        onAddingDealToPipeline(adminDealId, get(res, 'id'), dealStage, false);
        setSidePaneDeals((prevState) => ({
          ...prevState,
          active: {
            ...prevState.active,
            dealStage: dealStage,
            id: get(res, 'id'),
            dealStatus: DEAL_STATUS_TYPE.PROSPECTIVE,
          },
        }));
        setAddingToDealPipeline({});
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR WHILE ADDING DEAL TO PIPELINE: ', message);
        setAddingToDealPipeline({});
      });
  };

  const handleDealStatusChange = (
    adminDealId: string,
    vcDealId: string,
    dealStage: string,
  ) => {
    setLoadingDealStageChange({
      ...{
        [adminDealId]: true,
      },
    });
    updateVcDealInfo({
      id: vcDealId,
      vcFirmId: get(vcFirm, 'id'),
      investorId: get(user, 'investorId'),
      dealStage: dealStage,
    })
      .then(() => {
        onChangingYcDealStage(adminDealId, dealStage, false);
      })
      .finally(() => setLoadingDealStageChange({}));
  };

  const handleDealStatusChangeFromSidepane = (
    adminDealId: string,
    vcDealId: string,
    dealStage: string,
  ) => {
    setLoadingDealStageChange({
      ...{
        [adminDealId]: true,
      },
    });
    updateVcDealInfo({
      id: vcDealId,
      vcFirmId: get(vcFirm, 'id'),
      investorId: get(user, 'investorId'),
      dealStage: dealStage,
    })
      .then(() => {
        onChangingYcDealStage(adminDealId, dealStage, false);
        setSidePaneDeals((prevState) => ({
          ...prevState,
          active: {
            ...prevState.active,
            dealStage: dealStage,
          },
        }));
      })
      .finally(() => setLoadingDealStageChange({}));
  };

  const isDealLocked = (deal: any): boolean => {
    // if (get(deal, 'isFreeDeal')) return false;
    const isDealUnlocked = ycDealsMappings.find(
      (mapping: any) =>
        mapping.adminDealId === deal.vcAdminDealId && mapping.unlocked === true,
    );
    if (isDealUnlocked) return false;
    return true;
  };

  const userHasBalance = () => {
    return get(
      userHasCreditBalance(aiAnalaystUsage, subscription, balance),
      'hasBalance',
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const getUnlockText = (deal: any) => {
    if (get(deal, 'isFreeDeal')) return '';
    if (userHasBalance()) return '';
    const isDealUnlocked = ycDealsMappings.find(
      (mapping: any) =>
        mapping.adminDealId === deal.vcAdminDealId && mapping.unlocked === true,
    );
    if (isDealUnlocked) return '';
    if (unlockingDeal[deal.vcAdminDealId])
      return <Loader size={20} className={classes.unlockLoader} />;
    return (
      <WrappedTypography
        className={classes.unlockText}
        onClick={() => {
          if (!valid) {
            dispatch(planExpiredDialog(true));
            return;
          }
          handleUnlockDeal(deal);
        }}
      >
        Unlock Deal
      </WrappedTypography>
    );
  };

  const handleUnlockDeal = (deal: any, cb?: any) => {
    // TODO: We can use this for future requirement
    // if (isEmpty(paymentCards) && !userHasBalance()) {
    //   history.push(
    //     `/subscription-v2?source=ycdeals&adminDeal=${deal.vcAdminDealId}`,
    //   );
    // } else {
    //   handleUnlockDealDialog(
    //     {
    //       id: deal.vcAdminDealId,
    //       type: 'YC',
    //       companyName: get(deal, 'companyName'),
    //     },
    //     cb,
    //   );
    // }
    console.log(deal); //eslint-disable-line
    handleUnlockDealDialog(
      {
        id: deal.vcAdminDealId,
        type: 'YC',
        deal: deal,
        companyName: get(deal, 'companyName'),
      },
      cb,
    );
  };

  const handleInvestmentReport = (deal: any, otherDeals: any) => {
    // eslint-disable-next-line no-console
    console.log(deal, 'ASDAKDADSNM', otherDeals);
    setLoadingInvestmentNote({ ...{ [deal.vcAdminDealId]: true } });
    setNoteError('');
    getYcPreloadedVcAdminDealInvestmentReport(deal.vcAdminDealId)
      .then((res: any) => {
        deal.aiAnalyst = res;
        setSelectedDeal({ ...deal });
        handleIncrementVcAdminDealView(
          deal,
          VC_ADMIN_DEAL_VIEW_TYPE.INVESTMENT_NOTE,
        );
        setSidePaneDeals({
          active: deal,
          left: get(otherDeals, 'left'),
          right: get(otherDeals, 'right'),
        });
        // setOpen(true);
        !ycDealSidepaneOpen && setYcDealSidepaneOpen(true);
        postLoginLogAmpEvent('Deal-InvestmentNote-Viewed', userInfo, {
          vcDealId: deal.vcAdminDealId,
          companyName: get(deal, 'companyName'),
          freeDeal: get(deal, 'isFreeDeal') ? 'Yes' : 'No',
        });
      })
      .catch((err: any) => {
        setOpenNotification(true);
        const message: string = errorMessageHandler(err);
        deal.aiAnalyst = {};
        setSidePaneDeals({
          active: deal,
          left: get(otherDeals, 'left'),
          right: get(otherDeals, 'right'),
        });
        setNoteError(message);
      })
      .finally(() => setLoadingInvestmentNote({}));
  };

  const handleInvestmentReportDialog = (
    deal: any,
    otherDeals: any,
    index: number,
  ) => {
    !ycDealSidepaneOpen && setYcDealSidepaneOpen(true);
    deal.index = index;
    if (!isEmpty(get(otherDeals, 'left'))) {
      otherDeals.left.index = index - 1;
    }
    if (!isEmpty(get(otherDeals, 'right'))) {
      otherDeals.right.index = index + 1;
    }
    otherDeals &&
      setSidePaneDeals({
        active: deal,
        left: get(otherDeals, 'left'),
        right: get(otherDeals, 'right'),
      });
    handleInvestmentReport(deal, otherDeals);
  };

  const onClickDeal = (deal: any, direction: string) => {
    const handleDirection = () => {
      if (direction === 'right') {
        const index = get(deal, 'index');
        const newActiveDeal = filteredSearchYCDealsData[index];
        const newLeftDeal = filteredSearchYCDealsData[get(deal, 'index') - 1];
        const newRightDeal = filteredSearchYCDealsData[index + 1];
        setSidePaneDeals({
          ...{
            active: newActiveDeal,
            left: newLeftDeal,
            right: newRightDeal,
          },
        });
        if (deal) {
          handleInvestmentReportDialog(
            newActiveDeal,
            { left: newLeftDeal, right: newRightDeal },
            index,
          );
        }
      } else if (direction === 'left') {
        const index = get(deal, 'index');
        const newActiveDeal = filteredSearchYCDealsData[index];
        const newLeftDeal = filteredSearchYCDealsData[get(deal, 'index') - 1];
        const newRightDeal = filteredSearchYCDealsData[index + 1];
        setSidePaneDeals({
          ...{
            active: newActiveDeal,
            left: newLeftDeal,
            right: newRightDeal,
          },
        });
        if (deal) {
          handleInvestmentReportDialog(
            newActiveDeal,
            { left: newLeftDeal, right: newRightDeal },
            index,
          );
        }
      }
    };

    const unlocked = ycDealsMappings.find(
      (mapping: any) =>
        mapping.adminDealId === deal.vcAdminDealId && mapping.unlocked === true,
    );
    scrollToDeal(get(deal, 'vcAdminDealId'));
    if (!unlocked) {
      handleUnlockDeal(deal, () => {
        handleDirection();
      });
    } else {
      handleDirection();
    }
  };

  const handleIncrementVcAdminDealView = (deal: any, type: string) => {
    const obj = {
      adminDealId: get(deal, 'vcAdminDealId'),
      investorId: get(user, 'investorId'),
      type: type,
    };
    incrementVcAdminDealView(obj)
      .then((res: any) => {
        const updatedYCData = filteredSearchYCDealsData.reduce(
          (acc: any, ycDeal: any) => {
            if (ycDeal.vcAdminDealId === get(res, 'vcAdminDealId')) {
              acc.push({
                ...ycDeal,
                noOfDealViews: get(res, 'noOfDealViews'),
              });
            } else {
              acc.push(ycDeal);
            }
            return acc;
          },
          [],
        );
        setFilteredSearchYCDealsData(updatedYCData);
        const updatedYCMappingData = ycDealsMappings.find(
          (dealMapping: any) =>
            dealMapping.adminDealId === get(res, 'vcAdminDealId'),
        );
        if (!isEmpty(updatedYCMappingData)) {
          updatedYCMappingData.viewedAt = get(res, 'viewedAt');
          ycDealsMappings = uniqBy(
            [...ycDealsMappings, updatedYCMappingData],
            'id',
          );
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_INCREMENT_DEAL_VIEWS', message);
      });
  };

  const scrollToDeal = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center' });
    }
  };

  useEffect(() => {
    ycDealsRef.current = handleInvestmentReport;
  }, []);

  useEffect(() => {
    if (!aiAnalaystUsageLoading) {
      if (filter === 'YC' && adminDealId) {
        const deal = ycDeals.find(
          (deal: any) => deal.vcAdminDealId === adminDealId,
        );
        if (deal) {
          setSelectedDeal(deal);
          scrollToDeal(adminDealId);
          const activeIndex = (filteredSearchYCDealsData || []).findIndex(
            (item: any) => item.vcAdminDealId === adminDealId,
          );
          if (activeIndex !== -1) {
            const left = filteredSearchYCDealsData[activeIndex - 1];
            const right = filteredSearchYCDealsData[activeIndex + 1];
            if (!isEmpty(left)) left.index = activeIndex - 1;
            if (!isEmpty(right)) right.index = activeIndex + 1;
            isDealLocked(deal)
              ? handleUnlockDeal(deal)
              : handleInvestmentReportDialog(
                  deal,
                  {
                    left: get(
                      filteredSearchYCDealsData,
                      `[${activeIndex - 1}]`,
                    ),
                    right: get(
                      filteredSearchYCDealsData,
                      `[${activeIndex + 1}]`,
                    ),
                  },
                  activeIndex,
                );
            // handleInvestmentReport(deal, {
            //   left: filteredSearchYCDealsData[activeIndex - 1],
            //   right: filteredSearchYCDealsData[activeIndex + 1],
            // });
          } else {
            isDealLocked(deal)
              ? handleUnlockDeal(deal)
              : handleInvestmentReport(deal, { left: {}, right: {} });
          }
        }
        searchParams.delete('adminDeal');
        searchParams.delete('ycbatchname');
        window.history.replaceState(
          {},
          '',
          '/deals?type=prospective&filter=YC',
        );
      }
    }
  }, [filter, adminDealId, aiAnalaystUsageLoading]);

  return (
    <Box className={classes.ycDealsTableBox}>
      {!isEmpty(filteredSearchYCDealsData) ? (
        (filteredSearchYCDealsData || [])
          .sort((a: any, b: any) => b.isFreeDeal - a.isFreeDeal)
          .sort((a: any, b: any) => b?.createdAt - a?.createdAt)
          .map((deal: any, index: number) => (
            <div
              key={`yc_${index}_${get(deal, 'vcAdminDealId')}`}
              id={get(deal, 'vcAdminDealId')}
            >
              <Box
                className={`${classes.ycDealContainer}
                  ${
                    get(loadingInvestmentNote, deal.vcAdminDealId)
                      ? classes.ycDealContainerHighlight
                      : ''
                  }
                `}
              >
                <Box className={classes.ycDealBox}>
                  <Box style={{ display: 'flex' }}>
                    <WrappedTypography className={classes.ycDealName}>
                      {get(deal, 'companyName')}
                    </WrappedTypography>
                    {!isEmpty(get(deal, 'countryOfOperation')) && (
                      <WrappedTypography className={classes.ycDealLocation}>
                        {get(deal, 'countryOfOperation')
                          .split(',')
                          .map((item: any) =>
                            get(getCountryOfOperationsObject(item), 'country'),
                          )
                          .join(', ')}
                      </WrappedTypography>
                    )}
                  </Box>
                  {!fetchingYcDealsMapping ? (
                    <Box className={classes.investBlurpBtnWrapper}>
                      {!isEmpty(get(deal, 'companyBlurp')) && (
                        <Box className={classes.investBlurpButtonBox}>
                          <Button
                            className={
                              get(showCompanyBlurp, deal.vcAdminDealId)
                                ? classes.openCompanyBlurpBtn
                                : classes.companyBlurpBtn
                            }
                            onClick={() => {
                              if (!valid) {
                                dispatch(planExpiredDialog(true));
                                return;
                              }
                              if (!get(showCompanyBlurp, deal.vcAdminDealId)) {
                                handleIncrementVcAdminDealView(
                                  deal,
                                  VC_ADMIN_DEAL_VIEW_TYPE.COMPANY_BLURP,
                                );
                              }
                              !get(showCompanyBlurp, deal.vcAdminDealId) &&
                                postLoginLogAmpEvent(
                                  'CompanyBlurb-Viewed',
                                  userInfo,
                                  { companyName: get(deal, 'companyName') },
                                );
                              setShowCompanyBlurp({
                                ...{
                                  [deal.vcAdminDealId]: !get(
                                    showCompanyBlurp,
                                    deal.vcAdminDealId,
                                  ),
                                },
                              });
                            }}
                          />
                        </Box>
                      )}
                      <Box className={classes.investBlurpButtonBox}>
                        {/* {get(deal, 'isFreeDeal') && (
                          <span className={classes.ycDealFreeTagText}>Free</span>
                        )} */}
                        <CustomizedTooltip
                          arrow={true}
                          placement="right-end"
                          className={classes.childMenuToolTip}
                          title={
                            get(deal, 'isInvestmentReportPresent', false)
                              ? ''
                              : `${get(
                                  deal,
                                  'companyName',
                                )} has not yet launched publicly`
                          }
                        >
                          <span>
                            <Button
                              className={classes.investmentNoteBtn}
                              disabled={
                                get(
                                  loadingInvestmentNote,
                                  deal.vcAdminDealId,
                                ) ||
                                get(
                                  deal,
                                  'isInvestmentReportPresent',
                                  false,
                                ) === false
                              }
                              isLoading={
                                unlockingDeal[deal.vcAdminDealId] ||
                                get(loadingInvestmentNote, deal.vcAdminDealId)
                              }
                              onClick={() => {
                                if (!valid) {
                                  dispatch(planExpiredDialog(true));
                                } else {
                                  isDealLocked(deal)
                                    ? handleUnlockDeal(deal)
                                    : handleInvestmentReportDialog(
                                        deal,
                                        {
                                          left: get(
                                            filteredSearchYCDealsData,
                                            `[${index - 1}]`,
                                          ),
                                          right: get(
                                            filteredSearchYCDealsData,
                                            `[${index + 1}]`,
                                          ),
                                        },
                                        index,
                                      );
                                }
                              }}
                            />
                          </span>
                        </CustomizedTooltip>
                      </Box>
                    </Box>
                  ) : (
                    <Skeleton variant="rectangular" height={35} width={130} />
                  )}
                </Box>
                <Box className={classes.ycDealUnlockDealBox}>
                  <WrappedTypography className={classes.ycDealOnelinerText}>
                    {get(deal, 'companyOneLiner')}
                  </WrappedTypography>
                  {/* {!fetchingYcDealsMapping ? (
                    getUnlockText(deal)
                  ) : (
                    <Skeleton variant="text" height={28} width={130} />
                  )} */}
                </Box>
                <Box className={classes.ycDealAddToLeadBox}>
                  <Box>
                    {!isEmpty(get(deal, 'sector')) && (
                      <Chip
                        className={classes.ycTagText}
                        label={get(deal, 'sector')}
                      />
                    )}
                    {!isEmpty(get(deal, 'tags')) &&
                      get(deal, 'tags')
                        .split(',')
                        .map((item: any, index: number) => (
                          <Chip
                            key={`tag_${get(deal, 'vcAdminDealId')}_${index}`}
                            className={classes.ycTagText}
                            label={item}
                          />
                        ))}
                  </Box>
                  {!fetchingYcDealsMapping ? (
                    <Box style={{ display: 'flex' }}>
                      <WrappedTypography className={classes.viewsBox}>
                        Views: {get(deal, 'noOfDealViews')}
                      </WrappedTypography>
                      <Button
                        name={
                          !isEmpty(get(deal, 'id')) &&
                          get(deal, 'dealStatus') !==
                            DEAL_STATUS_TYPE.PROSPECTIVE
                            ? VC_YC_DEAL_STATUS_MAPPER[get(deal, 'dealStatus')]
                            : !isEmpty(get(deal, 'dealStage'))
                            ? VC_DEAL_STAGE_MAPPER[get(deal, 'dealStage')] ===
                              'Lead'
                              ? 'Leads'
                              : `${
                                  VC_DEAL_STAGE_MAPPER[get(deal, 'dealStage')]
                                }`
                            : 'Add to Pipeline'
                        }
                        sx={
                          !isEmpty(get(deal, 'dealStage')) &&
                          get(deal, 'dealStatus') ===
                            DEAL_STATUS_TYPE.PROSPECTIVE
                            ? {
                                backgroundColor: `${
                                  VC_DEAL_STAGE_COLOR_MAPPER[
                                    get(deal, 'dealStage')
                                  ]
                                } !important`,
                                '&:hover': {
                                  backgroundColor: `${
                                    VC_DEAL_STAGE_COLOR_MAPPER[
                                      get(deal, 'dealStage')
                                    ]
                                  } !important`,
                                },
                              }
                            : {}
                        }
                        className={
                          !isEmpty(get(deal, 'id')) &&
                          get(deal, 'dealStatus') !==
                            DEAL_STATUS_TYPE.PROSPECTIVE
                            ? `${classes.pipelineBtn} ${classes.investedBtn}`
                            : !isEmpty(get(deal, 'dealStage'))
                            ? classes.pipelineBtn
                            : classes.addToPipelineBtn
                        }
                        endIcon={
                          !isEmpty(get(deal, 'id')) &&
                          get(deal, 'dealStatus') !==
                            DEAL_STATUS_TYPE.PROSPECTIVE ? undefined : (
                            <ArrowRightAltIcon />
                          )
                        }
                        isLoading={
                          get(addingToDealPipeline, [deal.vcAdminDealId]) ||
                          get(loadingDealStageChange, [deal.vcAdminDealId])
                        }
                        onClick={(event: any) => {
                          // if (
                          //   ((get(dealPipeline, 'status') ===
                          //     'START_SUBSCRIPTION' ||
                          //     get(dealPipeline, 'status') ===
                          //       FREE_PLATFORM_DEAL) &&
                          //     isUserOnJoiningPlan(subscription)) ||
                          //   get(dealPipeline, 'status') ===
                          //     'SUBSCRIPTION_CANCELLED'
                          // ) {
                          //   setIsStartSubscription(true);
                          //   return;
                          if (!valid) {
                            dispatch(planExpiredDialog(true));
                            return;
                          } else {
                            if (
                              !isEmpty(get(deal, 'id')) &&
                              get(deal, 'dealStatus') !==
                                DEAL_STATUS_TYPE.PROSPECTIVE
                            ) {
                              return;
                            } else {
                              handleMenuChangeStatusClick(event);
                              setSelectedDeal({ ...deal });
                            }
                          }
                        }}
                        disabled={disableAsPerPermission}
                      />
                    </Box>
                  ) : (
                    <Skeleton variant="rectangular" height={28} width={90} />
                  )}
                </Box>
                {!isEmpty(
                  get(
                    find(ycDealsMappings, {
                      adminDealId: get(deal, 'vcAdminDealId'),
                    }),
                    'viewedAt',
                  ),
                ) && (
                  <Box>
                    <WrappedTypography className={classes.ycDealLastViewedText}>
                      Last viewed:{' '}
                      {moment(
                        get(
                          find(ycDealsMappings, {
                            adminDealId: get(deal, 'vcAdminDealId'),
                          }),
                          'viewedAt',
                        ),
                      ).format('MMMM D, YYYY')}
                    </WrappedTypography>
                  </Box>
                )}
                {!isEmpty(get(deal, 'companyBlurp')) && (
                  <Box
                    className={classes.ycDealCompanyBlurpBox}
                    style={
                      get(showCompanyBlurp, deal.vcAdminDealId)
                        ? {
                            visibility: 'visible',
                            opacity: 1,
                            height: 'auto',
                          }
                        : {}
                    }
                  >
                    <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                    <WrappedTypography
                      className={classes.ycDealCompanyBlurpText}
                    >
                      {get(deal, 'companyBlurp')}
                    </WrappedTypography>
                  </Box>
                )}
              </Box>
            </div>
          ))
      ) : (
        <Box className={classes.noRecordBox}>
          <WrappedTypography>No record Found</WrappedTypography>
        </Box>
      )}
      <Menu
        id="simple-menu-stage-change"
        anchorEl={anchorElChangeStatus}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElChangeStatus)}
        onClose={handleMenuChangeStatusClose}
        className={classes.dealStageMenu}
      >
        <MenuItem disabled sx={{ opacity: 1 }}>
          Move To
        </MenuItem>
        {vcDealStageList.map((item) => (
          <MenuItem
            key={item.value}
            onClick={() => {
              if (!valid) {
                dispatch(planExpiredDialog(true));
              } else {
                if (!isEmpty(get(selectedDeal, 'dealStage'))) {
                  handleDealStatusChange(
                    get(selectedDeal, 'vcAdminDealId'),
                    get(selectedDeal, 'id'),
                    item.value,
                  );
                } else if (isEmpty(get(selectedDeal, 'dealStage'))) {
                  addToPipeline(get(selectedDeal, 'vcAdminDealId'), item.value);
                }
                handleMenuChangeStatusClose();
              }
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
      {open && (
        <AiAnalystContent
          open={open}
          setOpen={(val: boolean) => {
            setOpen(val);
            if (val === false && adminDealId) {
              history.push('/deals?type=prospective&filter=YC');
              scrollToDeal(adminDealId);
            }
          }}
          investmentNote={get(selectedDeal, 'aiAnalyst')}
          dealId={get(selectedDeal, 'vcAdminDealId')}
          vcFirm={vcFirm}
          deal={{ ycDeal: true, companyName: get(selectedDeal, 'companyName') }}
          vcAdminDeal={true}
          onCloseDialog={() => setSelectedDeal({})}
        />
      )}
      {openNotification && (
        <SnackBar
          type={noteError ? 'error' : 'success'}
          open={openNotification}
          handleClose={() => {
            setOpenNotification(false);
          }}
          message={noteError}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        />
      )}
      {ycDealSidepaneOpen && (
        <YCDealSidePane
          open={ycDealSidepaneOpen}
          handleClose={() => {
            setYcDealSidepaneOpen(false);
            setSidePaneDeals({ active: {}, left: {}, right: {} });
          }}
          sidePaneDeals={sidePaneDeals}
          loadingInvestmentNote={
            get(
              loadingInvestmentNote,
              get(sidePaneDeals, 'active.vcAdminDealId'),
            ) || !isEmpty(unlockingDeal)
          }
          onClickDeal={onClickDeal}
          addToPipeline={addToPipelineFromSidepane}
          handleDealStatusChange={handleDealStatusChangeFromSidepane}
          addingToDealPipeline={addingToDealPipeline}
          disableAsPerPermission={disableAsPerPermission}
        />
      )}
    </Box>
  );
};

export default YCDeals;
