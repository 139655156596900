/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useRef, Fragment, useEffect } from 'react';
import { isEmpty, get, orderBy, size } from 'lodash';
import {
  Box,
  ClickAwayListener,
  Grid,
  Typography,
  LinearProgress,
} from '@mui/material';
import moment from 'moment';
import { Close } from '@mui/icons-material';

import { UploadSafeIcon } from 'assets';
import {
  WrappedTypography,
  InputTextField,
  SelectField,
  AutoCompleteField,
  InputPriceField,
  MultipleSelectField,
  Button,
  CustomizedTooltip,
  ConfirmationDialog,
  Accordion,
  Loader,
} from 'components';
import {
  getSignedUrlForVCFirmDocs,
  createUpdateSAFEDetail,
  addOrUpdateVcDealsMultipleDocuments,
  // getUploadedDocuments,
  // deleteVcFirmDocuments,
  getDocByVcFirmAndDocumentName,
  getVcDeal,
  updateVcDealSafeDetails,
} from 'services';
import {
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  DEAL_STATUS_TYPE,
  TYPE_OF_INSTRUMENT,
  VC_FIRM_DOCUMENT_NAME,
  // TERMS_OF_SAFE_MAPPER,
} from 'common/utils/constants';
import {
  formatAmount,
  getBusinessOpsCountryObject,
  getPricingLatestRoundObject,
  getSafeTermFieldsCalculation,
  sortSafeFields,
} from 'common/utils/helpers';
import {
  fundingRoundStageList,
  businessOpsCountryList,
  typeOfInstrumentList,
  vcDealTermsOfSafeList,
} from 'common/utils/option-list';
import { FN } from 'common/types';

import LatestValuationAccord from './LatestValuationAccord';
import styles from './styles';

type props = {
  type?: any;
  investorId: string;
  vcFirmId: string;
  vcFirmCurrency: any;
  vcFirmCurrencySymbol: any;
  dealPartiallyExited: boolean;
  selectedDealData: any;
  selectedDeal: string;
  control: any;
  setValue: FN;
  getValues: FN;
  watch: FN;
  resetField: any;
  getCalculatedCurrentValuation: FN;
  getCalculatedCurrentValue: FN;
  getCalculatedPercentageOwned: FN;
  getCalculatedMoic: FN;
  getCalculatedRealisedMoic: FN;
  safeFields: FN;
  setSafeFields: FN;
  valuationField: any;
  valuationPrepend: any;
  valuationRemove: any;
  onSubmit: FN;
  handleSubmit: FN;
  setIsMFNFreezeChecked: FN;
  isMFNFreezeChecked: boolean;
  disableAsPerPermission: boolean;
};

const InvestmentTab = ({
  type,
  investorId,
  vcFirmId,
  vcFirmCurrency,
  vcFirmCurrencySymbol,
  dealPartiallyExited,
  selectedDealData,
  selectedDeal,
  control,
  setValue,
  getValues,
  watch,
  resetField,
  getCalculatedCurrentValuation,
  getCalculatedCurrentValue,
  getCalculatedPercentageOwned,
  getCalculatedMoic,
  getCalculatedRealisedMoic,
  safeFields,
  setSafeFields,
  valuationField,
  valuationPrepend,
  valuationRemove,
  onSubmit,
  handleSubmit,
  setIsMFNFreezeChecked,
  isMFNFreezeChecked,
  disableAsPerPermission,
}: props) => {
  const classes = styles();

  const [open, setOpen] = React.useState<any>(false);
  const [isProcessing, setIsProcessing] = React.useState<any>(false);

  const [showPercentageOwnedTooltip, setShowPercentageOwnedTooltip] =
    React.useState<string>('');
  const [, setShowMFNTooltip] = React.useState<string>('');
  const [, setRoundStageList] = React.useState<any>(fundingRoundStageList);
  const [confirmationDialog, setOpenConfirmationDialog] =
    React.useState<boolean>(false);
  const [, setShowValuationCapTooltip] = React.useState<string>('');

  const [expanded, setExpanded] = React.useState<string[]>(['Overview']);

  const calculateCurrentOwnedMoic = () => {
    if (
      !isEmpty(watch('NoOfSharesAlloted')) &&
      !isEmpty(watch('totalNoOfShares')) &&
      !isEmpty(watch('pricePerShare'))
    ) {
      setValue(
        'currentValue',
        watch('pricePerShare') * watch('NoOfSharesAlloted'),
      );
      setValue(
        'percentageOwned',
        ((watch('NoOfSharesAlloted') / watch('totalNoOfShares')) * 100).toFixed(
          2,
        ),
      );
      setValue(
        'moic',
        (
          parseFloat(watch('currentValue')) /
          parseFloat(watch('investedAmount'))
        ).toFixed(1),
      );
    } else {
      getCalculatedCurrentValuation();
      getCalculatedCurrentValue();
      getCalculatedPercentageOwned();
      getCalculatedMoic();
    }
  };
  const getCalculatedEntryValuation = () => {
    const payload = {
      priceRoundValuation: watch('priceRoundValuation'),
      discountMfn: watch('discountMfn'),
      mfnValuation: watch('mfnValuation'),
      valuationCap: watch('valuationCap'),
      freezeMfnValuation: isMFNFreezeChecked,
    };
    setValue('lastRoundValuation', safeFields?.entryValuation(payload));
    setValue(
      'safeToEquityValuation',
      safeFields?.safeToEquityValuation(payload),
    );
    getCalculatedCurrentValuation();
    getCalculatedCurrentValue();
    getCalculatedPercentageOwned();
    getCalculatedMoic();
  };

  const isPriceRoundCheckboxChecked = () => {
    const defaultRoundIndex = (watch('valuationRoundDetail') || []).findIndex(
      (round: any) => round.isPriceRound,
    );
    if (defaultRoundIndex > -1) {
      return true;
    } else return false;
  };

  const getRoundList = () => {
    const selectedRoundList: any[] = [];
    watch('valuationRoundDetail').map(
      (i: any) => !isEmpty(i.roundName) && selectedRoundList.push(i.roundName),
    );
    const newArr = fundingRoundStageList.map((object: any) => {
      if (selectedRoundList.includes(object.value)) {
        return { ...object, disableOption: true };
      }
      return object;
    });
    setRoundStageList(newArr);
  };

  const getLastRoundDate = () => {
    const dates: string[] = [];
    (watch('valuationRoundDetail') || []).map(
      (i: any) => !isEmpty(i.date) && dates.push(i.date),
    );
    if (!isEmpty(dates)) {
      const arrDates = dates.map((str) => new Date(str));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const max = new Date(Math.max(...arrDates));
      return moment(max).format('YYYY-MM-DD');
    } else return '';
  };

  const handleClearInvestmentDetail = (
    array: string[] | ConcatArray<string>,
  ) => {
    let invField = [
      'investmentDate',
      'priceRoundValuation',
      'valuationCap',
      'discountMfn',
      'mfnValuation',
      'investedAmount',
      'exitValuation',
      'percentageOwned',
      'moic',
      'currentValue',
      'lastRoundValuation',
    ];
    if (!isEmpty(array)) {
      invField = invField.concat(array);
    }
    invField.map((i: string) => {
      resetField(i);
    });
    getCurrentValuationText();
    confirmationDialog && setOpenConfirmationDialog(false);
  };

  const getLatestRound = () => {
    let valuationRoundDetail = watch('valuationRoundDetail');
    valuationRoundDetail = orderBy(
      valuationRoundDetail,
      ['createdAt'],
      ['desc'],
    );
    return !isEmpty(valuationRoundDetail) ? valuationRoundDetail[0] : [];
  };

  const getCurrentValuationText = () => {
    if (!isEmpty(watch('valuationRoundDetail'))) {
      if (!isEmpty(getLatestRound())) {
        return `${
          !isEmpty(get(getLatestRound(), 'roundName'))
            ? get(getLatestRound(), 'roundName.value')
            : ''
        } - ${
          !isEmpty(get(getLatestRound(), 'valuation'))
            ? formatAmount(get(getLatestRound(), 'valuation'))
            : ''
        }`;
      } else if (
        isEmpty(getLatestRound()) &&
        watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
        !isEmpty(watch('priceRoundValuation'))
      ) {
        return `1st Priced Round - ${formatAmount(
          watch('priceRoundValuation'),
        )}`;
      } else if (
        isEmpty(getLatestRound()) &&
        watch('investedInstrumentType') !== TYPE_OF_INSTRUMENT.SAFE &&
        !isEmpty(watch('lastRoundValuation'))
      ) {
        return `1st Priced Round -  ${formatAmount(
          watch('lastRoundValuation'),
        )}`;
      } else {
        return `Update latest valuation after each funding round`;
      }
    } else {
      return `Update latest valuation after each funding round`;
    }
  };

  useEffect(() => {
    if (!isEmpty(watch('termsOfSafe'))) {
      const sortedData = sortSafeFields(watch('termsOfSafe'));
      // alert(JSON.stringify(getSafeTermFieldsCalculation(sortedData)));
      // alert(JSON.stringify(getValues('termsOfSafe')));

      // getValues('investmentDate'),
      setSafeFields(getSafeTermFieldsCalculation(sortedData));
    }
  }, [watch('termsOfSafe')]);

  useEffect(() => {
    if (!isEmpty(watch('valuationRoundDetail'))) {
      getRoundList();
      getLastRoundDate();
    }
    getCurrentValuationText();
  }, [watch('valuationRoundDetail')]);

  useEffect(() => {
    if (!isEmpty(watch('valuationRoundDetail'))) {
      getCurrentValuationText();
    }
  }, [
    watch(`valuationRoundDetail[0].valuation`),
    watch(`valuationRoundDetail[0].roundName`),
    watch(`valuationRoundDetail[0].date`),
  ]);

  // useEffect(() => {
  //   if (
  //     isEmpty(get(selectedDealData, 'valuationRoundDetail')) &&
  //     ['', null, undefined].includes(watch('priceRoundValuation')) &&
  //     size(watch('valuationRoundDetail')) >= 1
  //   ) {
  //     setValue('valuationRoundDetail', []);
  //     setValue('valuationCap', null);
  //     setValue('discountMfn', null);
  //     setValue('mfnValuation', null);
  //   }
  //   getCurrentValuationText();
  // }, [watch('priceRoundValuation')]);

  useEffect(() => {
    if (
      ['', null, undefined].includes(watch('lastRoundValuation')) &&
      [TYPE_OF_INSTRUMENT.EQUITY].includes(watch('investedInstrumentType'))
    ) {
      setValue('valuationRoundDetail', []);
      setValue('percentageOwned', '');
      setValue('moic', '');
      setValue('currentValue', '');
    } else if (
      ['', null, undefined].includes(watch('investedAmount')) &&
      [TYPE_OF_INSTRUMENT.EQUITY].includes(watch('investedInstrumentType'))
    ) {
      setValue('percentageOwned', '');
      setValue('moic', '');
      setValue('currentValue', '');
    } else if (
      ['', null, undefined].includes(watch('investedAmount')) &&
      [TYPE_OF_INSTRUMENT.SAFE].includes(watch('investedInstrumentType'))
    ) {
      setValue('percentageOwned', '');
      setValue('moic', '');
      setValue('currentValue', '');
    }
    getCurrentValuationText();
  }, [watch('lastRoundValuation'), watch('investedAmount')]);

  useEffect(() => {
    if (!isEmpty(safeFields)) {
      getCalculatedEntryValuation();
    }
  }, [isMFNFreezeChecked]);
  const [selectedDocument, setSelectedDocument] = React.useState<any>({});
  interface FileType {
    // Define the structure of your file object here
    id: number;
    name: string;
    // add other relevant properties
  }
  const [files, setFiles] = React.useState<any>([]);
  // eslint-disable-next-line no-undef
  const inputRef = useRef<HTMLInputElement>(null);
  const handleAccordianChange = (panel: string) => () => {
    if (expanded.includes(panel)) {
      const newArray = expanded.filter((i) => i !== panel);
      setExpanded(newArray);
    } else {
      setExpanded([...expanded, panel]);
    }
  };

  const [currentStep, setCurrentStep] = React.useState<number>(0); // Initial synchronous value
  const [isInvestmentDetail, setIsInvestmentDetail] =
    React.useState<boolean>(false); // Converted to state
  const [isConfirmed, setIsConfirmed] = React.useState<boolean>(false);

  useEffect(() => {
    const initializeStep = async () => {
      const vcDeal = await getVcDeal(selectedDeal);
      if (get(vcDeal, 'investedInstrumentType')) {
        setIsConfirmed(true);
        setIsInvestmentDetail(true); // Updating state
      } else {
        setIsConfirmed(false);
        setIsInvestmentDetail(false); // Resetting state just in case
      }

      // Update current step based on isInvestmentDetail
      // setCurrentStep(isInvestmentDetail ? 1 : 0); // Adjust according to your logic
    };

    initializeStep();
  }, [selectedDeal]); // Added isInvestmentDetail as a dependency

  const handleValuationChange = () => {
    if (!valuationField.length) {
      valuationPrepend({
        roundName: !isEmpty(watch('lastFundingRound'))
          ? getPricingLatestRoundObject(watch('lastFundingRound'))
          : null,
        date: !isEmpty(watch('investmentDate'))
          ? watch('investmentDate')
          : null,
        valuation: watch('lastRoundValuation'),
        defaultRound: true,
        isNew: true,
        isPriceRound: false,
      });
    } else {
      const defaultRoundIndex = valuationField.findIndex(
        (round: any) => round.defaultRound,
      );
      if (defaultRoundIndex > -1) {
        setValue(
          `valuationRoundDetail[${defaultRoundIndex}].valuation`,
          watch('lastRoundValuation'),
        );
      } else {
        valuationPrepend({
          roundName: !isEmpty(watch('lastFundingRound'))
            ? getPricingLatestRoundObject(watch('lastFundingRound'))
            : null,
          date: !isEmpty(watch('investmentDate'))
            ? watch('investmentDate')
            : null,
          valuation: watch('lastRoundValuation'),
          defaultRound: true,
          isNew: true,
          isPriceRound: false,
        });
      }
    }
    getCalculatedCurrentValuation();
    getCalculatedCurrentValue();
    getCalculatedPercentageOwned();
    getCalculatedMoic();
  };

  const handleProcess = async () => {
    try {
      setIsProcessing(true);
      const signedUrl = await getSignedUrlForVCFirmDocs({
        investorId: investorId,
        filename: files[0]?.name, // Ensure the file exists before accessing its name
        vcFirmId: vcFirmId,
        vcDealId: selectedDeal, // Use the passed dealId
        documentName: VC_FIRM_DOCUMENT_NAME.DEAL_SAFE_DOC,
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
      }); // Use newly created dealId
      const file: any = files[0];
      await fetch(get(signedUrl, 'uploadUrl'), {
        method: 'PUT',
        body: file,
      });
      const existingDocuments = await getDocByVcFirmAndDocumentName({
        investorId: investorId,
        vcFirmId: vcFirmId,
        dealId: selectedDeal, // Use the passed dealId
        documentName: VC_FIRM_DOCUMENT_NAME.DEAL_SAFE_DOC,
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
      });
      setSelectedDocument(existingDocuments);
      const objArray: any = [];
      objArray.push({
        vcFirmId: vcFirmId,
        investorId: investorId,
        documentName: VC_FIRM_DOCUMENT_NAME.DEAL_SAFE_DOC,
        vcDealId: selectedDeal,
        filename: files[0]?.name,
        type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
        documentUrl: get(signedUrl, 'accessUrl'),
        id: get(existingDocuments, 'id', null),
      });
      if (!isEmpty(objArray)) {
        await addOrUpdateVcDealsMultipleDocuments(objArray);
        // await getUploadedDocuments();
      }
      // await creareAiJob(createdDealId); // Use newly created dealId
      createUpdateSAFEDetail({
        name: 'SAFE_DOCUMENT',
        dealId: selectedDeal,
        documentsRequiredForInvestmentNote: ['DEAL_SAFE_DOC'],
      }).catch(() => {
        // eslint-disable-next-line no-unused-vars
        // const message: string = errorMessageHandler(err);
        // setNotificationError(ERROR_MESSAGES[message] || message);
        // setOpenNotification(true);
      });

      // setCurrentStep((prevStep) => prevStep + 1);
      let vcDeal: any = {};
      // const timer = setInterval(async () => {
      // }, 10 * 1000);
      setTimeout(async () => {
        vcDeal = await getVcDeal(selectedDeal);
        if (get(vcDeal, 'aiSafeDetails')) {
          console.log(get(vcDeal, 'aiSafeDetails'));
          // setIsInvestmentDetail(true);
          // eslint-disable-next-line no-undef
          // alert(get(vcDeal, 'aiSafeDetails.valuation'));
          setValue(
            'investedAmount',
            get(vcDeal, 'aiSafeDetails.amount_invested'),
          );
          setValue('discountMfn', get(vcDeal, 'aiSafeDetails.discount'));
          const defaultRoundIndex = valuationField.findIndex(
            (round: any) => round.defaultRound,
          );
          if (defaultRoundIndex > -1) {
            setValue(
              `valuationRoundDetail[${
                defaultRoundIndex > -1 ? defaultRoundIndex : 0
              }].valuation`,
              get(vcDeal, 'aiSafeDetails.valuation'),
            );
          }
          setValue('valuationCap', get(vcDeal, 'aiSafeDetails.valuation'));
          setValue(
            'investmentDate',
            get(vcDeal, 'aiSafeDetails.date_of_investment'),
          );
          setValue(
            'lastRoundValuation',
            get(vcDeal, 'aiSafeDetails.valuation'),
          );
          setCurrentStep((prevStep) => prevStep + 1);
        }
        // clearInterval(timer);
        setValue('lastRoundValuation', get(vcDeal, 'aiSafeDetails.valuation'));
        handleValuationChange();
        getCalculatedCurrentValuation();
        getCalculatedCurrentValue();
        getCalculatedPercentageOwned();
        getCalculatedMoic();
        setIsInvestmentDetail(true);
      }, 11 * 1000);
    } catch (error) {
      // const message: string = errorMessageHandler(err);
    }
  };
  console.log(getValues());
  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    // setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleReUpload = () => {
    setIsInvestmentDetail(false);
    setCurrentStep((prevStep) => prevStep - 1);
    // setCurrentStep((prevStep) => prevStep - 1);
  };
  const handleNext = (currentStep: number) => {
    // event.preventDefault();
    if (
      !(watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE) &&
      currentStep == 2
    ) {
      // setCurrentStep((prevStep) => prevStep + 3);
      setIsInvestmentDetail(true);
    } else {
      if (currentStep == 4) {
        handleProcess();
      } else {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }
  };

  const handleConfirm = async () => {
    const obj = {
      id: selectedDeal,
      vcFirmId: vcFirmId,
      investorId: investorId,
      investedAmount: getValues('investedAmount'),
      discountMfn: getValues('discountMfn'),
      lastRoundValuation: getValues('lastRoundValuation'),
      investmentDate: getValues('investmentDate'),
    };
    // eslint-disable-next-line no-undef
    // alert(getValues('lastRoundValuation'));
    await updateVcDealSafeDetails(obj);
    setIsConfirmed(true);
    // setCurrentStep((prevStep) => prevStep + 1);
    // setCurrentStep((prevStep) => prevStep - 1);
  };

  const DragDropFiles = ({ files, setFiles }: any) => {
    const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes

    const handleDragOver = (event: any) => {
      event.preventDefault();
    };

    const handleDrop = (event: any) => {
      event.preventDefault();
      if (files.length > 0) {
        // eslint-disable-next-line no-undef
        alert('Only one file can be uploaded at a time.');
        return;
      }
      const droppedFiles = Array.from(event.dataTransfer.files);
      const validatedFile = validateFiles(droppedFiles);
      if (validatedFile.length > 0) {
        setFiles(validatedFile); // Set only the new file
      }
    };

    const validateFiles = (selectedFiles: any) => {
      return selectedFiles.filter((file: any) => {
        if (file.type !== 'application/pdf') {
          // eslint-disable-next-line no-undef
          alert(`The file ${file.name} is not a PDF.`);
          return false;
        } else if (file.size > MAX_FILE_SIZE) {
          // eslint-disable-next-line no-undef
          alert(`The file ${file.name} exceeds the 50MB size limit.`);
          return false;
        }
        return true;
      });
    };

    const handleFileChange = (event: any) => {
      if (files.length > 0) {
        // eslint-disable-next-line no-undef
        alert('Only one file can be uploaded at a time.');
        return;
      }
      const selectedFiles = Array.from(event.target.files);
      const validatedFile = validateFiles(selectedFiles);
      if (validatedFile.length > 0) {
        setFiles(validatedFile); // Set only the new file
      }
    };

    const handleFileRemove = (index: any) => {
      setFiles(files.filter((_: any, i: any) => i !== index));
      // const obj = {
      //   id: selectedDocument.id,
      //   investorId: investorId,
      //   vcDealId: dealId,
      //   documentName: selectedDocument.documentName,
      //   type: VC_FIRM_DOCUMENT_RELATIVE_NAME.DEAL,
      // };
      // deleteVcFirmDocuments(obj);
    };

    return (
      <>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} md={12} style={{ width: '100%' }}>
            <Box className={classes.centerFlexColumn}>
              <Box
                className={classes.groupContainer}
                id="groupContainer"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <Box className={classes.uploadImgContainer}>
                  <img
                    className={classes.uploadSafeIcon}
                    loading="lazy"
                    alt={UploadSafeIcon}
                    src={UploadSafeIcon}
                  />
                </Box>
                <Box
                  className={classes.uploadTextContainer}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <span>Drag & drop files or</span>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    hidden
                    accept=".doc, .docx, .pdf"
                    ref={inputRef}
                  />
                  <button
                    className={classes.uploadInput}
                    onClick={(event) => {
                      event.preventDefault();
                      inputRef.current?.click();
                    }}
                  >
                    <span>Browse</span>
                  </button>
                </Box>
                <Box className={classes.supportedFormats}>
                  <Box className={classes.supportedFormatsText}>
                    Supported formats: PDF, Word.
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} style={{ width: '100%' }}>
            {files.length > 0 && (
              <div
                className="uploads"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  maxWidth: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <ul
                  style={{
                    maxWidth: '100%',
                    padding: 0,
                    width: '100%', // Ensure the UL takes the full width
                  }}
                >
                  {files.map((file: any, idx: any) => (
                    <li
                      key={idx}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 0,
                        width: '100%', // Ensure the LI takes the full width
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          textAlign: 'left',
                          fontSize: '12px',
                          color: '#121212',
                          fontFamily: 'Mulish',
                          maxWidth: '100%',
                          width: '100%', // Ensure the wrapper div takes the full width
                          justifyContent: 'space-between', // Ensure content is spread between
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '10px 20px',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            zIndex: 1,
                            gap: '10px',
                            maxWidth: '100%',
                            width: '100%', // Ensure the nested div takes the full width
                            border: '0.5px solid #ccc', // Add border with width 1px
                          }}
                        >
                          <div
                            style={{
                              position: 'relative',
                              letterSpacing: '0.3px',
                              fontWeight: 400,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {file.name}
                          </div>
                          <div>
                            <button
                              onClick={() => handleFileRemove(idx)}
                              style={{
                                border: 'none',
                                backgroundColor: '#E6E6E6', // Grey background color
                                cursor: 'pointer',
                                color: 'white', // Color of the cross mark
                                borderRadius: '50%', // Make the button circular
                                width: '14px', // Set width of the circle
                                height: '14px', // Set height of the circle
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '10px', // Set font size to make sure the cross mark scales appropriately
                                lineHeight: '1',
                              }}
                            >
                              &#x2715; {/* Cross mark */}
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  interface GridProps {
    files: FileType[];
  }

  const FileName = ({ files }: GridProps) => {
    return (
      <>
        <Grid container sx={{ alignItems: 'center' }}>
          <div className={classes.uploadText}>
            <span style={{ fontWeight: 800 }}>{files[0]?.name} </span>Uploaded.
            Are these info correct to your knowledge?
          </div>
          <Button
            type="button"
            name="Confirm"
            className={classes.confirmButtom}
            onClick={() => handleConfirm()}
          >
            Confirm
          </Button>
        </Grid>
        <Grid>
          <div>
            If not,
            <Button
              type="button"
              name="Re-upload"
              className={classes.reUploadButtom}
              onClick={() => handleReUpload()}
            >
              Re-upload
            </Button>
            or edit the details above
          </div>
        </Grid>
      </>
    );
  };

  const disableOptions = (selectedValues: string[]) => {
    const valuationCappedSelected =
      selectedValues?.includes('Valuation Capped');
    const uncappedSelected = selectedValues?.includes('Uncapped');
    const discountSelected = selectedValues?.includes('Discount');
    const mfnSelected = selectedValues?.includes('MFN');

    const disableDiscountAndMfn = !valuationCappedSelected && !uncappedSelected;
    const disableCappedAndUncapped = discountSelected || mfnSelected;

    return vcDealTermsOfSafeList.map((option) => {
      if (
        disableCappedAndUncapped &&
        (option.value === 'Valuation Capped' || option.value === 'Uncapped')
      ) {
        return { ...option, disabled: true };
      } else if (
        (option.value === 'Valuation Capped' && uncappedSelected) ||
        (option.value === 'Uncapped' && valuationCappedSelected)
      ) {
        return { ...option, disabled: true };
      } else if (
        (option.value === 'Discount' || option.value === 'MFN') &&
        disableDiscountAndMfn
      ) {
        return { ...option, disabled: true };
      } else {
        return { ...option, disabled: false };
      }
    });
  };

  const handleChangeOption = () => {
    handleClearInvestmentDetail([]);
    setValue('valuationRoundDetail', []);
    resetField('lastRoundValuation');
  };

  const steps = [
    <div key="step0">
      <Grid
        container
        spacing={3}
        className={`${classes.inputFundBox} ${classes.centerAligned}`}
      >
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>
            Country of Incorporation
          </WrappedTypography>
          <AutoCompleteField
            id="geography"
            control={control}
            name="geography"
            placeholder="Select Incorporation Country"
            options={businessOpsCountryList} // TODO: Need to Check for options as it is incorporation country
            fieldValue={getBusinessOpsCountryObject(
              get(selectedDealData, 'geography'),
            )}
            getOptionLabel={(option: any) => option.country || ''}
            isOptionEqualToValue={(option: any, value: any) =>
              option.countryCode === value.countryCode ||
              value === undefined ||
              value === ''
            }
            disableClearable
            disabled={disableAsPerPermission}
          />
        </Grid>
      </Grid>
    </div>,
    <div key="step1">
      <Grid
        container
        spacing={3}
        className={`${classes.inputFundBox} ${classes.centerAligned}`}
      >
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>Fundraise Round</WrappedTypography>
          <SelectField
            control={control}
            name="lastFundingRound"
            options={fundingRoundStageList}
            placeholder="Select Fundraise Round"
            disabled={disableAsPerPermission}
            onChangeOption={() => {
              if (watch('investedInstrumentType') !== TYPE_OF_INSTRUMENT.SAFE) {
                if (!valuationField.length) {
                  valuationPrepend({
                    roundName: !isEmpty(watch('lastFundingRound'))
                      ? getPricingLatestRoundObject(watch('lastFundingRound'))
                      : null,
                    date: !isEmpty(watch('investmentDate'))
                      ? watch('investmentDate')
                      : null,
                    valuation: watch('lastRoundValuation'),
                    defaultRound: true,
                    isNew: true,
                    isPriceRound: false,
                  });
                } else {
                  const defaultRoundIndex = valuationField.findIndex(
                    (round: any) => round.defaultRound,
                  );
                  if (defaultRoundIndex > -1) {
                    setValue(
                      `valuationRoundDetail[${defaultRoundIndex}].valuation`,
                      watch('lastRoundValuation'),
                    );

                    setValue(
                      `valuationRoundDetail[${defaultRoundIndex}].roundName`,
                      getPricingLatestRoundObject(watch('lastFundingRound')),
                    );
                  } else {
                    valuationPrepend({
                      roundName: !isEmpty(watch('lastFundingRound'))
                        ? getPricingLatestRoundObject(watch('lastFundingRound'))
                        : null,
                      date: !isEmpty(watch('investmentDate'))
                        ? watch('investmentDate')
                        : null,
                      valuation: watch('lastRoundValuation'),
                      defaultRound: true,
                      isNew: true,
                      isPriceRound: false,
                    });
                  }
                }
              }
              getCurrentValuationText();
            }}
          />
        </Grid>
      </Grid>
    </div>,
    <div key="step2">
      <Grid
        container
        spacing={3}
        className={`${classes.inputFundBox} ${classes.centerAligned}`}
      >
        <Grid item xs={12} md={6}>
          <WrappedTypography type={'body2'}>
            Type of instrument
          </WrappedTypography>
          <SelectField
            control={control}
            name="investedInstrumentType"
            options={typeOfInstrumentList}
            placeholder="Select Instrument Type"
            onChangeOption={(value: any) => {
              if (
                !isEmpty(get(selectedDealData, 'investedInstrumentType')) &&
                watch('investedInstrumentType') !==
                  get(selectedDealData, 'investedInstrumentType')
              ) {
                setOpenConfirmationDialog(true);
              } else if (
                isEmpty(get(selectedDealData, 'investedInstrumentType'))
              ) {
                if (
                  watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE
                ) {
                  setIsInvestmentDetail(false);
                  setIsConfirmed(false);
                  setCurrentStep(3);
                  // setIsInvestmentDetail(false);
                  setOpen(true);
                } else {
                  setIsInvestmentDetail(true);
                  setIsConfirmed(true);
                }

                handleClearInvestmentDetail(['termsOfSafe']);
                setValue('valuationRoundDetail', []);
                resetField('lastRoundValuation'); // TODO check if we can remove this
                setOpen(true);
              }
              if (
                ![
                  TYPE_OF_INSTRUMENT.SAFE,
                  TYPE_OF_INSTRUMENT.EQUITY,
                  TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
                ].includes(value) &&
                isEmpty(selectedDealData)
              ) {
                setValue(
                  'percentageOwned',
                  get(selectedDealData, 'percentageOwned'),
                );
              } else {
                getCalculatedPercentageOwned();
                getCalculatedMoic();
              }
            }}
            disabled={type === 'REALIZED_DEAL' || disableAsPerPermission}
          />
        </Grid>
      </Grid>
    </div>,
    <div key="step3">
      {watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE && (
        <Grid
          container
          spacing={3}
          className={`${classes.inputFundBox} ${classes.centerAligned}`}
        >
          <Grid item xs={12} md={6}>
            <WrappedTypography type={'body2'}>Terms of SAFE</WrappedTypography>
            <MultipleSelectField
              control={control}
              onOpen={() => setOpen(true)}
              open={open}
              onClose={() => setOpen(false)}
              name="termsOfSafe"
              options={disableOptions(watch('termsOfSafe'))}
              placeholder="Select Terms of SAFE"
              onChangeOption={handleChangeOption}
              disabled={disableAsPerPermission}
            />
          </Grid>
        </Grid>
      )}
      {watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
        !isEmpty(watch('termsOfSafe')) &&
        !isEmpty(get(safeFields, 'error')) && (
          <Box className={classes.inputErrorBox}>
            <span className={classes.ribbon}>SAFE</span>
            <WrappedTypography type={'body2'}>
              {get(safeFields, 'error')}
            </WrappedTypography>
          </Box>
        )}
    </div>,
    <div key="step4">
      {isProcessing ? (
        <Box className={classes.circularBarRoot}>
          <Loader className={classes.circularProgress} />
          <Loader className={classes.circularInverseProgress} />
        </Box>
      ) : (
        <DragDropFiles
          files={files}
          setFiles={setFiles}
          selectedDocument={selectedDocument}
        />
      )}
    </div>,
  ];
  // currentStep = 3;
  const calculateProgress = (step: number): number => {
    return (step + 1) * (100 / 6); // 5 because steps are from 1 to 6 inclusive
  };
  // isInvestmentDetail = false;
  // currentStep = 5;
  return (
    <>
      {!isConfirmed && (
        <Grid
          container
          alignItems="center"
          spacing={2}
          className={classes.progressBarContainer}
        >
          <Grid item>
            <Typography>{currentStep + 1}/6</Typography>
          </Grid>
          <Grid item xs>
            <LinearProgress
              variant="determinate"
              value={calculateProgress(currentStep)}
              className={classes.progressBar}
            />
          </Grid>
        </Grid>
      )}
      {isInvestmentDetail ? (
        <>
          <Grid container spacing={3} className={classes.inputFundBox}>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Country of Incorporation
              </WrappedTypography>
              <AutoCompleteField
                id="geography"
                control={control}
                name="geography"
                placeholder="Select Incorporation Country"
                options={businessOpsCountryList} // TODO: Need to Check for options as it is incorporation country
                fieldValue={getBusinessOpsCountryObject(
                  get(selectedDealData, 'geography'),
                )}
                getOptionLabel={(option: any) => option.country || ''}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.countryCode === value.countryCode ||
                  value === undefined ||
                  value === ''
                }
                disableClearable
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Fundraise Round
              </WrappedTypography>
              <SelectField
                control={control}
                name="lastFundingRound"
                options={fundingRoundStageList}
                placeholder="Select Fundraise Round"
                onChangeOption={() => {
                  if (
                    watch('investedInstrumentType') !== TYPE_OF_INSTRUMENT.SAFE
                  ) {
                    if (!valuationField.length) {
                      valuationPrepend({
                        roundName: !isEmpty(watch('lastFundingRound'))
                          ? getPricingLatestRoundObject(
                              watch('lastFundingRound'),
                            )
                          : null,
                        date: !isEmpty(watch('investmentDate'))
                          ? watch('investmentDate')
                          : null,
                        valuation: watch('lastRoundValuation'),
                        defaultRound: true,
                        isNew: true,
                        isPriceRound: false,
                      });
                    } else {
                      const defaultRoundIndex = valuationField.findIndex(
                        (round: any) => round.defaultRound,
                      );
                      if (defaultRoundIndex > -1) {
                        setValue(
                          `valuationRoundDetail[${defaultRoundIndex}].valuation`,
                          watch('lastRoundValuation'),
                        );

                        setValue(
                          `valuationRoundDetail[${defaultRoundIndex}].roundName`,
                          getPricingLatestRoundObject(
                            watch('lastFundingRound'),
                          ),
                        );
                      } else {
                        valuationPrepend({
                          roundName: !isEmpty(watch('lastFundingRound'))
                            ? getPricingLatestRoundObject(
                                watch('lastFundingRound'),
                              )
                            : null,
                          date: !isEmpty(watch('investmentDate'))
                            ? watch('investmentDate')
                            : null,
                          valuation: watch('lastRoundValuation'),
                          defaultRound: true,
                          isNew: true,
                          isPriceRound: false,
                        });
                      }
                    }
                  }
                  getCurrentValuationText();
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <WrappedTypography type={'body2'}>
                Type of instrument
              </WrappedTypography>
              <SelectField
                control={control}
                name="investedInstrumentType"
                options={typeOfInstrumentList}
                placeholder="Select Instrument Type"
                onChangeOption={(value: any) => {
                  if (!isConfirmed) {
                    setValue('investmentDate', null);
                    setValue('investedAmount', null);
                    setValue('valuationRoundDetail', []);
                    setValue('lastRoundValuation', null);
                  }
                  if (
                    !isEmpty(get(selectedDealData, 'investedInstrumentType')) &&
                    watch('investedInstrumentType') !==
                      get(selectedDealData, 'investedInstrumentType')
                  ) {
                    setOpenConfirmationDialog(true);
                  } else if (
                    isEmpty(get(selectedDealData, 'investedInstrumentType'))
                  ) {
                    if (
                      watch('investedInstrumentType') ===
                      TYPE_OF_INSTRUMENT.SAFE
                    ) {
                      setIsInvestmentDetail(false);
                      setCurrentStep(3);
                      // setIsInvestmentDetail(false);
                      setOpen(true);
                    }
                    handleClearInvestmentDetail(['termsOfSafe']);
                    setValue('valuationRoundDetail', []);
                    resetField('lastRoundValuation'); // TODO check if we can remove this
                    setOpen(true);
                  }
                  if (
                    ![
                      TYPE_OF_INSTRUMENT.SAFE,
                      TYPE_OF_INSTRUMENT.EQUITY,
                      TYPE_OF_INSTRUMENT.CONVERTIBLE_NOTES,
                    ].includes(value) &&
                    isEmpty(selectedDealData)
                  ) {
                    setValue(
                      'percentageOwned',
                      get(selectedDealData, 'percentageOwned'),
                    );
                  } else {
                    getCalculatedPercentageOwned();
                    getCalculatedMoic();
                  }
                }}
                disabled={type === 'REALIZED_DEAL'}
              />
            </Grid>
            {watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE && (
              <Grid item xs={12} md={6}>
                <WrappedTypography type={'body2'}>
                  Terms of SAFE
                </WrappedTypography>
                <MultipleSelectField
                  control={control}
                  onOpen={() => setOpen(true)}
                  open={open}
                  onClose={() => setOpen(false)}
                  name="termsOfSafe"
                  options={disableOptions(watch('termsOfSafe'))}
                  placeholder="Select Terms of SAFE"
                  onChangeOption={handleChangeOption}
                />
              </Grid>
            )}
            {watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
              !isEmpty(watch('termsOfSafe')) &&
              !isEmpty(get(safeFields, 'error')) && (
                <Box className={classes.inputErrorBox}>
                  <span className={classes.ribbon}>SAFE</span>
                  <WrappedTypography type={'body2'}>
                    {get(safeFields, 'error')}
                  </WrappedTypography>
                </Box>
              )}
          </Grid>
          <Grid container spacing={3} className={classes.inputFundBox}>
            <Grid item xs={12} md={6} className={classes.inputPriceBox}>
              <WrappedTypography type={'body2'}>
                <span style={!isConfirmed ? { fontWeight: 800 } : {}}>
                  Time Of Investment
                </span>
              </WrappedTypography>
              <InputTextField
                placeholder="Enter Time of Investment"
                control={control}
                name="investmentDate"
                type={'date'}
                InputProps={{
                  inputProps: {
                    max: moment().format('YYYY-MM-DD'),
                  },
                }}
                onInputChange={(data: any) => {
                  const defaultRoundIndex = valuationField.findIndex(
                    (round: any) => round.defaultRound,
                  );
                  if (defaultRoundIndex > -1) {
                    setValue(
                      `valuationRoundDetail[${defaultRoundIndex}].date`,
                      get(data, 'target.value'),
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.inputPriceBox}>
              <WrappedTypography type={'body2'}>
                <span style={!isConfirmed ? { fontWeight: 800 } : {}}>
                  Amount Invested ({vcFirmCurrency} {vcFirmCurrencySymbol})
                </span>
              </WrappedTypography>
              <InputPriceField
                placeholder="Enter Invested Amount"
                control={control}
                name="investedAmount"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                  decimalScale: 0,
                }}
                onInputChange={() => {
                  getCalculatedCurrentValue();
                  getCalculatedPercentageOwned();
                  getCalculatedMoic();
                }}
                disabled={type === 'REALIZED_DEAL' || dealPartiallyExited}
              />
              {dealPartiallyExited && (
                <span className={classes.amountInvestedText}>
                  Invested:{' '}
                  {formatAmount(get(selectedDealData, 'investedAmount'), {
                    currency: vcFirmCurrency,
                  })}
                </span>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.inputFundBox}>
            <Grid
              item
              xs={12}
              md={6}
              className={`${classes.inputPriceEntryValBox} ${
                watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
                [null, undefined, ''].includes(watch('lastRoundValuation')) &&
                !get(safeFields, 'isMFNTooltip') &&
                classes.placeholderText
              }`}
            >
              <WrappedTypography type={'body2'}>
                <span style={!isConfirmed ? { fontWeight: 800 } : {}}>
                  Entry Valuation ({vcFirmCurrency} {vcFirmCurrencySymbol})
                </span>
              </WrappedTypography>
              <InputPriceField
                placeholder={
                  watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
                  get(safeFields, 'entryValPlaceholderText')
                    ? get(safeFields, 'entryValPlaceholderText')
                    : 'Enter Entry Valuation'
                }
                control={control}
                name="lastRoundValuation"
                startAdornment={vcFirmCurrencySymbol}
                priceInputProps={{
                  thousandSeparator: true,
                  decimalScale: 0,
                }}
                onInputChange={handleValuationChange}
                // disabled={
                //   watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE
                // }
                onClick={() => {
                  if (!isConfirmed) {
                    return;
                  }
                  // if (get(safeFields, 'isMFNTooltip')) {
                  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //   // @ts-ignore
                  //   document.getElementById(
                  //     'scroll-wrap-sidepane',
                  //   ).scrollTop += 200;
                  //   if (['', null, undefined].includes(watch('mfnValuation'))) {
                  //     setShowMFNTooltip('show');
                  //   }
                  //   return;
                  // }
                  // if (get(safeFields, 'isValuationCapTooltip')) {
                  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //   // @ts-ignore
                  //   document.getElementById(
                  //     'scroll-wrap-sidepane',
                  //   ).scrollTop += 200;
                  //   if (['', null, undefined].includes(watch('valuationCap'))) {
                  //     setShowValuationCapTooltip('show');
                  //   }
                  //   return;
                  // }
                  setShowMFNTooltip('');
                  setShowValuationCapTooltip('');
                }}
              />
            </Grid>
            {!isEmpty(safeFields) &&
              safeFields?.fields.includes('discountMfn') &&
              watch('investedInstrumentType') == TYPE_OF_INSTRUMENT.SAFE && (
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>Discount</WrappedTypography>
                  <InputPriceField
                    placeholder={`Enter when available`}
                    control={control}
                    name="discountMfn"
                    startAdornment={''}
                    endAdornment={'%'}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    onInputChange={() => {
                      getCalculatedEntryValuation();
                    }}
                    disabled={type === 'REALIZED_DEAL'}
                  />
                </Grid>
              )}
          </Grid>
          <Grid>
            <Accordion
              subheading="Latest Valuation"
              expanded={expanded.includes('Latest Valuation')}
              handleChange={(e: string) => handleAccordianChange(e)}
              index="Latest Valuation"
              className={classes.accordBoxLatestVal}
              backgroundStyle={{
                borderRadius: `8px`,
              }}
            >
              <LatestValuationAccord
                type={type}
                vcFirmCurrency={vcFirmCurrency}
                vcFirmCurrencySymbol={vcFirmCurrencySymbol}
                dealPartiallyExited={dealPartiallyExited}
                selectedDealData={selectedDealData}
                control={control}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                resetField={resetField}
                getCalculatedCurrentValuation={getCalculatedCurrentValuation}
                getCalculatedCurrentValue={getCalculatedCurrentValue}
                getCalculatedPercentageOwned={getCalculatedPercentageOwned}
                getCalculatedMoic={getCalculatedMoic}
                getCalculatedRealisedMoic={getCalculatedRealisedMoic}
                safeFields={safeFields}
                setSafeFields={setSafeFields}
                valuationField={valuationField}
                valuationPrepend={valuationPrepend}
                valuationRemove={valuationRemove}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                setIsMFNFreezeChecked={setIsMFNFreezeChecked}
                isMFNFreezeChecked={isMFNFreezeChecked}
              />
            </Accordion>
          </Grid>
          {!isConfirmed && (
            <Grid style={{ marginBottom: 30 }}>
              <FileName files={files} />
            </Grid>
          )}
          {watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
            isPriceRoundCheckboxChecked() && (
              <Grid container spacing={3} className={classes.inputFundBox}>
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>
                    No. of Shares Alloted
                  </WrappedTypography>
                  <InputPriceField
                    placeholder="Enter Realised Amount"
                    control={control}
                    name="NoOfSharesAlloted"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    onInputChange={() => {
                      calculateCurrentOwnedMoic();
                    }}
                    disabled={type === 'REALIZED_DEAL' && dealPartiallyExited}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>
                    Total no of shares
                  </WrappedTypography>
                  <InputPriceField
                    placeholder="Enter Realised Amount"
                    control={control}
                    name="totalNoOfShares"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    onInputChange={() => calculateCurrentOwnedMoic()}
                    disabled={type === 'REALIZED_DEAL' && dealPartiallyExited}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>
                    Price per share
                  </WrappedTypography>
                  <InputPriceField
                    placeholder="Enter Realised Amount"
                    control={control}
                    name="pricePerShare"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    onInputChange={() => calculateCurrentOwnedMoic()}
                    disabled={type === 'REALIZED_DEAL' && dealPartiallyExited}
                  />
                </Grid>
              </Grid>
            )}
          {
            <Grid container spacing={3} className={classes.inputFundBox}>
              {selectedDealData.dealStatus !== DEAL_STATUS_TYPE.EXITED &&
              type !== 'REALIZED_DEAL' ? (
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={`${classes.inputPriceBox} ${
                    watch('investedInstrumentType') ===
                      TYPE_OF_INSTRUMENT.SAFE &&
                    [null, undefined, ''].includes(watch('currentValue')) &&
                    classes.placeholderText
                  }`}
                >
                  <WrappedTypography type={'body2'}>
                    Current Value ({vcFirmCurrency} {vcFirmCurrencySymbol})
                  </WrappedTypography>
                  <InputPriceField
                    placeholder={
                      watch('investedInstrumentType') ===
                        TYPE_OF_INSTRUMENT.SAFE &&
                      get(safeFields, 'placeholderText')
                        ? get(safeFields, 'placeholderText')
                        : watch('investedInstrumentType') ===
                          TYPE_OF_INSTRUMENT.EQUITY
                        ? 'Auto Calculated'
                        : 'Enter Current Value'
                    }
                    control={control}
                    name="currentValue"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 2,
                    }}
                    onInputChange={() => getCalculatedMoic()}
                    disabled={
                      watch('investedInstrumentType') ===
                      TYPE_OF_INSTRUMENT.SAFE
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={6} className={classes.inputPriceBox}>
                  <WrappedTypography type={'body2'}>
                    Realised Amount ({vcFirmCurrency} {vcFirmCurrencySymbol})
                  </WrappedTypography>
                  <InputPriceField
                    placeholder="Enter Realised Amount"
                    control={control}
                    name="exitValuation"
                    startAdornment={vcFirmCurrencySymbol}
                    priceInputProps={{
                      thousandSeparator: true,
                      decimalScale: 0,
                    }}
                    onInputChange={() => getCalculatedRealisedMoic()}
                    disabled={type === 'REALIZED_DEAL' && dealPartiallyExited}
                  />
                </Grid>
              )}
              {selectedDealData.dealStatus !== DEAL_STATUS_TYPE.EXITED &&
                type !== 'REALIZED_DEAL' && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className={`${classes.inputPriceBox} ${
                      watch('investedInstrumentType') ===
                        TYPE_OF_INSTRUMENT.SAFE &&
                      [null, undefined, ''].includes(
                        watch('percentageOwned'),
                      ) &&
                      classes.placeholderText
                    }`}
                  >
                    <ClickAwayListener
                      onClickAway={() =>
                        setShowPercentageOwnedTooltip('showed')
                      }
                    >
                      <CustomizedTooltip
                        title={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ margin: 0 }}>Update % Ownership</p>
                            <Close
                              className={classes.tooltipCrossIcon}
                              onClick={() =>
                                setShowPercentageOwnedTooltip('showed')
                              }
                            />
                          </Box>
                        }
                        className={classes.priceRoundTooltip}
                        placement="right"
                        arrow={true}
                        open={showPercentageOwnedTooltip === 'show'}
                        onClose={() => setShowPercentageOwnedTooltip('showed')}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                      >
                        <span>% Owned (Post Money)</span>
                      </CustomizedTooltip>
                    </ClickAwayListener>
                    <InputPriceField
                      placeholder={
                        watch('investedInstrumentType') ===
                          TYPE_OF_INSTRUMENT.SAFE &&
                        get(safeFields, 'placeholderText')
                          ? get(safeFields, 'placeholderText')
                          : watch('investedInstrumentType') ===
                            TYPE_OF_INSTRUMENT.EQUITY
                          ? 'Auto Calculated'
                          : 'Enter Owned (%)'
                      }
                      control={control}
                      name="percentageOwned"
                      endAdornment={'%'}
                      max={100}
                      disabled={
                        watch('investedInstrumentType') ===
                        TYPE_OF_INSTRUMENT.SAFE
                          ? isEmpty(watch('priceRoundValuation'))
                          : size(watch('valuationRoundDetail')) <= 1
                      }
                      onInputChange={() => {
                        setShowPercentageOwnedTooltip('showed');
                      }}
                    />
                  </Grid>
                )}
              <Grid
                item
                xs={12}
                md={6}
                className={`${classes.inputPriceBox} ${
                  watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
                  [null, undefined, ''].includes(watch('moic')) &&
                  classes.placeholderText
                }`}
              >
                <WrappedTypography type={'body2'}>MOIC (X)</WrappedTypography>
                <InputPriceField
                  placeholder={
                    watch('investedInstrumentType') ===
                      TYPE_OF_INSTRUMENT.SAFE &&
                    get(safeFields, 'placeholderText')
                      ? get(safeFields, 'placeholderText')
                      : watch('investedInstrumentType') ===
                        TYPE_OF_INSTRUMENT.EQUITY
                      ? 'Auto Calculated'
                      : 'Enter MOIC'
                  }
                  control={control}
                  name="moic"
                  priceInputProps={{
                    thousandSeparator: true,
                  }}
                  disabled={
                    watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE
                  }
                />
              </Grid>
            </Grid>
          }

          {watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE &&
            !isEmpty(watch('termsOfSafe')) &&
            !isEmpty(get(safeFields, 'error')) && (
              <Box className={classes.inputErrorBox}>
                <span className={classes.ribbon}>SAFE</span>
                <WrappedTypography type={'body2'}>
                  {get(safeFields, 'error')}
                </WrappedTypography>
              </Box>
            )}
          {confirmationDialog && (
            <ConfirmationDialog
              open={confirmationDialog}
              handleClose={() => {
                setValue(
                  'investedInstrumentType',
                  get(selectedDealData, 'investedInstrumentType'),
                );
                setOpenConfirmationDialog(false);
              }}
              title=""
              content="Are you sure you want to change Type of Instrument?"
              onOk={() => {
                handleClearInvestmentDetail(['termsOfSafe']);
                setValue('valuationRoundDetail', []);
                if (
                  watch('investedInstrumentType') === TYPE_OF_INSTRUMENT.SAFE
                ) {
                  setIsInvestmentDetail(false);
                  setIsConfirmed(false);
                  setCurrentStep(3);
                  // setIsInvestmentDetail(false);
                  setOpen(true);
                } else {
                  setIsInvestmentDetail(true);
                }
              }}
              okButton="Yes"
              canceButton="Cancel"
            />
          )}
        </>
      ) : (
        <>
          {steps[currentStep]}

          <Grid container className={classes.buttonContainer}>
            {currentStep >= 1 && currentStep < 5 && !isProcessing && (
              <Button
                type="button"
                name="Back"
                className={classes.backButton}
                onClick={() => handleBack()}
              >
                Back
              </Button>
            )}
            {currentStep >= 0 && currentStep < 5 && !isProcessing && (
              <Button
                type="button"
                name="Next"
                className={classes.button}
                onClick={() => handleNext(currentStep)}
                disabled={
                  currentStep === 0
                    ? !getValues('geography')
                    : currentStep === 1
                    ? !getValues('lastFundingRound')
                    : currentStep === 2
                    ? !getValues('investedInstrumentType')
                    : currentStep === 3
                    ? !getValues('termsOfSafe')?.length
                    : currentStep === 4
                    ? !(files || [])?.length
                    : currentStep === 5
                    ? !getValues('investedInstrumentType')
                    : false
                }
              >
                Next
              </Button>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default InvestmentTab;
