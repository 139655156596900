export const GET_USER_PAY_CARDS = 'global/GET_USER_PAY_CARDS';
export const GET_USER_PAY_CARDS_LOADING = 'global/GET_USER_PAY_CARDS_LOADING';
export const GET_USER_PAY_CARDS_FAILED = 'global/GET_USER_PAY_CARDS_FAILED';
export const GET_USER_PAY_CARDS_SUCCESS = 'global/GET_USER_PAY_CARDS_SUCCESS';

export const GET_USER_CREDIT_BALANCE = 'global/GET_USER_CREDIT_BALANCE';
export const GET_USER_CREDIT_BALANCE_LOADING =
  'global/GET_USER_CREDIT_BALANCE_LOADING';
export const GET_USER_CREDIT_BALANCE_FAILED =
  'global/GET_USER_CREDIT_BALANCE_FAILED';
export const GET_USER_CREDIT_BALANCE_SUCCESS =
  'global/GET_USER_CREDIT_BALANCE_SUCCESS';

export const CURRENT_MONTH_AI_USAGE = 'global/CURRENT_MONTH_AI_USAGE';
export const CURRENT_MONTH_AI_USAGE_LOADING =
  'global/CURRENT_MONTH_AI_USAGE_LOADING';
export const CURRENT_MONTH_AI_USAGE_FAILED =
  'global/CURRENT_MONTH_AI_USAGE_FAILED';
export const CURRENT_MONTH_AI_USAGE_SUCCESS =
  'global/CURRENT_MONTH_AI_USAGE_SUCCESS';

export const OUTSTANDING_BALANCE = 'global/OUTSTANDING_BALANCE';
export const OUTSTANDING_BALANCE_LOADING = 'global/OUTSTANDING_BALANCE_LOADING';
export const OUTSTANDING_BALANCE_FAILED = 'global/OUTSTANDING_BALANCE_FAILED';
export const OUTSTANDING_BALANCE_SUCCESS = 'global/OUTSTANDING_BALANCE_SUCCESS';

export const GET_DEAL_PIPELINE = 'global/GET_DEAL_PIPELINE';
export const GET_DEAL_PIPELINE_LOADING = 'global/GET_DEAL_PIPELINE_LOADING';
export const GET_DEAL_PIPELINE_FAILED = 'global/GET_DEAL_PIPELINE_FAILED';
export const GET_DEAL_PIPELINE_SUCCESS = 'global/GET_DEAL_PIPELINE_SUCCESS';

export const INIT_PUSHER_REQUEST = 'global/INIT_PUSHER_REQUEST';
export const INIT_PUSHER_SUCCESS = 'global/INIT_PUSHER_SUCCESS';
export const INIT_PUSHER_FAILED = 'global/INIT_PUSHER_FAILED';
export const GET_ALL_YC_BATCH = 'global/GET_ALL_YC_BATCH';
export const GET_ALL_YC_BATCH_LOADING = 'vcFirm/GET_ALL_YC_BATCH_LOADING';
export const GET_ALL_YC_BATCH_SUCCESS = 'vcFirm/GET_ALL_YC_BATCH_SUCCESS';
export const GET_ALL_YC_BATCH_FAILURE = 'vcFirm/GET_ALL_YC_BATCH_FAILURE';

export const PLAN_EXPIRED_DIALOG = 'vcFirm/PLAN_EXPIRED_DIALOG';

export const GET_INVITED_WORKSPACES = 'global/GET_INVITED_WORKSPACES';
export const GET_INVITED_WORKSPACES_LOADING =
  'global/GET_INVITED_WORKSPACES_LOADING';
export const GET_INVITED_WORKSPACES_SUCCESS =
  'global/GET_INVITED_WORKSPACES_SUCCESS';
export const GET_INVITED_WORKSPACES_FAILURE =
  'global/GET_INVITED_WORKSPACES_FAILURE';

export const fetchPaymentCards = () => {
  return {
    type: GET_USER_PAY_CARDS,
  };
};

export const fetchCreditBalance = () => {
  return {
    type: GET_USER_CREDIT_BALANCE,
  };
};

export const fetchCurrentMonthAiUsage = () => {
  return {
    type: CURRENT_MONTH_AI_USAGE,
  };
};

export const fetchOutstandingBalance = () => {
  return {
    type: OUTSTANDING_BALANCE,
  };
};

export const fetchDealPipelineToStartSubscription = () => {
  return {
    type: GET_DEAL_PIPELINE,
  };
};

export const initializePusher = (investorId: string, vcFirmId: string) => {
  return {
    type: INIT_PUSHER_REQUEST,
    payload: { investorId, vcFirmId },
  };
};

export const getAllYCBatches = () => {
  return {
    type: GET_ALL_YC_BATCH,
  };
};

export const planExpiredDialog = (payload: boolean) => {
  return {
    type: PLAN_EXPIRED_DIALOG,
    payload,
  };
};
export const fetchInvitedWorkspaces = () => {
  return {
    type: GET_INVITED_WORKSPACES,
  };
};
