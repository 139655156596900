import React, { ReactNode } from 'react';
import Accordion from '@mui/material/Accordion';
import moment from 'moment';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import { every, get, isEmpty, some } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  InputBase,
  Checkbox,
  ListItemText,
  Skeleton,
} from '@mui/material';

import {
  UploadOrangeIcon,
  ThreeDotVerticalOrangeIcon,
  DeleteIcon,
  CompanySnapshotButton,
  SearchIcon,
  CompanySnapshotButtonLock,
  AddTagIcon,
  ArrowUpIcon,
  RefreshWhiteIcon,
  RefreshGreenIcon,
  DollarSignWhiteIcon,
  BatteryLowIcon,
  LogoutRedIcon,
  GreenFlagIcon,
  GrowthBarIcon,
  PivotIcon,
  ArrowDownIcon,
} from 'assets';
import { Box, Button, CustomizedTooltip, SnackBar } from 'components';
import {
  getVCFirmSubscription,
  updateVCFirm,
} from 'redux-modules/VCFirm/Actions';
import { FN } from 'common/types';
import { RootState } from 'redux-modules/Store/RootState';
import {
  recordInvestmentUpdateServiceUsage,
  refreshInvestmentUpdateJob,
  updateDashboardSettings,
  updateVcDealInfo,
} from 'services';
import {
  // checkVcInsightsReportUsageLimit,
  errorMessageHandler,
} from 'common/utils/helpers';
import { REMIND_STARTUP } from 'common/utils/constants';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import CompanyReportQuaterlySteps from './CompanyReportQuaterlySteps';
import {
  InvestmentUpdateAccordionItem,
  InvestmentUpdateProcessingAccordionItem,
} from './InvestmentUpdateAccordionItem';
import MergeDialog from './MergeDialog';
import PreviewEmailDialog from './PreviewEmailDialog';
import AutoDetectMergeDialog from './AutoDetectMergeDialog';

type ComponentProps = {
  subheading: string;
  handleChange: any;
  company: any;
  expanded?: boolean;
  index: string;
  buttons?: ReactNode;
  style?: any;
  onClickIcon?: any;
  handleAddToActive: any;
  onClickSubHeading?: any;
  sx?: any;
  companyList?: any;
  className?: any;
  tooltip?: string;
  processingStatus?: boolean;
  newReports?: any;
  investUpdateIndexValue?: any;
  firstEmptyInvDeal?: any;
  setModalCompany: React.Dispatch<React.SetStateAction<any>>;
  setModal: React.Dispatch<React.SetStateAction<any>>;
  hasPendingUpdateRef?: boolean;
  setOpenUploadDialog: React.Dispatch<React.SetStateAction<FN>>;
  setIsOpenDeleteModal: React.Dispatch<React.SetStateAction<any>>;
  setDeleteUpdate: React.Dispatch<React.SetStateAction<any>>;
  setIsCompanyDelete: React.Dispatch<React.SetStateAction<any>>;
  setChargeDialog: any;
  setInvestmentUpdateId: any;
  setSnapShotIndexForCharge: any;
  setIsJobDelete: React.Dispatch<React.SetStateAction<boolean>>;
  jobs: any;
  watch: any;
  control: any;
  setValue: any;
  setSnapShotIndex: any;
  handleNameEdit: any;
  setIsEditMode: any;
  isEditMode: any;
  isEditLoading: any;
  setIsEditLoading: any;
  handleEditSave: any;
  onUpdateInvestmentUpdate: any;
  processingStatusChange: any;
  setProcessingStatusChange: any;
  onMergeInvestmentUpdate?: any;
  steps: any;
  step: any;
  aiMonitoring: boolean;
  setIsCompanySnapshotClicked: any;
  hardReset: boolean;
  getAllVcDealInvestmentUpdate: any;
  infinityScrollRef?: any;
};

const InvestmentUpdateAccordion = ({
  subheading,
  company,
  handleAddToActive,
  setModalCompany,
  setIsOpenDeleteModal,
  setDeleteUpdate,
  setIsCompanyDelete,
  setChargeDialog,
  setInvestmentUpdateId,
  setSnapShotIndexForCharge,
  processingStatusChange,
  setProcessingStatusChange,
  setIsJobDelete,
  setModal,
  //children,
  expanded = false,
  index,
  style,
  handleChange,
  onClickSubHeading,
  sx,
  className,
  investUpdateIndexValue,
  firstEmptyInvDeal,
  processingStatus,
  hasPendingUpdateRef,
  setOpenUploadDialog,
  jobs,
  watch,
  control,
  setValue,
  setSnapShotIndex,
  handleNameEdit,
  setIsEditMode,
  isEditMode,
  isEditLoading,
  setIsEditLoading,
  handleEditSave,
  onUpdateInvestmentUpdate,
  onMergeInvestmentUpdate,
  steps,
  step,
  aiMonitoring,
  companyList,
  setIsCompanySnapshotClicked,
  hardReset,
  getAllVcDealInvestmentUpdate,
  infinityScrollRef = null,
}: ComponentProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    user: { email, investorId },
    user,
  } = useSelector(({ Auth }: RootState) => Auth);
  // const { aiAnalaystUsage } = useSelector(({ Global }: RootState) => Global);
  const { vcFirm, workspace } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { valid, joiningPlan } = subscription;
  const [previewEmailOpen, setPreviewEmailOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('info');
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [reTryLoading, setReTryLoading] = React.useState(false);
  const [retryNudge, setRetryNudge] = React.useState(true);
  const [companySnapNudge, setCompanySnapNudge] = React.useState(false);
  const [emailSubject, setEmailSubject] = React.useState<string>(
    REMIND_STARTUP.initEmailSubject.replace(
      '##firmName##',
      get(vcFirm, 'name'),
    ),
  );
  const [emailBody, setEmailBody] = React.useState<string>(
    REMIND_STARTUP.initEmail
      .replace('##companyName##', get(company, 'companyName'))
      .replace('##firmName##', get(vcFirm, 'name'))
      .replace('##emailReader##', get(vcFirm, 'investmentUpdateReader')),
  );
  const [anchorEl, setAnchorEl] = React.useState<null>(null);
  const [searchStringText, setSearchStringText] = React.useState<string>('');
  const [mergeDialog, setMergeDialog] = React.useState(false);
  const [mergeUpdatesDialog, setMergeUpdatesDialog] =
    React.useState<boolean>(false);
  const [emailIndex] = React.useState(
    company?.additionalFields?.findIndex(
      (field: any) =>
        field.lastRemindStartupEmail && field.lastRemindStartupEmail.sentAt,
    ),
  );
  const [destinationMergeCompany, setDestinationMergeCompany] =
    React.useState<any>({});
  const [rotation, setRotation] = React.useState(0);
  const [rotationInterval, setRotationInterval] = React.useState<any>(null);
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [contextMenuTag, setContextMenuTag] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [chipData, setChipData] = React.useState<any>(
    get(company, 'tags') ? get(company, 'tags').split(',') : [],
  );
  const scrollRef = React.useRef<any>(null);
  const open = Boolean(anchorEl);
  const reTryJobs = jobs.filter((obj: any) => {
    const s3Key = get(obj, 'errorResponse.s3Key', false);
    const bucket = get(obj, 'errorResponse.bucket', false);
    const moduleValue = get(obj, 'module', false);
    const status = get(obj, 'status', false);

    return (
      s3Key &&
      bucket &&
      moduleValue === 'INVESTMENT_UPDATE' &&
      status !== 'COMPLETED'
    );
  });

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const disableAsPerPermission = permissionInfo.PORTFOLIO === 'view';

  const startRotation = () => {
    const interval = setInterval(() => {
      setRotation((prevRotation) => prevRotation - 1);
    }, 0);

    setRotationInterval(interval);
  };

  const stopRotation = () => {
    clearInterval(rotationInterval);
    setRotationInterval(null);
  };

  const handleTooltipClose = () => {
    handleInvestmentUpdateNudgeSeen(email, 'upload');
  };

  const handleCustomTagAddAndDelete =
    (chipToAdd: any, chipToDelete: any, add = false) =>
    () => {
      if (disableAsPerPermission) {
        return;
      }
      if (!valid) {
        dispatch(planExpiredDialog(true));
        return;
      } else if (add) {
        if (chipToAdd) {
          const obj: any = {
            id: get(company, 'id'),
            vcFirmId: get(company, 'vcFirmId'),
            investorId: get(user, 'investorId'),
            tags: [...chipData, chipToAdd].join(','),
          };
          updateVcDealInfo(obj).then(() => {
            setChipData((chips: any) => {
              return [...chips, chipToAdd];
            });
            handleSubClose();
          });
        }
      } else {
        const obj: any = {
          id: get(company, 'id'),
          vcFirmId: get(company, 'vcFirmId'),
          investorId: get(user, 'investorId'),
          tags: chipData
            .filter((chip: any, i: any) => {
              return i !== chipToDelete;
            })
            .join(','),
        };
        updateVcDealInfo(obj).then(() => {
          setChipData((chips: any) => {
            const newChips = chips.filter((chip: any, i: any) => {
              return i !== chipToDelete;
            });
            return newChips;
          });
        });
      }
    };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubClose = () => {
    setContextMenu(null);
    setContextMenuTag(null);
    setSearchStringText('');
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX,
            mouseY: event.clientY,
          }
        : null,
    );
  };

  const handleContextMenuTag = (event: React.MouseEvent) => {
    if (!valid) {
      dispatch(planExpiredDialog(true));
      return;
    }
    event.preventDefault();
    setContextMenuTag(
      contextMenu === null
        ? {
            mouseX: event.clientX,
            mouseY: event.clientY,
          }
        : null,
    );
  };

  const getFiltredCompanyList = () => {
    return companyList?.filter((company: any) => {
      const nameMatch =
        searchStringText === ''
          ? true
          : get(company, 'companyName')
              .toLowerCase()
              .includes(searchStringText.toLowerCase());
      return nameMatch;
    });
  };

  const isFreeLimitAvailable = async () => {
    // const freeLimitAvailable: any = await checkVcInsightsReportUsageLimit(
    //   get(subscription, 'subscriptionPlan'),
    //   aiAnalaystUsage,
    //   subscription,
    // );
    dispatch(getVCFirmSubscription());
    // return freeLimitAvailable;
    return true;
  };

  const handleInvestmentUpdateNudgeSeen = (email: any, category: any) => {
    const investmentUpdateNudgeSeen = get(
      vcFirm,
      'dashboardSettings.investmentUpdateNudgeSeen',
      { summary: [], upload: [] },
    );
    const obj = {
      investorId,
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        investmentUpdateNudgeSeen: {
          ...investmentUpdateNudgeSeen,
          [category]: [
            ...get(
              vcFirm,
              `dashboardSettings.investmentUpdateNudgeSeen[${category}]`,
              [],
            ),
            email,
          ],
        },
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        investmentUpdateNudgeSeen: {
          ...investmentUpdateNudgeSeen,
          [category]: [
            ...get(
              vcFirm,
              `dashboardSettings.investmentUpdateNudgeSeen[${category}]`,
              [],
            ),
            email,
          ],
        },
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
    updateDashboardSettings(obj) // eslint-disable-next-line no-console
      .then((res: any) => console.log('UPDATE_DASHBOARD_SETTING', res))
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
      });
  };

  const handleMultipleReTry = () => {
    if (
      company?.additionalFields?.findIndex((field: any) => field.changeType) >=
      0
    ) {
      setMergeUpdatesDialog(true);
      return;
    }
    setReTryLoading(true);
    startRotation();
    reTryJobs.forEach((obj: any) => {
      const s3Key = get(obj, 'errorResponse.s3Key', false);
      const bucket = get(obj, 'errorResponse.bucket', false);
      const module = get(obj, 'module', false);
      const status = get(obj, 'status', false);
      if (
        s3Key &&
        bucket &&
        module === 'INVESTMENT_UPDATE' &&
        status !== 'COMPLETED'
      ) {
        if (!(get(obj, 'status') === 'IN_PROGRESS') || !hardReset) {
          const reqObj = {
            dealId: get(obj, 'dealId'),
            id: get(obj, 'id'),
            status: get(obj, 'status'),
            vcFirmId: get(vcFirm, 'id'),
            s3Key: get(obj, 'errorResponse.s3Key'),
            bucket: get(obj, 'errorResponse.bucket'),
          };
          refreshInvestmentUpdateJob(reqObj).then(() => {
            // startRotation();
          });
        }
      }
    });
  };

  const handleLockClick = () => {
    setCompanySnapNudge(true);
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        top: '50%',
      });
    }
  };

  React.useEffect(() => {
    if (!isEmpty(get(company, 'tags')) && isEmpty(chipData))
      setChipData(get(company, 'tags').split(','));
  }, [get(company, 'tags')]);

  React.useEffect(() => {
    if (reTryJobs.some((job: any) => get(job, 'status') === 'IN_PROGRESS')) {
      setReTryLoading(true);
      startRotation();
    } else {
      stopRotation();
      setReTryLoading(false);
    }
  }, [get(company, 'jobs')]);

  React.useEffect(() => {
    return () => {
      if (rotationInterval) {
        stopRotation();
      }
    };
  }, [rotationInterval]);

  return (
    <>
      {get(company, 'isLoadingData') ? (
        <Skeleton
          variant="rounded"
          height={60}
          animation="wave"
          style={{ marginBottom: 10 }}
          ref={infinityScrollRef}
        />
      ) : (
        <Accordion
          id={investUpdateIndexValue === 0 ? `element-introduction` : ''}
          expanded={expanded}
          style={style}
          onChange={() => null}
          className={className ? className : classes.accordBox}
          sx={sx}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.summaryAccord}
          >
            <Box className={classes.summaryContainer}>
              <Box className={classes.headingBoxSub}>
                {expanded && (
                  <Box
                    className={classes.invReportContainer}
                    style={{
                      position: 'relative',
                      left: -5,
                      top: 5,
                      transition: 'all 15s ease',
                    }}
                    onClick={handleChange(index) || (() => null)}
                  >
                    <Box className={classes.invReportBoxA}>
                      <Box className={classes.invReportLabel}>
                        <img
                          src={ArrowDownIcon}
                          style={{ transform: 'rotate(180deg)' }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box
                  className={classes.accordHeadText}
                  onClick={() => {
                    if (!valid) {
                      dispatch(planExpiredDialog(true));
                      return;
                    }
                    onClickSubHeading() || (() => null);
                  }}
                >
                  {get(company, 'isLoadingData', false) ? (
                    <Skeleton
                      variant="rounded"
                      width={70}
                      // height={}
                    />
                  ) : (
                    subheading
                  )}
                </Box>
                {hasPendingUpdateRef && (
                  <Button
                    name="Add to Portfolio"
                    className={`${classes.addButton}`}
                    onClick={() => handleAddToActive(get(company, 'id'))}
                  />
                )}
                <div
                  className={classes.paragraphScroll}
                  style={
                    aiMonitoring
                      ? { position: 'relative', width: 200, height: 26, top: 8 }
                      : {
                          position: 'relative',
                          width: 400,
                          height: 27,
                          top: 8,
                          maxWidth: 400,
                        }
                  }
                >
                  <div
                    style={
                      aiMonitoring
                        ? {
                            width: 'max-content',
                            display: 'flex',
                            gap: 10,
                            position: 'absolute',
                            alignItems: 'end',
                          }
                        : {
                            width: 'max-content',
                            display: 'flex',
                            gap: 10,
                            position: 'absolute',
                            alignItems: 'end',
                          }
                    }
                  >
                    {company?.pastThreeMonthReleventTags &&
                      Object.prototype.hasOwnProperty.call(
                        company?.pastThreeMonthReleventTags,
                        'companyNameChange',
                      ) && (
                        <CustomizedTooltip
                          title={
                            get(
                              company,
                              'pastThreeMonthReleventTags.companyNameChange',
                            )
                              ? get(
                                  company,
                                  'pastThreeMonthReleventTags.companyNameChange',
                                )
                              : ''
                          }
                          placement="top"
                          className={classes.customTagTooltip}
                          arrow={true}
                        >
                          <span style={{ height: '100%' }}>
                            <Box
                              className={`${classes.tagBox}`}
                              style={{ background: 'rgb(222, 222, 222)' }}
                            >
                              <Box
                                style={{
                                  justifyContent: 'center',
                                }}
                                className={classes.tagBoxIcon}
                              >
                                <img src={RefreshGreenIcon} alt="" />
                              </Box>
                              <Box
                                style={{
                                  color: '#404852',
                                }}
                                className={classes.tagBoxText}
                              >
                                Rebranded
                              </Box>
                            </Box>
                          </span>
                        </CustomizedTooltip>
                      )}
                    {company?.pastThreeMonthReleventTags &&
                      Object.prototype.hasOwnProperty.call(
                        company?.pastThreeMonthReleventTags,
                        'fundraising',
                      ) && (
                        <CustomizedTooltip
                          title={
                            get(
                              company,
                              'pastThreeMonthReleventTags.fundraising',
                            )
                              ? get(
                                  company,
                                  'pastThreeMonthReleventTags.fundraising',
                                )
                              : ''
                          }
                          placement="top"
                          className={classes.customTagTooltip}
                          arrow={true}
                        >
                          <span style={{ height: '100%' }}>
                            <Box
                              className={`${classes.tagBox}`}
                              style={{ background: '#43A547' }}
                            >
                              <Box className={classes.tagBoxText}>
                                Fund Raising
                              </Box>
                              <Box className={classes.tagBoxIcon}>
                                <img
                                  src={DollarSignWhiteIcon}
                                  style={{
                                    height: '100%',
                                  }}
                                />
                              </Box>
                            </Box>
                          </span>
                        </CustomizedTooltip>
                      )}
                    {company?.pastThreeMonthReleventTags &&
                      Object.prototype.hasOwnProperty.call(
                        company?.pastThreeMonthReleventTags,
                        'runwayLow',
                      ) && (
                        <CustomizedTooltip
                          title={
                            get(company, 'pastThreeMonthReleventTags.runwayLow')
                              ? get(
                                  company,
                                  'pastThreeMonthReleventTags.runwayLow',
                                )
                              : ''
                          }
                          placement="top"
                          className={classes.customTagTooltip}
                          arrow={true}
                        >
                          <span style={{ height: '100%' }}>
                            <Box
                              style={{ background: '#FE372A' }}
                              className={`${classes.tagBox}`}
                            >
                              <Box className={classes.tagBoxText}>
                                Low Runway
                              </Box>
                              <Box className={classes.tagBoxIcon}>
                                <img
                                  src={BatteryLowIcon}
                                  style={{
                                    height: '100%',
                                  }}
                                />
                              </Box>
                            </Box>
                          </span>
                        </CustomizedTooltip>
                      )}
                    {company?.pastThreeMonthReleventTags &&
                      Object.prototype.hasOwnProperty.call(
                        company?.pastThreeMonthReleventTags,
                        'founderLeaving',
                      ) && (
                        <CustomizedTooltip
                          title={
                            get(
                              company,
                              'pastThreeMonthReleventTags.founderLeaving',
                            )
                              ? get(
                                  company,
                                  'pastThreeMonthReleventTags.founderLeaving',
                                )
                              : ''
                          }
                          placement="top"
                          className={classes.customTagTooltip}
                          arrow={true}
                        >
                          <span style={{ height: '100%' }}>
                            <Box
                              style={{ background: '#FE372A' }}
                              className={`${classes.tagBox}`}
                            >
                              <Box className={classes.tagBoxText}>
                                Co-Founder Exit
                              </Box>
                              <Box className={classes.tagBoxIcon}>
                                <img
                                  src={LogoutRedIcon}
                                  style={{
                                    height: '100%',
                                  }}
                                />
                              </Box>
                            </Box>
                          </span>
                        </CustomizedTooltip>
                      )}
                    {company?.pastThreeMonthReleventTags &&
                      Object.prototype.hasOwnProperty.call(
                        company?.pastThreeMonthReleventTags,
                        'pmfAchieved',
                      ) && (
                        <CustomizedTooltip
                          title={
                            get(
                              company,
                              'pastThreeMonthReleventTags.pmfAchieved',
                            )
                              ? get(
                                  company,
                                  'pastThreeMonthReleventTags.pmfAchieved',
                                )
                              : ''
                          }
                          placement="top"
                          className={classes.customTagTooltip}
                          arrow={true}
                        >
                          <span style={{ height: '100%' }}>
                            <Box
                              style={{ background: '#43A547' }}
                              className={`${classes.tagBox}`}
                            >
                              <Box className={classes.tagBoxText}>
                                PMF Achieved
                              </Box>
                              <Box
                                className={classes.tagBoxIcon}
                                style={{ marginBottom: 'auto' }}
                              >
                                <img
                                  src={GreenFlagIcon}
                                  style={{
                                    height: '100%',
                                  }}
                                />
                              </Box>
                            </Box>
                          </span>
                        </CustomizedTooltip>
                      )}
                    {company?.pastThreeMonthReleventTags &&
                      Object.prototype.hasOwnProperty.call(
                        company?.pastThreeMonthReleventTags,
                        'arrAboveMillion',
                      ) && (
                        <Box
                          style={{ background: '#43A547' }}
                          className={`${classes.tagBox}`}
                        >
                          <Box className={classes.tagBoxText}>
                            {'Revenue > $1M'}
                          </Box>
                          <Box
                            className={classes.tagBoxIcon}
                            style={{ marginBottom: 'auto' }}
                          >
                            <img
                              src={GrowthBarIcon}
                              style={{
                                height: '100%',
                              }}
                            />
                          </Box>
                        </Box>
                      )}
                    {company?.pastThreeMonthReleventTags &&
                      Object.prototype.hasOwnProperty.call(
                        company?.pastThreeMonthReleventTags,
                        'pivoting',
                      ) && (
                        <CustomizedTooltip
                          title={
                            get(company, 'pastThreeMonthReleventTags.pivoting')
                              ? get(
                                  company,
                                  'pastThreeMonthReleventTags.pivoting',
                                )
                              : ''
                          }
                          placement="top"
                          className={classes.customTagTooltip}
                          arrow={true}
                        >
                          <span style={{ height: '100%' }}>
                            <Box className={classes.button2}>
                              <Box>Pivot</Box>
                              <img alt={PivotIcon} src={PivotIcon} />
                            </Box>
                          </span>
                        </CustomizedTooltip>
                      )}
                    {get(company, 'sector') && (
                      <Chip
                        className={classes.tagText1}
                        label={get(company, 'sector')}
                      />
                    )}
                    {get(company, 'lastFundingRound') && (
                      <Chip
                        className={classes.tagText1}
                        label={get(company, 'lastFundingRound')}
                      />
                    )}
                    {chipData?.map((data: any, index: any) => (
                      <Chip
                        key={`${data}${index}`}
                        className={classes.tagText1}
                        label={data}
                        onDelete={handleCustomTagAddAndDelete(data, index)}
                        disabled={disableAsPerPermission}
                      />
                    ))}
                    {get(company, 'isLoadingData') ? (
                      <Skeleton variant="rounded" width={40} />
                    ) : (
                      <CustomizedTooltip
                        title={
                          'Assign custom tags to company updates for better categorization'
                        }
                        placement="top"
                        className={classes.customTagTooltip}
                        arrow={true}
                      >
                        <span style={{ height: '100%' }}>
                          <IconButton
                            className={classes.addTagIcon}
                            aria-label="search"
                            onClick={handleContextMenuTag}
                            disabled={disableAsPerPermission}
                          >
                            <img src={AddTagIcon} alt={'add tag'} />
                          </IconButton>
                        </span>
                      </CustomizedTooltip>
                    )}
                  </div>
                </div>

                <Box>
                  <Menu
                    className={classes.customTagMenu}
                    open={contextMenuTag !== null}
                    onClose={handleSubClose}
                    anchorReference="anchorPosition"
                    style={{ overflow: 'hidden' }}
                    anchorPosition={
                      contextMenuTag !== null
                        ? {
                            top: contextMenuTag.mouseY - 28,
                            left: contextMenuTag.mouseX + 20,
                          }
                        : undefined
                    }
                  >
                    <MenuItem className={classes.customTagMenuItem}>
                      <InputBase
                        onKeyDown={(e: any) => {
                          e.stopPropagation();
                        }}
                        value={searchStringText}
                        className={classes.inputTag}
                        style={{ width: 150 }}
                        placeholder={'Enter Tag...'}
                        inputProps={{ 'aria-label': 'search' }}
                        endAdornment={
                          <IconButton
                            aria-label="search"
                            onClick={handleCustomTagAddAndDelete(
                              searchStringText,
                              0,
                              true,
                            )}
                          >
                            <img src={ArrowUpIcon} alt={'add tag'} />
                          </IconButton>
                        }
                        onChange={(e: any) => {
                          const searchString = get(e, 'target.value');
                          setSearchStringText(searchString);
                        }}
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                      />
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
              <Box style={{ display: 'flex', gap: 10 }}>
                {processingStatus && (
                  <Box
                    className={classes.processingBox}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <b>
                      Processing
                      <span className={classes['dot-one']}> .</span>
                      <span className={classes['dot-two']}> .</span>
                      <span className={classes['dot-three']}> .</span>
                    </b>
                  </Box>
                )}
                {(steps || []).length !== 0 && reTryJobs?.length === 0 ? (
                  <>
                    {companySnapNudge && (
                      <Box
                        className={classes.labelBoxSnap}
                        style={{
                          right: `${get(company, 'companyName')}`.length + 344,
                        }}
                      >
                        <Box className={classes.labelSubBoxSnap}>
                          <Box className={classes.labelPointSnap}></Box>

                          <Box
                            className={`${classes.labelDescSnap} ${classes.labelDesc} `}
                            style={{ width: 'max-content', position: 'static' }}
                          >
                            .Click on Company Snapshot to unlock{' '}
                            {get(company, 'companyName')} updates
                          </Box>
                          <Box
                            className={classes.closeIconContainer}
                            style={{ position: 'static' }}
                          >
                            <Box
                              className={classes.closeIconButton}
                              style={{ position: 'static' }}
                              onClick={() => setCompanySnapNudge(false)}
                            >
                              <CloseIcon className={classes.labelcloseIcon} />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    <img
                      src={
                        some(
                          get(company, 'investmentUpdates'),
                          (update: any) => {
                            const value = get(update, 'lastViewedAt', null);
                            return value === null;
                          },
                        )
                          ? CompanySnapshotButtonLock
                          : CompanySnapshotButton
                      }
                      style={
                        some(
                          get(company, 'investmentUpdates'),
                          (update: any) => {
                            const value = get(update, 'lastViewedAt', null);
                            return value === null;
                          },
                        )
                          ? { width: 175 }
                          : {}
                      }
                      className={classes.cSnap}
                      onClick={async () => {
                        if (!valid) {
                          dispatch(planExpiredDialog(true));
                          return;
                        }
                        if (
                          company?.additionalFields?.findIndex(
                            (field: any) => field.changeType,
                          ) >= 0
                        ) {
                          setMergeUpdatesDialog(true);
                          return;
                        }
                        setModalCompany(company);
                        if (
                          every(
                            get(company, 'investmentUpdates'),
                            (update: any) => {
                              const lastViewedAt = get(
                                update,
                                'lastViewedAt',
                                null,
                              );
                              const createdAt = get(update, 'createdAt', null);
                              return lastViewedAt >= createdAt;
                            },
                          )
                        ) {
                          setModal(true);
                          handleEditSave(
                            0,
                            get(company, `investmentUpdates[0].id`),
                            get(company, `investmentUpdates[0].investorId`),
                            company,
                            true,
                          );
                        } else {
                          setIsCompanySnapshotClicked(true);
                          setInvestmentUpdateId(
                            company?.investmentUpdates
                              .filter(
                                (update: any) =>
                                  get(update, 'lastViewedAt') === null,
                              )
                              .map((update: any) => get(update, 'id')),
                          );
                          recordInvestmentUpdateServiceUsage({
                            investmentUpdateIds: company?.investmentUpdates
                              .filter((update: any) => {
                                const lastViewedAt = get(
                                  update,
                                  'lastViewedAt',
                                  null,
                                );
                                const createdAt = get(
                                  update,
                                  'createdAt',
                                  null,
                                );
                                return lastViewedAt <= createdAt;
                              })
                              .map((update: any) => get(update, 'id')),
                            vcFirmId: get(vcFirm, 'id'),
                            chargesSeen: false,
                          });
                          setModal(true);
                          handleEditSave(
                            0,
                            get(company, `investmentUpdates[0].id`),
                            get(company, `investmentUpdates[0].investorId`),
                            company,
                            true,
                          );
                          // setChargeDialog(true);
                        }
                      }}
                      alt="cSnap"
                      id="companySnapshot-element"
                    />
                  </>
                ) : reTryJobs?.length > 0 ? (
                  <>
                    {!reTryLoading && retryNudge && (
                      <Box
                        className={classes.labelBoxContainer}
                        style={{ top: 5, left: -187 }}
                      >
                        <Box
                          className={classes.labelBoxContainerSub}
                          style={{ width: 203 }}
                        ></Box>
                        <Box className={classes.closeIconContainer}>
                          <Box
                            className={classes.closeIconButton}
                            onClick={() => setRetryNudge(false)}
                          >
                            <CloseIcon className={classes.labelcloseIcon} />
                          </Box>
                        </Box>
                        <Box
                          className={classes.labelDesc}
                          style={{ width: 176 }}
                        >
                          Processing failed, please re-try.
                        </Box>
                        <Box
                          className={classes.labelPoint}
                          style={{ left: 203 }}
                        >
                          {' '}
                        </Box>
                      </Box>
                    )}
                    <Button
                      variant="row"
                      name="Re-try"
                      className={`${classes.recoverButton}`}
                      onClick={() => handleMultipleReTry()}
                      disabled={
                        reTryLoading ||
                        reTryJobs.some(
                          (job: any) => get(job, 'status') === 'IN_PROGRESS',
                        )
                      }
                      // isLoading={true}
                      endIcon={
                        <img
                          src={RefreshWhiteIcon}
                          style={{
                            transform: `rotate(${rotation}deg)`,
                          }}
                        />
                      }
                    />
                  </>
                ) : null}
                {investUpdateIndexValue === firstEmptyInvDeal &&
                  !(steps || []).length &&
                  !get(
                    vcFirm,
                    'dashboardSettings.investmentUpdateNudgeSeen.upload',
                    [],
                  ).includes(email) && (
                    <Box className={classes.wrapper}>
                      <Box className={classes.topDiv}></Box>
                      <Box className={classes.middleDiv}>
                        <Box onClick={() => handleTooltipClose()}>
                          <CloseIcon className={classes.labelcloseIcon} />{' '}
                        </Box>
                        Upload the most recent investment updates for this
                        company.
                      </Box>
                      <Box className={classes.bottomDiv}></Box>
                    </Box>
                  )}
                <Box>
                  <Box
                    className={classes.uploadIconBoxContainer}
                    onClick={() => {
                      if (disableAsPerPermission) {
                        return;
                      }
                      if (!valid) {
                        dispatch(planExpiredDialog(true));
                        return;
                      }
                      setModalCompany(company);
                      setOpenUploadDialog(true);
                    }}
                  >
                    <Box className={classes.uploadIconBox}>
                      <img
                        src={UploadOrangeIcon}
                        alt={UploadOrangeIcon}
                        className={classes.uploadIcon}
                      />
                    </Box>
                  </Box>
                </Box>
                {!aiMonitoring && (
                  <Box>
                    <Box
                      className={`${classes.expandButtonContainer} ${
                        expanded ? classes.expandedButtonContainerActive : ''
                      }`}
                    >
                      <Box
                        className={classes.expandButton}
                        onClick={handleChange(index) || (() => null)}
                      >
                        <ExpandMoreIcon
                          className={`${classes.chRightIcon} ${
                            expanded ? classes.expandedButtonIcon : ''
                          }`}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box
                  className={classes.moreButton}
                  onClick={(e: any) => {
                    if (!valid) {
                      dispatch(planExpiredDialog(true));
                      return;
                    }
                    handleClick(e);
                  }}
                >
                  <img
                    src={ThreeDotVerticalOrangeIcon}
                    alt={ThreeDotVerticalOrangeIcon}
                  />
                </Box>
              </Box>
            </Box>
            {!expanded && step && !aiMonitoring ? (
              <InvestmentUpdateAccordionItem
                setInvestmentUpdateId={setInvestmentUpdateId}
                setChargeDialog={setChargeDialog}
                company={company}
                isEditMode={isEditMode}
                companyName={company?.companyName}
                watch={watch}
                isFreeLimitAvailable={isFreeLimitAvailable}
                control={control}
                setValue={setValue}
                step={step}
                isEditLoading={isEditLoading}
                processingStatusChange={processingStatusChange}
                setProcessingStatusChange={setProcessingStatusChange}
                index={0}
                hardReset={hardReset}
                handleInvestmentUpdateNudgeSeen={
                  handleInvestmentUpdateNudgeSeen
                }
                handleNameEdit={handleNameEdit}
                handleEditSave={handleEditSave}
                handleLockClick={handleLockClick}
                setModalCompany={setModalCompany}
                setSnapShotIndex={setSnapShotIndex}
                setModal={setModal}
                setSnapShotIndexForCharge={setSnapShotIndexForCharge}
                setDeleteUpdate={setDeleteUpdate}
                DeleteIcon={DeleteIcon}
                setIsOpenDeleteModal={setIsOpenDeleteModal}
                initialItem={true}
                valid={valid}
                joiningPlan={joiningPlan}
                handleChange={handleChange}
                parentIndex={index}
                getAllVcDealInvestmentUpdate={getAllVcDealInvestmentUpdate}
                disableAsPerPermission={disableAsPerPermission}
              />
            ) : (
              !expanded &&
              (jobs || []).slice(0, 1).map((step: any) => {
                return (
                  <InvestmentUpdateProcessingAccordionItem
                    key={get(step, 'id')}
                    companyName={get(company, 'companyName')}
                    step={step}
                    hardReset={hardReset}
                    setIsJobDelete={setIsJobDelete}
                    setDeleteUpdate={setDeleteUpdate}
                    setIsOpenDeleteModal={setIsOpenDeleteModal}
                    disableAsPerPermission={disableAsPerPermission}
                  />
                );
              })
            )}
          </AccordionSummary>
          <AccordionDetails className={classes.accordDetails}>
            {expanded && (
              <CompanyReportQuaterlySteps
                setDeleteUpdate={setDeleteUpdate}
                isEditLoading={isEditLoading}
                setIsEditLoading={setIsEditLoading}
                handleEditSave={handleEditSave}
                isFreeLimitAvailable={isFreeLimitAvailable}
                company={company}
                control={control}
                watch={watch}
                setValue={setValue}
                hardReset={hardReset}
                handleLockClick={handleLockClick}
                processingStatusChange={processingStatusChange}
                setProcessingStatusChange={setProcessingStatusChange}
                setInvestmentUpdateId={setInvestmentUpdateId}
                setSnapShotIndexForCharge={setSnapShotIndexForCharge}
                setChargeDialog={setChargeDialog}
                setIsOpenDeleteModal={setIsOpenDeleteModal}
                setModalCompany={setModalCompany}
                setIsJobDelete={setIsJobDelete}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                handleNameEdit={handleNameEdit}
                companyName={company.companyName}
                steps={company.investmentUpdates}
                jobs={jobs}
                setModal={setModal}
                onUpdateInvestmentUpdate={onUpdateInvestmentUpdate}
                setSnapShotIndex={setSnapShotIndex}
                getAllVcDealInvestmentUpdate={getAllVcDealInvestmentUpdate}
                disableAsPerPermission={disableAsPerPermission}
              />
            )}
          </AccordionDetails>
        </Accordion>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setEmailSubject(
              REMIND_STARTUP.initEmailSubject.replace(
                '##firmName##',
                get(vcFirm, 'name'),
              ),
            );
            setEmailBody(
              REMIND_STARTUP.initEmail
                .replace('##companyName##', get(company, 'companyName'))
                .replace('##firmName##', get(vcFirm, 'name'))
                .replace(
                  '##emailReader##',
                  get(vcFirm, 'investmentUpdateReader'),
                ),
            );
            setPreviewEmailOpen(true);
          }}
          disabled={disableAsPerPermission}
        >
          <span>
            Remind Startup
            <br />
            {get(
              company,
              `additionalFields[${emailIndex}].lastRemindStartupEmail.sentAt`,
            ) && (
              <span
                style={{
                  color: '#979797',
                  fontFamily: 'Mulish',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >
                Last sent:{' '}
                {moment(
                  get(
                    company,
                    `additionalFields[${emailIndex}].lastRemindStartupEmail.sentAt`,
                  ),
                ).format('DD/MM/YYYY')}
              </span>
            )}
          </span>
        </MenuItem>
        {get(company, 'hasPendingUpdateRef') && (
          <MenuItem disabled={disableAsPerPermission}>
            <Box onClick={handleContextMenu}>
              <Typography>Assign to</Typography>
              <Menu
                open={contextMenu !== null}
                onClose={handleSubClose}
                anchorReference="anchorPosition"
                style={{ overflow: 'hidden' }}
                anchorPosition={
                  contextMenu !== null
                    ? {
                        top: contextMenu.mouseY,
                        left: contextMenu.mouseX - 320,
                      }
                    : undefined
                }
              >
                <MenuItem>
                  <InputBase
                    onKeyDown={(e: any) => {
                      e.stopPropagation();
                    }}
                    value={searchStringText}
                    className={classes.inputSearch}
                    placeholder={'Search Company'}
                    inputProps={{ 'aria-label': 'search' }}
                    endAdornment={
                      <IconButton
                        className={classes.searchIcon}
                        aria-label="search"
                      >
                        <img src={SearchIcon} alt={SearchIcon} />
                      </IconButton>
                    }
                    onChange={(e: any) => {
                      const searchString = get(e, 'target.value');
                      setSearchStringText(searchString);
                    }}
                    onClick={(e: any) => {
                      e.stopPropagation();
                    }}
                  />
                </MenuItem>
                <Box className={classes.mergeContainer}>
                  <Box className={classes.mergeInfo}>
                    {`${get(company, 'companyName')} is getting assigned to`}
                  </Box>
                </Box>
                <Box className={classes.mergeList}>
                  {searchStringText &&
                    getFiltredCompanyList()?.map(
                      (filterCompany: any, key: number) => {
                        if (get(company, 'id') !== get(filterCompany, 'id')) {
                          return (
                            <MenuItem
                              key={key}
                              value={get(filterCompany, 'id')}
                            >
                              <Box
                                className={classes.checkBox}
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Checkbox
                                  checked={
                                    mergeDialog &&
                                    get(filterCompany, 'id') ===
                                      get(destinationMergeCompany, 'id')
                                  }
                                  onChange={(event) => {
                                    if (get(event, 'target.checked')) {
                                      setDestinationMergeCompany(filterCompany);
                                      setMergeDialog(true);
                                    } else {
                                      setMergeDialog(false);
                                    }
                                  }}
                                />
                                <ListItemText
                                  primary={get(filterCompany, 'companyName')}
                                />
                                {!get(filterCompany, 'hasPendingUpdateRef') && (
                                  <Chip
                                    className={classes.tagText}
                                    label={`Portfolio`}
                                  />
                                )}
                              </Box>
                            </MenuItem>
                          );
                        }
                      },
                    )}
                </Box>
              </Menu>
            </Box>
          </MenuItem>
        )}

        <MenuItem
          disabled={disableAsPerPermission}
          onClick={() => {
            setIsCompanyDelete(true);
            setDeleteUpdate(company);
            setIsOpenDeleteModal(true);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      {previewEmailOpen && (
        <PreviewEmailDialog
          openPreviewEmailDialog={previewEmailOpen}
          setOpenPreviewEmailDialog={setPreviewEmailOpen}
          title={'Preview Email'}
          deal={company}
          emailBody={emailBody}
          setEmailBody={setEmailBody}
          emailSubject={emailSubject}
          setEmailSubject={setEmailSubject}
          setSnackBarOpen={setSnackBarOpen}
          setSnackBarMessage={setSnackBarMessage}
          getAllVcDealInvestmentUpdate={getAllVcDealInvestmentUpdate}
          handleClose={() => {
            setEmailSubject(
              REMIND_STARTUP.initEmailSubject.replace(
                '##firmName##',
                get(vcFirm, 'name'),
              ),
            );
            setEmailBody(
              REMIND_STARTUP.initEmail
                .replace('##companyName##', get(company, 'companyName'))
                .replace('##firmName##', get(vcFirm, 'name'))
                .replace(
                  '##emailReader##',
                  get(vcFirm, 'investmentUpdateReader'),
                ),
            );
          }}
        />
      )}
      {mergeDialog && (
        <MergeDialog
          isOpenMergeModal={mergeDialog}
          setIsOpenMergeModal={setMergeDialog}
          sourceCompany={company}
          destinationCompany={destinationMergeCompany}
          reTryJobs={reTryJobs}
          setReTryLoading={setReTryLoading}
          onMergeInvestmentUpdate={onMergeInvestmentUpdate}
          closeDialog={() => {
            handleClose();
          }}
          title={``}
        />
      )}
      {snackBarOpen && (
        <SnackBar
          type={snackBarMessage === 'success' ? 'success' : 'error'}
          open={true}
          autoHideDuration={5000}
          handleClose={() => setSnackBarOpen(false)}
          message={
            snackBarMessage === 'success'
              ? 'Remind sent successfully'
              : 'Email not available'
          }
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        />
      )}
      {mergeUpdatesDialog && (
        <AutoDetectMergeDialog
          isOpenMergeModal={mergeUpdatesDialog}
          setIsOpenMergeModal={setMergeUpdatesDialog}
          company={company}
          reTryJobs={reTryJobs}
          setReTryLoading={setReTryLoading}
          onMergeInvestmentUpdate={onMergeInvestmentUpdate}
          closeDialog={() => {
            // handleClose();
          }}
          title={`Update frequency change detected`}
        />
      )}
    </>
  );
};

export default InvestmentUpdateAccordion;
