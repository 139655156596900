import React from 'react';
import {
  Avatar,
  Badge,
  Divider,
  Grid,
  Chip,
  Slider,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get, isEmpty, isArray, uniq } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import LinkIcon from '@mui/icons-material/Link';
import { useLocation } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import history from 'common/utils/history';
import {
  Box,
  FileUpload,
  WrappedTypography,
  InputTextField,
  InputPriceField,
  MultipleSelectField,
  MultiValueTextField,
  CustomFormField,
  Button,
  SelectField,
  AutoCompleteField,
} from 'components';
import {
  sectorList,
  vcDealsTagsList,
  businessOpsCountryList,
  fundingRoundStageList,
  fundRegisteredWithList,
  countryList,
  entityList,
} from 'common/utils/option-list';
import {
  getSignedUrlForVCFirmDocs,
  uploadVCFirmDocument,
  getVCFirmDocsByInvestor,
  updateDashboardSettings,
} from 'services';
import {
  VC_FIRM_DOCUMENT_NAME,
  VC_FIRM_DOCUMENT_RELATIVE_NAME,
  ROLE,
} from 'common/utils/constants';
import { RootState } from 'redux-modules/Store/RootState';
import {
  AngelDisabledIcon,
  CrunchbaseDisabledIcon,
  LinkedinDisabledIcon,
  AngelActiveIcon,
  CrunchbaseActiveIcon,
  LinkedinActiveIcon,
  YCOrangeIcon,
} from 'assets';
import {
  errorMessageHandler,
  getBusinessOpsCountryObject,
  getUserRole,
  getCountryObject,
} from 'common/utils/helpers';
import {
  getVCFirmDashboardProgress,
  getVCFirmProgress,
  submitVCInvestorProfileDetails,
  updateVCFirm,
} from 'redux-modules/VCFirm/Actions';
import { postLoginLogAmpEvent } from 'config/amplitude';
import { planExpiredDialog } from 'redux-modules/Global/Actions';

import styles from './styles';
import AddUrlDialog from './AddUrlDialog';
import {
  VcInvestorProfileSchema,
  VcAngelInvestorProfileSchema,
  VcAngelInvestorProfileOnboardSchema,
  VcInvestorProfileOnboardSchema,
} from '../validation';

const urlList = [
  {
    key: 'linkedinUrl',
    value: LinkedinDisabledIcon,
    active: LinkedinActiveIcon,
  },
  { key: 'angellistUrl', value: AngelDisabledIcon, active: AngelActiveIcon },
  {
    key: 'crunchbaseUrl',
    value: CrunchbaseDisabledIcon,
    active: CrunchbaseActiveIcon,
  },
];

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
  color: theme.palette.common.black,
}));

const VcInvestorProfileDetail = ({
  onClose,
  onboardFlow = false,
  investorId,
  vcFirm,
  countryOfOrigin,
  defaultOpenForm = false,
  userRole,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const {
    user: { role, signupSource },
  } = useSelector(({ Auth }: RootState) => Auth);
  const {
    subscription,
    vcInvestorProfileSubmit: { isLoading, errorMessage },
    workspace,
  } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const userRoles = getUserRole(userRole || role);
  const { valid } = subscription;
  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      userRoles.includes(ROLE.VC_ADMIN)
        ? onboardFlow
          ? VcInvestorProfileOnboardSchema
          : VcInvestorProfileSchema
        : onboardFlow
        ? VcAngelInvestorProfileOnboardSchema
        : VcAngelInvestorProfileSchema,
    ),
  });

  const [successMessage, setSuccessMessage] = React.useState<string>('');
  const [isUploadLoading, setIsUploadLoading] = React.useState<boolean>(false);
  const [logoUploadErrorMessage, setLogoUploadErrorMessage] =
    React.useState<string>('');
  const [isGetDocumentLoading, setIsGetDocumentLoading] =
    React.useState<boolean>(false);
  const [getDocumentErrorMessage, setGetDocumentErrorMessage] =
    React.useState<string>('');
  const [uploadedDocs, setUploadedDocs] = React.useState<any>([]);
  const [fundRegisteredList, setFundRegisteredList] = React.useState<string[]>(
    [],
  );
  const [modal, setModal] = React.useState<boolean>(false);
  const [url, setUrl] = React.useState<any>({
    linkedinUrl: '',
    crunchbaseUrl: '',
    angellistUrl: '',
    otherUrl: '',
  });
  const [selectedUrl, setSelectedUrl] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(
    defaultOpenForm === true
      ? true
      : userRoles.includes(ROLE.VC_ADMIN)
      ? false
      : true,
  );

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const disableAsPerPermission = permissionInfo.FUNDS === 'view';

  const uploadFile = async (fileuploadReq: any): Promise<void> => {
    setLogoUploadErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsUploadLoading(true);
      try {
        const url = await getSignedUrlForVCFirmDocs({
          investorId: investorId,
          vcFirmId: get(vcFirm, 'id'),
          documentName: VC_FIRM_DOCUMENT_NAME.LOGO,
          type: VC_FIRM_DOCUMENT_RELATIVE_NAME.VC_FIRM,
          filename: fileuploadReq.filename,
        });
        // eslint-disable-next-line no-undef
        await fetch(get(url, 'uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await uploadVCFirmDocument({
          investorId: investorId,
          vcFirmId: get(vcFirm, 'id'),
          documentName: VC_FIRM_DOCUMENT_NAME.LOGO,
          filename: fileuploadReq.filename,
          documentUrl: get(url, 'accessUrl'),
          type: VC_FIRM_DOCUMENT_RELATIVE_NAME.VC_FIRM,
          id: get(uploadedDocs, 'id', null),
        });
        await getUploadedDocuments();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setLogoUploadErrorMessage(message);
      } finally {
        setIsUploadLoading(false);
      }
    } else {
      setLogoUploadErrorMessage('Please upload file');
    }
  };

  const onSubmit = (data: any) => {
    const obj = {
      ...data,
      investorId,
      firmType: get(vcFirm, 'firmType'),
      onboardFlow,
      interestedSectors: get(data, 'interestedSectors')
        ? get(data, 'interestedSectors').toString()
        : undefined,
      companiesInvestedInPast: get(data, 'companiesInvestedInPast')
        ? get(data, 'companiesInvestedInPast').toString()
        : undefined,
      interestedGeographies: get(data, 'interestedGeographies')
        ? get(data, 'interestedGeographies')
            .map((i: any) => i.countryCode)
            .toString()
        : undefined,
      interestedFundingStage: get(data, 'interestedFundingStage')
        ? get(data, 'interestedFundingStage').toString()
        : undefined,
      sectorTags: get(data, 'sectorTags')
        ? get(data, 'sectorTags').toString()
        : undefined,
      linkedinUrl: get(url, 'linkedinUrl') ? get(url, 'linkedinUrl') : null,
      crunchbaseUrl: get(url, 'crunchbaseUrl')
        ? get(url, 'crunchbaseUrl')
        : null,
      angellistUrl: get(url, 'angellistUrl') ? get(url, 'angellistUrl') : null,
      otherUrl: get(url, 'otherUrl') ? get(url, 'otherUrl') : null,
      registeredFirmName: get(data, 'registeredFirmName'),
      businessOpsCountry: get(data, 'businessOpsCountry.countryCode'),
      countryOfOrigin: get(data, 'countryOfOrigin.countryCode'),
      entityType: get(data, 'entityType'),
      fundRegisteredWith:
        watch('fundRegisteredWith') === 'Other'
          ? get(data, 'fundRegisteredWithName')
          : get(data, 'fundRegisteredWith'),
    };
    const callback = () => {
      onboardFlow && dispatch(getVCFirmProgress());
      onboardFlow && dispatch(getVCFirmDashboardProgress());
      onboardFlow && handleSkipClick();
      onboardFlow && onClose();
      if (signupSource === 'ycs23_marketing' && onboardFlow)
        history.push('/deals?type=prospective&filter=YC');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (signupSource?.startsWith('/deals')) history.push(signupSource);
      setSuccessMessage('Successfully updated');
    };
    obj.callback = callback;
    delete obj.averageCheckSizeSlider;
    delete obj?.role;
    dispatch(submitVCInvestorProfileDetails(obj));
    if (history.location.pathname === '/dashboard')
      postLoginLogAmpEvent('Dashboard-Profile-Updated', userInfo, {});
    setTimeout(() => setSuccessMessage(''), 4500);
  };

  const getUploadedDocuments = async () => {
    setIsGetDocumentLoading(true);
    setGetDocumentErrorMessage('');
    const obj = {
      type: VC_FIRM_DOCUMENT_RELATIVE_NAME.VC_FIRM,
      vcFirmId: get(vcFirm, 'id'),
    };
    await getVCFirmDocsByInvestor(investorId, obj)
      .then((res: any) => {
        if (!isEmpty(res) && isArray(res)) {
          res.map((item: any) => {
            if (item.documentName === VC_FIRM_DOCUMENT_NAME.LOGO) {
              setUploadedDocs(item);
            }
          });
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setGetDocumentErrorMessage(message);
      })
      .finally(() => {
        setIsGetDocumentLoading(false);
      });
  };

  const handleSkipClick = () => {
    const obj = {
      investorId,
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        investorProfile: true, //investorProfile on Onboard Done
      },
    };
    const updateVcFirmPayload = {
      dashboardSettings: {
        ...get(vcFirm, 'dashboardSettings'),
        investorProfile: true,
      },
    };
    dispatch(updateVCFirm(updateVcFirmPayload));
    onboardFlow && onClose();
    if (signupSource === 'ycs23_marketing' && onboardFlow)
      history.push('/deals?type=prospective&filter=YC');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (signupSource?.startsWith('/deals')) history.push(signupSource);
    updateDashboardSettings(obj)
      // eslint-disable-next-line no-console
      .then((res: any) => console.log('UPDATE_DASHBOARD_SETTING', res))
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error('ERROR_WHILE_UPDATE_DASHBOARD_SETTING', message);
      });
  };

  const onCheckSizeTextChange = () => {
    setValue('averageCheckSizeSlider', watch('averageCheckSize'));
  };

  React.useEffect(() => {
    setValue('role', userRoles);
    if (get(vcFirm, 'id')) {
      getUploadedDocuments();
    }
  }, [vcFirm]);

  React.useEffect(() => {
    if (!isEmpty(get(vcFirm, 'id'))) {
      setValue(
        'interestedSectors',
        get(vcFirm, 'investorProfile.interestedSectors')
          ? get(vcFirm, 'investorProfile.interestedSectors')?.split(',')
          : [],
      );
      setValue(
        'companiesInvestedInPast',
        get(vcFirm, 'investorProfile.companiesInvestedInPast')
          ? get(vcFirm, 'investorProfile.companiesInvestedInPast').split(',')
          : [],
      );
      setValue(
        'interestedFundingStage',
        get(vcFirm, 'investorProfile.interestedFundingStage')
          ? get(vcFirm, 'investorProfile.interestedFundingStage')?.split(',')
          : [],
      );
      setValue(
        'interestedGeographies',
        get(vcFirm, 'investorProfile.interestedGeographies')
          ? get(vcFirm, 'investorProfile.interestedGeographies')
              ?.split(',')
              .map((i: any) => getBusinessOpsCountryObject(i))
          : [],
      );
      if (!isEmpty(get(vcFirm, 'investorProfile.averageCheckSize'))) {
        setValue(
          'averageCheckSize',
          get(vcFirm, 'investorProfile.averageCheckSize'),
        );
        setValue(
          'averageCheckSizeSlider',
          get(vcFirm, 'investorProfile.averageCheckSize'),
        );
      }
      setValue(
        'sectorTags',
        get(vcFirm, 'investorProfile.sectorTags')
          ? get(vcFirm, 'investorProfile.sectorTags').split(',')
          : [],
      );
      setValue('about', get(vcFirm, 'investorProfile.about'));
      setValue(
        'interestedInYCStartup',
        get(vcFirm, 'dashboardSettings.ycPreloadText') === true && onboardFlow
          ? true
          : get(vcFirm, 'interestedInYCStartup'),
      );
      setValue(
        'firmName',
        get(vcFirm, 'dashboardSettings.ycPreloadText') === true && onboardFlow
          ? ''
          : get(vcFirm, 'name'),
      );
      ['linkedinUrl', 'crunchbaseUrl', 'angellistUrl', 'otherUrl'].map((item) =>
        setUrl((prevState: any) => ({
          ...prevState,
          [item]: get(vcFirm, item) ? get(vcFirm, item) : '',
        })),
      );
      setValue('registeredFirmName', get(vcFirm, 'registeredFirmName'));
      setValue('entityType', get(vcFirm, 'entityType'));
      if (!isEmpty(watch('businessOpsCountry.countryCode'))) {
        if (
          fundRegisteredWithList[
            watch('businessOpsCountry.countryCode') || []
          ].includes(get(vcFirm, 'fundRegisteredWith'))
        ) {
          setValue('fundRegisteredWith', get(vcFirm, 'fundRegisteredWith'));
        } else {
          setValue('fundRegisteredWith', 'Other');
          setValue('fundRegisteredWithName', get(vcFirm, 'fundRegisteredWith'));
        }
      }
      setValue(
        'businessOpsCountry',
        getCountryObject(get(vcFirm, 'businessOpsCountry')),
      );
      setValue(
        'countryOfOrigin',
        getCountryObject(get(vcFirm, 'countryOfOrigin') || countryOfOrigin),
      );
    }
  }, [vcFirm]);

  React.useEffect(() => {
    const registeredList: string[] =
      fundRegisteredWithList[watch('businessOpsCountry.countryCode')] || [];
    setFundRegisteredList([...registeredList]);
  }, [watch('businessOpsCountry')]);

  React.useEffect(() => {
    if (
      !isEmpty(get(vcFirm, 'id')) &&
      !isEmpty(watch('businessOpsCountry.countryCode'))
    ) {
      if (
        fundRegisteredWithList[
          watch('businessOpsCountry.countryCode') || []
        ].includes(get(vcFirm, 'fundRegisteredWith'))
      ) {
        setValue('fundRegisteredWith', get(vcFirm, 'fundRegisteredWith'));
      } else {
        setValue('fundRegisteredWith', 'Other');
        setValue('fundRegisteredWithName', get(vcFirm, 'fundRegisteredWith'));
      }
    }
  }, [watch('businessOpsCountry')]);

  React.useEffect(() => {
    setValue('averageCheckSize', watch('averageCheckSizeSlider'));
    if (
      watch('averageCheckSize') < userRoles.includes(ROLE.VC_ADMIN)
        ? '25000'
        : '5000'
    ) {
      clearErrors('averageCheckSize');
    }
  }, [watch('averageCheckSizeSlider')]);

  React.useEffect(() => {
    if (!isEmpty(errors) && onboardFlow === false && !open) {
      setOpen(true);
    }
  }, [errors]);

  return (
    <>
      <Box
        style={pathname === '/vc-firm/profile' ? { padding: 25 } : undefined}
        className={classes.profileContainer}
      >
        <Box className={classes.leftContainer}>
          <FileUpload
            fileExtensions={['png', 'jpg', 'jpeg']}
            requestObject={{
              documentName: VC_FIRM_DOCUMENT_NAME.LOGO,
            }}
            showSelectedFilePreview={false}
            uploadOnSelect={false}
            callGetSignedUrl={false}
            onSuccess={(req: any) => uploadFile(req)}
            disabled={disableAsPerPermission}
            content={
              <Badge
                overlap="circular"
                style={{ cursor: 'pointer' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <SmallAvatar>
                    <CameraAltOutlinedIcon style={{ fontSize: 14 }} />
                  </SmallAvatar>
                }
              >
                <Avatar
                  className={classes.avatar}
                  src={uploadedDocs.documentUrl}
                >
                  {localStorage.getItem('selectedWorkspace')
                    ? userRoles.includes(ROLE.VC_ADMIN)
                      ? 'Firm Logo'
                      : 'Profile Picture'
                    : userRoles.includes(ROLE.VC_ADMIN) &&
                      !userRoles.includes(ROLE.ANGEL_INVESTOR)
                    ? 'Firm Logo'
                    : 'Profile Picture'}
                </Avatar>
              </Badge>
            }
          />
          {/* <WrappedTypography className={classes.firmNameText}>
            {name}
          </WrappedTypography> */}
          <Box className={classes.linkContainer}>
            {urlList.map((item: any, index: number) => (
              <img
                src={get(url, item.key) ? item.active : item.value}
                alt={get(url, item.key) ? item.active : item.value}
                key={index}
                onClick={() => {
                  if (!valid) {
                    dispatch(planExpiredDialog(true));
                    return;
                  }
                  setModal(true);
                  setSelectedUrl(item.key);
                }}
              />
            ))}
            {get(url, 'otherUrl') ? (
              <LinkIcon />
            ) : (
              <Box
                className={classes.addCardBox}
                onClick={() => {
                  if (!valid) {
                    dispatch(planExpiredDialog(true));
                    return;
                  }
                  setModal(true);
                  setSelectedUrl('otherUrl');
                }}
              >
                <Box>+</Box>
              </Box>
            )}
          </Box>
        </Box>
        <form
          className={classes.rightContainer}
          onSubmit={
            !valid
              ? (e) => {
                  e.preventDefault();
                  dispatch(planExpiredDialog(true));
                }
              : handleSubmit(onSubmit)
          }
          data-testid="vc-investor-profile"
        >
          {!isEmpty(successMessage) && (
            <WrappedTypography className={classes.successText}>
              {successMessage}
            </WrappedTypography>
          )}
          {(errorMessage ||
            getDocumentErrorMessage ||
            logoUploadErrorMessage) && (
            <WrappedTypography gutterBottom className={classes.errorMessage}>
              {errorMessage ||
                getDocumentErrorMessage ||
                logoUploadErrorMessage}
            </WrappedTypography>
          )}
          <WrappedTypography className={classes.investorProfileText}>
            {localStorage.getItem('selectedWorkspace')
              ? userRoles.includes(ROLE.VC_ADMIN)
                ? 'Firm Profile'
                : 'Investor Profile'
              : userRoles.includes(ROLE.VC_ADMIN) &&
                !userRoles.includes(ROLE.ANGEL_INVESTOR)
              ? 'Firm Profile'
              : 'Investor Profile'}
          </WrappedTypography>
          <Divider style={{ marginTop: 10 }} />
          <Grid container spacing={3} className={classes.firmBox}>
            {localStorage.getItem('selectedWorkspace') ? (
              userRoles.includes(ROLE.VC_ADMIN) ? (
                <Grid item xs={12}>
                  <WrappedTypography type={'body2'}>
                    Name of the Firm
                    <span className={classes.requiredText}>*</span>
                  </WrappedTypography>
                  <InputTextField
                    placeholder="Enter your Firm Name"
                    control={control}
                    name="firmName"
                    disabled={disableAsPerPermission}
                  />
                </Grid>
              ) : (
                false
              )
            ) : userRoles.includes(ROLE.VC_ADMIN) &&
              !userRoles.includes(ROLE.ANGEL_INVESTOR) ? (
              <Grid item xs={12}>
                <WrappedTypography type={'body2'}>
                  Name of the Firm
                  <span className={classes.requiredText}>*</span>
                </WrappedTypography>
                <InputTextField
                  placeholder="Enter your Firm Name"
                  control={control}
                  name="firmName"
                />
              </Grid>
            ) : (
              false
            )}
            <Grid item xs={12} className={classes.inputYCInterestedBox}>
              <WrappedTypography type={'body2'}>
                Interested in investing in{' '}
                <span className={classes.ycCombinatorText}>
                  <img
                    src={YCOrangeIcon}
                    alt={YCOrangeIcon}
                    className={classes.ycIcon}
                  />{' '}
                  Combinator
                </span>{' '}
                startups?
              </WrappedTypography>
              <CustomFormField
                name={'interestedInYCStartup'}
                control={control}
                className={classes.radioField}
                errorStyle={classes.radioFieldError}
                renderField={(onChange: any, value: any) => (
                  <RadioGroup
                    onChange={onChange}
                    row={true}
                    value={value}
                    className={classes.radioGroup}
                  >
                    {[
                      { name: 'Yes', key: true },
                      { name: 'No', key: false },
                    ].map((type: any) => (
                      <FormControlLabel
                        key={get(type, 'key')}
                        value={get(type, 'key')}
                        control={<Radio size="small" />}
                        label={get(type, 'name')}
                        disabled={disableAsPerPermission}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Grid>
            {userRoles.includes(ROLE.VC_ADMIN) && (
              <Box
                className={classes.completeProfileBox}
                onClick={() => setOpen(!open)}
              >
                <WrappedTypography className={classes.completeProfileText}>
                  Complete Your Profile
                  <span className={classes.optionalText}>{` (optional)`}</span>
                </WrappedTypography>
                <Box>
                  {open ? (
                    <ExpandLess style={{ cursor: 'pointer' }} />
                  ) : (
                    <ExpandMore style={{ cursor: 'pointer' }} />
                  )}
                </Box>
              </Box>
            )}
          </Grid>
          {open && (
            <Grid container spacing={3} className={classes.firmBox}>
              <Grid item xs={12}>
                <WrappedTypography type={'caption'}>About</WrappedTypography>
                <InputTextField
                  placeholder="Write here..."
                  control={control}
                  name="about"
                  multiline={true}
                  variant={'outlined'}
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WrappedTypography type={'body2'}>
                  Interested Sector (select as many applicable)
                </WrappedTypography>
                <MultipleSelectField
                  control={control}
                  name="interestedSectors"
                  options={sectorList}
                  placeholder="Select Sector"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WrappedTypography type={'body2'}>Tags</WrappedTypography>
                <MultiValueTextField
                  id="sectorTags"
                  control={control}
                  name="sectorTags"
                  className={classes.tagStyle}
                  placeholder="Input Tags & hit Enter"
                  options={vcDealsTagsList}
                  freeSolo={false}
                  renderTags={(value: string[], getTagProps: any) => {
                    return value.map((option: string, index: number) => (
                      <Chip
                        key={`tag_${index}`}
                        size="small"
                        label={option}
                        deleteIcon={<CloseIcon />}
                        {...getTagProps({ index })}
                      />
                    ));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <WrappedTypography type={'body2'}>
                  Prominent Investments
                </WrappedTypography>
                <MultiValueTextField
                  id="companiesInvestedInPast"
                  control={control}
                  name="companiesInvestedInPast"
                  className={classes.tagStyle}
                  placeholder="Enter Company Names"
                  freeSolo={true}
                  renderTags={(value: string[], getTagProps: any) => {
                    return value.map((option: string, index: number) => (
                      <Chip
                        key={`name_${index}`}
                        size="small"
                        label={option}
                        deleteIcon={<CloseIcon />}
                        {...getTagProps({ index })}
                      />
                    ));
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WrappedTypography type={'body2'}>
                  Preferred Geography
                </WrappedTypography>
                <MultiValueTextField
                  id="interestedGeographies"
                  control={control}
                  name={'interestedGeographies'}
                  className={classes.countryTagStyle}
                  placeholder="Select Preferred Geography"
                  options={businessOpsCountryList}
                  freeSolo={false}
                  disableCloseOnSelect
                  getOptionLabel={(option: any) => option.country || ''}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.countryCode === value.countryCode ||
                    value === undefined ||
                    value === ''
                  }
                  renderOption={(
                    props: any,
                    option: any,
                    { selected }: any,
                  ) => (
                    <li {...props}>
                      <Checkbox
                        sx={{ padding: '0px 7px 2px 2px' }}
                        checked={
                          selected ||
                          !isEmpty(
                            watch('interestedGeographies').find(
                              (i: any) => i.countryCode === option.countryCode,
                            ),
                          )
                        }
                      />
                      {option.country}
                    </li>
                  )}
                  renderTags={(value: any[], getTagProps: any) => {
                    return value.map((option: any, index: number) => (
                      <Chip
                        key={`country_${index}`}
                        size="small"
                        label={option.country}
                        deleteIcon={<CloseIcon />}
                        {...getTagProps({ index })}
                      />
                    ));
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WrappedTypography type={'body2'}>
                  Preferred Stage
                </WrappedTypography>
                <MultipleSelectField
                  control={control}
                  name="interestedFundingStage"
                  options={fundingRoundStageList}
                  placeholder="Select Preferred Stage"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                defaultValue={30}
                className={classes.sliderClass}
              >
                <Box className={classes.checkSizeBox}>
                  <WrappedTypography type={'body2'}>
                    Average Check Size
                  </WrappedTypography>
                  <InputPriceField
                    placeholder="Enter Check Size"
                    control={control}
                    name="averageCheckSize"
                    startAdornment={'$'}
                    priceInputProps={{
                      thousandSeparator: true,
                    }}
                    onInputChange={() => {
                      onCheckSizeTextChange();
                    }}
                  />
                </Box>
                <CustomFormField
                  name={'averageCheckSizeSlider'}
                  control={control}
                  defaultValue={
                    userRoles.includes(ROLE.VC_ADMIN) ? 25000 : 5000
                  }
                  renderField={(onChange: any, value: any) => (
                    <Slider
                      onChange={onChange}
                      value={Number(value)}
                      step={userRoles.includes(ROLE.VC_ADMIN) ? 25000 : 5000}
                      min={userRoles.includes(ROLE.VC_ADMIN) ? 25000 : 5000}
                      max={userRoles.includes(ROLE.VC_ADMIN) ? 1000000 : 500000}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          {onboardFlow === false && open && (
            <>
              <Divider style={{ marginTop: 5, marginBottom: 15 }} />
              <Grid container spacing={3} className={classes.firmBox}>
                {userRoles.includes(ROLE.VC_ADMIN) && (
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      <WrappedTypography className={classes.formLabelText}>
                        Registered Firm Name
                        <span className={classes.vMandatory}>*</span>
                      </WrappedTypography>
                      <InputTextField
                        placeholder="Enter Registered Firm Name"
                        control={control}
                        name="registeredFirmName"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <WrappedTypography className={classes.formLabelText}>
                        Type of Entity
                        <span className={classes.vMandatory}>*</span>
                      </WrappedTypography>
                      <SelectField
                        control={control}
                        name="entityType"
                        options={entityList}
                        placeholder="Select entity"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <WrappedTypography className={classes.formLabelText}>
                        Country of Business Operation
                        <span className={classes.vMandatory}>*</span>
                      </WrappedTypography>
                      <AutoCompleteField
                        id="businessOpsCountry"
                        control={control}
                        name="businessOpsCountry"
                        placeholder="Choose country of business operation"
                        fieldValue={getCountryObject(
                          get(vcFirm, 'businessOpsCountry'),
                        )}
                        options={countryList}
                        getOptionLabel={(option: any) => option.country || ''}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.countryCode === value.countryCode ||
                          value === undefined ||
                          value === ''
                        }
                        disableClearable
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} md={6}>
                  <WrappedTypography className={classes.formLabelText}>
                    Country of Origin
                    <span className={classes.vMandatory}>*</span>
                  </WrappedTypography>
                  <AutoCompleteField
                    id="countryOfOrigin"
                    control={control}
                    name="countryOfOrigin"
                    placeholder="Choose country of origin"
                    fieldValue={getCountryObject(
                      get(vcFirm, 'countryOfOrigin'),
                    )}
                    options={countryList}
                    getOptionLabel={(option: any) => option.country || ''}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.countryCode === value?.countryCode ||
                      value === undefined ||
                      value === ''
                    }
                    disableClearable
                  />
                </Grid>
                {userRoles.includes(ROLE.VC_ADMIN) && (
                  <Grid item xs={12} sm={6} md={6}>
                    <WrappedTypography className={classes.formLabelText}>
                      Fund registered with
                    </WrappedTypography>
                    <Box style={{ display: 'flex' }}>
                      <Grid
                        item
                        xs={watch('fundRegisteredWith') === 'Other' ? 3 : 12}
                      >
                        <SelectField
                          control={control}
                          name="fundRegisteredWith"
                          options={uniq([...fundRegisteredList, 'Other']).map(
                            (opt: any) => ({
                              text: opt,
                              value: opt,
                            }),
                          )}
                          placeholder="Select Fund registered"
                        />
                      </Grid>
                      {watch('fundRegisteredWith') === 'Other' && (
                        <Grid
                          item
                          xs={12}
                          style={{ marginLeft: 20, marginTop: 3 }}
                        >
                          <InputTextField
                            placeholder="Enter Name"
                            control={control}
                            name="fundRegisteredWithName"
                          />
                        </Grid>
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          <Box className={classes.btnContainer}>
            {userRoles.includes(ROLE.ANGEL_INVESTOR) && onboardFlow === true && (
              <WrappedTypography
                className={classes.skipText}
                onClick={() => handleSkipClick()}
              >
                Skip for now
              </WrappedTypography>
            )}
            <Button
              className={classes.saveBtn}
              type="submit"
              name={`Save`}
              isLoading={isLoading || isUploadLoading}
              disabled={
                isLoading ||
                isUploadLoading ||
                isGetDocumentLoading ||
                disableAsPerPermission
              }
            />
          </Box>
        </form>
      </Box>
      {modal && (
        <AddUrlDialog
          isOpen={modal}
          setIsOpen={setModal}
          url={url}
          setUrl={setUrl}
          selectedUrl={selectedUrl}
          vcFirm={vcFirm}
          userRoles={userRoles}
          disableAsPerPermission={disableAsPerPermission}
        />
      )}
    </>
  );
};

export default VcInvestorProfileDetail;
