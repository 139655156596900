import React from 'react';

import { DocAddIcon, ExcelIcon } from 'assets';
import { Box, Button } from 'components';

import styles from '../styles';

const ActiveDealLandingPage = ({
  onImportExcelClick,
  onAddManuallyClick,
}: any) => {
  const classes = styles();
  return (
    <Box className={classes.activeDealsContainer}>
      <Box className={classes.activeDealstitle}>Add Deal Manually</Box>
      <Box className={classes.importManualBoxContainer}>
        <Box className={classes.importDealsParent}>
          <Box className={classes.groupBox} />
          <img className={classes.groupInnerBox} alt="" src={ExcelIcon} />
          <Button
            name="Import Excel"
            className={`${classes.addButton}`}
            onClick={() => {
              onImportExcelClick();
            }}
          />
          <Box className={classes.boxDescription}>
            Conveniently upload all your deals at once, simplifying and speeding
            up your deal management.
          </Box>
        </Box>
        <Box className={classes.orBoxContainer}>
          <Box className={classes.orWrapper}>
            <Box className={classes.orBox}>Or</Box>
          </Box>
        </Box>
        <Box className={classes.rectangleGroup}>
          <Box className={classes.groupBox} />
          <img className={classes.groupInnerBox} alt="" src={DocAddIcon} />
          <Button
            name="Add Manually"
            className={`${classes.addButton}`}
            onClick={() => {
              onAddManuallyClick();
            }}
          />
          <Box className={classes.boxDescription}>
            Simply enter deal details directly into the platform for
            straightforward deal creation.
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ActiveDealLandingPage;
