// TODO revist and refactor
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { get, isEmpty } from 'lodash';
import { Stripe } from '@stripe/stripe-js';
import { Alert } from '@mui/material';
import { Auth } from 'aws-amplify';

import { Box, Button, WrappedTypography } from 'components';
import {
  fetchOutstandingBalance,
  fetchPaymentCards,
} from 'redux-modules/Global/Actions';
import { attachPaymentMethodCard, getPaymentIntent } from 'services';
import { FN } from 'common/types';
import {
  errorMessageHandler,
  formatAmount,
  getCognitoUserAttributes,
} from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import {
  confirmSubscription,
  confirmSwitchSubscription,
  deletePaymentMethod,
  restartSubscription,
  switchSubscription,
} from 'services/payment';
import {
  getVCFirmDetails,
  getVCFirmSubscription,
} from 'redux-modules/VCFirm/Actions';
import { SUBSCRIPTION } from 'common/utils/constants';
import history from 'common/utils/history';
import { PoweredbyStripeLogo } from 'assets';
import { postLoginLogAmpEvent } from 'config/amplitude';
import { setCognitoUser } from 'redux-modules/Auth/Actions';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';

import styles from '../styles';

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CardInputV2 = ({
  isAddActive,
  editCardActive,
  setEditCardActive,
  cards,
  onBack,
  failedPayment,
  restartSubscriptionActive,
  subscription,
  creditBalance,
  joiningPlan,
  switchFirm,
  switchPlan,
  unpaidInvoiceActive,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const stripe: Stripe | null = useStripe();
  const elements: FN = useElements();

  const search = history.location.search;
  const source = new URLSearchParams(search).get('source');
  const adminDealId = new URLSearchParams(search).get('adminDeal');
  const vcDealId = new URLSearchParams(search).get('vcDeal');
  const type = new URLSearchParams(search).get('type');

  const { vcFirm } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);

  const [savingCard, setSavingCard] = React.useState<boolean>(false);
  const [cardErrorMessage, setCardErrorMessage] = React.useState<string>('');
  const [makingPayment, setMakingPayment] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>('');
  const [nameError, setNameError] = React.useState<string>('');
  const [createSubResponse, setCreateSubResponse] = React.useState<any>({});
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [creatingSubscription, setCreatingSubscription] =
    React.useState<boolean>(false);

  const getPriceText = () => {
    const subscriptionPlan = get(subscription, 'subscriptionPlan');
    if (!isEmpty(subscriptionPlan)) {
      const prices = get(subscriptionPlan, 'prices') || [];
      const addOnPrice = prices.find((price: any) => price.addon);
      const regularPlanPrice = prices.find((price: any) => !price.addon);
      return (
        <WrappedTypography type={'body1'} sx={{ margin: 0 }}>
          You will be charged{' '}
          <strong>{formatAmount(get(addOnPrice, 'amount') || 0)}</strong>
          /deal to generate a report using the AI Analyst. After your free
          trial, you will be charged a platform subscription fee of
          <strong> {formatAmount(get(regularPlanPrice, 'amount'))}</strong>
          /month.
        </WrappedTypography>
      );
    }
    return '';
  };

  const handlePayInvoice = async () => {
    if (restartSubscriptionActive || switchPlan) {
      return handleCreateSubscription();
    }
    setCardErrorMessage('');
    setMakingPayment(true);
    let paymentIntent: any;
    try {
      paymentIntent = await getPaymentIntent({
        paymentIntentId:
          get(failedPayment, 'paymentIntent') ||
          get(failedPayment, 'payment_intent'),
      });
      if (get(paymentIntent, 'status') === 'succeeded') {
        throw new Error('Payment already made');
      }
    } catch (err: any) {
      setMakingPayment(false);
      setCardErrorMessage(err.message);
      return;
    }
    stripe
      ?.confirmCardPayment(paymentIntent?.client_secret, {
        payment_method: get(cards, '[0].paymentRefId'),
      })
      .then((result: FN) => {
        if (result.error) {
          setCardErrorMessage(get(result, 'error.message'));
          setMakingPayment(false);
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          throw result;
        } else {
          setTimeout(() => setMakingPayment(false), 2000);
          if (
            get(result, 'setupIntent.status') === 'succeeded' ||
            get(result, 'paymentIntent.status') === 'succeeded'
          ) {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to setup_intent.succeeded.
            setTimeout(() => onBack(true), 2000);
          }
        }
      })
      .catch(() => setMakingPayment(false));
  };

  const handleCardSetupRequired = async ({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    subscriptionId,
    oldSubscriptionRefId,
  }: FN) => {
    const setupIntent = subscription.pending_setup_intent;

    if (setupIntent && setupIntent.status === 'requires_action') {
      return stripe
        ?.confirmCardSetup(setupIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result: FN) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.setupIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to setup_intent.succeeded.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
                subscriptionId,
                oldSubscriptionRefId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId, subscriptionId };
    }
  };

  const handlePaymentThatRequiresCustomerAction = async ({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    subscriptionId,
    isRetry,
    oldSubscriptionRefId,
  }: FN) => {
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (!paymentIntent)
      return {
        subscription,
        priceId,
        paymentMethodId,
        subscriptionId,
        oldSubscriptionRefId,
      };

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        ?.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result: FN) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.paid. This webhook endpoint returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
                subscriptionId,
                oldSubscriptionRefId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId, subscriptionId };
    }
  };

  const handleRequiresPaymentMethod = ({
    subscription,
    paymentMethodId,
    priceId,
    subscriptionId,
    oldSubscriptionRefId,
  }: FN) => {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId, subscriptionId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status,
      );
      throw { error: { message: 'Your card was declined.' } };
    } else {
      return {
        subscription,
        priceId,
        paymentMethodId,
        subscriptionId,
        oldSubscriptionRefId,
      };
    }
  };

  const handleCreateSubscription = async () => {
    setErrorMessage('');
    setCreatingSubscription(true);

    const plan = switchPlan
      ? switchSubscription({
          paymentMethod: get(cards, '[0].paymentRefId'),
          subscriptionPlanId: get(subscription, 'subscriptionPlan.id'),
          joiningPlanId: get(joiningPlan, 'id'),
        })
      : restartSubscription({
          paymentMethod: get(cards, '[0].paymentRefId'),
          subscriptionId: get(subscription, 'id'),
          // paymentMethodName: selectedCardName,
        });
    plan
      .then((res: FN) => {
        const { subscription, priceId, subscriptionId, oldSubscriptionRefId } =
          res;
        setCreateSubResponse(res);
        return {
          // Use the Stripe 'object' property on the
          // returned result to understand what object is returned.
          subscription,
          paymentMethodId: get(cards, '[0].paymentRefId'),
          subscriptionId,
          priceId,
          oldSubscriptionRefId,
        };
      })
      // Some payment methods require a customer to do additional
      // authentication with their financial institution.
      // Eg: 2FA for cards.
      .then(handleCardSetupRequired)
      .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail. You will
      // get a requires_payment_method error.
      .then(handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then((res: any) => {
        const { subscriptionId } = res;
        const plan = switchPlan
          ? confirmSwitchSubscription({
              success: true,
              subscriptionId,
              switchFirm,
              vcFirmId: get(vcFirm, 'id'),
            })
          : confirmSubscription({
              subscriptionId: subscriptionId,
              success: true,
            });
        plan
          .then(() => {
            setCreatingSubscription(false);
            dispatch(getVCFirmSubscription());
            // dispatch(fetchCreditBalance());
            dispatch(fetchOutstandingBalance());
            if (switchFirm) refreshVcFirm();
            else onBack();
          })
          .catch((error) => {
            setCreatingSubscription(false);
            setErrorMessage(
              get(error, 'response.data.message') ||
                get(error, 'error.message'),
            );
          });
      })
      .catch((error) => {
        setCreatingSubscription(false);
        setErrorMessage(
          get(error, 'response.data.message') || get(error, 'error.message'),
        );
        if (!isEmpty(createSubResponse) && !switchPlan)
          confirmSubscription({
            subscriptionId: get(createSubResponse, 'subscriptionId'),
            success: false,
            oldSubscriptionRefId: get(
              createSubResponse,
              'oldSubscriptionRefId',
            ),
          });
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        // displayError(error);
      });
  };

  const saveCard = async () => {
    try {
      if (!name) {
        setNameError(
          'To move forward, enter the name as it appears on the card',
        );
        return false;
      }
      setSavingCard(true);
      try {
        setCardErrorMessage('');
        const paymentMethod = await stripe?.createPaymentMethod({
          card: elements.getElement('card'),
          billing_details: {
            name,
          },
          type: 'card',
        });
        if (
          !get(paymentMethod, 'paymentMethod') &&
          get(paymentMethod, 'error')
        ) {
          const paymentErrormessage =
            get(paymentMethod, 'error.code') === 'incomplete_number'
              ? 'The card number entered is incorrect'
              : get(paymentMethod, 'error.code') === 'incomplete_expiry'
              ? 'The card expiration date is incomplete'
              : get(paymentMethod, 'error.code') === 'invalid_expiry_year_past'
              ? 'The card expiration year provided is outdated. Please use a current expiration year'
              : get(paymentMethod, 'error.code') === 'incomplete_cvc'
              ? 'The security code for the card is incomplete'
              : get(paymentMethod, 'error.message');
          setCardErrorMessage(paymentErrormessage);
        } else {
          const request: any = {
            paymentMethod: get(paymentMethod, 'paymentMethod'),
          };
          if (source && adminDealId) {
            request.action = 'DEAL_UNLOCK';
            request.moduleId = adminDealId;
          } else if (type) {
            request.action = 'VC_DEAL_UNLOCK';
            request.moduleId = vcDealId;
            request.vcFirmId = get(vcFirm, 'id');
          }
          await attachPaymentMethodCard(request);
          if (editCardActive && get(cards, '[0].id')) {
            await deletePaymentMethod({
              paymentMethodId: get(cards, '[0].id'),
            });
          }
          if (
            !restartSubscriptionActive &&
            !failedPayment &&
            !source &&
            !switchPlan
          )
            onBack();
          else setEditCardActive(false);
          dispatch(fetchPaymentCards());
          if (source === 'ycdeals' && adminDealId) {
            postLoginLogAmpEvent(
              'Added-Card-Detail-To-Unlock-Deal',
              userInfo,
              {},
            );
            history.push(
              `/deals?type=prospective&filter=YC&adminDeal=${adminDealId}`,
            );
          } else if (source === 'list') {
            postLoginLogAmpEvent(
              'Added-Card-Detail-To-Unlock-Deal',
              userInfo,
              {},
            );
            history.push(`/deals?type=prospective`);
          } else if (source === 'sidepane') {
            postLoginLogAmpEvent(
              'Added-Card-Detail-To-Unlock-Deal',
              userInfo,
              {},
            );
            history.push(
              `/deals?type=prospective&source=subscription&id=${vcDealId}`,
            );
          }
        }
      } catch (err: any) {
        const message: string = errorMessageHandler(err);
        if (message) {
          setCardErrorMessage(message);
        }
      } finally {
        setSavingCard(false);
      }
    } catch (err) {
      const message: string = errorMessageHandler(err);
      // eslint-disable-next-line no-console
      console.log('ERROR WHILE SAVING CARD: ', message);
      setSavingCard(false);
    }
  };

  const refreshVcFirm = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const refreshToken = (await Auth.currentSession()).getRefreshToken();
    await cognitoUser.refreshSession(refreshToken, (err: any) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
    setTimeout(() => {
      Auth.userAttributes(cognitoUser).then(async (_attributes) => {
        const customAttribute = _attributes.reduce(
          (a, v) => ({ ...a, [v.Name]: v.Value }),
          {},
        );
        const userObj = getCognitoUserAttributes(customAttribute);
        setTimeout(() => {
          dispatch(setCognitoUser(userObj));
          dispatch(getVCFirmAllFund(get(vcFirm, 'investorId')));
        }, 1000);
        if (switchFirm) localStorage.setItem('openProfile', 'yes');
        onBack();
      });
    }, 2000);
    dispatch(getVCFirmDetails(get(vcFirm, 'investorId')));
  };

  const getMakeButton = () => {
    if (editCardActive) return;
    if (
      get(subscription, 'subscriptionPlan.planType') ===
        SUBSCRIPTION.YARDSTICK &&
      restartSubscriptionActive
    ) {
      return;
    }
    const btn = (
      <Button
        variant="standard"
        name={
          isCreditBalanceMoreThanPlanPrice().full
            ? 'Utilize Credits'
            : 'Make Payment'
        }
        size="small"
        disabled={makingPayment || creatingSubscription || isEmpty(cards)}
        isLoading={makingPayment || creatingSubscription}
        onClick={handlePayInvoice}
      />
    );
    if (switchPlan || (restartSubscriptionActive && !isEmpty(cards))) {
      return btn;
    } else if (
      failedPayment &&
      get(failedPayment, 'subscriptionPlan') &&
      !isEmpty(cards)
    )
      return btn;
  };

  const isCreditBalanceMoreThanPlanPrice = () => {
    let amt: any = 0;
    const creditBalanceFloat = parseFloat(creditBalance);
    if (!isEmpty(failedPayment) && unpaidInvoiceActive) {
      amt = get(failedPayment, 'amount_due') / 100;
    } else {
      const subscriptionPlan = get(subscription, 'subscriptionPlan');
      const prices = get(subscriptionPlan, 'prices') || [];
      amt =
        get(
          prices.find((price: any) => !price.addon),
          'amount',
        ) || 0;
    }

    if (parseFloat(creditBalance) >= parseFloat(amt)) {
      console.log('ISFULL'); //eslint-disable-line
      return {
        full: true,
        partial: false,
      };
    } else if (!isNaN(creditBalanceFloat) && creditBalanceFloat > 0) {
      return {
        full: false,
        partial: true,
      };
    }
    return {
      full: false,
      partial: false,
    };
  };

  return (
    <div>
      {(editCardActive || (isAddActive && isEmpty(cards))) && (
        <>
          <div
            style={{
              border: '1px solid #e5e5e5',
              padding: 10,
              borderRadius: 5,
              width: '40%',
              marginTop: 30,
              marginBottom: 20,
              paddingBottom: 10,
            }}
          >
            <input
              placeholder="Name on card"
              onChange={(e: FN) => {
                setName(e.target.value);
                if (e.target.value) {
                  setNameError('');
                }
              }}
              required
              className={classes.cardNameInput}
            />
            {nameError && (
              <div
                style={{
                  fontSize: 14,
                  marginTop: -18,
                  marginBottom: 8,
                  color: 'red',
                }}
              >
                {nameError}
              </div>
            )}
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <div style={{ textAlign: 'right' }}>
              <Button
                variant="outlined"
                name={`Save`}
                size="small"
                isLoading={savingCard}
                disabled={savingCard}
                onClick={saveCard}
                style={{ marginTop: 20 }}
              />
            </div>
          </div>
          <Box>
            <img
              src={PoweredbyStripeLogo}
              alt={PoweredbyStripeLogo}
              className={classes.powerByStripeLogo}
            />
          </Box>
        </>
      )}
      {source &&
        getPriceText() &&
        !get(subscription, 'subscriptionPlan.isJoiningPlan') && (
          <Alert severity="info" className={classes.planPriceText}>
            {getPriceText()}
          </Alert>
        )}

      <Box className={classes.cardBtnWrapper}>
        {getMakeButton()}
        <Button
          name={editCardActive ? 'Cancel' : 'Back'}
          className={classes.btnPrevious}
          onClick={() => {
            if (editCardActive) {
              setEditCardActive(false);
            } else if (source) {
              history.back();
            } else onBack();
          }}
          size="small"
          disabled={makingPayment || creatingSubscription || savingCard}
        />
      </Box>
      {isCreditBalanceMoreThanPlanPrice().full && (
        <WrappedTypography className={classes.newsText}>
          {`🎉 Great news! Your bonus credits 💰 are enough to cover the subscription cost. No charges will be applied to your account. 🌟 🔒 Continue with the payment process to start the subscription; we'll only save your card details.`}
        </WrappedTypography>
      )}
      {isCreditBalanceMoreThanPlanPrice().partial && (
        <WrappedTypography className={classes.newsText}>
          <Alert severity="info">
            We'll utilize your available credits and charge the remaining amount
            from your card to complete the transaction.
          </Alert>
        </WrappedTypography>
      )}
      {cardErrorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {cardErrorMessage}
        </WrappedTypography>
      )}
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
    </div>
  );
};

export default CardInputV2;
