import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';

import { Box, WrappedTypography } from 'components';
import { getUserRole } from 'common/utils/helpers';
import { RootState } from 'redux-modules/Store/RootState';
import { ROLE } from 'common/utils/constants';
import { WorkspaceDialog } from 'components';
import { acceptedInviteDetail } from 'services';

import styles from './styles';
import AngelDashboard from './AngelDashboard';
import VcFirmDashboard from './VcFirmDashboard';

const Dashboard = () => {
  const classes = styles();

  const {
    user: { role, email },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm, vcFirmProgress } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );
  const { subscription } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const userRoles = getUserRole(role);

  const [workspaceDialog, setWorkspaceDialog] = React.useState(false);
  const [workspaceInviteList, setWorkspaceInviteList] = React.useState<any>({});
  const remainingDays = () => {
    const startingDate = moment(get(subscription, 'startingDate'));
    const trialEndDate = moment(startingDate)
      .add(
        get(subscription, 'subscriptionPlan.subscriptionFreeTrialDays'),
        'days',
      )
      .format('ll');
    const remDays = moment(trialEndDate).diff(moment(), 'days');
    return remDays;
  };
  const remainingDaysValue = remainingDays();

  React.useEffect(() => {
    acceptedInviteDetail(email).then((res: any) => {
      if (res) {
        setWorkspaceInviteList(res);
        setWorkspaceDialog(true);
      }
    });
  }, []);

  React.useEffect(() => {
    if (
      (userRoles.includes(ROLE.VC_ADMIN) &&
        get(vcFirmProgress, 'investorProfileCreated') === false) ||
      (userRoles.includes(ROLE.ANGEL_INVESTOR) &&
        get(vcFirmProgress, 'investorProfileCreated') === false &&
        !get(vcFirm, 'dashboardSettings.investorProfile'))
    ) {
      // setModal(true);
    }
  }, [vcFirmProgress]);

  return (
    <>
      {get(subscription, 'valid') &&
        get(subscription, 'subscriptionPlan.isJoiningPlan') &&
        remainingDaysValue >= 0 &&
        remainingDaysValue < 8 && (
          <Box className={classes.aiTextBox}>
            <Box className={classes.linearBorder}>
              <WrappedTypography className={classes.aiCreditsBannerText}>
                {remainingDaysValue} days left in your free trial!{' '}
                <a href="/subscription-v2?action=renew">Subscribe</a> to keep
                using the platform.
              </WrappedTypography>
            </Box>
          </Box>
        )}
      {userRoles.includes(ROLE.ANGEL_INVESTOR) ? (
        <AngelDashboard />
      ) : userRoles.includes(ROLE.VC_ADMIN) ? (
        <VcFirmDashboard />
      ) : (
        <></>
      )}
      {workspaceDialog && (
        <WorkspaceDialog
          open={workspaceDialog}
          handleClose={() => {
            setWorkspaceDialog(false);
          }}
          title="Welcome to VentureInsights!"
          content={`You've successfully joined ${workspaceInviteList.vcFirmName} - workspace`}
          onOk={() => {
            null;
          }}
          workspaceInviteList={workspaceInviteList}
          okButton="OK"
          loading={false}
        />
      )}
      {/* {modal && (
        <Dialog
          open={modal}
          handleClose={() => setModal(false)}
          maxWidth={'lg'}
          showClose={false}
          componentsProps={{
            backdrop: {
              className: userRoles.includes(ROLE.VC_ADMIN)
                ? classes.investorProfileBackdrop
                : classes.angelInvestorProfileBackdrop,
            },
          }}
          style={{
            marginTop: 35,
          }}
        >
          <VcInvestorProfileDetail
            onClose={() => {
              setModal(false);
            }}
            onboardFlow={true}
            investorId={investorId}
            vcFirm={vcFirm}
            countryOfOrigin={countryOfOrigin}
          />
        </Dialog>
      )} */}
      {/* {creditBalanceModal && (
        <Dialog
          open={creditBalanceModal}
          handleClose={() => setCreditBalanceModal(false)}
          maxWidth={'md'}
          showClose={true}
          PaperProps={{
            classes: {
              root: classes.creditBalanceDialogPaper,
            },
          }}
        >
          <Box className={classes.creditBalanceBox}>
            <Box className={classes.creditBalanceTextBox}>
              <WrappedTypography className={classes.creditBalanceText}>
                You have received {formatAmount(userCreditBalance())} in
                credits.
              </WrappedTypography>
            </Box>
            <WrappedTypography className={classes.creditBalanceUtilizeText}>
              🌟 Welcome to VentureInsights! Unleash the power of AI with{' '}
              {formatAmount(userCreditBalance())} in FREE credits. Make smarter
              deals, faster decisions, and seize your next opportunity!
            </WrappedTypography>
          </Box>
        </Dialog>
      )} */}
    </>
  );
};

export default Dashboard;
