import React from 'react';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import { find, get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';

import { WrappedTypography, Button, Loader, Dialog } from 'components';
import { listVCSubscriptionPlans, upgradeSubscriptionPlan } from 'services';
import { SUBSCRIPTION } from 'common/utils/constants';
import { RootState } from 'redux-modules/Store/RootState';
import {
  errorMessageHandler,
  formatAmount,
  getCognitoUserAttributes,
} from 'common/utils/helpers';
import {
  getVCFirmDetails,
  getVCFirmSubscription,
} from 'redux-modules/VCFirm/Actions';
import { setCognitoUser } from 'redux-modules/Auth/Actions';
import { getVCFirmAllFund } from 'redux-modules/Funds/Actions';

import styles from './styles';

type SubscriptionPlanProps = {
  firmType: string;
  subscription: any;
  outstandingBalance: any;
  onClickPlan: any;
  onBack: any;
  setOpenNotification: any;
};

const planDetailList: Array<any> = [
  {
    name: 'VentureInsights',
    key: SUBSCRIPTION.ANGEL_INVESTOR,
    subText: 'Recommended for Angel Investors',
    description: 'AI Powered Deal Management and Evaluation',
    subDescription: null,
    priceUnit: 'mo',
    oldPrice: 499.99,
  },
  {
    name: 'VentureInsights Plus',
    key: SUBSCRIPTION.VC_FIRM,
    subText: 'Recommended for VCs and Family Offices',
    description: 'AI Powered Investing and LP Management Platform',
    subDescription: 'Everything in VentureInsights, and:',
    priceUnit: 'mo',
    oldPrice: 2999.99,
  },
];

const planDescriptionList: Array<any> = [
  {
    text: 'AI powered Deal Pipeline Management',
    includedIn: [SUBSCRIPTION.ANGEL_INVESTOR],
  },
  {
    text: 'AI Analyst generated Investment Notes',
    includedIn: [SUBSCRIPTION.ANGEL_INVESTOR],
  },
  {
    text: 'Yardstick Benchmarking Tool',
    includedIn: [SUBSCRIPTION.ANGEL_INVESTOR],
  },
  {
    text: 'Single Seat',
    includedIn: [SUBSCRIPTION.ANGEL_INVESTOR],
  },
  {
    text: 'VC Fund(s) Management & Dashboard',
    includedIn: [SUBSCRIPTION.VC_FIRM],
  },
  {
    text: 'Dashboards for your LPs',
    includedIn: [SUBSCRIPTION.VC_FIRM],
  },
  {
    text: 'Co-invest with your LPs',
    includedIn: [SUBSCRIPTION.VC_FIRM],
  },
  {
    text: 'Investor Reporting for LPs',
    includedIn: [SUBSCRIPTION.VC_FIRM],
  },
  {
    text: 'Up to 5 seats for your team',
    includedIn: [SUBSCRIPTION.VC_FIRM],
  },
];

const SubscriptionPlan = ({
  firmType,
  subscription,
  outstandingBalance,
  onClickPlan,
  onBack,
  setOpenNotification,
}: SubscriptionPlanProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { user } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm, isLoadingSubscription } = useSelector(
    ({ VCFirm }: RootState) => VCFirm,
  );

  const [loadingPlans, setLoadingPlans] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [subscriptionList, setSubscriptionList] = React.useState<
    Array<Record<string, any>>
  >([]);
  const [selectedPlan, setSelectedPlan] = React.useState<any>();
  const [isUpgradePlan, setUpgradePlan] = React.useState<boolean>(false);
  const [upgradingPlan, setUpgradingPlan] = React.useState<boolean>(false);

  const getPlanDetail = (planType: string) => {
    return find(planDetailList, {
      key: planType,
    });
  };

  const getPlanPrice = (subscriptionPlan: any) => {
    const prices = get(subscriptionPlan, 'prices') || [];
    return get(
      prices.find((price: any) => !price.addon),
      'amount',
    );
  };

  const onUpgradePlan = async () => {
    setUpgradingPlan(true);
    upgradeSubscriptionPlan({
      currentInvestorSubscriptionId: get(subscription, 'id'),
      subscribingToPlanId: get(selectedPlan, 'id'),
      vcFirmId: get(vcFirm, 'id'),
    })
      .then(async () => {
        dispatch(getVCFirmSubscription());
        dispatch(getVCFirmDetails(get(vcFirm, 'investorId')));
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const refreshToken = (await Auth.currentSession()).getRefreshToken();
        await cognitoUser.refreshSession(refreshToken, (err: any) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
        Auth.userAttributes(cognitoUser).then(async (_attributes) => {
          const customAttribute = _attributes.reduce(
            (a, v) => ({ ...a, [v.Name]: v.Value }),
            {},
          );
          const userObj = getCognitoUserAttributes(customAttribute);
          setTimeout(() => {
            dispatch(setCognitoUser(userObj));
            dispatch(getVCFirmAllFund(get(vcFirm, 'investorId')));
          }, 1000);
          setUpgradingPlan(false);
          setUpgradePlan(false);
          onBack();
          localStorage.setItem('openProfile', 'yes');
        });
      })
      .catch((err) => {
        console.log(err); //eslint-disable-line
        setOpenNotification(get(err, 'response.data.message'));
        setUpgradingPlan(false);
      });
  };

  React.useEffect(() => {
    setErrorMessage('');
    setLoadingPlans(true);
    listVCSubscriptionPlans(true)
      .then((res: any) => {
        // setSubscriptionList([
        //   res.find((obj: any) => obj.planType === firmType),
        // ]);
        if (firmType === SUBSCRIPTION.ANGEL_INVESTOR) {
          if (get(subscription, 'subscriptionPlan.isJoiningPlan')) {
            setSubscriptionList([
              res.find(
                (obj: any) => obj.planType === SUBSCRIPTION.ANGEL_INVESTOR,
              ),
              res.find((obj: any) => obj.planType === SUBSCRIPTION.VC_FIRM),
            ]);
          } else {
            setSubscriptionList([
              res.find((obj: any) => obj.planType === SUBSCRIPTION.VC_FIRM),
            ]);
          }
        } else {
          setSubscriptionList([
            res.find((obj: any) => obj.planType === firmType),
          ]);
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setLoadingPlans(false);
      })
      .finally(() => setLoadingPlans(false));
  }, [subscription]);

  return (
    <>
      {errorMessage && (
        <WrappedTypography className={classes.errorMessage}>
          {errorMessage}
        </WrappedTypography>
      )}
      {loadingPlans || isLoadingSubscription ? (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      ) : !errorMessage && !isEmpty(subscriptionList) ? (
        <>
          <Box
            className={
              get(user, 'signupSource') !== 'ycs23_marketing'
                ? classes.contentContainer
                : classes.contentContainerSource
            }
          >
            {(subscriptionList || []).map((planItem: any, index: number) => (
              <Box
                key={index}
                className={
                  get(planItem, 'planType') === SUBSCRIPTION.VC_FIRM
                    ? classes.subscriptionVentureInsightPlusTile
                    : classes.subscriptionVentureInsightTile
                }
                style={
                  get(user, 'signupSource') === 'ycs23_marketing'
                    ? {
                        paddingTop: 20,
                      }
                    : {}
                }
              >
                {firmType !== get(planItem, 'planType') && (
                  <Box className={classes.upgradeToBox}>
                    <WrappedTypography className={classes.upgradeToText}>
                      Upgrade To
                    </WrappedTypography>
                  </Box>
                )}
                <Box
                  className={
                    get(user, 'signupSource') !== 'ycs23_marketing'
                      ? classes.subscriptionPlanDetailBox
                      : classes.subscriptionPlanDetailSourceBox
                  }
                >
                  <WrappedTypography
                    className={classes.subscriptionPlanNameText}
                  >
                    {get(getPlanDetail(get(planItem, 'planType')), 'name')}
                  </WrappedTypography>
                  <WrappedTypography className={classes.subPlanNameText}>
                    {get(getPlanDetail(get(planItem, 'planType')), 'subText')}
                  </WrappedTypography>
                  <WrappedTypography className={classes.priceText}>
                    {formatAmount(getPlanPrice(planItem) || 0).replace(
                      /[.,]00$/,
                      '',
                    )}
                    /
                    {get(getPlanDetail(get(planItem, 'planType')), 'priceUnit')}
                  </WrappedTypography>
                  <Box className={classes.billedTextBox}>
                    <WrappedTypography className={classes.billedText}>
                      {formatAmount(
                        get(
                          getPlanDetail(get(planItem, 'planType')),
                          'oldPrice',
                        ),
                      ).replace(/[.,]00$/, '')}
                      /
                      {get(
                        getPlanDetail(get(planItem, 'planType')),
                        'priceUnit',
                      )}
                    </WrappedTypography>
                    <WrappedTypography className={classes.billedMonthlyText}>
                      billed monthly
                    </WrappedTypography>
                  </Box>

                  <Button
                    className={
                      get(planItem, 'planType') === SUBSCRIPTION.VC_FIRM
                        ? classes.freeTrialPlusBtn
                        : classes.freeTrialBtn
                    }
                    // name={
                    //   firmType === get(planItem, 'planType')
                    //     ? 'Start Subscription'
                    //     : 'Upgrade Now'
                    // }
                    name="Start Subscription"
                    onClick={() => {
                      setSelectedPlan(planItem);
                      onClickPlan(planItem);
                      // if (outstandingBalance === '0.00') {
                      //   onClickPlan(planItem);
                      // } else {
                      //   setIsOpen(true);
                      // }
                    }}
                    // onClick={() =>
                    //   createSubscription({
                    //     subscriptionPlanId: get(planItem, 'id'),
                    //     planType: get(planItem, 'planType'),
                    //     ycPLan: true,
                    //     plan: planItem,
                    //   })
                    // }
                    // disabled={creatingSubscription}
                    // isLoading={
                    //   creatingSubscription &&
                    //   selectedPlan === get(planItem, 'planType')
                    // }
                  />

                  <WrappedTypography className={classes.aiPoweredText}>
                    {get(
                      getPlanDetail(get(planItem, 'planType')),
                      'description',
                    )}
                  </WrappedTypography>
                </Box>
                <Box className={classes.subscriptionPlanAIBox}>
                  <Box
                    className={
                      get(user, 'signupSource') !== 'ycs23_marketing'
                        ? classes.listBox
                        : classes.listBoxSource
                    }
                  >
                    <List>
                      {!isEmpty(
                        get(
                          getPlanDetail(get(planItem, 'planType')),
                          'subDescription',
                        ),
                      ) && (
                        <WrappedTypography className={classes.everyText}>
                          {get(
                            find(planDetailList, {
                              key: get(planItem, 'planType'),
                            }),
                            'subDescription',
                          )}
                        </WrappedTypography>
                      )}
                      {planDescriptionList.map(
                        (item: any, index: number) =>
                          (get(item, 'includedIn') || []).includes(
                            get(planItem, 'planType'),
                          ) && (
                            <ListItem key={index} disablePadding>
                              <ListItemButton>
                                <ListItemIcon>
                                  <CheckIcon className={classes.checkIcon} />
                                </ListItemIcon>
                                <ListItemText primary={get(item, 'text')} />
                              </ListItemButton>
                            </ListItem>
                          ),
                      )}
                    </List>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      ) : isEmpty(subscriptionList) ? (
        <WrappedTypography className={classes.subscriptionNotFoundText}>
          Subscription plans not found. Please contact administration.
        </WrappedTypography>
      ) : (
        ''
      )}
      {isOpen && (
        <Dialog
          open={isOpen}
          maxWidth={'md'}
          subheading={''}
          title={' '}
          handleClose={() => {
            setIsOpen(false);
          }}
        >
          <Box className={classes.dialogText}>
            <WrappedTypography className={classes.dialogAttentionText}>
              Attention: By upgrading to a paid subscription, you will be
              charged the subscription fee plus an outstanding balance of $
              {outstandingBalance}
            </WrappedTypography>
            <Button
              type="button"
              name="Confirm to Proceed"
              className={classes.confirmProceedBtn}
              onClick={() => {
                onClickPlan(selectedPlan);
                setIsOpen(false);
              }}
            />
          </Box>
        </Dialog>
      )}
      <Dialog
        open={isUpgradePlan}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        dialogAction
        dialogActionClass={classes.dialogActionClass}
        dialogActionChildren={
          <>
            <Box className={classes.rejectBtnContainer}>
              <Button
                sx={{ marginRight: 1 }}
                className={classes.outlinedBtn}
                disabled={upgradingPlan}
                name="No"
                onClick={() => setUpgradePlan(false)}
              />
              <Button
                type="button"
                name={'Yes'}
                variant={'standard'}
                disabled={upgradingPlan}
                isLoading={upgradingPlan}
                onClick={onUpgradePlan}
              />
            </Box>
          </>
        }
        handleClose={() => {
          if (upgradingPlan) return;
          setUpgradePlan(false);
        }}
      >
        <Box className={classes.dialogBody}>
          Are you sure you want to upgrade your plan?
        </Box>
      </Dialog>
    </>
  );
};

export default SubscriptionPlan;
