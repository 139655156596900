import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { CompanyBlurpOutlinedButton } from 'assets';

export default makeStyles((theme: Theme) => ({
  marketingInnerContainer: {
    padding: '10px 10px 10px 10px',
  },
  inputDocumentBox: {
    padding: 0,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  uploadBoxContainer: {
    borderRadius: 3,
    border: '0.50px solid' + theme.palette.text.disabled,
    height: '100%',
    padding: '10px !important',
    marginBottom: '15px',
  },
  uploadBoxHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  previewBox: {
    height: 25,
    background: theme.shadows[17],
    borderRadius: 3,
    display: 'flex',
    marginBottom: 5,
    marginTop: 5,
    justifyContent: 'space-between',
  },
  previewOtherDocBox: {
    height: 25,
    background: theme.shadows[17],
    borderRadius: 3,
    display: 'flex',
    marginTop: 5,
    marginBottom: 5,
    justifyContent: 'space-between',
  },
  nameContainer: {
    display: 'flex',
    margin: 10,
    width: 'calc(100% - 20px)',
    alignItems: 'center',
  },
  sampleFile: {
    width: 14,
    height: 15,
  },
  uploadSafeIcon: {
    width: 70,
    height: 60,
  },
  uploadFileName: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    padding: '0 7px',
    width: 'calc(100% - 13px)',
  },
  deleteImg: {
    width: '12px',
    height: '14px',
    cursor: 'pointer',
    margin: 5,
    marginRight: 10,
  },
  downloadIcon: {
    fill: theme.palette.primary.main,
    cursor: 'pointer',
  },
  addCardBox: {
    border: '1px dashed' + theme.palette.primary.main,
    background: '#FFF8F3',
    cursor: 'pointer',
    height: 38,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    color: 'grey',
    marginTop: 10,
    fontWeight: 200,
  },
  addCardBoxInner: {
    color: 'black',
    fontSize: 12,
  },
  aiMonitorBox: {
    width: '100%',
    height: '100%',
  },
  aiMonitorBoxHeader: {
    display: 'flex',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  companyBlurpBtn: {
    backgroundImage: `url(${CompanyBlurpOutlinedButton})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 140,
    height: 37,
    paddingBottom: 5,
    marginRight: 12,
    cursor: 'pointer',
  },
  uploadIcon: {
    width: 'auto',
    height: 'auto',
    margin: '0px 10px',
  },
  stepperBox: {
    padding: '10px',
  },
  stepperLabelBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  viewIcon: {
    height: 17,
    width: 18,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: 10,
  },
  descriptionMessageBox: {
    textAlign: 'center',
    color: '#0A6DF7',
    fontSize: 11,
    fontFamily: 'Mulish',
    wordWrap: 'break-word',
  },
  ribbon: {
    position: 'absolute',
    left: -8,
    top: -8,
    width: 37,
    height: 14,
    fontSize: 9,
    fontWeight: 600,
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
  },
  inputSafeFieldsBox: {
    marginTop: 30,
    marginBottom: 20,
    position: 'relative',
    border: '0.5px solid #DEDEDE',
    padding: 10,
    //marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  inputFundFullBox: {
    padding: 0,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  inputErrorBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
    position: 'relative',
    border: '0.5px solid #DEDEDE',
    padding: 10,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
      fontSize: 16,
      FontWeight: 400,
      color: theme.palette.error.dark,
    },
  },
  inputPriceBox: {
    position: 'relative',
    '& span': {
      bottom: -5,
      lineHeight: '15px',
      minHeight: 27,
    },
    '& p': {
      margin: 0,
    },
  },
  inputPriceEntryValBox: {
    position: 'relative',
    '& span': {
      bottom: -5,
      lineHeight: '15px',
      minHeight: 27,
    },
    '& p': {
      margin: 0,
    },
    '& .MuiTextField-root': {
      '& .MuiInput-underline:before': {
        borderBottom: '0.5px dotted grey',
      },
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  amountInvestedText: {
    color: theme.palette.common.black,
    fontSize: 12,
    position: 'absolute',
    right: 0,
    paddingTop: 10,
  },
  accordBox: {
    marginBottom: '20px !important',
    marginTop: 20,
    borderRadius: 2,
    padding: '0',
    boxShadow: theme.shadows[0],
    '&::before': {
      display: 'none',
      position: 'static',
    },
    '& .MuiAccordionSummary-root': {
      padding: '10px 10px',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.primary.dark,
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 7,
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
  },
  accordBoxLatestVal: {
    marginBottom: '20px !important',
    marginTop: 20,
    borderRadius: 2,
    padding: '0',
    boxShadow: theme.shadows[0],
    '&::before': {
      display: 'none',
      position: 'static',
    },
    '& .MuiAccordionSummary-root': {
      padding: '10px 10px',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.grey[50],
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 7,
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
  },
  textfieldBox: {
    paddingRight: 20,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
  },
  editIcon: {
    height: 17,
    width: 18,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: 10,
  },
  inputFundBox: {
    padding: 0,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  container: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    fontSize: 20,
    top: '10%',
    left: '50%',
    color: theme.palette.text.disabled,
  },
  childContainer: {
    filter: 'blur(2px)',
  },
  removeText: {
    fontSize: 17,
    cursor: 'pointer',
    color: theme.palette.error.main,
  },
  disableClick: {
    pointerEvents: 'none',
    color: theme.palette.primary.main,
    fontSize: 17,
  },
  addFounderBtn: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 17,
  },
  inputDisabled: {
    '& .Mui-disabled': {
      cursor: 'pointer',
    },
    '& .MuiInputBase-input': {
      cursor: 'pointer',
      color: '#979797',
    },
  },
  tagSmallDesc: {
    color: theme.palette.text.primary,
    fontSize: 12,
  },
  tagStyle: {
    marginBottom: 25,
    width: '100%',
    '& .MuiChip-root': {
      color: theme.palette.common.black,
      borderRadius: 2,
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
  },
  docContainer: {
    borderRadius: 3,
    border: '0.25px solid #E5E5E5 ',
    height: '100%',
    padding: '10px 5px 0px 5px !important',
    marginTop: 10,
  },
  fundSetupSelectBox: {
    width: '100%',
  },
  fundSetupFundText: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  emailChipStyle: {
    marginBottom: 25,
    width: '100%',
    '& .MuiChip-root': {
      color: theme.palette.text.secondary,
      borderRadius: 10,
      backgroundColor: '#DEDEDE',
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
  },
  editToolTip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  processingBox: {
    color: '#323232',
    marginRight: 15,
    fontSize: 11,
    fontFamily: 'Mulish',
    fontWeight: '700',
    letterSpacing: 5,
    wordWrap: 'break-word',
  },
  companySnapshotBox: {
    width: 307,
    color: 'rgb(64, 72, 82)',
    fontSize: 14,
    fontFamily: 'Mulish',
    fontWeight: 600,
    overflowWrap: 'break-word',
  },
  updateHeader: {
    width: 598,
    color: 'rgb(18, 18, 18)',
    fontSize: 16,
    fontFamily: 'Mulish',
    fontWeight: 600,
    overflowWrap: 'break-word',
  },
  updatesContainer: {
    width: '100%',
    height: 40,
    padding: 10,
    borderBottom: '0.5px solid rgb(222, 222, 222)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    display: 'inline-flex',
  },
  stepperDescriptionBox: {
    color: 'rgb(64, 72, 82)',
    fontSize: 10,
    fontFamily: 'Mulish',
    fontWeight: 400,
    overflowWrap: 'break-word',
  },
  aiMonitorContainer: {
    width: 327,
    height: 43,
    padding: '5px 10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 2,
    display: 'inline-flex',
  },
  //
  currentValuationInputSelectBox: {
    display: 'flex',
    color: theme.palette.text.disabled,
    width: '100%',
    padding: '4px 0 5px',
    borderBottom: `1px solid #949494`,
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  avatarMenuCurrentValuation: {
    '& .MuiMenu-paper': {
      marginTop: 35,
      borderRadius: 5,
      boxShadow: theme.shadows[5],
      minWidth: 580,
      maxWidth: 800,
    },
    '& .MuiList-root.MuiMenu-list': {
      maxHeight: '250px',
      overflowY: 'scroll',
    },
  },
  parentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    // padding: '5px 5px 10px 2px',
    // border: '1px solid #DEDEDE',
    // boxShadow: theme.shadows[10],
  },
  childMenuContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  timeline: {
    '& .MuiTimelineDot-root': {
      backgroundColor: theme.palette.primary.main,
      margin: '8.5px 0px',
    },
    '& .MuiTimelineConnector-root': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiTimeline-root': {
      padding: '6px 5px',
    },
    '& .MuiTimelineItem-root': {
      minHeight: 80,
      '&:last-child': {
        height: 50,
      },
      '&:before': {
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
    '& hr': {
      // borderTop: 'red',
      // height: 3,
    },
    '& ul': {
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  valuationMenuBox: {
    padding: '0px 0px 0px 0px',
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  addRoundBox: {
    // margin: 10,
  },
  addLatestRound: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 3,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    width: 151,
    fontSize: 12,
    lineHeight: '16px',
    cursor: 'pointer',
    height: 26,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 13,
      lineHeight: '16px',
    },
  },
  uploadEnabledIcon: {
    cursor: 'pointer',
    color: 'black',
  },
  uploadDisabledIcon: {
    color: theme.palette.text.disabled,
  },
  linkText: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  priceRoundTooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#273240',
      color: '#FFFFFF',
    },
    '& .MuiTooltip-arrow': {
      color: '#273240',
    },
  },
  tooltipCrossIcon: {
    fontSize: 13,
    cursor: 'pointer',
    marginLeft: 8,
    color: '#FFFFFFF',
  },
  countryOperationTagStyle: {
    marginBottom: 25,
    width: '100%',
    '& .MuiChip-root': {
      color: theme.palette.common.black,
      borderRadius: 2,
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
    '& .MuiAutocomplete-root .MuiInput-root .MuiInput-input': {
      paddingTop: 0,
    },
  },
  placeholderText: {
    '& .MuiInputBase-input': {
      fontSize: '0.75rem',
      paddingTop: 5,
    },
  },
  lineBreak: {
    width: '100%',
    borderColor: '#DEDEDE',
    borderBottomWidth: 2,
    marginBottom: 20,
  },
  checkBoxContainer: {
    display: 'flex',
    position: 'absolute',
    bottom: 4,
    left: -2,
    '& .MuiCheckbox-root': {
      padding: 0,
      color: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
  checkboxFieldBox: {
    width: '100%',
    position: 'relative',
  },
  sponsorCheckbox: {
    marginLeft: 10,
    fontSize: 13,
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
    '& .MuiTypography-root': {
      fontSize: '12px',
      fontWeight: '400',
      textAlign: 'start',
      marginTop: '3px',
      marginLeft: 2,
      fontFamily: theme.typography.fontFamily,
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'left',
    gap: '5px',
    '& .MuiIconButton-root': {
      paddingTop: 0,
      '&:hover': {
        backgroundColor: 'initial',
      },
    },
  },
  disableDeleteBtn: {
    color: theme.palette.text.disabled,
  },
  deleteBtn: {
    cursor: 'pointer',
    color: '#FF5151',
  },
  mfnBox: {
    position: 'absolute',
    bottom: -20,
    left: -2,
    paddingRight: 12,
    border: '1px solid' + theme.palette.primary.main,
    '& .MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 600,
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.text.disabled,
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.text.disabled,
    },
    '& .Mui-checked': {
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  switchContainer: {
    position: 'relative',
    marginBottom: 20,
  },
  // AI monitioring
  accordABox: {
    marginBottom: '20px !important',
    marginTop: 20,
    borderRadius: 2,
    padding: '0',
    boxShadow: theme.shadows[0],
    '&::before': {
      display: 'none',
      position: 'static',
    },
    // '&:active': {
    //   border: '0.50px #FF8010 solid',
    //   // backgroundColor: '#e0e0e0',
    //   borderRadius: '6px 6px 0 0 !important',
    // },
    '& .MuiAccordionSummary-root': {
      padding: '10px 10px 0px 10px',
      border: '1px #1EA59A solid',
      // boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.primary.dark,
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48,
      border: '0.50px #FF8010 solid',
      borderBottom: 'none !important',
      borderRadius: '6px 6px 0 0 !important',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'self-end',
      paddingBottom: '10px',
      paddingLeft: 7,
      flexDirection: 'column',
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
  },
  accordAiBox: {
    marginBottom: 20,
    borderRadius: 2,
    padding: '0',
    boxShadow: theme.shadows[0],
    '&::before': {
      display: 'none',
      position: 'static',
    },
    // '&:active': {
    //   border: '0.50px #FF8010 solid',
    //   // backgroundColor: '#e0e0e0',
    //   borderRadius: '6px 6px 0 0 !important',
    // },
    '& .MuiAccordionSummary-root': {
      padding: '10px 10px 0px 10px',
      //border: '1px #e5e5e5 solid',
      // boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.primary.dark,
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48,
      //border: '0.50px #FF8010 solid',
      borderBottom: 'none !important',
      borderRadius: '6px 6px 0 0 !important',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'self-end',
      paddingBottom: '10px',
      paddingLeft: 7,
      flexDirection: 'column',
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
    '& .MuiAccordionDetails-root': {
      border: 'none',
      boxShadow: 'none',
      padding: 0,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2), // Adjust the margin top if needed
  },
  button: {
    marginLeft: theme.spacing(1), // Adjust the gap between buttons
    marginRight: theme.spacing(1),
    border: `1.5px solid ${theme.palette.primary.main}`, // Adding border
    minWidth: 90, // Ensures minimum width is the same for both buttons
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main, // Change to main theme color
      color: theme.palette.primary.contrastText, // Change text color to complementary
    },
  },
  backButton: {
    marginLeft: theme.spacing(1), // Adjust the gap between buttons
    marginRight: theme.spacing(1),
    border: `1.5px solid ${theme.palette.grey[100]}`, // Adding border
    color: theme.palette.grey[100],
    minWidth: 90, // Ensures minimum width is the same for both buttons
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[100], // Change to main theme color
      color: theme.palette.primary.contrastText, // Change text color to complementary
    },
  },
  progressBarContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    justifyContent: 'center',
  },
  progressBar: {
    flexGrow: 1,
    // marginLeft: theme.spacing(2),
  },
  centerAligned: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
  },
  centerFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 20,
    // zIndex: 1,
  },
  groupContainer: {
    alignSelf: 'stretch',
    borderRadius: 9,
    backgroundColor: '#fbfbfb',
    border: '2px dashed #d5d5d5',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '27px 20px',
    gap: 10,
    maxWidth: '100%',
    fontSize: 16,
    fontWeight: 400,
    color: '#425466',
  },
  uploadImgContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '0px 77px',
  },
  uploadTextContainer: {
    position: 'relative',
    fontWeight: 700,
    // zIndex: 1,
    fontSize: '16px',
  },
  uploadInput: {
    textDecoration: 'underline',
    color: '#FF8010',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: '16px',
  },
  supportedFormats: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '0px 44px',
    color: '#8c98a5',
  },
  supportedFormatsText: {
    position: 'relative',
    fontWeight: 400,
    display: 'inline-block',
    minWidth: '106px',
    // zIndex: 1,
    fontSize: 12,
  },
  circularBarRoot: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  circularProgress: {
    position: 'absolute',
    animation: '$rotate 2s linear infinite',
    width: '80px !important',
    height: '80px !important',
    color: '#DEDEDE',
  },
  circularInverseProgress: {
    position: 'absolute',
    animation: '$rotateInverse 2s linear infinite',
    color: '#DEDEDE',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes rotateInverse': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(-720deg)',
    },
  },
  uploadText: {
    fontWeight: 600,
    fontSize: 12,
    color: '#0A6DF7',
  },
  confirmButtom: {
    marginLeft: theme.spacing(1), // Adjust the gap between buttons
    marginRight: theme.spacing(1),
    border: `1.5px solid ${theme.palette.primary.main}`, // Adding border
    minWidth: 90, // Ensures minimum width is the same for both buttons
    padding: `${theme.spacing(0.3)} ${theme.spacing(2)}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main, // Change to main theme color
      color: theme.palette.primary.contrastText, // Change text color to complementary
    },
  },
  reUploadButtom: {
    marginLeft: theme.spacing(1), // Adjust the gap between buttons
    marginRight: theme.spacing(1),
    border: `1.5px solid ${theme.palette.grey[100]}`, // Adding border
    color: theme.palette.grey[100],
    minWidth: 90, // Ensures minimum width is the same for both buttons
    padding: `${theme.spacing(0.3)} ${theme.spacing(2)}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[100], // Change to main theme color
      color: theme.palette.primary.contrastText, // Change text color to complementary
    },
  },
}));
