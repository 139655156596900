import {
  takeEvery,
  put,
  call,
  all,
  fork,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { get, isEmpty } from 'lodash';
import { Auth } from 'aws-amplify';
import moment from 'moment';

import { signUp } from 'services';
import { RootState } from 'redux-modules/Store/RootState';
import { investorSignUp } from 'InvestorServices';
import {
  containsEncodedURIComponents,
  errorMessageHandler,
  getCognitoUserAttributes,
  getPortalName,
  handlePortalRedirection,
} from 'common/utils/helpers';
import {
  ISagaAction,
  ISetPassword,
  ISignInModal,
  ISignUpModal,
  IVerifyUser,
  IUsername,
  IForgotPassword,
  ISignInVerifyCode,
  ISignUpInvestorModal,
} from 'common/types/common';
import history from 'common/utils/history';
import { emptyVcFirmState } from 'redux-modules/VCFirm/Actions';
import { emptyFundsState } from 'redux-modules/Funds/Actions';
import { emptyInvestorFundsState } from 'redux-modules/InvestorFunds/Actions';
import { emptyInvestorState } from 'redux-modules/Investor/Actions';
import { emptyDealsState } from 'redux-modules/Deals/Actions';
import { emptyInvestorDealState } from 'redux-modules/InvestorDeals/Actions';
import { logAmpEvent, resetAmp } from 'config/amplitude';

import {
  USER_LOGIN_LOADING,
  USER_LOGIN,
  USER_LOGIN_COMPLETE,
  USER_SET_PASSWORD,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERROR,
  USER_LOGIN_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  USER_CONFIRM_PASSWORD_ERROR,
  USER_CONFIRM_PASSWORD,
  USER_CONFIRM_PASSWORD_SUCCESS,
  USER_SET_PASSWORD_SUCCESS,
  USER_SET_PASSWORD_FAILURE,
  SIGN_OUT_ERROR,
  SIGN_OUT,
  logoutComplete,
  USER_LOGIN_VERIFICATION_PROCESS,
  USER_LOGIN_VERIFICATION,
  INVESTOR_SIGN_UP,
  INVESTOR_SIGN_UP_SUCCESS,
  INVESTOR_SIGN_UP_FAILURE,
  SIGN_OUT_LOADING,
} from './Actions';
type typesObj = {
  user: any;
  attributes: any;
  route?: string;
  challengeName: string;
};

const signIn = async (payload: ISignInModal) => {
  const { username, password } = payload;
  // const preCheck = await loginPreCheck({
  //   email: username,
  //   portal: getPortalName(),
  // });
  // if (get(preCheck, 'MSG_CODE') === 'ROLE_NOT_EXIST') {
  //   throw new Error('User does not exist');
  // }
  return Auth.signIn(username, password)
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const signInVerifyCode = async (payload: ISignInVerifyCode) => {
  const { user, code } = payload;
  return Auth.sendCustomChallengeAnswer(user, code)
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const signUpAPI = async (payload: ISignUpModal) =>
  signUp(payload)
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const investorSignUpAPI = async (payload: ISignUpModal) =>
  investorSignUp(payload)
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const verifyUser = async (payload: IVerifyUser) =>
  Auth.confirmSignUp(payload.username, payload.code)
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const signOut = async () =>
  Auth.signOut()
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const forgotPassword = async (payload: IUsername) =>
  Auth.forgotPassword(payload.username, {
    portal: getPortalName(),
  })
    .then((data) => data)
    .catch((err) => Promise.reject(err));

const confirmPassword = async (payload: IForgotPassword) => {
  const { username, password, code } = payload;
  return Auth.forgotPasswordSubmit(username, code, password)
    .then((data) => data)
    .catch((err) => Promise.reject(err));
};

const newPasswordRequired = async (payload: ISetPassword) => {
  const { newPassword, username, password } = payload;
  return Auth.signIn(username, password)
    .then((res) => {
      return Auth.completeNewPassword(res, newPassword)
        .then((res) => res)
        .catch((err) => Promise.reject(err));
    })
    .catch((err) => Promise.reject(err));
};

function* loginUser(data: any) {
  const { payload } = data;
  try {
    const search = history.location.search;
    const redirectUrl: any = new URLSearchParams(search).get('redirecturl');
    const source: any = new URLSearchParams(search).get('source');
    yield put({ type: USER_LOGIN_LOADING });
    const response: typesObj = yield call<any>(signIn, payload);
    const { challengeName } = response;
    if (challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put({ type: USER_LOGIN_ERROR });
      history.push(`/reset-password/${payload.username}`);
    } else if (challengeName === 'CUSTOM_CHALLENGE') {
      yield put({ type: USER_LOGIN_VERIFICATION, payload: response });
    } else {
      const userObj = getCognitoUserAttributes(response.attributes);

      const {
        email,
        email_verified,
        name,
        sub: cognitoSubId,
      } = get(response, 'attributes') || {};

      logAmpEvent(
        'Signin',
        {
          email,
          email_verified,
          name,
          sub: cognitoSubId,
          role: get(response, 'attributes.custom:role'),
          invited: get(response, 'attributes.custom:invited'),
          companyName: get(response, 'attributes.custom:company_name'),
          accelerator: get(response, 'attributes.custom:accelerator'),
        },
        { createdAt: moment().format('MMMM d, YYYY h:mma') },
        email,
      );

      yield put({
        type: USER_LOGIN_COMPLETE,
        payload: { ...userObj },
      });
      if (redirectUrl) {
        history.push(
          `/navigation?redirecturl=${
            containsEncodedURIComponents(redirectUrl)
              ? redirectUrl
              : encodeURIComponent(redirectUrl)
          }`,
        );
      } else {
        if (source?.startsWith('staticPage')) {
          history.push(`/join-options?source=${source}`);
        } else {
          history.push('/navigation');
        }
      }
    }
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: USER_LOGIN_ERROR, payload: message });
  }
}

function* loginVerifyCodeProcess(data: any) {
  const { payload } = data;
  try {
    const search = window.location.search;
    const redirectUrl: any = new URLSearchParams(search).get('redirecturl');

    yield put({ type: USER_LOGIN_LOADING });
    const response: typesObj = yield call<any>(signInVerifyCode, payload);
    if (!isEmpty(response.attributes)) {
      const userObj = getCognitoUserAttributes(response.attributes);
      const {
        email,
        email_verified,
        name,
        sub: cognitoSubId,
      } = get(response, 'attributes') || {};
      console.log(userObj); //eslint-disable-line
      logAmpEvent(
        'Signin',
        {
          email,
          email_verified,
          name,
          sub: cognitoSubId,
          role: get(response, 'attributes.custom:role'),
          invited: get(response, 'attributes.custom:invited'),
          companyName: get(response, 'attributes.custom:company_name'),
          accelerator: get(response, 'attributes.custom:accelerator'),
        },
        { createdAt: moment().format('MMMM d, YYYY h:mma') },
        email,
      );
      yield put({
        type: USER_LOGIN_COMPLETE,
        payload: { ...userObj },
      });

      if (redirectUrl) {
        history.push(
          `/navigation?redirecturl=${
            containsEncodedURIComponents(redirectUrl)
              ? redirectUrl
              : encodeURIComponent(redirectUrl)
          }`,
        );
      } else {
        history.push('/navigation');
      }
    } else {
      yield put({
        type: USER_LOGIN_ERROR,
        payload:
          'The code you entered is not valid. Please check and try again.',
      });
    }
  } catch (err) {
    let message: string = errorMessageHandler(err);
    if (message.includes(`Invalid session for the user.`)) {
      message = 'Verification code expired, please login again.';
    }
    yield put({ type: USER_LOGIN_ERROR, payload: message });
  }
}

function* handleSignUp(data: ISagaAction<ISignUpModal>) {
  const { payload } = data;
  try {
    const { cb } = payload;
    yield call<any>(signUpAPI, payload);

    const ampPayload: any = payload;
    delete ampPayload.password;

    const search = history.location.search;
    const source = new URLSearchParams(search).get('source');
    logAmpEvent(
      source ? `${source}-Signup-User` : 'Signup-User',
      { ...ampPayload },
      { createdAt: moment().format('MMMM d, YYYY h:mma') },
      payload.email,
    );

    yield put({ type: SIGN_UP_SUCCESS, payload: payload.email });
    // history.push('/sign-up-success');
    if (cb) {
      cb();
    }
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: SIGN_UP_FAILURE, payload: message });
  }
}

function* handleInvestorSignUp(data: ISagaAction<ISignUpInvestorModal>) {
  const { payload } = data;
  try {
    yield call<any>(investorSignUpAPI, payload);
    yield put({ type: INVESTOR_SIGN_UP_SUCCESS, payload: payload.email });
    history.push('/investor/sign-up-success');
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: INVESTOR_SIGN_UP_FAILURE, payload: message });
  }
}

// Auto redirect as per domain history.push
function* handleVerifyUser(data: ISagaAction<IVerifyUser>) {
  const { payload } = data;
  try {
    yield call<any>(verifyUser, payload);
    yield put({ type: VERIFY_USER_SUCCESS, payload: 'Successfully Verified' });

    logAmpEvent(
      'Verify-User',
      {},
      { createdAt: moment().format('MMMM d, YYYY h:mma') },
    );

    setTimeout(() => handlePortalRedirection(), 3500);
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: VERIFY_USER_ERROR, payload: message });
  }
}

// Auto redirect as per domain history.push
function* handleForgotPassword(data: ISagaAction<IUsername>) {
  const { payload } = data;
  try {
    yield call<any>(forgotPassword, payload);
    yield put({ type: FORGOT_PASSWORD_SUCCESS, payload: payload.username });
    logAmpEvent(
      'Init-forgot-password',
      { email: payload.username },
      { createdAt: moment().format('MMMM d, YYYY h:mma') },
      payload.username,
    );

    handlePortalRedirection('/forgot-password-success');
  } catch (err) {
    const code: string = get(err, 'code');
    const message: string =
      code === 'UserNotFoundException'
        ? 'There is no account linked to the entered email. Please double-check and try again or register a new account with this email address.'
        : errorMessageHandler(err);
    yield put({ type: FORGOT_PASSWORD_ERROR, payload: message });
  }
}

// Auto redirect as per domain history.push
function* handleConfirmPassword(data: ISagaAction<IForgotPassword>) {
  const { payload } = data;
  try {
    yield call<any>(confirmPassword, payload);
    yield put({
      type: USER_CONFIRM_PASSWORD_SUCCESS,
      payload: 'Successfully Changed',
    });

    logAmpEvent('complete-forgot-password', {
      createdAt: moment().format('MMMM d, YYYY h:mma'),
    });

    setTimeout(() => handlePortalRedirection(), 3500);
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: USER_CONFIRM_PASSWORD_ERROR, payload: message });
  }
}

function* setPassword(data: ISagaAction<ISetPassword>) {
  const { payload } = data;
  try {
    const response: typesObj = yield call<any>(newPasswordRequired, payload);
    // eslint-disable-next-line no-console
    console.log('response------', response);
    yield put({
      type: USER_SET_PASSWORD_SUCCESS,
      payload: 'Successfully Changed',
    });
    setTimeout(() => {
      history.push('/navigation');
    }, 3500);
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: USER_SET_PASSWORD_FAILURE, payload: message });
  }
}

function* userSignOut() {
  try {
    yield put({ type: SIGN_OUT_LOADING });
    resetAmp();
    yield call(signOut);
    const {
      user: { investorId },
    } = yield select((state: RootState) => state.Auth);

    const item: any = localStorage.getItem(`${investorId}-ycDealUnlockText`);
    const selectedWorkspace: any = localStorage.getItem('selectedWorkspace');
    const defaultWorkspaceName: any = localStorage.getItem(
      'defaultWorkspaceName',
    );
    const defaultWorkspaceLogo: any = localStorage.getItem(
      'defaultWorkspaceLogo',
    );
    localStorage.clear();
    localStorage.setItem(`${investorId}-ycDealUnlockText`, item);
    yield put(logoutComplete());
    yield put(emptyVcFirmState());
    yield put(emptyFundsState());
    yield put(emptyDealsState());
    yield put(emptyInvestorDealState());
    yield put(emptyInvestorFundsState());
    yield put(emptyInvestorState());
    handlePortalRedirection();
    if (selectedWorkspace !== null) {
      localStorage.setItem('selectedWorkspace', selectedWorkspace);
    }
    if (defaultWorkspaceName !== null) {
      localStorage.setItem('defaultWorkspaceName', defaultWorkspaceName);
    }
    if (defaultWorkspaceLogo !== null) {
      localStorage.setItem('defaultWorkspaceLogo', defaultWorkspaceLogo);
    }
  } catch (err) {
    const message: string = get(err, 'message');
    yield put({ type: SIGN_OUT_ERROR, payload: message });
  }
}

function* loginSaga() {
  yield takeEvery(USER_LOGIN, loginUser);
}

function* loginVerifiyCodeSaga() {
  yield takeLatest(USER_LOGIN_VERIFICATION_PROCESS, loginVerifyCodeProcess);
}

function* signUpSaga() {
  yield takeEvery(SIGN_UP, handleSignUp);
}

function* investorSignUpSaga() {
  yield takeEvery(INVESTOR_SIGN_UP, handleInvestorSignUp);
}

function* verifyUserSaga() {
  yield takeEvery(VERIFY_USER, handleVerifyUser);
}

function* forgotPasswordSaga() {
  yield takeEvery(FORGOT_PASSWORD, handleForgotPassword);
}

function* confirmPasswordSaga() {
  yield takeEvery(USER_CONFIRM_PASSWORD, handleConfirmPassword);
}

function* setPasswordSaga() {
  yield takeEvery(USER_SET_PASSWORD, setPassword);
}

function* signOutSaga() {
  yield takeLatest(SIGN_OUT, userSignOut);
}

export default function* authSagas() {
  yield all([
    fork(loginSaga),
    fork(loginVerifiyCodeSaga),
    fork(signUpSaga),
    fork(verifyUserSaga),
    fork(forgotPasswordSaga),
    fork(confirmPasswordSaga),
    fork(setPasswordSaga),
    fork(signOutSaga),
    fork(investorSignUpSaga),
  ]);
}
