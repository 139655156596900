import React from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { get, findIndex } from 'lodash';

import history from 'common/utils/history';
import { RootState } from 'redux-modules/Store/RootState';
import { ROLE, PORTAL } from 'common/utils/constants';
import { getPortalName, getUserRole } from 'common/utils/helpers';
import {
  InvestorIcon,
  DealsIcon,
  InvestorReportingIcon,
  DocumentIcon,
  DashboardIcon,
  YardstickActiveIcon,
  DashboardActiveIcon,
  DealsActiveIcon,
  FundsActiveIcon,
  InvestorReportingActiveIcon,
  InvestmentUpdateActiveIcon,
  DocumentActiveIcon,
  CoinvestActiveIcon,
  FundsIcon,
  YardstickIcon,
  InvestorActiveIcon,
  CoinvestIcon,
  SubscriptionIcon,
  SubscriptionActiveIcon,
  ProfileIcon,
  ProfileActiveIcon,
  YCMenuIcon,
  YCMenuActiveIcon,
  AdminIcon,
  InvestmentUpdateIcon,
} from 'assets';
import { postLoginLogAmpEvent } from 'config/amplitude';

import styles from './styles';

const YardstickMenu = [
  {
    name: 'Yardstick',
    path: '/yardstick',
    key: ['yardstick', 'yardstick-startup'],
    activeIcon: YardstickActiveIcon,
    icon: YardstickIcon,
  },
  {
    name: 'Subscription Plan',
    path: '/subscription-v2',
    key: ['subscription-v2'],
    activeIcon: SubscriptionActiveIcon,
    icon: SubscriptionIcon,
  },
];

const InvestorAdminMenu = [
  {
    name: 'Dashboard',
    path: '/investor/dashboard',
    key: ['dashboard'],
  },
  {
    name: 'Co-invest',
    path: '/co-invest',
    key: ['co-invest'],
  },
  {
    name: 'Documents',
    path: '/investor-documents',
    key: ['investor-documents'],
  },
  {
    name: 'Funds',
    path: '/investor-funds',
    key: ['investor-funds'],
  },
  {
    name: 'Transactions',
    path: '/transactions',
    key: ['transactions'],
  },
  {
    name: 'Portfolio',
    path: '/portfolio',
    key: ['portfolio'],
  },
];

const NavLinks = ({ open }: any) => {
  const classes = styles();
  const pathName = useLocation().pathname;
  const params = useParams<Record<string, string>>();
  const [searchParams] = useSearchParams();
  const ycFilter = searchParams.get('filter') === 'YC';
  const investmentTab = searchParams.get('tab') === 'INVESTMENT_UPDATE';

  const {
    user: { subscribedTo, role },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { ycBatch } = useSelector(({ Global }: RootState) => Global);
  const { user: userInfo } = useSelector(({ Auth }: RootState) => Auth);
  const { vcFirm, workspace } = useSelector(({ VCFirm }: RootState) => VCFirm);
  const userRoles = getUserRole(role);
  const portal = getPortalName();

  const permissionInfo = get(workspace, 'data.roleDetail.allowedRoles') || {
    DEAL_PIPELINE: 'none',
    FUNDS: 'none',
    PORTFOLIO: 'none',
  };
  const [active, setActive] = React.useState('');
  // const [openDealMenu, setOpenDealMenu] = React.useState(false);
  const [otherMenu, setOtherMenu] = React.useState(false);
  const isVCAdmin =
    localStorage.getItem('selectedWorkspace') && portal === PORTAL.VC
      ? userRoles.includes(ROLE.VC_ADMIN)
        ? true
        : false
      : userRoles.includes(ROLE.VC_ADMIN) &&
        !userRoles.includes(ROLE.ANGEL_INVESTOR)
      ? true
      : false;
  const isAngelInvestor =
    localStorage.getItem('selectedWorkspace') && portal === PORTAL.VC
      ? false
      : userRoles.includes(ROLE.ANGEL_INVESTOR) && portal === PORTAL.VC
      ? true
      : false;
  userRoles.includes(ROLE.ANGEL_INVESTOR) && portal === PORTAL.VC;
  const isYardStick =
    userRoles.includes(ROLE.YARDSTICK_USER) &&
    portal === PORTAL.VC &&
    subscribedTo === 'YARDSTICK';

  const VCFirmMenu = [
    {
      name: 'Dashboard',
      path: '/dashboard',
      key: ['dashboard'],
      collapse: false,
      activeIcon: DashboardActiveIcon,
      icon: DashboardIcon,
    },
    {
      name: get(ycBatch, 'currentYCBatch'),
      path: '/deals?type=prospective&filter=YC',
      key: ['YC'],
      collapse: false,
      activeIcon: YCMenuActiveIcon,
      icon: YCMenuIcon,
    },
    {
      name: 'Deals',
      path:
        permissionInfo.DEAL_PIPELINE.toLowerCase() === 'none'
          ? '/deals?type=active'
          : '/deals?type=prospective',
      key: ['deals'],
      collapse: false,
      activeIcon: DealsActiveIcon,
      icon: DealsIcon,
    },
    {
      name: 'Investment Updates',
      path: '/deals?type=active&tab=INVESTMENT_UPDATE',
      key: ['investment-update'],
      collapse: false,
      activeIcon: InvestmentUpdateActiveIcon,
      icon: InvestmentUpdateIcon,
    },
    {
      name: 'Yardstick',
      path: '/yardstick',
      key: ['yardstick', 'yardstick-startup'],
      collapse: false,
      activeIcon: YardstickActiveIcon,
      icon: YardstickIcon,
    },
    {
      name: 'Co-invest',
      path: '/co-invest',
      key: ['co-invest', 'investor-deal-view'],
      collapse: false,
      activeIcon: CoinvestActiveIcon,
      icon: CoinvestIcon,
    },
    {
      name: 'ADMIN',
      path: '/expand',
      key: ['expand'],
      collapse: false,
      activeIcon: AdminIcon,
      icon: AdminIcon,
    },
    {
      name: 'Funds',
      path: '/funds',
      key: ['funds'],
      collapse: true,
      activeIcon: FundsActiveIcon,
      icon: FundsIcon,
    },
    {
      name: 'Investors',
      path: '/investors',
      key: ['investors', 'investor-view'],
      collapse: true,
      activeIcon: InvestorActiveIcon,
      icon: InvestorIcon,
    },
    {
      name: 'Investor Reporting',
      path: '/investment-value',
      key: ['investment-value'],
      collapse: true,
      activeIcon: InvestorReportingActiveIcon,
      icon: InvestorReportingIcon,
    },
    {
      name: 'Documents',
      path: '/documents',
      key: ['documents'],
      collapse: true,
      activeIcon: DocumentActiveIcon,
      icon: DocumentIcon,
    },
    {
      name: 'Investor Profile',
      path: '/vc-firm/profile',
      key: ['profile'],
      collapse: true,
      activeIcon: ProfileActiveIcon,
      icon: ProfileIcon,
    },
  ];

  const AngelInvestorMenu = [
    {
      name: 'Dashboard',
      path: '/dashboard',
      key: ['dashboard'],
      activeIcon: DashboardActiveIcon,
      icon: DashboardIcon,
    },
    {
      name: get(ycBatch, 'currentYCBatch'),
      path: '/deals?type=prospective&filter=YC',
      key: ['YC'],
      collapse: false,
      activeIcon: YCMenuActiveIcon,
      icon: YCMenuIcon,
    },
    {
      name: 'Deals',
      path: '/deals?type=prospective',
      key: ['deals'],
      activeIcon: DealsActiveIcon,
      icon: DealsIcon,
    },
    {
      name: 'Investment Updates',
      path: '/deals?type=active&tab=INVESTMENT_UPDATE',
      key: ['investment-update'],
      collapse: false,
      activeIcon: InvestmentUpdateActiveIcon,
      icon: InvestmentUpdateIcon,
    },
    {
      name: 'Yardstick',
      path: '/yardstick',
      key: ['yardstick', 'yardstick-startup'],
      activeIcon: YardstickActiveIcon,
      icon: YardstickIcon,
    },
    {
      name: 'Investor Profile',
      path: '/vc-firm/profile',
      key: ['profile'],
      activeIcon: ProfileActiveIcon,
      icon: ProfileIcon,
    },
  ];

  if (!get(vcFirm, 'interestedInYCStartup')) {
    // Remove YC Menu
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['YC'] }), 1);
    AngelInvestorMenu.splice(findIndex(AngelInvestorMenu, { key: ['YC'] }), 1);
  }
  if (
    permissionInfo.DEAL_PIPELINE.toLowerCase() == 'none' &&
    permissionInfo.PORTFOLIO.toLowerCase() === 'none'
  ) {
    // Remove Deals
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['YC'] }), 1);
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['deals'] }), 1);
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['investment-update'] }), 1);
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['yardstick'] }), 1);
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['co-invest'] }), 1);
    AngelInvestorMenu.splice(findIndex(AngelInvestorMenu, { key: ['YC'] }), 1);
    AngelInvestorMenu.splice(
      findIndex(AngelInvestorMenu, { key: ['deals'] }),
      1,
    );
    AngelInvestorMenu.splice(
      findIndex(VCFirmMenu, { key: ['investment-update'] }),
      1,
    );
    AngelInvestorMenu.splice(findIndex(VCFirmMenu, { key: ['yardstick'] }), 1);
    AngelInvestorMenu.splice(findIndex(VCFirmMenu, { key: ['co-invest'] }), 1);
  }

  if (
    permissionInfo.PORTFOLIO.toLowerCase() === 'none' &&
    permissionInfo.DEAL_PIPELINE.toLowerCase() !== 'none'
  ) {
    // Remove Deals
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['investment-update'] }), 1);
    AngelInvestorMenu.splice(
      findIndex(VCFirmMenu, { key: ['investment-update'] }),
      1,
    );
  }

  if (
    permissionInfo.DEAL_PIPELINE.toLowerCase() === 'none' &&
    permissionInfo.PORTFOLIO.toLowerCase() !== 'none'
  ) {
    // Remove Deals
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['YC'] }), 1);
    AngelInvestorMenu.splice(findIndex(VCFirmMenu, { key: ['YC'] }), 1);
  }

  if (permissionInfo.FUNDS.toLowerCase() === 'none') {
    // Remove Deals
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['expand'] }), 1);
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['funds'] }), 1);
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['investors'] }), 1);
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['documents'] }), 1);
    VCFirmMenu.splice(findIndex(VCFirmMenu, { key: ['profile'] }), 1);
    AngelInvestorMenu.splice(
      findIndex(AngelInvestorMenu, { key: ['expand'] }),
      1,
    );
    AngelInvestorMenu.splice(findIndex(VCFirmMenu, { key: ['funds'] }), 1);
    AngelInvestorMenu.splice(findIndex(VCFirmMenu, { key: ['investors'] }), 1);
    AngelInvestorMenu.splice(findIndex(VCFirmMenu, { key: ['documents'] }), 1);
    AngelInvestorMenu.splice(findIndex(VCFirmMenu, { key: ['profile'] }), 1);
  }
  // const handleDealClick = () => {
  //   setOpenDealMenu(!openDealMenu);
  // };

  const handleExpandClick = () => {
    setOtherMenu(!otherMenu);
  };

  const handleAmpEvent = (path: string) => {
    // const currentPath = window.location.pathname;
    let evenName = '';
    switch (path) {
      case '/funds':
        evenName = `Funds-LeftMenu-Click`;
        break;
      case '/investors':
        evenName = `Investors-LeftMenu-Click`;
        break;
      case 'active':
        evenName = `ActiveDeal-LeftMenu-Click`;
        break;
      case 'prospective':
        evenName = `ProspectiveDeal-LeftMenu-Click`;
        break;
      case 'exited':
        evenName = `ExitedDeal-LeftMenu-Click`;
        break;
      case 'rejected':
        evenName = `RejectedDeal-LeftMenu-Click`;
        break;
    }
    if (evenName) postLoginLogAmpEvent(evenName, userInfo);
  };

  const getListItem = (item: any) => {
    return (
      <>
        {!item.collapse && (
          <>
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {
                // if (item.key.includes('deals')) {
                //   handleDealClick();
                // }
                if (item.key.includes('expand')) {
                  handleExpandClick();
                } else {
                  handleAmpEvent(item.path);
                  history.push(item.path);
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                  display: item.key.includes('expand') && open ? 'none' : '',
                }}
              >
                {item.key.includes('YC') ? (
                  <img
                    src={ycFilter ? item.activeIcon : item.icon}
                    alt={ycFilter ? item.activeIcon : item.icon}
                    className={classes.menuItemIcon}
                  />
                ) : item.key.includes('investment-update') ? (
                  <img
                    src={investmentTab ? item.activeIcon : item.icon}
                    alt={investmentTab ? item.activeIcon : item.icon}
                    className={classes.menuItemIcon}
                  />
                ) : !item.key.includes('expand') ? (
                  <img
                    src={
                      item.key.includes(active) && !ycFilter && !investmentTab
                        ? item.activeIcon
                        : item.icon
                    }
                    alt={
                      item.key.includes(active) && !ycFilter && !investmentTab
                        ? item.activeIcon
                        : item.icon
                    }
                    className={classes.menuItemIcon}
                  />
                ) : item.key.includes('expand') && !open ? (
                  <img
                    src={
                      item.key.includes(active) && !ycFilter && !investmentTab
                        ? item.activeIcon
                        : item.icon
                    }
                    alt={
                      item.key.includes(active) && !ycFilter && !investmentTab
                        ? item.activeIcon
                        : item.icon
                    }
                    className={classes.menuItemIcon}
                  />
                ) : (
                  ''
                )}
              </ListItemIcon>
              {item.key.includes('YC') ? (
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: open ? 1 : 0 }}
                  classes={{
                    primary: ycFilter
                      ? classes.activeMenuItem
                      : classes.menuItem,
                  }}
                />
              ) : item.key.includes('investment-update') ? (
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: open ? 1 : 0 }}
                  classes={{
                    primary: investmentTab
                      ? classes.activeMenuItem
                      : classes.menuItem,
                  }}
                />
              ) : (
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: open ? 1 : 0 }}
                  classes={{
                    primary:
                      item.key.includes(active) && !ycFilter && !investmentTab
                        ? classes.activeMenuItem
                        : item.key.includes('expand') && open
                        ? classes.investing
                        : classes.menuItem,
                  }}
                />
              )}
              {/* {item.key.includes('deals') &&
                open &&
                (openDealMenu ? <ExpandLess /> : <ExpandMore />)} */}
              {item.key.includes('expand') &&
                open &&
                (otherMenu ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            {open &&
              item.key.includes('dashboard') &&
              (permissionInfo.DEAL_PIPELINE !== 'none' ||
                permissionInfo.PORTFOLIO.toLowerCase() !== 'none') && (
                <ListItemButton
                  sx={{
                    minHeight: 40,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  className={classes.listBtn}
                >
                  <ListItemText
                    primary={'INVESTING'}
                    sx={{ opacity: open ? 1 : 0 }}
                    classes={{
                      primary: classes.investing,
                    }}
                  />
                </ListItemButton>
              )}
          </>
        )}
        {/* {item.key.includes('deals') && (
          <Collapse in={openDealMenu && open} timeout="auto" unmountOnExit>
            {DealsMenu.map((item: any, index: number) => (
              <ListItem
                key={`subItemDeals_${index}`}
                disablePadding
                sx={{ display: 'block', pl: 5, position: 'relative' }}
              >
                <ListItemButton
                  sx={{ pl: 2 }}
                  onClick={() => {
                    handleAmpEvent(item.path);
                    history.push({
                      pathname: '/deals',
                      search: `?type=${item.path}`,
                    });
                  }}
                >
                  <ListItemText
                    primary={item.name}
                    classes={{
                      primary: item.key.includes(active)
                        ? classes.activeMenuItem
                        : classes.menuItem,
                    }}
                  />
                  {item.path.includes('prospective') && (
                    <Box className={classes.aiPoweredBox}>
                      <WrappedTypography className={classes.ycText}>
                        {get(ycBatch, 'currentYCBatch')}
                      </WrappedTypography>
                    </Box>
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </Collapse>
        )} */}
        {item.collapse && (
          <Collapse in={otherMenu} timeout="auto" unmountOnExit>
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {
                handleAmpEvent(item.path);
                history.push(item.path);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={item.key.includes(active) ? item.activeIcon : item.icon}
                  alt={item.key.includes(active) ? item.activeIcon : item.icon}
                  className={classes.menuItemIcon}
                />
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                sx={{ opacity: open ? 1 : 0 }}
                classes={{
                  primary: item.key.includes(active)
                    ? classes.activeMenuItem
                    : classes.menuItem,
                }}
              />
            </ListItemButton>
          </Collapse>
        )}
      </>
    );
  };

  React.useEffect(() => {
    if (pathName) {
      const basePathName = Object.values(params).reduce(
        (path: any, param: any) => path.replace('/' + param, ''),
        pathName,
      );
      const activeItem =
        basePathName.split('/')[basePathName.split('/').length - 1];
      setActive(activeItem);
    }
  }, [pathName]);

  return (
    <Box className={classes.navBarLinks}>
      {isVCAdmin &&
        VCFirmMenu.map((item: any, index: number) => (
          <ListItem
            key={`item_${index}`}
            disablePadding
            className={classes.listItem}
          >
            {getListItem(item)}
          </ListItem>
        ))}
      {isAngelInvestor &&
        AngelInvestorMenu.map((item: any, index: number) => (
          <ListItem
            key={`item_${index}`}
            disablePadding
            className={classes.listItem}
          >
            {getListItem(item)}
          </ListItem>
        ))}
      {isYardStick &&
        YardstickMenu.map((item: any, index: number) => (
          <ListItem
            key={`item_${index}`}
            disablePadding
            className={classes.listItem}
          >
            {getListItem(item)}
          </ListItem>
        ))}
      {!isVCAdmin &&
        !isYardStick &&
        !isAngelInvestor &&
        portal === PORTAL.VCI &&
        InvestorAdminMenu.map((item: any, index: number) => (
          <ListItem
            key={`item_${index}`}
            disablePadding
            className={classes.listItem}
          >
            {getListItem(item)}
          </ListItem>
        ))}
    </Box>
  );
};

export default NavLinks;
